<div class="negotiation-intermediate">
  <div *ngIf="showTxtMsg()">{{ negotiation.messageText }}</div>
  <div *ngIf="negotiationWasBidden(); else nonBidden" class="bidden-msg">
    You have already submitted your selection for this negotiaiton.
  </div>
  <ng-template #nonBidden>
    <div
      *ngFor="let option of slotOptions"
      class="negotiation-option-container"
      (click)="!negotiationWasBidden() && slotSelectAction(option)"
    >
      <div class="option-description">
        <div class="option-title">{{ option.title }}</div>
        <div>
          {{ option.description }}
        </div>
      </div>
      <mat-icon>chevron_right</mat-icon>
    </div>

    <div
      *ngFor="let option of negotiationOptions"
      class="negotiation-option-container"
      (click)="!negotiationWasBidden() && selectAction(option)"
    >
      <div class="option-description">
        <div class="option-title">{{ option.title }}</div>
        <div>
          {{ option.description }}
        </div>
      </div>
      <mat-icon>chevron_right</mat-icon>
    </div>
  </ng-template>
</div>
