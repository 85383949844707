import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateContentExpanded } from 'app/model/whatsapp-option-template-content-expanded';
import { WhatsappOptionTemplateContentStatus } from 'app/model/whatsapp-option-template-content-status';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class StudioApiService {
  private whatsappOptionTemplateUrl: string;
  private whatsappOptionTemplateContentUrl: string;

  constructor(private httpClient: HttpClient) {
    this.whatsappOptionTemplateUrl = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/WhatsappOptionTemplate';
    this.whatsappOptionTemplateContentUrl = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/WhatsappOptionTemplateContent';
  }

  getWhatsappOptionTemplatesList(): Observable<WhatsappOptionTemplate[]> {
    return this.httpClient.get<WhatsappOptionTemplate[]>(this.whatsappOptionTemplateUrl);
  }

  getPendingWhatsappOptionTemplateContentsList(): Observable<WhatsappOptionTemplateContentExpanded[]> {
    return this.httpClient.get<WhatsappOptionTemplateContentExpanded[]>(this.whatsappOptionTemplateContentUrl + '/pendingreview');
  }

  createWhatsappOptionTemplateForReview(whatsappOptionTemplate: WhatsappOptionTemplate): Observable<number> {
    return this.httpClient.post<number>(this.whatsappOptionTemplateUrl + '/create/sendforreview', whatsappOptionTemplate);
  }

  reviewWhatsappOptionTemplate(
    whatsappOptionTemplateContentId: number,
    status: WhatsappOptionTemplateContentStatus,
    contentSid: string
  ): Observable<void> {
    return this.httpClient.post<void>(this.whatsappOptionTemplateContentUrl + '/admin/review',
      { Id: whatsappOptionTemplateContentId, status: status, contentSid: contentSid });
  }

}
