import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { HtmlMessageDialogComponent, HtmlMessageDialogResponse } from 'app/components/common/html-message-dialog/html-message-dialog.component';
import { NotificationsService } from 'app/services/notifications.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {

  constructor(
    public auth: AuthService,
    public router: Router,
    public dialog: MatDialog,
    public uiService: UIService,
    public snackBar: MatSnackBar,
    public notificationService: NotificationsService
  ) {}

  public navigateToDocumentation() {
    this.router.navigate(['documentation']);
  }

  public showContactPopup() {
    this.dialog.open(
      HtmlMessageDialogComponent,
      {
        data: { 
          title: 'Support request',
          message: 'Please state your support request here',
          acceptButtonTitle: 'Send',
          cancelButtonTitle: 'Cancel'
        }
    })
    .afterClosed()
    .subscribe((response: HtmlMessageDialogResponse) => {
      if (response.confirmed) {
        this.uiService.toggleGlobalSpinner(true);
        this.notificationService.sendSupportEmail(response.message)
        .subscribe(result => {
          this.uiService.toggleGlobalSpinner(false);
          this.showMessage('Success', 'Your request was sent successfully.');
        }, err => {
          this.handleError(err);
        });
      }
    });
  }

  private handleError(err) {
    console.error(err);
    this.uiService.toggleGlobalSpinner(false);
    this.showMessage('Error', 'An error ocurred while attempting to send your request. Please try again latter.');
  }

  private showMessage(title, message) {
    this.dialog.open(AlertComponent, {data: message});
  }

  ngOnInit() {}
}
