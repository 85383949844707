import { Injectable } from '@angular/core';
import { Negotiation, NegotiationDto, PaginatedNegotiationsDataRequest } from 'app/model/negotiation';
import { NegotiationFlowAction } from 'app/model/negotiation-flow-action';
import { PaginatedDataResponse, PaginationUtils } from 'app/model/pagination';
import { FilterPaginatorComponent } from 'core/components/filter-paginator/filter-paginator.component';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/core/services/user.service';
import { NegotiationsApiService } from './negotiations-api.service';
import { handleCachedResponse } from './service-utils';
import { UIService } from 'core/services/ui.service';

@Injectable()
export class NegotiationsService {
  public negotiations = new BehaviorSubject<Negotiation[]>([]);
  public paginatedNegotiations = new BehaviorSubject<PaginatedDataResponse<Negotiation>>(null);
  public selectedSlot: number;
  public ownerFiltered = false;
  public $negotiationFlowActions = new BehaviorSubject<NegotiationFlowAction[]>([]);
  public $currentSlotInfo = new BehaviorSubject<any>(null);

  constructor(
    private userService: UserService,
    private negotiationApiService: NegotiationsApiService,
    private uiService: UIService
  ) {
    this.userService.currentUserAsync().subscribe((currentUser) => {
      this.getData();
    });
  }

  /**
   * Returns a list of negotiations grouped by slot
   */
  public getGroupedNegotiations() {
    const groups = [];
    this.negotiations.value.forEach((n) => {
      if (n.slotId) {
        const group = groups.find((s) => s.slotId === n.slotId);
        if (group) {
          group.negotiations.push(n);
        } else {
          groups.push({
            slotId: n.slotId,
            negotiations: [n],
          });
        }
      }
    });
    return groups;
  }

  getData(acceptCachedResponse: boolean = false): Observable<NegotiationDto[]> {
    const observable: Observable<NegotiationDto[]> = this.ownerFiltered
      ? this.negotiationApiService.getMyNegotiationsList(this.selectedSlot, acceptCachedResponse)
      : this.negotiationApiService.getNegotiationsList(this.selectedSlot, acceptCachedResponse);

    observable.pipe(handleCachedResponse()).subscribe({
      next: (res: NegotiationDto[]) => {
        this.negotiations.next(res.map((x) => new Negotiation(x)));
      },
      error: (err) => {
        console.error(err);
        this.paginatedNegotiations.next(null);
      },
    });
    return observable;
  }

  getPaginatedData(filter: PaginatedNegotiationsDataRequest, acceptCachedResponse: boolean = false) {
    filter.pageSize = filter.pageSize ? filter.pageSize : FilterPaginatorComponent.DEFAULT_PAGE_SIZE;
    filter.pageNumber = filter.pageNumber ? filter.pageNumber : 1;
    this.uiService.toggleGlobalSpinner(true);
    this.negotiationApiService
      .getPaginatedNegotiationsList(filter, acceptCachedResponse)
      .pipe(handleCachedResponse())
      .subscribe({
        next: (res: PaginatedDataResponse<NegotiationDto>) => {
          const data = PaginationUtils.CastPaginatedResponse(res, (x) => new Negotiation(x));
          this.$currentSlotInfo.next({
            slotId: this.selectedSlot,
            hasSchedule: data.hasSchedule,
          });
          this.paginatedNegotiations.next(data);
        },
        error: (err) => {
          console.error(err);
          this.paginatedNegotiations.next(null);
          this.$currentSlotInfo.next(null);
        },
        complete: () => {
          this.uiService.toggleGlobalSpinner(false);
        },
      });
  }

  public saveNegotiation(negotiation: Negotiation): Observable<any> {
    return this.negotiationApiService.saveNegotiation(negotiation);
  }

  public updateNegotiation(negotiation: Negotiation): Observable<any> {
    return this.negotiationApiService.updateNegotiation(negotiation);
  }

  public deleteNegotiation(negotiationId: number): Observable<any> {
    const negotiationIds = new Array();
    negotiationIds.push(negotiationId);
    return this.negotiationApiService.deleteNegotiations(negotiationIds);
  }

  public deleteNegotiations(negotiationIds: Array<number>): Observable<any> {
    return this.negotiationApiService.deleteNegotiations(negotiationIds);
  }

  public startNegotiation(negotiationId: number, flowId: number): Observable<any> {
    return this.negotiationApiService.startNegotiation(negotiationId, flowId);
  }

  public cancelNegotiation(negotiationId: number): Observable<any> {
    return this.negotiationApiService.cancelNegotiation(negotiationId);
  }

  public pauseNegotiation(negotiationId: number): Observable<any> {
    return this.negotiationApiService.pauseNegotiation(negotiationId);
  }

  public restartNegotiation(negotiationId: number): Observable<any> {
    return this.negotiationApiService.restartNegotiation(negotiationId);
  }

  public addNegotiationToSlot(negotiationId: number, slotId: number, flowId: number): Observable<any> {
    return this.negotiationApiService.addNegotiationToSlot(negotiationId, slotId, flowId);
  }

  public removeNegotiationFromSlot(negotiationId: number): Observable<any> {
    return this.negotiationApiService.removeNegotiationFromSlot(negotiationId);
  }

  public removeManyNegotiations(negotiationIds: Array<number>): Observable<any> {
    return this.negotiationApiService.removeManyNegotiations(negotiationIds);
  }

  public setFavorite(negotiationId: number, favorite: boolean): Observable<any> {
    return this.negotiationApiService.setFavorite(negotiationId, favorite);
  }

  public setSlot(slotId: number) {
    this.selectedSlot = slotId;
  }
  public getSlot() {
    return this.selectedSlot;
  }

  public setOwnerFiltered(ownerFiltered: boolean) {
    this.ownerFiltered = ownerFiltered;
  }
  public getOwnerFiltered(): boolean {
    return this.ownerFiltered;
  }

  uploadExcel(docType, formData): Observable<any> {
    return this.negotiationApiService.postUploadNegotiations(docType, formData);
  }

  setNegotiationFlowActions(flowActions: NegotiationFlowAction[]) {
    this.$negotiationFlowActions.next(flowActions);
  }

  getNegotiationFlowActions(flowId) {
    this.negotiationApiService.getNegotiationFlowActions(flowId).subscribe((actions: NegotiationFlowAction[]) => {
      this.$negotiationFlowActions.next(actions);
      console.log(actions);
    });
  }

  getFlowActionsByNegotiation(negotiationId, flowId) {
    this.negotiationApiService
      .getFlowActionsByNegotiation(negotiationId)
      .subscribe((actions: NegotiationFlowAction[]) => {
        if (!actions || actions.length === 0) {
          this.getNegotiationFlowActions(flowId);
        } else {
          this.$negotiationFlowActions.next(actions);
        }
      });
  }
}
