import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentTemplateSmallPreviewComponent } from 'app/components/deal/studio/content-template';
import { CreateContentTemplateComponent } from 'app/components/deal/studio/content-template/main/create-content-template.component';
import { ContentTemplatePreviewComponent } from 'app/components/deal/studio/content-template/preview/content-template-preview.component';
import { FlowActionWpTemplateApiService } from 'app/components/deal/studio/content-template/services/flow-action-wp-template-api.service';
import { WhatsAppContentTemplate } from 'app/model/content-template';
import { FlowActionWhatsapp } from 'app/model/flow-action-whatsapp';
import { ContentTemplateApiService, ContentTemplateService } from 'app/services/content-template';
import { LanguageService } from 'app/services/language.service';

@Component({
  selector: 'app-content-template-details',
  templateUrl: './content-template-details.component.html',
  styleUrls: ['./content-template-details.component.css'],
})
export class ContentTemplateDetailsComponent implements OnChanges {
  @Input() node: FlowActionWhatsapp;
  templateId: number;
  description: string;
  languages: string[];
  language: string;
  public templatesMap: Array<ITemplateMap> = [];
  public selectedTemplate: WhatsAppContentTemplate;
  public availableTemplates: WhatsAppContentTemplate[] = [];
  private RELOAD_SECONDS = 5;
  private TOAST_SECONDS = 4;
  
  constructor(
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private languageService: LanguageService,
    private contentTemplateService: ContentTemplateService,
    private flowActionWpTemplateApiService: FlowActionWpTemplateApiService
  ) {
    this.flowActionWpTemplateApiService.templatesMap.subscribe(templates => {
      this.templatesMap = templates;
    });
    this.contentTemplateService.availableTemplates.subscribe(value => {
      this.availableTemplates = value;
      this.checkForReload();
    });
    this.loadLanguages();
  }

  checkForReload() {
    let needsReloading = this.availableTemplates.find(x => x.status === 'received');
    if(needsReloading) {
      setTimeout(() => {
        this.contentTemplateService.realodAsync();
      }, 1000 * this.RELOAD_SECONDS);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  private init() {
    if(this.node?.flowActionId) {
      this.flowActionWpTemplateApiService.loadTemplateMapping(this.node.flowActionId);
    }
    this.description = this.node.description;
  }

  public onCreateNewTemplate() {
    const templateDialog = this.dialog.open(CreateContentTemplateComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
    });
    templateDialog.afterClosed().subscribe((templateResponse: any) => {
      if (templateResponse) {
        this.init();
        this.templateId = templateResponse.id;
        // this.hasChanged = true;
      }
    });
  }

  public onTemplateSelect() {
    this.selectedTemplate = this.availableTemplates.find(x => x.id === this.templateId);
  }

  public showWarning(template: WhatsAppContentTemplate): boolean {
    let warningStatuses = ['pending', 'received', 'rejected'];
    let currentStatus = template?.status;
    return warningStatuses.includes(currentStatus);
  }
  
  private async loadLanguages() {
    this.languages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.language = this.languages[0];
  }

  public onLanguageSelect() {

  }

  public onAddTemplateLang() {
    this.templatesMap.push({whatsAppContentTemplateId: null, language: null});
  }

  public onRemoveTemplateLang(template) {
    let index = this.templatesMap.indexOf(template);
    if(index !== -1) {
      this.templatesMap.splice(index, 1);
    }
  }

  public onShowTemplatePreview(template: ITemplateMap) {
    let actualTemplate = this.availableTemplates.find(x => x.id === template.whatsAppContentTemplateId)
    const templateDialog = this.dialog.open(ContentTemplateSmallPreviewComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
      data: actualTemplate
    });
    templateDialog.afterClosed().subscribe();
  }

  public onSave() {
    try {
      this.validateNullTemplates();
      this.validateNullLanguages();
      this.validateRepeatedLanguages();

      this.flowActionWpTemplateApiService
      .saveTemplateMapping(this.node?.flowActionId, this.templatesMap)
      .subscribe(result => {
        this.showToast('Templates saved succesfully');
        this.flowActionWpTemplateApiService.loadTemplateMapping(this.node.flowActionId);
      }, err => {this.showToast(`ERROR: ${err}`);});
    } catch (err) {
      this.showToast(`ERROR: ${err}`);
    }
  }

  private showToast(msg: string): void {
    this.snackBar.open(msg, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 1000 * this.TOAST_SECONDS,
    });
  }

  private validateNullTemplates(): void {
    if(this.templatesMap.find(x => x.whatsAppContentTemplateId === null)) {
      throw `ERROR: Mapped templates cannot be null`;
    }
  }

  private validateNullLanguages(): void {
    if(this.templatesMap.find(x => x.language === null)) {
      throw `ERROR: Mapped languages cannot be null`;
    }
  }

  private validateRepeatedLanguages(): void {
    let mappedLanguages: string[] = this.templatesMap.map(x => x.language);
    let foundRepeated = new Set(mappedLanguages).size < mappedLanguages.length;
    if(foundRepeated) {
      throw `ERROR: Mapped languages cannot be repeated`;
    }
  }
}

export class ITemplateMap {
  language: string;
  whatsAppContentTemplateId: number;
}
