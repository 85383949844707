<div #droppableCanvas class="dropable-canvas" dropzone="copy" (dragover)="allowDrop($event)" (drop)="onDrop($event)">
  <app-canvas-node
    *ngFor="let node of nodes"
    [node]="node"
    [selected]="node.flowActionId === selected"
    [isConnected]="connectedNodes[node.flowActionId]"
    (nodeSelected)="nodeSelected($event)"
    (addLink)="addLink($event)"
    (deleteNode)="deleteNode($event)"
  ></app-canvas-node>
  <app-canvas-arrow
    *ngFor="let edge of edges"
    [edge]="edge"
    [selected]="edge.edgeId === selected"
    (edgeSelected)="edgeSelected($event)"
    (deleteEdge)="edgeDeleted($event)"
  ></app-canvas-arrow>
  <app-canvas-node-details></app-canvas-node-details>
</div>
