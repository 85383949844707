import { FlowAction } from './flow-action';

export class ActionMessage {
  index = 0;
  language: string;
  content: string;
  pollyVoice: string;
  flowActionMessageId: number;

  constructor(language: string, content: string, pollyVoice: string) {
    this.language = language;
    this.content = content;
    this.pollyVoice = pollyVoice;
  }

  static cloneMsg({ index, language, content, pollyVoice, flowActionMessageId }) {
    return {
      index,
      language,
      content,
      pollyVoice,
      flowActionMessageId,
    } as ActionMessage;
  }
}

export class ActionCcpProperties {
  id: null | number = null;
  enableFiles = false;
  primaryColor: string;
  secondaryColor: string;
  logo: string;
  redirectUrl: string;
  use2FA = false;
}

export class FlowActionCcp extends FlowAction {
  flowActionCCPParameter: ActionCcpProperties = new ActionCcpProperties();
  flowActionMessages: ActionMessage[] = [];

  constructor(
    flowActionId: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable,
    description: string,
    messages = [],
    flowActionCCPParameter = new ActionCcpProperties()
  ) {
    super(
      flowActionId,
      flowId,
      flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      children,
      enable,
      description,
      false
    );
    this.flowActionMessages = messages;
    this.flowActionCCPParameter = flowActionCCPParameter;
  }

  // Get unreferenced properties
  static getProperties({ id, enableFiles, primaryColor, secondaryColor, logo, redirectUrl, use2FA }) {
    const properties = {
      id,
      enableFiles,
      primaryColor,
      secondaryColor,
      logo,
      redirectUrl,
      use2FA,
    };
    return properties;
  }

  // get unreferenced Flow Action
  static getFlowAction(ccp: FlowActionCcp) {
    const flowAction = {
      flowActionProperties: FlowActionCcp.getProperties(ccp.flowActionCCPParameter),
      flowActionMessages: ccp.flowActionMessages.map((msg) => ActionMessage.cloneMsg(msg)),
    };
    return flowAction;
  }
}
