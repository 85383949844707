import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContentTemplateType } from '../model';
import { ContentTemplateService } from 'app/services/content-template';

@Component({
  selector: 'app-content-template-type-selector',
  templateUrl: './content-template-type-selector.component.html',
  styleUrls: ['./content-template-type-selector.component.css'],
})
export class ContentTemplateTypeSelectorComponent implements OnInit {
    public selectedType: string;
    public typesList: ContentTemplateType[];
    @Output() public onSelected = new EventEmitter<ContentTemplateType>();
    @Output() public onCancelled = new EventEmitter<string>();

    constructor(
        private contentTemplateService: ContentTemplateService
    ) {
        this.typesList = this.contentTemplateService.getTypesList();
        this.selectedType =  undefined;
    }

    ngOnInit(): void { }
    
    public onCancel() {
        this.selectedType =  undefined;
        this.onCancelled.emit(null);
    }

    public onNext() {
        let selectedTypeObj = this.typesList.find(x => x.type === this.selectedType);
        if(selectedTypeObj) {
            this.onSelected.emit(selectedTypeObj);
        }
    }
}

