<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Description</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="full-width-textarea">
    <input matInput [(ngModel)]="description" />
  </mat-form-field>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Text</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field style="display: flex">
    <mat-form-label>Language</mat-form-label>
    <mat-select [(value)]="language" (selectionChange)="onLanguageSelect()">
      <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="display: flex">
    <mat-form-label>Voice</mat-form-label>
    <mat-select [(value)]="voice" (selectionChange)="onVoiceSelect()">
      <mat-option *ngFor="let voice of voices" [value]="voice">{{ voice }}</mat-option>
    </mat-select>
  </mat-form-field>
  <app-insertable-textarea #textField [(value)]="selectedMessage.content" *ngIf="selectedMessage"></app-insertable-textarea>
  <button class="sgnd-btn sgnd-btn-ok prev-btn" (click)="onCallPreview()">Call Preview</button>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title
      ><mat-checkbox labelPosition="before" (click)="clickCheckbox($event)" [(ngModel)]="retry">Retry</mat-checkbox>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field>
    <mat-label>Attempts</mat-label>
    <mat-select [disabled]="!retry" [(value)]="retryAttempts">
      <mat-option value="1">1</mat-option>
      <mat-option value="2">2</mat-option>
      <mat-option value="3">3</mat-option>
      <mat-option value="4">4</mat-option>
      <mat-option value="5">5</mat-option>
    </mat-select>
  </mat-form-field>
  <section>
    <span class="list-section">
      <mat-checkbox [(ngModel)]="retryOnAbsent" [disabled]="!retry || all" (change)="onCheckboxSelect($event)"
        >No Answer</mat-checkbox
      >
      <mat-checkbox [(ngModel)]="retryOnFailed" [disabled]="!retry || all" (change)="onCheckboxSelect($event)"
        >Call Failed</mat-checkbox
      >
      <mat-checkbox name="all" [(ngModel)]="all" [disabled]="!retry" (change)="onCheckboxSelect($event)"
        >All</mat-checkbox
      >
    </span>
  </section>
  <mat-form-field>
    <mat-label>Retry Every</mat-label>
    <mat-select [disabled]="!retry" [(value)]="retryFrequency">
      <mat-option value="0.5">30 Minutes</mat-option>
      <mat-option value="1">1 Hour</mat-option>
      <mat-option value="2">2 Hours</mat-option>
      <mat-option value="3">3 Hours</mat-option>
      <mat-option value="5">5 Hours</mat-option>
      <mat-option value="12">12 Hours</mat-option>
      <mat-option value="24">24 Hours</mat-option>
    </mat-select>
  </mat-form-field>
</mat-expansion-panel>
<app-negotiation-variables [customVars]="customVars" (selectOption)="insertText($event)"></app-negotiation-variables>
<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="saveMessages()">Save</button>
</div>
