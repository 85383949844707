import { BaseDocument } from './base-document';

export class AccountsReceivableDocument extends BaseDocument {
  public debtor: string;
  public amount: number;
  public date: Date;
  public dueDate: Date;

  constructor(document: AccountsReceivableDocument) {
    super();
    this.id = document?.id;
    this.description = document?.description;
    this.externalId = document?.externalId;
    this.debtor = document?.debtor;
    this.amount = document?.amount;
    this.date = document?.date;
    this.dueDate = document?.dueDate;
  }
}

export class AccountsReceivableDocumentDto {
  public id: number;
  public description: string;
  public externalId: string;
  public debtor: string;
  public amount: number;
  public date: Date;
  public dueDate: Date;

  constructor(document: AccountsReceivableDocument) {
    this.id = document?.id;
    this.description = document?.description;
    this.externalId = document?.externalId;
    this.debtor = document?.debtor;
    this.amount = document?.amount;
    this.date = document?.date;
    this.dueDate = document?.dueDate;
  }
}
