import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { DemoDocument } from 'app/model/documents/demo-document';
import { DocumentsService } from 'app/services/documents.service';
import { AddDemoDocumentComponent } from '../add-demo-document/add-demo-document.component';

@Component({
  selector: 'app-demo-documents-grid',
  templateUrl: './demo-documents-grid.component.html',
  styleUrls: ['./demo-documents-grid.component.css'],
})
export class DemoDocumentsGridComponent implements OnInit, OnDestroy {
  public documents: Array<DemoDocument> = [];

  dataSource;
  displayedColumns = ['title', 'description', 'actions'];

  constructor(public documentsService: DocumentsService, public dialog: MatDialog, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.documentsService.documentsSubject.subscribe((values: DemoDocument[]) => {
      this.documents = values;
      this.dataSource = new MatTableDataSource<DemoDocument>(this.documents);
    });
  }

  public onAdd() {
    this.dialog.open(AddDemoDocumentComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: null,
    });
  }

  public onEdit(negotiation) {
    this.dialog.open(AddDemoDocumentComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: negotiation,
    });
  }

  public onDelete(document: DemoDocument) {
    this.documentsService.deleteDocument(document);
    this.snackBar.open(`Document deleted successfully.`, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  ngOnDestroy(): void { }
}
