import { WhatsappOptionTemplateButton } from './whatsapp-option-template-button';
import { WhatsappOptionTemplateContent } from './whatsapp-option-template-content';

export class WhatsappOptionTemplate {
  templateCode: string;
  templateLabel: string;
  whatsappOptionTemplateContents: WhatsappOptionTemplateContent[] = [];
  whatsappOptionTemplateButtons: WhatsappOptionTemplateButton[] = [];
  variablesQty: number;
  companyId: number;
  contentSid: string;

  constructor(
    code?: string,
    label?: string
  ) {
    this.templateCode = code;
    this.templateLabel = label;
  }
}
