<div>
  <div class="title">Request a template</div>
  <div class="description">Requested templates will be available once approved by our customer service.</div>
  <form [formGroup]="templateCreateForm" (ngSubmit)="onSubmit()" class="whole-form" autocomplete="off">

    <div class="form-fields-container">
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Code</mat-label>
        <input matInput formControlName="templateCode" type="text" autocomplete="off" required />
        <mat-icon class="message-tooltip" matTooltip="Write here a unique and short name that doesn't contain spaces to identify this template" matTooltipPosition="right">info</mat-icon>
      </mat-form-field>
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Title / Label</mat-label>
        <input matInput formControlName="templateLabel" type="text" autocomplete="off" required />
      </mat-form-field>
      <div class="flex-container">
        <mat-form-field appearance="fill">
          <mat-label>Variables Quantity</mat-label>
          <input matInput type="number" formControlName="variablesQty" min="0" max="4" autocomplete="off" required />
        </mat-form-field>
      </div>
    </div>

    <div class="table-container">
      <label class="table-title">Buttons</label>
      <table mat-table [dataSource]="buttonsDataSource" class="table" fixedLayout>
        <ng-container matColumnDef="text">
          <th mat-header-cell *matHeaderCellDef>Text</th>
          <td mat-cell *matCellDef="let element" [matTooltip]="element.content">{{ element.text }}</td>
        </ng-container>
        <ng-container matColumnDef="url">
          <th mat-header-cell *matHeaderCellDef>URL</th>
          <td mat-cell *matCellDef="let element" [matTooltip]="element.content">{{
            element.url }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="th-actions">
            <button mat-button (click)="onAddButton()" type="button" matTooltip="Add buttons">
              <mat-icon>note_add</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element" matTooltip="Remove">
            <button mat-button (click)="onRemoveButton(element)" type="button">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedButtonsColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedButtonsColumns"></tr>
      </table>
    </div>

    <div class="table-container">
      <label class="table-title">Contents</label>
      <table mat-table [dataSource]="contentDataSource" class="table" fixedLayout>
        <ng-container matColumnDef="title">
          <th mat-header-cell *matHeaderCellDef class="th-language">Language</th>
          <td mat-cell *matCellDef="let element">{{allLangs[element.language]}}</td>
        </ng-container>
        <ng-container matColumnDef="content">
          <th mat-header-cell *matHeaderCellDef>Content</th>
          <td mat-cell *matCellDef="let element" [matTooltip]="element.content" class="td-template-content">{{
            element.content }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="th-actions">
            <button mat-button (click)="onAddContent()" type="button" matTooltip="Add contents">
              <mat-icon>note_add</mat-icon>
            </button>
          </th>
          <td mat-cell *matCellDef="let element" matTooltip="Remove">
            <button mat-button (click)="onRemoveContent(element)" type="button">
              <mat-icon>remove_circle_outline</mat-icon>
            </button>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedContentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedContentColumns"></tr>
      </table>
    </div>

    <div class="buttons-container">
      <button class="sgnd-btn sgnd-btn-ok" [disabled]="!templateCreateForm.valid || contentDataSource.data.length == 0"
        type="submit">Save</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>