import { formatDate } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanySettingsService } from 'app/components/settings';
import { SimpleCompanySettings } from 'app/components/settings/model/simple-company-settings';
import { NegotiationStatus, NEGOTIATION_STATUS_DESCRIPTIONS } from 'app/model/negotiation-status';
import { Schedule, ScheduleObject } from 'app/model/schedule';
import { Slot } from 'app/model/slot';
import { ScheduleService } from 'app/services/schedule.service';
import { UIService } from 'core/services/ui.service';
import { environment } from 'environments/environment';

export enum Days {
  Monday = '1',
  Tuesday = '2',
  Wednesday = '3',
  Thursday = '4',
  Friday = '5',
  Saturday = '6',
  Sunday = '7',
}
export enum Months {
  January = '1',
  February = '2',
  March = '3',
  April = '4',
  May = '5',
  June = '6',
  July = '7',
  August = '8',
  September = '9',
  October = '10',
  November = '11',
  December = '12',
}

export enum Repeat {
  None = 'None',
  Daily = 'Daily',
  Weekly = 'Weekly',
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  Hourly = 'Hourly',
}
@Component({
  selector: 'schedule-slot',
  templateUrl: './schedule-slot.component.html',
  styleUrls: ['./schedule-slot.component.css'],
})
export class ScheduleSlotComponent implements OnInit {
  scheduleCreateForm: FormGroup;
  public availableMinutes: number[] = [...Array(60).keys()];
  public availableHours: number[] = [...Array(24).keys()];
  public availableDays: number[] = Array.from(new Array(31), (x, i) => i + 1);
  public availableMonths = Months;
  public availableDayOfWeek = Days;
  public repeat = Repeat;
  public maxDate = new Date(9999,0);
  public showSchedulesThatDoNotWork: boolean = true;

  public statusKeys = [
    'ALL',
    ...Object.keys(NegotiationStatus).filter((key) => !isNaN(Number(NegotiationStatus[key]))),
  ];
  public statusDescriptions = NEGOTIATION_STATUS_DESCRIPTIONS;
  private baseUrl = window.location.origin + '/mdi/';
  public url = this.baseUrl + 2;
  private schedule: Schedule;

  constructor(
    public scheduleService: ScheduleService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ScheduleSlotComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      slot: Slot;
    },
    private uiService: UIService,
    private companySettingsService: CompanySettingsService
  ) {
    //this.showSchedulesThatDoNotWork = !environment.production;

    this.schedule = new Schedule(null);
    this.companySettingsService.$companyData.subscribe((companySettings: SimpleCompanySettings) => {
      if(companySettings?.companyId) {
        this.url = this.baseUrl + companySettings?.companyId
      }
    });
    if (data.slot) {
      this.schedule.entityId = data.slot.slotId;
      this.schedule.entityType = 'Slot';
    }
    this.buildScheduleCreateForm(this.schedule);
    this.scheduleService.getScheduleData(data.slot.slotId).subscribe((res) => {
      if (res) {
        this.schedule.schedule = res.schedule;
        this.schedule.repeats = res.repeats;
        this.schedule.starts = res.starts;
        this.schedule.ends = res.ends;
        this.assignScheduleCreateForm(this.schedule);
      }
    });
  }

  unsorted() {}

  ngOnInit(): void {}

  onSelectingItem(val) {
    if (
      val !== 'ALL' &&
      !this.scheduleCreateForm.value.status.includes('ALL') &&
      this.scheduleCreateForm.value.status.length === this.statusKeys.length - 1
    ) {
      this.scheduleCreateForm.setValue({ ...this.scheduleCreateForm.value, status: [...this.statusKeys] });
    } else if (val === 'ALL' && this.scheduleCreateForm.value.status.length >= this.statusKeys.length - 1) {
      this.scheduleCreateForm.setValue({ ...this.scheduleCreateForm.value, status: [] });
    } else if (val !== 'ALL' && this.scheduleCreateForm.value.status.length < this.statusKeys.length) {
      this.scheduleCreateForm.setValue({
        ...this.scheduleCreateForm.value,
        status: this.scheduleCreateForm.value.status.filter((st) => st !== 'ALL'),
      });
    } else {
      this.scheduleCreateForm.setValue({ ...this.scheduleCreateForm.value, status: [...this.statusKeys] });
    }
  }

  buildScheduleCreateForm(schedule: Schedule) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const values = [[], 0, 0, 0, '0', []];
    this.scheduleCreateForm = this.formBuilder.group({
      repeat: 'None',
      startDate: formatDate(new Date(), 'yyyy-MM-dd', 'en'),
      timeRun: '12:00',
      endDate: formatDate(tomorrow, 'yyyy-MM-dd', 'en'),
      showEndDate: false,
      selectedDW: values[0],
      selectedDay: 0,
      selectedHour: 0,
      selectedMin: 0,
      selectedMonth: '0',
      selectedDWNumber: 1,
      selectedDMNumber: 1,
      selectedDNumber: 1,
      status: [],
    });
  }

  assignScheduleCreateForm(schedule: Schedule) {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    const values = schedule.schedule ? schedule.schedule.split(' ') : [[], 0, 0, 0, '0', [], null];
    this.schedule.starts = schedule.starts ? schedule.starts : new Date();
    this.schedule.ends = schedule.ends ? schedule.ends : tomorrow;
    this.schedule.repeats = Object.keys(this.repeat)[schedule.repeats];
    const selectedDays = parseInt(values[1].toString(), 10);
    const selectedMonths = values[4];
    this.scheduleCreateForm.patchValue({
      slotId: schedule.entityId,
      repeat: this.schedule.repeats,
      startDate: formatDate(this.schedule.starts, 'yyyy-MM-dd', 'en'),
      endDate: formatDate(this.schedule.ends, 'yyyy-MM-dd', 'en'),
      showEndDate: schedule.ends != null && schedule.ends.getTime()!== this.maxDate.getTime(),
      selectedDW: values[0],
      selectedDay: selectedDays,
      selectedHour: parseInt(values[2].toString(), 10),
      selectedMin: parseInt(values[3].toString(), 10),
      selectedMonth: values[4],
      selectedDWNumber: selectedDays > 0 ? selectedDays : 1,
      selectedDMNumber: selectedMonths > 0 ? selectedMonths : 1,
      selectedDNumber: selectedDays > 0 ? selectedDays : 1,
      status: values[5] ? values[5].toString().split(',') : [],
      timeRun: values[6],
    });
  }

  onDelete(): void {
    const scheduleObject = new ScheduleObject(this.scheduleCreateForm.value);


    this.scheduleService.deleteSchedule(this.schedule).subscribe(
      (response) => {
        this.snackBar.open('Schedule Deleted successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
        this.dialogRef.close();
      },
      (errorResponse: HttpErrorResponse) => {
        console.error(errorResponse.error);
        this.snackBar.open('There was an error while trying to delete a schedule.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  onSubmit(): void {
    const scheduleObject = new ScheduleObject(this.scheduleCreateForm.value);

    if (this.isRepeatWeekly) {
      const selDW = this.scheduleCreateForm.get('selectedDWNumber').value;
      scheduleObject.selectedDay = selDW;
      scheduleObject.selectedHour = 0;
      scheduleObject.selectedMin = 0;

      if (scheduleObject.selectedDW === '') {
        this.snackBar.open('You must select a day of the week.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
        return;
      }
    } else {
      scheduleObject.selectedDW = '';
    }
    if (this.isRepeatDaily) {
      const selDay = this.scheduleCreateForm.get('selectedDNumber').value;
      scheduleObject.selectedDay = selDay;
      scheduleObject.selectedMonth = 0;
      scheduleObject.selectedHour = 0;
      scheduleObject.selectedMin = 0;
    }
    if (this.isRepeatMonthly) {
      const selDM = this.scheduleCreateForm.get('selectedDMNumber').value;
      scheduleObject.selectedMonth = selDM;
      scheduleObject.selectedHour = 0;
      scheduleObject.selectedMin = 0;
    }
    if (this.isRepeatHourly) {
      scheduleObject.selectedDay = 0;
      scheduleObject.selectedMonth = 0;
    }
    if (this.isRepeatYearly) {
      scheduleObject.selectedHour = 0;
      scheduleObject.selectedMin = 0;
    }

    const allKeys = Object.keys(scheduleObject).sort();
    const valueMap = allKeys.map((key) => scheduleObject[key]);
    this.schedule.schedule = valueMap.join(' ');
    this.schedule.repeats = this.scheduleCreateForm.get('repeat').value;
    this.schedule.starts = new Date(this.scheduleCreateForm.get('startDate').value.toString().replace(/-/g, '/'));
    this.schedule.ends = new Date(this.scheduleCreateForm.get('endDate').value.toString().replace(/-/g, '/'));
    this.schedule.ends.setUTCHours(23,59,59,999);
    this.uiService.toggleGlobalSpinner(true);

    const showEndDate = this.scheduleCreateForm.get('showEndDate').value;

    if (!showEndDate){
      this.schedule.ends = this.maxDate;
    }

    this.scheduleService.saveSchedule(this.schedule).subscribe(
      (response) => {
        this.snackBar.open('Schedule configured successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
        this.dialogRef.close();
      },
      (errorResponse: HttpErrorResponse) => {
        console.error(errorResponse.error);
        this.snackBar.open('There was an error while trying to set a schedule.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  get isRepeat(): boolean {
    return this.scheduleCreateForm.get('repeat').value === 'None';
  }

  get isRepeatWeekly(): boolean {
    return this.scheduleCreateForm.get('repeat').value === 'Weekly';
  }

  get isRepeatYearly(): boolean {
    return this.scheduleCreateForm.get('repeat').value === 'Yearly';
  }

  get isRepeatMonthly(): boolean {
    return this.scheduleCreateForm.get('repeat').value === 'Monthly';
  }
  get isRepeatDaily(): boolean {
    return this.scheduleCreateForm.get('repeat').value === 'Daily';
  }
  get isRepeatHourly(): boolean {
    return this.scheduleCreateForm.get('repeat').value === 'Hourly';
  }
  get isEndDate(): boolean {
    return this.scheduleCreateForm.get('showEndDate').value;
  }

  shouldGlow(key): boolean {
    const value = this.scheduleCreateForm.get('selectedDW').value;
    if (value) {
      const sd = value.split(',');
      return sd.includes(key);
    }
    return false;
  }

  addDayOfWeek(day) {
    let sd = [];

    const dw = this.scheduleCreateForm.get('selectedDW').value;
    if (dw) {
      sd = dw.split(',');
    }
    if (sd.includes(day)) {
      sd.splice(sd.indexOf(day), 1);
    } else {
      sd.push(day);
    }
    this.scheduleCreateForm.get('selectedDW').setValue(sd.join(','));
  }

  extractDay(day: string): string {
    return day.charAt(0).toUpperCase();
  }

  get scheduleSummary(): string {
    let summary = '';

    const selMonth = this.scheduleCreateForm.get('selectedMonth').value;
    const selOnDay = this.scheduleCreateForm.get('selectedDay').value;
    const selDM = this.scheduleCreateForm.get('selectedDMNumber').value;
    const selDW = this.scheduleCreateForm.get('selectedDWNumber').value;
    const selWD = this.scheduleCreateForm.get('selectedDW').value;
    const selDay = this.scheduleCreateForm.get('selectedDNumber').value;
    const selHour = this.scheduleCreateForm.get('selectedHour').value;
    const selMin = this.scheduleCreateForm.get('selectedMin').value;

    if (this.isRepeatYearly) {
      const monthName = Object.keys(this.availableMonths).find((name) => this.availableMonths[name] === selMonth);
      if (monthName) {
        summary += `Every ${monthName}`;
      }
      if (selOnDay) {
        summary += ` on Day ${selOnDay}`;
      }
    }

    if (this.isRepeatMonthly) {
      summary += `Every ${selDM} month(s)`;
      if (selOnDay) {
        summary += ` on Day ${selOnDay}`;
      }
    }
    if (this.isRepeatDaily) {
      summary += `Every ${selDay} day(s)`;
    }
    if (this.isRepeatWeekly) {
      summary += `Every ${selDW} week(s)`;
      if (selWD) {
        summary += ` on ${selWD}`;
      }
    }
    if (this.isRepeatHourly) {
      summary += `Every ${selHour} hour(s) ${selMin} minutes`;
    }

    return summary;
  }
}
