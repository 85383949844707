import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class BillingApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Billing';
  }

  public addNewSubscription(newSubscriptionPayload: AddNewSubscriptionPayload) {
    return this.http.post(`${this.baseURL}/add-new-subscription`, newSubscriptionPayload);
  }

  public calculateCost(calculateCostPayload: CalculateCostPayload) {
    return this.http.post(`${this.baseURL}/calculate-cost`, calculateCostPayload);
  }

  public newSubscription(newSubscriptionPayload: NewSubscriptionPayload) {
    return this.http.post(`${this.baseURL}/new-subscription`, newSubscriptionPayload);
  }

  public addCredit(addCreditPayload: AddCreditPayload) {
    return this.http.post(`${this.baseURL}/add-credit`, addCreditPayload);
  }

  public cancelSubscription() {
    return this.http.post(`${this.baseURL}/cancel-subscription`, {});
  }

  public changeSubscription(subscriptionType: number) {
    return this.http.post(`${this.baseURL}/change-subscription`, { subscriptionType });
  }

  public getSubscriptionDetails() {
    return this.http.get(`${this.baseURL}/subscription-details`);
  }
}

interface AddNewSubscriptionPayload {
  expiration: string;
  account: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  phone: string;
  email: string;
  subscriptionType: number;
  password: string;
}

interface CalculateCostPayload {
  negotiationId: number;
  slotId: number;
}

interface NewSubscriptionPayload {
  expiration: string;
  account: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  phone: string;
  email: string;
  subscriptionType: number;
}

interface AddCreditPayload {
  expiration: string;
  account: string;
  name: string;
  address: string;
  city: string;
  postalCode: string;
  region: string;
  country: string;
  phone: string;
  email: string;
  amount: number;
}
