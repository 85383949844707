<div class="contacts-grid-container" cdkDropListGroup>
  <mat-card class="slots-card">
    <div class="flex items-center flex-1">
      <form>
        <mat-form-field class="form-field-no-padding" appearance="fill">
          <mat-label>Target Channel</mat-label>
          <input
            type="text"
            placeholder="All Channels"
            matInput
            [formControl]="targetSlotControl"
            [matAutocomplete]="matSlotAutocomplete"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #matSlotAutocomplete="matAutocomplete"
            [displayWith]="slotDisplayFn.bind(this)"
          >
            <mat-option *ngFor="let slotElement of filteredSlotOptions | async" [value]="slotElement.key">
              {{ (slotElement.isDataInjestor ? 'DI-' : ' ') + slotElement.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <div
        *ngIf="selectedSlot"
        class="droppable-slot-container"
        (click)="assignContactsClick()"
        cdkDropList
        (cdkDropListDropped)="assignContacts($event)"
      >
        Click or drag here to assign the selected contacts to the current Channel
      </div>
    </div>
  </mat-card>
  <mat-card style="margin-bottom: 0.25rem">
    <filter-paginator
      placeholder="Filter by last name, name or email"
      [length]="dataLength"
      [reset]="resetFilterPaginator"
      [setState]="setFilterState"
      (filterPage)="onFilterPage($event)"
    >
      <div class="filter-extra-buttons">
        <mat-icon (click)="exportContacts()" matTooltip="Export">save_alt</mat-icon>
      </div>
    </filter-paginator>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortContacts($event)"
      class="mat-elevation-z1"
      fixedLayout
      #dataTable
      cdkDropList
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="masterToggle(); emitSelectedContacts()"
            [checked]="selection.hasValue() && isAllSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (change)="toggleSelection(row); emitSelectedContacts()" [checked]="isContactSelected(row)">
          </mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="NAME">Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="lastName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="LASTNAME">Last Name</th>
        <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="PHONE">Phone</th>
        <td mat-cell *matCellDef="let element">{{ element.phone }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="EMAIL">Email</th>
        <td mat-cell *matCellDef="let element">{{ element.email }}</td>
      </ng-container>

      <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="LASTMODIFIED">Last Modified</th>
        <td mat-cell *matCellDef="let element">
          {{ element.lastModified || element.created | date : 'MM/dd/yyyy hh:mm a' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon class="action-icon hoverable" aria-label="Options">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onEdit(element)">
              <mat-icon class="action-icon hoverable" aria-label="Edit Contact">edit</mat-icon>
              <span>Edit Contact</span>
            </button>
            <button mat-menu-item (click)="onDelete(element)">
              <mat-icon class="action-icon hoverable" aria-label="Delete Contact">delete_outline</mat-icon>
              <span>Delete Contact</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        cdkDrag
        [cdkDragData]="row"
        [cdkDragDisabled]="!selectedSlot"
      >
        <div *cdkDragPreview class="dragged-placeholder">
          <div>+ {{ selection.selected.length ? selection.selected.length : 1 }}</div>
        </div>
      </tr>
    </table>
  </mat-card>
</div>
