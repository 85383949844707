<div class="title">Schedule Execution</div>

<form [formGroup]="scheduleCreateForm" (ngSubmit)="onSubmit()">
  <div class="form-fields-container">
    <mat-form-field class="regular-field" *ngIf="showSchedulesThatDoNotWork">
      <mat-label>Repeat</mat-label>
      <mat-select matInput name="repeat" ngDefaultControl formControlName="repeat" required>
        <mat-option *ngFor="let item of repeat | keyvalue: unsorted" [value]="item.value">{{ item.key }} </mat-option>
      </mat-select>
    </mat-form-field>

    <div fxLayout="row" fxLayoutAlign="space-around">
      <mat-form-field class="regular-field">
        <mat-label *ngIf="isRepeat">Run Date</mat-label>
        <mat-label *ngIf="!isRepeat">Start Date</mat-label>
        <input matInput formControlName="startDate" name="startDate" type="date" required />
      </mat-form-field>

      <mat-form-field class="regular-field">
        <input matInput type="time" formControlName="timeRun" placeholder="Run Time" />
      </mat-form-field>
    </div>

    <mat-form-field class="regular-field">
      <mat-label>Status</mat-label>
      <mat-select matInput name="status" ngDefaultControl formControlName="status" multiple required>
        <mat-option *ngFor="let item of statusKeys" [value]="item" (click)="onSelectingItem(item)"
          >{{ item | statusParse }} {{ statusDescriptions[item] ? '-' : '' }} {{ statusDescriptions[item] }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="weekDaySelector" *ngIf="isRepeatDaily">
      <span>Every</span>
      <md-input-container class="weekDaySelectorContainer"
        ><input matInput type="number" min="1" max="7" formControlName="selectedDNumber" />
      </md-input-container>
      <span>days(s) once</span>
    </div>

    <div class="weekDaySelector" *ngIf="isRepeatWeekly && false">
      <span>Every</span>
      <md-input-container class="weekDaySelectorContainer"
        ><input matInput type="number" min="1" max="4" formControlName="selectedDWNumber" />
      </md-input-container>
      <span>week(s) once</span>
    </div>

    <div class="daysOfWeek" *ngIf="isRepeatWeekly">
      <span
        *ngFor="let item of availableDayOfWeek | keyvalue: unsorted"
        [value]="item.value"
        class="chip"
        [class.reversed]="shouldGlow(item.key)"
        [matTooltip]="item.key"
        (click)="addDayOfWeek(item.key)"
        >{{ extractDay(item.key) }}</span
      >
    </div>
    <mat-form-field *ngIf="isRepeatYearly" class="regular-field">
      <mat-label>Every</mat-label>
      <mat-select matInput name="selectedMonth" ngDefaultControl formControlName="selectedMonth" required>
        <mat-option [value]="0">None</mat-option>
        <mat-option *ngFor="let item of availableMonths | keyvalue: unsorted" [value]="item.value"
          >{{ item.key }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <div class="weekDaySelector" *ngIf="isRepeatMonthly">
      <span>Every</span>
      <md-input-container class="weekDaySelectorContainer"
        ><input matInput type="number" min="1" max="12" formControlName="selectedDMNumber" />
      </md-input-container>
      <span>month(s) once</span>
    </div>

    <mat-form-field *ngIf="isRepeatYearly || isRepeatMonthly" class="regular-field">
      <mat-label>On Day</mat-label>
      <mat-select matInput name="selectedDay" ngDefaultControl formControlName="selectedDay" required>
        <mat-option [value]="0">None</mat-option>
        <mat-option *ngFor="let item of availableDays" [value]="item">{{ item }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="isRepeatHourly" class="regular-field">
      <mat-label>Hours</mat-label>
      <mat-select matInput name="selectedHour" ngDefaultControl formControlName="selectedHour" required>
        <mat-option [value]="0">None</mat-option>
        <mat-option *ngFor="let item of availableHours" [value]="item">{{ item }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="isRepeatHourly" class="regular-field">
      <mat-label>Minutes</mat-label>
      <mat-select matInput name="selectedMin" ngDefaultControl formControlName="selectedMin" required>
        <mat-option [value]="0">None</mat-option>
        <mat-option *ngFor="let item of availableMinutes" [value]="item">{{ item }} </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="regular-field" *ngIf="showSchedulesThatDoNotWork">
      <mat-label>Ends</mat-label>
      <mat-select matInput name="showEndDate" formControlName="showEndDate" ngDefaultControl required>
        <mat-option [value]="false">Never</mat-option>
        <mat-option [value]="true">On Date</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field *ngIf="isEndDate" class="regular-field">
      <mat-label>End Date</mat-label>
      <input matInput formControlName="endDate" name="endDate" type="date" required />
    </mat-form-field>

    <div class="extra-info" *ngIf="scheduleSummary">
      <mat-card>{{ scheduleSummary }}</mat-card>
    </div>
    <div class="buttons-container-container">
      <button class="sgnd-btn sgnd-btn-cancel delete-btn" type="button" (click)="onDelete()">
        <mat-icon class="action-icon" aria-label="Delete Schedule">delete_outline</mat-icon>
        <span>Delete</span>
      </button>
      <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-ok" [disabled]="!scheduleCreateForm.valid" type="submit">Save</button>
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
      </div>
    </div>
  </div>
</form>
