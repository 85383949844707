import { animate, style, transition, trigger } from '@angular/animations';

const transitionEnter = transition(':enter', [
  style({ height: 0, width: 0, opacity: 0, overflow: 'hidden' }),
  animate('500ms ease-in', style({ height: 400, opacity: 1, width: 400 })),
]);

const transitionLeave = transition(':leave', [
  style({ height: 400, width: 400, opacity: 1, overflow: 'hidden' }),
  animate('500ms ease-in', style({ height: 0, opacity: 0, width: 0 })),
]);

export const inOutAnimation = trigger('inOutAnimation', [transitionEnter, transitionLeave]);
