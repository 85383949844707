import { Injectable } from "@angular/core";

@Injectable()
export class StorageService {
    private sessionData: Map<string, any> = new Map();
    
    constructor() {}

    public setSessionItem(key: string, value: any) {
        this.sessionData.set(key, value);
    }

    public getSessionItem(key: string) {
        return this.sessionData.get(key);
    }
}