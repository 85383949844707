import { Component, OnInit } from '@angular/core';
import { CompanyPermission } from 'app/model/company-permission';
import { NegotiationType } from 'app/model/negotiation-type';
import { STUDIO_ACTION_OPTIONS, STUDIO_ICONS } from 'app/model/studio-action-options';
import { CompanyPermissionsService } from 'app/services/company-permissions.service';
import { FlowsService } from 'app/services/flows.service';

@Component({
  selector: 'app-studio-canvas',
  templateUrl: './studio-canvas.component.html',
  styleUrls: ['./studio-canvas.component.css'],
})
export class StudioCanvasComponent implements OnInit {
  actionOptions = [];
  actionIcons = STUDIO_ICONS;
  whatsappIcon = STUDIO_ICONS[STUDIO_ACTION_OPTIONS.WHATSAPP];
  whatsappTemplateIcon = STUDIO_ICONS[STUDIO_ACTION_OPTIONS.WHATSAPP_TEMPLATE];
  expandedFlow = null;
  companyAllowedFlowActions = Object.values(STUDIO_ACTION_OPTIONS).filter((opt) => opt !== STUDIO_ACTION_OPTIONS.START);

  constructor(private flowService: FlowsService, private companyPermissionsService: CompanyPermissionsService) {
    this.companyPermissionsService.userCompanyPermissions$.subscribe(
      (permissions) => {
        this.actionOptions = this.getAllowedActionOptions(permissions);
      },
      (err) => {
        console.error(err);
      }
    );
  }

  ngOnInit(): void {
    this.flowService.$expandedFlow.subscribe((expandedFlow) => {
      this.expandedFlow = expandedFlow;
      // eslint-disable-next-line
      if (this.expandedFlow?.type == NegotiationType.THIRD_PARTY) {
        this.actionOptions = this.actionOptions.filter((actOpt) => actOpt !== STUDIO_ACTION_OPTIONS.CCP);
      } else {
        this.actionOptions = this.companyAllowedFlowActions;
      }
    });
  }

  getAllowedActionOptions(allowedActions: Array<CompanyPermission>) {
    const allowedFlowTypeActions = allowedActions.filter(a => a.name?.startsWith('FlowActionType'));
    this.companyAllowedFlowActions = allowedFlowTypeActions.map(a => {
      const optionName = this.camelToSnakeCase(a.name.replace('FlowActionType', ''));
      const selection = STUDIO_ACTION_OPTIONS[optionName.toUpperCase()];
      return selection;
    });
    this.companyAllowedFlowActions.push(STUDIO_ACTION_OPTIONS.DELAY);
    return this.companyAllowedFlowActions;
  }

  private camelToSnakeCase(str: string): string {
    return !!str && str.includes && !str.includes('_')? str.split(/(?=[A-Z])/).join('_').toLowerCase() : str;
  }

  onDragStart(event) {
    event.dataTransfer.setData('actionType', event.target.name);
    //alert(event)
    event.target.classList.toggle('draggin');
  }

  onDragEnd(event) {
    event.target.classList.toggle('draggin');
  }

  onDrag(event) {
    //console.log(event);
  }
}
