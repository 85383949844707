export class WhatsappOptionTemplateContent {
  id: number;
  language: string;
  content: string;
  status: number;

  constructor(language: string, content: string, status: number) {
    this.language = language;
    this.content = content;
    this.status = status;
  }
}
