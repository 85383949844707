/* eslint-disable */
export enum TwilioSmsStatus {
  READ = 1,
  RECEIVED = 2,
  DELIVERED = 3,
  UNDELIVERED = 4,
  FAILED = 5,
}

export const TWILIO_SMS_MAPPING = {
    [TwilioSmsStatus.READ]: {
        icon: 'done_all',
        color: 'lightblue'
    },
    [TwilioSmsStatus.RECEIVED]: {
        icon: 'done_all',
        color: 'gray'
    },
    [TwilioSmsStatus.DELIVERED]: {
        icon: 'done_all',
        color: 'gray'
    },
    [TwilioSmsStatus.UNDELIVERED]: {
        icon: 'error',
        color: 'red'
    },
    [TwilioSmsStatus.FAILED]: {
        icon: 'error',
        color: 'red'
    }
}