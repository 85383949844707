/* eslint-disable max-len */
import { A11yModule } from '@angular/cdk/a11y';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { CdkTableModule } from '@angular/cdk/table';
import { TextFieldModule } from '@angular/cdk/text-field';
import { HttpBackend, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogConfig, MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModule } from '@auth0/auth0-angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { ColorPickerModule } from 'ngx-color-picker';
import { ContactsApiService } from 'src/app/services/contacts-api.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { DynamicContactColumnsApiService } from 'src/app/services/dynamiccontactcolumns-api.service';
import { DynamicValuesApiService } from 'src/app/services/dynamicvalues-api.service';
import { CustomJsonParser, JsonHttpInterceptor, JsonParser } from 'src/core/components/jsonParser';
import { CoreModule } from 'src/core/core.module';
import { environment as env } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdminComponent } from './components/admin/admin.component';
import { CompanyApiCredentialsCreateComponent } from './components/admin/company-api-credentials-create/company-api-credentials-create.component';
import { CompanyCredentialsCreateComponent } from './components/settings/components/settings/company-credentials/company-credentials-create/company-credentials-create.component';
import { CompanyApiCredentialsListComponent } from './components/admin/company-api-credentials-list/company-api-credentials-list.component';
import { RebrandlyLinksComponent } from './components/admin/rebrandly-links.component/rebrandly-links.component';
import { ReviewWhatsappTemplateContentDialogComponent } from './components/admin/review-whatsapp-template-content-dialog/review-whatsapp-template-content-dialog.component';
import { ReviewWhatsappTemplateContentsListComponent } from './components/admin/review-whatsapp-template-contents-list-component/review-whatsapp-template-contents-list.component';
import { BillingDetailsComponent } from './components/billing/billing-details/billing-details.component';
import { BillingScreenComponent } from './components/billing/billing-screen/billing-screen.component';
import { BillingSubscriptionsComponent } from './components/billing/billing-subscriptions/billing-subscriptions.component';
import { BillingSuccessComponent } from './components/billing/billing-success/billing-success.component';
import { BillingTimerComponent } from './components/billing/billing-timer/billing-timer.component';
import { NewUserRegistrationComponent } from './components/billing/new-user-registration/new-user-registration.component';
import { SubscriptionCardComponent } from './components/billing/subscription-card/subscription-card.component';
import { AlertComponent } from './components/common/alert/alert.component';
import { AutocompleteComponent } from './components/common/autocomplete/autocomplete.component';
import { ConfirmDialogComponent } from './components/common/confirm-dialog/confirm-dialog.component';
import { CanvasArrowComponent } from './components/common/dropable-canvas/canvas-arrow/canvas-arrow.component';
import { CallDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/call-details/call-details.component';
import { CallPreviewComponent } from './components/common/dropable-canvas/canvas-node-details/call-details/call-preview/call-preview.component';
import { CanvasNodeDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/canvas-node-details.component';
import { CcpDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/ccp-details/ccp-details.component';
import { DelayDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/delay-details/delay-details.component';
import { EmailDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/email-details/email-details.component';
import { InsertableTextAreaComponent } from './components/common/dropable-canvas/canvas-node-details/InsertableTextArea';
import { MessageDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/message-details/message-details.component';
import { NegotiationVariablesComponent } from './components/common/dropable-canvas/canvas-node-details/negotiation-variables/negotiation-variables.component';
import { SignatureDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/signature-details/signature-details.component';
import { WhatsappDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/whatsapp-details/whatsapp-details.component';
import { ContentTemplateDetailsComponent } from './components/common/dropable-canvas/canvas-node-details/content-template-details/content-template-details.component';
import { CanvasNodeComponent } from './components/common/dropable-canvas/canvas-node/canvas-node.component';
import { DropableCanvasComponent } from './components/common/dropable-canvas/dropable-canvas.component';
import { FilesListComponent } from './components/common/files-list/files-list.component';
import { IntermediateMessagesModalComponent } from './components/common/intermediate-messages-modal/intermediate-messages-modal.component';
import { LateralActionComponent } from './components/common/lateral-table-actions/lateral-action/lateral-action.component';
import { LateralTableActionsComponent } from './components/common/lateral-table-actions/lateral-table-actions.component';
import { NegotiationCounterComponent } from './components/common/negotiation-counter/negotiation-counter.component';
import { PhonePickerComponent } from './components/common/phone-picker/phone-picker.component';
import { SmsMessagesModalComponent } from './components/common/sms-messages-modal/sms-messages-modal.component';
import { TabGroupComponent } from './components/common/tab-group/tab-group.component';
import { WhatsappMessagesModalComponent } from './components/common/whatsapp-messages-modal/whatsapp-messages-modal.component';
import { ContactSuscriptionComponent } from './components/data-ingestion/contact-suscription/contact-suscription.component';
import { DataIngestorSlotsSelectionComponent } from './components/data-ingestion/data-ingestor-slots-selection/data-ingestor-slots-selection.component';
import { AddContactComponent } from './components/deal/contacts/add-contact/add-contact.component';
import { ContactGroupsGridComponent } from './components/deal/contacts/contact-groups-grid/contact-groups-grid.component';
import { ContactsGridComponent } from './components/deal/contacts/contacts-grid/contacts-grid.component';
import { ContactsScreenComponent } from './components/deal/contacts/contacts-screen/contacts-screen.component';
import { UploadContactsFileComponent } from './components/deal/contacts/upload-contacts-file/upload-contacts-file.component';
import { DealComponent } from './components/deal/deal.component';
import { AccountsReceivableDocumentsGridComponent } from './components/deal/documents/accounts-receivable-documents-grid/accounts-receivable-documents-grid.component';
import { AddAccountsReceivableDocumentComponent } from './components/deal/documents/add-accounts-receivable-document/add-accounts-receivable-document.component';
import { AddDemoDocumentComponent } from './components/deal/documents/add-demo-document/add-demo-document.component';
import { DemoDocumentsGridComponent } from './components/deal/documents/demo-documents-grid/demo-documents-grid.component';
import { MonitorChatComponent } from './components/deal/monitor/monitor-chat/monitor-chat.component';
import { MonitorGridComponent } from './components/deal/monitor/monitor-grid/monitor-grid.component';
import { MonitorSlotDetailComponent } from './components/deal/monitor/monitor-slot-detail/monitor-slot-detail.component';
import { MonitorComponent } from './components/deal/monitor/monitor.component';
import { NegotiationProgressComponent } from './components/deal/monitor/negotiation-progress/negotiation-progress.component';
import { NegotiationsChartComponent } from './components/deal/monitor/negotiations-chart/negotiations-chart.component';
import { AddNegotiationToSlotComponent } from './components/deal/negotiations/add-negotiation-to-slot/add-negotiation-to-slot.component';
import { AddNegotiationComponent } from './components/deal/negotiations/add-negotiation/add-negotiation.component';
import { AddSlotComponent } from './components/deal/negotiations/add-slot/add-slot.component';
import { FlowRestarterComponent } from './components/deal/negotiations/flows/flow-restarter/flow-restarter.component';
import { FlowStoperComponent } from './components/deal/negotiations/flows/flow-stoper/flow-stoper.component';
import { NegotiationsGridComponent } from './components/deal/negotiations/negotiations-grid/negotiations-grid.component';
import { NegotiationsScreenComponent } from './components/deal/negotiations/negotiations-screen/negotiations-screen.component';
import { AddOptionComponent } from './components/deal/negotiations/options/add-option/add-option.component';
import { OptionsGridComponent } from './components/deal/negotiations/options/options-grid/options-grid.component';
import { ScheduleSlotComponent } from './components/deal/negotiations/schedule-slot/schedule-slot.component';
import { StartSlotComponent } from './components/deal/negotiations/start-slot/start-slot.component';
import { UploadNegotiationsFileComponent } from './components/deal/negotiations/upload-negotiations-file/upload-negotiations-file.component';
import { AddFlowComponent } from './components/deal/studio/add-flow/add-flow.component';
import { CloneFlowComponent } from './components/deal/studio/clone-flow/clone-flow.component';
import { EditWhatsappTemplateButtonDialogComponent } from './components/deal/studio/edit-whatsapp-template-button-dialog/edit-whatsapp-template-button-dialog.component';
import { EditWhatsappTemplateLanguageDialogComponent } from './components/deal/studio/edit-whatsapp-template-language-dialog/edit-whatsapp-template-language-dialog.component';
import { EditWhatsappTemplateComponent } from './components/deal/studio/edit-whatsapp-template/edit-whatsapp-template.component';
import { StudioCanvasComponent } from './components/deal/studio/studio-canvas/studio-canvas.component';
import { StudioMenuBarComponent } from './components/deal/studio/studio-menu-bar/studio-menu-bar.component';
import { StudioComponent } from './components/deal/studio/studio.component';
import { AccountsReceivableComponent } from './components/intermediate/accounts-receivable/accounts-receivable.component';
import { CallbackComponent } from './components/intermediate/callback/callback.component';
import { HardcodedQrIntermediateComponent } from './components/intermediate/hardcoded-qr-intermediate/hardcoded-qr-intermediate.component';
import { IntermediateComponent } from './components/intermediate/intermediate.component';
import { NegotiationIntermediateComponent } from './components/intermediate/negotiation-intermediate/negotiation-intermediate.component';
import { CompanySettingsComponent } from './components/settings/components/company-settings/company-settings.component';
import { UserPasswordChangeComponent } from './components/settings/components/user-password-change/user-password-change.component';
import { CompanyCredentialsComponent } from './components/settings/components/settings/company-credentials/company-credentials.component';
import { AutofocusDirective } from './directives/my-auto-focus';
import { IconModule } from './icon.module';
import { WhatsappIconComponent } from './icons/whatsapp-icon/whatsapp-icon.component';
import { StatusParsePipe } from './pipes/status-parse.pipe';
import { Auth0ManagementApiService } from './services/auth0-management-api.service';
import { Auth0ManagementService } from './services/auth0-management.service';
import { CompanyApiService } from './services/company-api.service';
import { CompanyParametersApiService } from './services/company-parameters-api.service';
import { CompanyParametersService } from './services/company-parameters.service';
import { CompanyCredentialsApiService } from './services/company-credentials-api.service';
import { CompanyCredentialsService } from './services/company-credentials.service';
import { CompanyService } from './services/company.service';
import { ContactsGroupApiService } from './services/contacts-group-api.service';
import { ContactsGroupService } from './services/contacts-group.service';
import { CreditsApiService } from './services/credits/credits-api.service';
import { CreditsService } from './services/credits/credits.service';
import { DocumentsApiService } from './services/documents-api.service';
import { DocumentsService } from './services/documents.service';
import { FlowsApiService } from './services/flows-api.service';
import { FlowsService } from './services/flows.service';
import { LoginActivate } from './services/login-activate';
import { MaintenanceService } from './services/maintenance.service';
import { NegotiationOptionsService } from './services/negotiation-options.service';
import { NegotiationsApiService } from './services/negotiations-api.service';
import { NegotiationsService } from './services/negotiations.service';
import { StorageService } from './services/persistence.service';
import { CanActivateBilling } from './services/routing/CanActivateBilling';
import { ScheduleApiService } from './services/schedule-api.service';
import { ScheduleService } from './services/schedule.service';
import { SlotsApiService } from './services/slots-api.service';
import { SlotsService } from './services/slots.service';
import { StudioApiService } from './services/studio.api.service';
import { BillingSettingsComponent } from './components/settings/components/settings/billing-settings/billing-settings.component';
import { SettingsComponent } from './components/settings/components/settings/settings.component';
import { AddContactsGroupComponent } from './components/deal/contacts/add-contacts-group/add-contacts-group.component';
import { CompaniesSettingsComponent } from './components/settings/components/settings/companies-settings/companies-settings.component';
import { ModalSampleComponent } from './components/settings/components/settings/companies-settings/modal-sample/modal-sample.component';
import { DocumentationDetailsComponent } from './components/documentation/documentation-details/documentation-details.component';
import { MatTabsModule } from '@angular/material/tabs';
import { UserSettingsComponent } from './components/settings/components/settings/user-settings/user-settings.component';
import { CurrentSubscriptionComponent } from './components/settings/components/settings/billing-settings/current-subscription/current-subscription.component';
import { PaymentComponent } from './components/settings/components/settings/billing-settings/payment/payment.component';
import { SubscriptionModalComponent } from './components/settings/components/settings/billing-settings/subscription-modal/subscription-modal.component';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { CompanyListComponent } from './components/admin/company-list/company-list.component';
import { CompanyEnterpriseCreateComponent } from './components/admin/company-enterprise-create/company-enterprise-create.component';
import { CompanyEnterpriseEditPermissionsComponent } from './components/admin/company-enterprise-edit-permissions/company-enterprise-edit-permissions.component';
import { AdminAnalyticsComponent } from './components/analytics/admin-analytics/admin-analytics.component';
import { HtmlMessageDialogComponent } from './components/common/html-message-dialog/html-message-dialog.component';
import { NotificationsService } from './services/notifications.service';
import { EmailSettingsComponent } from './components/settings/components/settings/email-settings/email-settings.component';
import { SenderModalComponent } from './components/admin/sender-modal/sender-modal.component';
import { CompanyPermissionsApiService } from './services/company-permissions-api.service';
import { CompanyPermissionsService } from './services/company-permissions.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { CreateContentTemplateComponent } from './components/deal/studio/content-template/main/create-content-template.component';
import { ContentTemplateTypeSelectorComponent } from './components/deal/studio/content-template/selector/content-template-type-selector.component';
import { ContentTemplateFormComponent } from './components/deal/studio/content-template/form/content-template-form.component';
import { ContentTemplatePreviewComponent } from './components/deal/studio/content-template/preview/content-template-preview.component';
import { TwilioMediaUrlComponent } from './components/deal/studio/content-template/type-based-components';

export const createTranslateLoader = (httpHandler: HttpBackend) => {
  const newHttp = new HttpClient(httpHandler);
  return new TranslateHttpLoader(newHttp, '../assets/i18n/', '.json');
};

@NgModule({
  declarations: [
    AccountsReceivableComponent,
    AccountsReceivableDocumentsGridComponent,
    AddAccountsReceivableDocumentComponent,
    AddContactComponent,
    AddContactsGroupComponent,
    DocumentationDetailsComponent,
    AddDemoDocumentComponent,
    AddNegotiationComponent,
    AddNegotiationToSlotComponent,
    AddOptionComponent,
    AddSlotComponent,
    ScheduleSlotComponent,
    AlertComponent,
    AppComponent,
    AutofocusDirective,
    AutocompleteComponent,
    ConfirmDialogComponent,
    ContactsGridComponent,
    ContactGroupsGridComponent,
    ContactsScreenComponent,
    DealComponent,
    DemoDocumentsGridComponent,
    FlowRestarterComponent,
    FlowStoperComponent,
    InsertableTextAreaComponent,
    IntermediateComponent,
    LateralActionComponent,
    LateralTableActionsComponent,
    MonitorComponent,
    MonitorGridComponent,
    MonitorSlotDetailComponent,
    NegotiationCounterComponent,
    NegotiationIntermediateComponent,
    NegotiationsChartComponent,
    NegotiationsGridComponent,
    NegotiationsScreenComponent,
    OptionsGridComponent,
    TabGroupComponent,
    UploadContactsFileComponent,
    StudioComponent,
    EditWhatsappTemplateComponent,
    CreateContentTemplateComponent,
    ContentTemplateTypeSelectorComponent,
    TwilioMediaUrlComponent,
    ContentTemplateFormComponent,
    ContentTemplatePreviewComponent,
    EditWhatsappTemplateLanguageDialogComponent,
    EditWhatsappTemplateButtonDialogComponent,
    IntermediateMessagesModalComponent,
    SmsMessagesModalComponent,
    WhatsappMessagesModalComponent,
    UploadNegotiationsFileComponent,
    StudioMenuBarComponent,
    StudioCanvasComponent,
    DropableCanvasComponent,
    CanvasNodeComponent,
    CanvasNodeDetailsComponent,
    CanvasArrowComponent,
    CallDetailsComponent,
    MessageDetailsComponent,
    WhatsappDetailsComponent,
    ContentTemplateDetailsComponent,
    EmailDetailsComponent,
    CcpDetailsComponent,
    SignatureDetailsComponent,
    NegotiationVariablesComponent,
    AddFlowComponent,
    CloneFlowComponent,
    ContactSuscriptionComponent,
    DataIngestorSlotsSelectionComponent,
    HardcodedQrIntermediateComponent,
    CallPreviewComponent,
    PhonePickerComponent,
    FilesListComponent,
    CompanySettingsComponent,
    CompanyCredentialsComponent,
    DelayDetailsComponent,
    NegotiationProgressComponent,
    WhatsappIconComponent,
    BillingScreenComponent,
    BillingSubscriptionsComponent,
    SubscriptionCardComponent,
    BillingDetailsComponent,
    BillingTimerComponent,
    BillingSuccessComponent,
    CallbackComponent,
    ReviewWhatsappTemplateContentsListComponent,
    ReviewWhatsappTemplateContentDialogComponent,
    StartSlotComponent,
    StatusParsePipe,
    MonitorChatComponent,
    NewUserRegistrationComponent,
    AdminComponent,
    RebrandlyLinksComponent,
    CompanyListComponent,
    CompanyEnterpriseCreateComponent,
    CompanyEnterpriseEditPermissionsComponent,
    CompanyApiCredentialsListComponent,
    CompanyApiCredentialsCreateComponent,
    CompanyCredentialsCreateComponent,
    UserPasswordChangeComponent,
    BillingSettingsComponent,
    SettingsComponent,
    CompaniesSettingsComponent,
    ModalSampleComponent,
    UserSettingsComponent,
    CurrentSubscriptionComponent,
    PaymentComponent,
    SubscriptionModalComponent,
    AdminAnalyticsComponent,
    HtmlMessageDialogComponent,
    EmailSettingsComponent,
    SenderModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'en-US',
      loader: { provide: TranslateLoader, useFactory: createTranslateLoader, deps: [HttpBackend] },
    }),
    AuthModule.forRoot({
      ...env.auth,
      httpInterceptor: {
        ...env.httpInterceptor,
      },
      cacheLocation: 'localstorage',
    }),
    A11yModule,
    BrowserAnimationsModule,
    IconModule,
    CdkTableModule,
    ColorPickerModule,
    CoreModule,
    DragDropModule,
    FlexLayoutModule,
    FormsModule,
    MatCheckboxModule,
    MatDialogModule,
    MatButtonModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatPaginatorModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatMenuModule,
    MatTableModule,
    MatTooltipModule,
    MatAutocompleteModule,
    NgbModule,
    NgxChartsModule,
    ReactiveFormsModule,
    TextFieldModule,
    NgxQRCodeModule,
    MatSidenavModule,
    MatListModule,
    MatSelectModule,
    MatTabsModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JsonHttpInterceptor,
      multi: true,
    },
    {
      provide: JsonParser,
      useClass: CustomJsonParser,
    },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        ...new MatDialogConfig(),
        disableClose: true,
      } as MatDialogConfig,
    },
    Auth0ManagementService,
    Auth0ManagementApiService,
    CanActivateBilling,
    ContactsApiService,
    ContactsService,
    ContactsGroupApiService,
    ContactsGroupService,
    CompanyApiService,
    CompanyService,
    CompanyParametersApiService,
    CompanyParametersService,
    CompanyPermissionsApiService,
    CompanyPermissionsService,
    CompanyCredentialsApiService,
    CompanyCredentialsService,
    CreditsApiService,
    CreditsService,
    DocumentsApiService,
    DocumentsService,
    DynamicContactColumnsApiService,
    DynamicValuesApiService,
    FlowsApiService,
    FlowsService,
    LoginActivate,
    NegotiationOptionsService,
    NegotiationsApiService,
    NegotiationsService,
    SlotsApiService,
    ScheduleApiService,
    SlotsService,
    ScheduleService,
    StorageService,
    StudioApiService,
    MaintenanceService,
    NotificationsService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
