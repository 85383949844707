import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  public handleExceptionMessage(messageText, exceptionErr) {
    let errorHasJson = !!(exceptionErr && exceptionErr.error && JSON.parse(exceptionErr.error).title);
    let jsonlessErrorIsValid = exceptionErr + '' !== '[object Object]';
    if (errorHasJson) {
      return messageText + ' Error: ' + JSON.parse(exceptionErr.error).title;
    } else if (jsonlessErrorIsValid) {
      return messageText + ' Error: ' + exceptionErr;
    } else return messageText;
  }
}
