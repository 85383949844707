<div class="preview-component">
    <div class="preview-title">This is how your message will look like</div>
    <div class="preview-container">
        <div class="preview-phone">
            <div class="preview-header">
                <div class="profile-pic"></div>
                <div>Sample {{selectedType?.name}} message</div>
            </div>
            <div class="preview-body">
                <div class="message-time">11:40 PM</div>
                <div class="message-content">
                    <div class="message-body">{{sampleBody}}</div>

                    <!-- Preview Media -->
                    <div *ngIf="contentData.media && linkType === 'image'">
                        <img [src]="contentData.media" alt="Image" class="media-image"
                            *ngIf="!contentData.media.includes('}}')">
                        <img [src]="'assets/SignedealLogo3.jpg'" alt="Image" class="media-image"
                            *ngIf="contentData.media.includes('}}')">
                    </div>

                    <!--
                    <div *ngIf="contentData.media && linkType === 'video'">
                        <img [src]="contentData.media" alt="Image" class="media-image">
                    </div>

                    <div *ngIf="contentData.media && linkType === 'audio'">
                        <img [src]="contentData.media" alt="Image" class="media-image">
                    </div>

                    <div *ngIf="contentData.media && linkType === 'other'">
                        <img [src]="contentData.media" alt="Image" class="media-image">
                    </div>-->

                    <!-- Preview Actions -->
                    <!--
                    <div class="message-actions" *ngIf="false">
                        
                    </div>
                    -->
                </div>
            </div>
        </div>
    </div>
    <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-ok" type="submit" (click)="onSubmitTemplate()">Next</button>
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onNavigateBackwards()">Back</button>
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
</div>
