import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-insertable-textarea',
  template: `<mat-form-field class="full-width-textarea" appearance="outline">
    <textarea
      *ngIf="!asInput"
      matInput
      autocomplete="off"
      [disabled]="disabled"
      cdkTextareaAutosize
      cdkAutosizeMinRows="5"
      cdkAutosizeMaxRows="5"
      [value]="value"
      [maxLength]="maxArealength"
      (focus)="onFocus($event)"
      (focusout)="onFocusOut()"
      (change)="onChange($event)"
    ></textarea>
    <input
      *ngIf="asInput"
      matInput
      autocomplete="off"
      [disabled]="disabled"
      [value]="value"
      (focus)="onFocus($event)"
      (focusout)="onFocusOut()"
      (change)="onChange($event)"
    />
  </mat-form-field>`,
})
export class InsertableTextAreaComponent {
  @Input() value = '';
  @Input() disabled: boolean;
  @Input() asInput = false;
  @Input() maxArealength = null;
  @Output() valueChange = new EventEmitter();
  private readonly DEFAULT_MAX_AREA_LENGTH = 32000;
  selectedElement = null;

  constructor() {
    this.maxArealength = this.maxArealength ? this.maxArealength : this.DEFAULT_MAX_AREA_LENGTH;
  }

  onFocus(event) {
    this.selectedElement = event.target;
  }

  onFocusOut() {
    this.selectedElement = null;
  }

  onChange(event) {
    this.valueChange.emit(event.target.value);
  }

  insertText(text) {
    try {
      if (this.selectedElement) {
        const start = this.selectedElement.selectionStart;
        const end = this.selectedElement.selectionEnd;
        let innerTxt = this.selectedElement.value;
        innerTxt = innerTxt.substring(0, start) + text + innerTxt.substring(end);
        this.selectedElement.value = innerTxt;
        this.selectedElement.selectionStart = start + text.length;
        this.selectedElement.selectionEnd = start + text.length;
        this.valueChange.emit(innerTxt);
      }
    } catch (err) {
      console.log('Error while inserting text');
    }
  }
}
