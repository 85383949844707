import { DynamicValue } from './dynamicvalue';
import { PaginatedDataRequest } from './pagination';

export class Contact {
  public contactId: number;
  public email: string;
  public name: string;
  public lastName: string;
  public language: string;
  public phone: string;
  public companyId: number;
  public dynamicValues: Array<DynamicValue>;
  public lastModified: Date;
  public created: Date;

  constructor(contact: Contact | ContactDto) {
    this.contactId = contact?.contactId;
    this.email = contact?.email;
    this.name = contact?.name;
    this.lastName = contact?.lastName;
    this.language = contact?.language;
    this.phone = contact?.phone;
    this.companyId = contact?.companyId;
    this.dynamicValues = contact?.dynamicValues ? contact?.dynamicValues : [];
    this.lastModified = contact?.lastModified;
    this.created = contact?.created;
  }

}

export class ContactDto {
  public contactId: number;
  public email: string;
  public name: string;
  public lastName: string;
  public language: string;
  public phone: string;
  public companyId: number;
  public dynamicValues: Array<DynamicValue>;
  public lastModified: Date;
  public created: Date;

  constructor(contact: Contact) {
    this.contactId = contact.contactId;
    this.email = contact.email;
    this.name = contact.name;
    this.lastName = contact.lastName;
    this.language = contact.language;
    this.phone = contact.phone;
    this.companyId = contact.companyId;
    this.dynamicValues = contact.dynamicValues ? contact.dynamicValues : [];
    this.lastModified = contact?.lastModified;
    this.created = contact?.created;
  }
}

export interface PaginatedContactsDataRequest extends PaginatedDataRequest {
  mineOnly?: boolean;
}