<div>
  <div class="form-title">Customize your message</div>
  <form [formGroup]="templateCreateForm" (ngSubmit)="onSubmit()" class="whole-form" autocomplete="off">
    <div class="form-fields-container">

      <mat-form-field appearance="fill" class="name-field">
        <mat-label>Name</mat-label>
        <input matInput formControlName="name" type="text" autocomplete="off"
          required pattern="[a-zA-Z0-9_]+" (change)="updateDisabledNextStatus()"/>
        <mat-icon class="message-tooltip"
          matTooltip="Write here a unique and short name to identify this template. It can only be composed of letters, numbers or underscores. No other Symbols or spaces are allowed." matTooltipPosition="right">info</mat-icon>
      </mat-form-field>

      <mat-form-field appearance="fill" class="form-field">
        <mat-label>Language</mat-label>
        <mat-select formControlName="lang">
          <mat-option *ngFor="let language of languages" [value]="language.code">{{ language.name }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <!-- Body and variables Section -->
    <div class="body-and-variables">
      <div class="body-container">
        <mat-form-field appearance="fill" class="form-field body-field">
          <mat-label>Body</mat-label>
          <textarea formControlName="body" required
            matInput placeholder="Add the message's content here..."
            class="text-area" maxlength="640" (change)="updateDisabledNextStatus()"
            ></textarea>
        </mat-form-field>
      </div>
      <div class="variables-container">
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="addVariable()">
          Add Variable
          <mat-icon class="message-tooltip"
          matTooltip="Adds a vaiable to the message's content. These variables need to be mapped to a negotiation variable" matTooltipPosition="right">info</mat-icon>
        </button>
        <div class="table-container">
          <table *ngIf="variables && variables.length">
            <thead>
                <tr>
                    <th></th>
                    <th style="width: 150px;">Negotiation Variables</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
              <tr *ngFor="let variable of variables; index as indx">
                  <td>{{indx + 1}}</td>
                  <td>
                    <mat-select (selectionChange)="updateVariable(indx, $event)" [value]="variable">
                      <mat-option *ngFor="let negVariable of variableValues" [value]="negVariable.value">
                        {{ negVariable.name }}
                      </mat-option>
                    </mat-select>
                  </td>
                  <td>
                    <button mat-button (click)="onRemoveVariable(indx)" type="button">
                      <mat-icon>remove_circle_outline</mat-icon>
                    </button>
                  </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <app-twilio-media-url *ngIf="contentType === templateTypes.MEDIA" #mediaUrl
      [variables]="variables" (onStatusUpdate)="onStatusUpdate($event)"
    ></app-twilio-media-url>

    
    <!-- Action Buttons: Hacerlo con otro componente con su propio form
    <div class="action-btns" *ngIf="showActionBtns && templateCreateForm.value.actions && templateCreateForm.value.actions.length">
      <div>
        <mat-form-field appearance="fill" class="name-field" *ngFor="let action of templateCreateForm.value.actions">
          <mat-label>Button Text</mat-label>
          <input matInput formControlName="action.name" type="text" autocomplete="off" required pattern="[a-zA-Z0-9_]+"/>
          <mat-icon class="message-tooltip"
            matTooltip="Write here a unique and short name to identify this template. It can only be composed of letters, numbers or underscores. No other Symbols or spaces are allowed." matTooltipPosition="right">info</mat-icon>
        </mat-form-field>
      </div>
    </div>-->

    <!-- Buttons Section -->
    <div class="buttons-container">
      <button class="sgnd-btn sgnd-btn-ok" [disabled]="nextIsDisabled" type="submit">Next</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onNavigateBackwards()">Back</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </form>
</div>