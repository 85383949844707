import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NegotiationType } from 'app/model/negotiation-type';
import { SlotsService } from 'app/services/slots.service';
import { NegotiationsService } from 'src/app/services/negotiations.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-upload-negotiations-file',
  templateUrl: './upload-negotiations-file.component.html',
  styleUrls: ['./upload-negotiations-file.component.css'],
})
export class UploadNegotiationsFileComponent implements OnInit {
  chosenFileName: string;
  chosenFile: any;
  negotiationType: number;
  public allNegotiationTypes = NegotiationType;
  public negotiationTypesOptions;

  constructor(
    private slotsService: SlotsService,
    public negotiationsService: NegotiationsService,
    public dialogRef: MatDialogRef<UploadNegotiationsFileComponent>,
    private snackBar: MatSnackBar,
    private uiService: UIService

  ) {
    const nt = this.allNegotiationTypes;
    this.negotiationTypesOptions = Object.keys(this.allNegotiationTypes)
      .filter((k) => !isNaN(Number(k)) && Number(k) < 3)
      .map((key) => ({ key, value: nt[key] }));
  }

  setChosenFile(fileInput: Event) {
    const control: any = fileInput.target;
    if (!control.files || control.length === 0) {
      this.chosenFile = null;
      this.chosenFileName = null;
    } else {
      this.chosenFile = control.files[0];
      this.chosenFileName = this.chosenFile.name;
    }
  }

  onSelectNegotiationType(nType: string) {
    this.negotiationType = parseInt(nType, 10);
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.chosenFile, this.chosenFileName);
    const types = ['Demo','AccountsPayable', 'ThirdParty'];
    this.uiService.toggleGlobalSpinner(true);

    this.negotiationsService.uploadExcel(types[this.negotiationType], formData).subscribe(
      (result) => {
        // This method refreshes the negotiation list in the service. That service needs some rewriting...
        this.negotiationsService.getData();
        this.slotsService.getData();
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open(`${result} negotiations uploaded.`, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.dialogRef.close();
      },
      (error) => {
        this.snackBar.open('Error: ' + error.title, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }
}
