import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import NEGOTIATION_VARIABLES, { NegotiationVariable } from 'app/model/studio-negotiation-variables';

@Component({
  selector: 'app-negotiation-variables',
  templateUrl: './negotiation-variables.component.html',
  styleUrls: ['./negotiation-variables.component.css'],
})
export class NegotiationVariablesComponent implements OnInit {
  @Input() customVars: NegotiationVariable[];
  @Output() selectOption = new EventEmitter();
  negotiationVariables: NegotiationVariable[] = NEGOTIATION_VARIABLES;
  
  constructor() {}

  ngOnInit(): void {
    if (this.customVars) {
      this.negotiationVariables = this.negotiationVariables.concat(this.customVars);
    }
  }

  preventFocus(event) {
    event.preventDefault();
  }

  selectNegotiationVariable(negVar: NegotiationVariable) {
    this.selectOption.emit(`{{${negVar.value}}}`);
  }
}
