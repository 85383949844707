import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation } from 'app/model/negotiation';
import { Slot } from 'app/model/slot';
import { NegotiationsService } from 'app/services/negotiations.service';
import { SlotsService } from 'app/services/slots.service';

@Component({
  selector: 'app-add-negotiation-to-slot',
  templateUrl: './add-negotiation-to-slot.component.html',
  styleUrls: ['./add-negotiation-to-slot.component.css'],
})
export class AddNegotiationToSlotComponent implements OnInit {
  // public availableFlows: Flow[];
  public availableSlots: Slot[];
  public selectedSlot: Slot;
  public processing = false;

  constructor(
    private snackBar: MatSnackBar,
    private slotsService: SlotsService,
    private negotiationService: NegotiationsService,
    @Inject(MAT_DIALOG_DATA) public data: { negotiation: Negotiation; onRefresh: any },
    public dialogRef: MatDialogRef<AddNegotiationToSlotComponent>
  ) {
    const slots = this.slotsService.slots.value;
    const groups = this.negotiationService.getGroupedNegotiations();
    let filteredGroups = this.filterGroupsByFlow(groups, this.data.negotiation?.flowId);
    filteredGroups = this.addSlotNames(filteredGroups, slots);
    this.availableSlots = filteredGroups;
  }

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }

  addSlotNames(filteredGroups: any[], slots: any[]) {
    filteredGroups.forEach((group) => {
      const slot = slots.find((s) => s.slotId === group.slotId);
      if (slot) {
        group.name = slot.name;
      }
    });
    return filteredGroups;
  }

  filterGroupsByFlow(groups: any, flowId: number) {
    const filteredGroups = groups.filter((g) => !!g.negotiations.find((n) => n.flowId === flowId));
    return filteredGroups;
  }

  onSubmit() {
    this.processing = true;
    this.negotiationService
      .addNegotiationToSlot(
        this.data.negotiation?.negotiationId,
        this.selectedSlot.slotId,
        this.data?.negotiation.flowId
      )
      .subscribe((response) => {
        this.snackBar.open(`Negotiation Added successfully to Channel: ${this.selectedSlot.name}.`, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.data.onRefresh?.();
        this.processing = false;
        this.dialogRef.close();
        this.negotiationService.getData();
      });
  }
}
