import { Component } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Auth0ManagementService } from 'app/services/auth0-management.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'user-password-change',
  templateUrl: './user-password-change.component.html',
  styleUrls: ['./user-password-change.component.css'],
})
export class UserPasswordChangeComponent {
  passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  regOneLower = new RegExp('(?=.*[a-z])');
  regOneUpper = new RegExp('(?=.*[A-Z])');
  regOneNumber = new RegExp('(?=.*[0-9])');
  regOneSpecial = new RegExp('(?=.*[!@#$%^&*])');
  regAtLeastEight = new RegExp('(?=.{8,})');

  formOptions: AbstractControlOptions;
  form = this.formBuilder.group({
    newPwd: new FormControl('', [Validators.required, Validators.minLength(8), Validators.pattern(this.passwordRegex)]),
    confirmPwd: new FormControl('', [Validators.required]),
  });

  constructor(
    public dialogRef: MatDialogRef<UserPasswordChangeComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public uiService: UIService,
    public auth0ManagementService: Auth0ManagementService
  ) {
    this.form.addValidators([this.passwordValidator(this.form.get('newPwd'), this.form.get('confirmPwd'))]);
  }

  passwordValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if (controlOne.value !== controlTwo.value) {
        return { pwdsDontMatch: true };
      }
      return null;
    };
  }

  onSubmit() {
    this.uiService.toggleGlobalSpinner(true);
    const newPw = this.form.get('newPwd');
    this.auth0ManagementService.userPasswordChange(newPw.value).subscribe(
      () => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open(`Password changed successfully`, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.dialogRef.close();
      },
      (errResponse) => {
        this.uiService.toggleGlobalSpinner(false);
        const error = JSON.parse(errResponse.error);
        this.snackBar.open(`Error: ` + error.title, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  get oldPwd() {
    return this.form.get('oldPwd');
  }

  get newPwd() {
    return this.form.get('newPwd');
  }

  get confirmPwd() {
    return this.form.get('confirmPwd');
  }
}
