<div class="title">Run Channel</div>
<div class="message">{{ message }}</div>
<form [formGroup]="startSlotForm" (ngSubmit)="onSubmit()">
  <div class="form-fields-container">
    <div class="message">Select the status of the negotiations you want to run</div>
    <mat-form-field class="regular-field">
      <mat-label>Status</mat-label>
      <mat-select matInput name="status" ngDefaultControl formControlName="status" multiple required>
        <mat-option *ngFor="let item of statusKeys" [value]="item" (click)="onSelectingItem(item)"
          >{{ item | statusParse }} {{ statusDescription[item] ? '-' : '' }} {{ statusDescription[item] }}</mat-option
        >
      </mat-select>
    </mat-form-field>
    <div class="buttons-container">
      <button class="sgnd-btn sgnd-btn-ok" [disabled]="!startSlotForm.valid" type="submit">Run Channel</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</form>
