import { Component, OnInit, Inject, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation } from 'app/model/negotiation';
import { NegotiationMessageService } from 'app/services/negotiation-message.service';

@Component({
  selector: 'app-monitor-chat',
  templateUrl: './monitor-chat.component.html',
  styleUrls: ['./monitor-chat.component.css'],
})
export class MonitorChatComponent implements OnInit, OnDestroy {
  @ViewChild('messagesRef') messagesRefContent: ElementRef;
  public messages = [];
  public contactName = '';
  public messageText = '';
  public contactPhone: string | null = '';

  //eslint-disable-next-line
  private readonly INTERVAL_TIME = 20 * 1000;
  private interval = null;
  constructor(
    public dialog: MatDialogRef<MonitorChatComponent>,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public negotiation: Negotiation,
    private messagesService: NegotiationMessageService
  ) {
    this.contactName = this.negotiation.contactName;
    this.contactPhone = this.negotiation.contactPhone;
    this.getMessages();
  }

  /**
   * Init interval to refresh messages
   */
  ngOnInit(): void {
    this.interval = setInterval(() => this.getMessages(), this.INTERVAL_TIME);
  }

  /**
   * Clear interval on component destroy
   */
  ngOnDestroy(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  public close() {
    this.dialog.close();
  }

  /**
   * Send new message if there is text in the text box.
   * On sent clear text box and refresh messages.
   */
  public sendMessage() {
    const now = new Date().toISOString();
    if (!this.messageText || this.messageText.trim().length === 0) {
      return;
    }
    this.messagesService
      .postMonitorMessage(
        null,
        this.negotiation.negotiationId,
        this.negotiation.contactId,
        now,
        this.messageText,
        false,
        false
      )
      .subscribe(
        () => {
          this.messageText = '';
          this.getMessages();
        },
        (err) => {
          console.error(err);
          this.snackBar.open('The message could not be delivered due to an error.', null, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
          });
        }
      );
  }

  private scrollToBottom() {
    try {
      this.messagesRefContent.nativeElement.scrollTop = this.messagesRefContent.nativeElement.scrollHeight;
    } catch (err) {}
  }

  /**
   * Get messages and mark as read all that haven't been read.
   * Scroll to bottom if it has new messages
   */
  private getMessages() {
    this.messagesService.getMonitorMessages(this.negotiation?.negotiationId).subscribe(
      (messages: any) => {
        const hasNewMessages = this.messages?.length !== messages?.length;
        this.messages = messages;
        if (hasNewMessages) {
          setTimeout(() => this.scrollToBottom(), 1);
        }
        this.markReadMessages();
      },
      (err) => {
        console.error(err);
        this.snackBar.open('The message could not be delivered due to an error.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  /**
   * Mark all messages that are not read and sent by intermediate as read.
   * Sender value for intermediate is 1
   */
  private markReadMessages() {
    const unreadMessages = this.messages.filter((msg) => !msg.read && msg.sender === 1);
    this.messagesService.markAsRead(unreadMessages);
  }
}
