import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import { CompanySettingsService } from 'app/components/settings';
import { UserService } from 'core/services/user.service';

@Component({
  selector: 'app-nav-user-widget',
  templateUrl: './nav-user-widget.component.html',
  styleUrls: ['./nav-user-widget.component.css'],
})
export class NavUserWidgetComponent implements OnInit {
  public menuIsOpen = true;
  public showSettingsMenu = false;

  constructor(
    private router: Router,
    public auth: AuthService,
    public companySettingsService: CompanySettingsService,
    public userService: UserService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.companySettingsService.$companyData.subscribe((value) => {
      this.showSettingsMenu = value && value.companyId != -1 && value.companyId != null;
    });
  }

  ngOnInit(): void {}

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  logout() {
    this.auth.logout({ returnTo: this.doc.location.origin });
  }

  onSettingsClick() {
    this.router.navigate(['settings']);
  }
}
