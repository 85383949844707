import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { ContactsGroup } from 'app/model/contacts-group';
import { ContactsGroupService } from 'app/services/contacts-group.service';
import { UIService } from 'core/services/ui.service';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
  selector: 'app-upload-contacts-file',
  templateUrl: './upload-contacts-file.component.html',
  styleUrls: ['./upload-contacts-file.component.css'],
})
export class UploadContactsFileComponent implements OnInit {
  chosenFileName: string;
  chosenFile: any;
  contactsGroups: ContactsGroup[];
  selectedContactsGroup: ContactsGroup;

  constructor(
    private dialog: MatDialog,
    private uiService: UIService,
    public contactService: ContactsService,
    public contactsGroupService: ContactsGroupService,
    public dialogRef: MatDialogRef<UploadContactsFileComponent>,
    private snackBar: MatSnackBar
  ) {}

  setChosenFile(fileInput: Event) {
    const control: any = fileInput.target;
    if (!control.files || control.length === 0) {
      this.chosenFile = null;
      this.chosenFileName = null;
    } else {
      this.chosenFile = control.files[0];
      this.chosenFileName = this.chosenFile.name;
    }
  }

  uploadFile() {
    const formData = new FormData();
    formData.append('file', this.chosenFile, this.chosenFileName);
    this.uiService.toggleGlobalSpinner(true);
    this.contactService.uploadExcel(formData, this.selectedContactsGroup?.id).subscribe(
      (result) => {
        this.contactService.getData(); // This method refreshes the contacts list in the service. That service needs some rewriting...
        this.uiService.toggleGlobalSpinner(false);
        this.dialog.open(ConfirmDialogComponent, {
          data: {
            // eslint-disable-next-line
            message: `${result?.added} Contacts Added. \n${result?.updated} Contacts Updated. \n${result?.assignedToGroup} Contacts Assigned to group. \n${result?.discarded} Contacts Discarded.`,
          },
        });
        this.dialogRef.close();
      },
      (error) => {
        const message = error?.error ?? error;
        this.snackBar.open('Error: ' + message, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  ngOnInit(): void {
    this.contactsGroupService.getContactsGroups().subscribe((contactsGroups) => {
      this.contactsGroups = contactsGroups;
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
