import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.css']
})
export class CallbackComponent implements OnInit {

  constructor(
    private uiService: UIService,
    private auth: AuthService,
    private router: Router
  ) {
    this.uiService.toggleGlobalSpinner(true);
    this.auth.isAuthenticated$.subscribe((isAuth) => {
      if(isAuth) {
        console.log(this.auth.isAuthenticated$);
        this.router.navigate(['contacts']);
      }
    });
    
  }

  ngOnInit(): void {
  }

}
