<div class="title">
  {{ title }}
</div>
<form [formGroup]="senderForm" autocomplete="off">
  <div class="form-fields-container">
    <mat-form-field appearance="fill" class="regular-field">
      <mat-label>SenderPhoneNumber</mat-label>
      <input
        matInput
        formControlName="senderPhoneNumber"
        type="text"
        placeholder="phone number provided by twilio"
        [pattern]="phoneNumberRegex"
        required
      />
    </mat-form-field>
    <mat-form-field appearance="fill" class="regular-field">
      <mat-label>TwilioAccountID</mat-label>
      <input
        matInput
        formControlName="twilioAccountId"
        type="text"
        placeholder="SigneDeal's accountId or subaccountId"
      />
    </mat-form-field>

    <div class="buttons-container">
      <button class="sgnd-btn sgnd-btn-ok" [disabled]="!senderForm.valid" (click)="onConfirm()">Save</button>
      <button class="sgnd-btn" *ngIf="!isNew" (click)="onDelete()">Delete</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</form>
