 <div class="intermediate-container">
    <div class="intermediate-component" *ngIf="companyFlowConfiguration">
        <div class="intermediate-header header-company-style"
            [style.background]="companyFlowConfiguration.primaryColor">
            <img [src]="companyFlowConfiguration.iconUrl" style="height: 2.6rem" />
        </div>
        <div class="intermediate-content">
            <h1>Scan this QR Code to get special discounts</h1>
            <ngx-qrcode id="qrelement"
               [elementType]="elementType"
               [errorCorrectionLevel]="correctionLevel"
               [value]="companyFlowConfiguration.redirectUrl"
               [width]="350"
               cssClass="coolQRCode">
            </ngx-qrcode>
        </div>
        <div class="intermediate-footer header-company-style"
            [style.background]="companyFlowConfiguration.primaryColor"
            [style]="cssHeaderStyles"></div>
    </div>
</div>
