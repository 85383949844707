export interface PaginatedDataRequest {
  pageSize?: number;
  pageNumber?: number;
  filterByParameter?: string[];
  filterString?: string;
  sortedBy?: string;
  sortDirection?: string;
}

export interface PaginatedDataResponse<T> {
  data: Array<T>;
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
  nextPageNumber: number;
  previousPageNumber: number;
  hasSchedule?: boolean;
}

export class PaginationUtils {
  public static CastPaginatedResponse<T, U>(
    src: PaginatedDataResponse<T>,
    convert: (item: T) => U
  ): PaginatedDataResponse<U> {
    const dest: PaginatedDataResponse<U> = {
      data: src.data.map((x) => convert(x)),
      currentPage: src.currentPage,
      totalPages: src.totalPages,
      pageSize: src.pageSize,
      totalCount: src.totalCount,
      hasPreviousPage: src.hasPreviousPage,
      hasNextPage: src.hasNextPage,
      nextPageNumber: src.nextPageNumber,
      previousPageNumber: src.previousPageNumber,
      hasSchedule: src.hasSchedule,
    };
    return dest;
  }
}
