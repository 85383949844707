<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Description</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="full-width-textarea">
    <input matInput [(ngModel)]="description" />
  </mat-form-field>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Email</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-accordion>
    <div class="fields-container">
      <mat-form-field>
        <mat-form-label>Language</mat-form-label>
        <mat-select [(value)]="language" (selectionChange)="onLanguageSelect()">
          <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Subject</mat-panel-title>
      </mat-expansion-panel-header>
      <app-insertable-textarea *ngIf="subjectMessage"
        #subjectField
        [(value)]="subjectMessage.content"
        [asInput]="true"
      ></app-insertable-textarea>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Body</mat-panel-title>
      </mat-expansion-panel-header>
      <app-html-editor #bodyField [(value)]="bodyMessage.content" *ngIf="bodyMessage"></app-html-editor>
    </mat-expansion-panel>
  </mat-accordion>
</mat-expansion-panel>

<app-negotiation-variables [customVars]="customVars" (selectOption)="insertText($event)"></app-negotiation-variables>
<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="saveMessages()">Save</button>
</div>
