import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { MonitorSlot } from 'app/model/monitor-slot';
import { NegotiationType } from 'app/model/negotiation-type';
import { PaginatedSlotsDataRequest, Slot } from 'app/model/slot';
import { SlotStatus } from 'app/model/slot-status';
import { MonitorService } from 'app/services/monitor.service';
import { SlotsService } from 'app/services/slots.service';
import { FilterPageEvent, FilterPaginatorComponent, FilterPaginatorResetMode } from 'core/components/filter-paginator/filter-paginator.component';
import { UIService } from 'core/services/ui.service';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { NegotiationsChartComponent } from '../negotiations-chart/negotiations-chart.component';
import { MonitorSlotDetailComponent } from './../monitor-slot-detail/monitor-slot-detail.component';

@Component({
  selector: 'app-monitor-grid',
  templateUrl: './monitor-grid.component.html',
  styleUrls: ['./monitor-grid.component.css'],
})
export class MonitorGridComponent implements OnInit, OnDestroy {
  @Input() reload: Subject<PaginatedSlotsDataRequest>;
  @Output() selectedSlots = new EventEmitter<MonitorSlot[]>();
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  
  dataSource;
  slots: MonitorSlot[] = [];
  displayedColumns = ['select', 'name', 'type', 'status', 'negotiations', 'progress', 'actions'];
  selection = new SelectionModel<MonitorSlot>(true, []);
  monitorSlotsSubscription: Subscription;
  public resetFilterPaginator: Subject<FilterPaginatorResetMode> = new Subject();
  public setFilterState: BehaviorSubject<FilterPageEvent> = new BehaviorSubject(null);
  public dataLength: number;

  private currentRequest: PaginatedSlotsDataRequest;

  constructor(
    private dialog: MatDialog,
    private monitorService: MonitorService,
    private slotsService: SlotsService,
    private uiService: UIService,
    private snackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    this.monitorSlotsSubscription = this.monitorService.$slots.subscribe((paginatedSlots) => {
      if (paginatedSlots) {
        this.dataLength = paginatedSlots.totalCount;
        this.slots = paginatedSlots.data;
        this.dataSource = new MatTableDataSource<MonitorSlot>(this.slots);
        const selected = this.selection.selected.filter((sel) => this.slots.some((sl) => sl.slotId === sel.slotId));
        this.selection.clear();
        this.selection.select(...selected);
        this.selectedSlots.emit(this.selection.selected);
      }
      this.uiService.toggleGlobalSpinner(false);
    });
    this.reload.subscribe((state: PaginatedSlotsDataRequest) => {
      if (this.currentRequest != null) {
        if (state.resetPageNumber) {
          this.resetFilterPaginator.next(FilterPaginatorResetMode.PAGINATOR);
        }
        this.refreshData({
          ownedOnly: state?.ownedOnly ?? this.currentRequest.ownedOnly,
          pageNumber: state.resetPageNumber ? 1 : this.currentRequest.pageNumber
        });
      }
      else {
        this.currentRequest = {
          ownedOnly: state?.ownedOnly ?? false
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.monitorSlotsSubscription.unsubscribe();
  }

  isSlotSelected(slot: Slot) {
    return this.selection.selected.some((sl) => sl.slotId === slot.slotId);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.dataSource.data);
  }

  onOpenDetails(slot: MonitorSlot) {
    this.dialog.open(MonitorSlotDetailComponent, this.getMonitorSlotDetailDialogConfig(slot));
  }

  onViewChart(slot: MonitorSlot) {
    this.dialog.open(NegotiationsChartComponent, this.getMonitorSlotDetailDialogConfig(slot));
  }

  onCancelSlot(slot: MonitorSlot) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Do you want to cancel the execution of the selected Channel?' },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.slotsService.cancelSlots([slot.slotId]).subscribe(
            () => {
              this.refreshData(this.currentRequest);
              this.snackBar.open(`Channel execution canceled successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorResponse) => {
              this.snackBar.open('Error: ' + errorResponse.error, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  getRoundedPercentage(value) {
    return Math.round(value);
  }

  onRemoveSlot(slot: MonitorSlot) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Channel?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.slotsService.deleteSlot(slot.slotId).subscribe(
            () => {
              this.refreshData(this.currentRequest);
              this.snackBar.open(`Channel deleted successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorResponse) => {
              this.snackBar.open('Error: ' + errorResponse.error, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  emitSelectedSlots() {
    this.selectedSlots.emit(this.selection.selected);
  }

  intToNegotiationType(n: number): string {
    return this.capitalize(NegotiationType[n]);
  }

  intToSlotStatus(n: number): string {
    return this.capitalize(SlotStatus[n]);
  }

  private capitalize(text: string, splitStr: string = '_'): string {
    return text && splitStr
      ? text
        .toLowerCase()
        .split(splitStr)
        .map((x) => x.charAt(0).toUpperCase() + x.substring(1, x.length))
        .join(' ')
      : text;
  }

  /**
   * Returns formatting settings and data source for the MonitorSlotDetail component.
   */
  private getMonitorSlotDetailDialogConfig(slot: MonitorSlot) {
    return {
      panelClass: 'custom-dialog-container',
      maxWidth: '1350px',
      minWidth: '400px',
      data: {
        slot: slot,
        ownedOnly: this.currentRequest.ownedOnly
      }
    };
  }

  /**
   * Returns true if the given negotiation can be canceled.
   */
  cannotBeCanceled(element: any): boolean {
    const invalidStatus = element.status === SlotStatus.CANCELED;
    const invalidType = element.type === NegotiationType.MIXED;
    const result = invalidStatus || invalidType;
    return result;
  }

  private refreshData(dataRequest: PaginatedSlotsDataRequest) {
    dataRequest.pageSize ??= this.currentRequest?.pageSize ?? FilterPaginatorComponent.DEFAULT_PAGE_SIZE;
    dataRequest.pageNumber ??= 1;
    dataRequest.sortedBy ??= this.currentRequest?.sortedBy;
    dataRequest.sortDirection ??= this.currentRequest?.sortDirection;
    dataRequest.ownedOnly ??= this.currentRequest?.ownedOnly;
    this.currentRequest = dataRequest;
    this.uiService.toggleGlobalSpinner(true);
    this.monitorService.refreshSlotsData(dataRequest);
  }

  public onFilterPage(event: FilterPageEvent) {
    this.refreshData({
      pageSize: event.pageSize,
      pageNumber: event.pageNumber,
      filterByParameter: ['SLOTNAME', 'STATUS'],
      filterString: event.filterString
    });
  }

  public onSortSlots(sort: Sort) {
    this.resetFilterPaginator.next(FilterPaginatorResetMode.PAGINATOR);
    this.refreshData({
      sortedBy: sort.active,
      sortDirection: sort.direction.toUpperCase()
    });
  }
}
