<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>Description</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field class="full-width-textarea">
        <input matInput [(ngModel)]="description"/>
    </mat-form-field>
  </mat-expansion-panel>

  <mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title style="align-items: center;">Templates</mat-panel-title>
    </mat-expansion-panel-header>
    <button mat-button (click)="onCreateNewTemplate()"
      matTooltip="Request new template creation"
      class="request-template-btn">
      Request New Template
    </button>

    <span [hidden]="availableTemplates && availableTemplates.length">No Templates available</span>
    
    <button mat-button (click)="onAddTemplateLang()" class="map-template-btn"
      matTooltip="Map language to a template"
      [hidden]="!availableTemplates || !availableTemplates.length">
      <mat-icon>add_circle</mat-icon>
      Add Template Mapping
    </button>

    <div [hidden]="!availableTemplates || !availableTemplates.length"
      class="templates-map-meta-container">
      <div *ngFor="let template of templatesMap"
        class="template-lang-map-container">

        <button mat-button (click)="onShowTemplatePreview(template)"
          [disabled]="!template|| !template.whatsAppContentTemplateId"
          matTooltip="View Template details" class="marginal">
          <mat-icon>search</mat-icon>
        </button>

        <mat-form-field class="marginal">
          <mat-form-label>Content Template</mat-form-label>
          <mat-select [(value)]="template.whatsAppContentTemplateId" (selectionChange)="onTemplateSelect()">
            <mat-option *ngFor="let contentTemplate of availableTemplates" [value]="contentTemplate.id" >
              <mat-icon *ngIf="showWarning(contentTemplate)">warning</mat-icon>
              {{ contentTemplate.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <mat-form-field style="display: flex" class="marginal">
          <mat-form-label>Language</mat-form-label>
          <mat-select [(value)]="template.language" (selectionChange)="onLanguageSelect()">
            <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
          </mat-select>
        </mat-form-field>

        <button mat-button (click)="onRemoveTemplateLang(template)" matTooltip="Remove Template to language mapping">
          <mat-icon>delete</mat-icon>
        </button>
      </div>
    </div>

    <!--
    <div *ngIf="selectedTemplate && selectedTemplate.body" class="template-text">
      <mat-panel-title>Message</mat-panel-title>
      <div class="template-text-container">
        {{ selectedTemplate.body }}
      </div>
    </div>
    -->

    <!--
    <mat-form-field style="display: flex">
      <mat-form-label>Language</mat-form-label>
      <mat-select [(value)]="selectedTemplateContent" (selectionChange)="onLanguageSelect()">
        <mat-select-trigger>
          <mat-icon *ngIf="selectedTemplateContent?.status == 0">warning</mat-icon>
          {{ selectedTemplateContent?.language }}
        </mat-select-trigger>
        <mat-option *ngFor="let templateContent of selectedTemplate.whatsappOptionTemplateContents"
          [value]="templateContent">
          <mat-icon *ngIf="templateContent.status == 0">warning</mat-icon>
          {{ templateContent.language }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    -->

    

    <!--
    <div *ngIf="selectedTemplate" class="variables-container">
      <mat-panel-title>Variables</mat-panel-title>
      <div>
        <mat-form-field style="display: flex" *ngFor="let property of properties; let $index = index">
          <mat-form-label>{{ property }}</mat-form-label>
          <mat-select [(value)]="props[$index]" [disabled]="$index !== 0 && !props[$index - 1]" (selectionChange)="setChanged()">
            <mat-option *ngFor="let variableOption of whatsappParameterOptions" [value]="variableOption.value">{{
              variableOption.label
              }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    -->
  </mat-expansion-panel>
  
  <div class="buttons-container">
    <!-- Verify that languages don't get repeated when saving -->
    <button class="sgnd-btn sgnd-btn-ok" (click)="onSave()">Save</button>
  </div>