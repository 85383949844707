import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowAction } from 'app/model/flow-action';
import { FlowActionCall } from 'app/model/flow-action-call';
import { FlowActionCcp } from 'app/model/flow-action-ccp';
import { FlowActionDelay } from 'app/model/flow-action-delay';
import { FlowActionEmail } from 'app/model/flow-action-email';
import { FlowActionMessage } from 'app/model/flow-action-message';
import { FlowActionSignature } from 'app/model/flow-action-signature';
import { FlowActionWhatsapp } from 'app/model/flow-action-whatsapp';
import { STUDIO_ACTION_OPTIONS, STUDIO_OPTIONS_LABELS } from 'app/model/studio-action-options';
import { FlowsService } from 'app/services/flows.service';
import { StudioService } from 'app/services/studio.service';
import { UIService } from 'core/services/ui.service';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-dropable-canvas',
  templateUrl: './dropable-canvas.component.html',
  styleUrls: ['./dropable-canvas.component.css'],
})
export class DropableCanvasComponent implements OnInit, AfterViewInit {
  @ViewChild('droppableCanvas')
  droppableCanvas: ElementRef<HTMLDivElement>;

  nodes: any[] = [];
  idGen = 0;
  flowId = null;
  flowType = null;

  receivingLinks = new Map<number, boolean>();
  connectedNodes = {};

  edges = [];

  selected = null;
  selectedNode = null;

  constructor(
    private dialog: MatDialog,
    private studioService: StudioService,
    private flowService: FlowsService,
    private uiService: UIService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.flowService.$expandedFlow.subscribe((flow) => {
      this.flowId = flow?.flowId;
      this.flowType = Number(flow?.type);

      if (flow) {
        this.nodes = flow.flowActions.map((fa) => this.getNode(fa));
      } else {
        this.nodes = [];
      }

      this.getChildrenReferences();
      this.calcEdges();
    });
  }

  getConnectedNodes() {
    const conNod = {};
    const root = this.nodes.find((nd) => nd.actionType === STUDIO_ACTION_OPTIONS.START);
    if (!root) {
      this.connectedNodes = {};
      return;
    }
    const firstArrow = this.edges.find((ed) => ed.startNodeId === root.flowActionId);
    if (!firstArrow) {
      this.connectedNodes = { [root.flowActionId]: true };
      return;
    }

    conNod[firstArrow.startNodeId] = true;
    let currentArrowStart = firstArrow.endNodeId;
    while (currentArrowStart) {
      conNod[currentArrowStart] = true;
      const currentArr = this.edges.find((arr) => arr.startNodeId === currentArrowStart);
      currentArrowStart = currentArr?.endNodeId;
    }
    this.connectedNodes = conNod;
  }

  getChildrenReferences() {
    const nodesSet = {};
    this.nodes.forEach((nd) => {
      /** get references */
      nodesSet[nd.flowActionId] = nd;
    });

    this.nodes = this.nodes.map((nd) => {
      if (nd.children) {
        nd.children = nd.children.map((ndchild) => nodesSet[ndchild.flowActionId]);
      }
      return nd;
    });
  }

  getNode(flowAction) {
    let node;
    switch (flowAction.actionType) {
      case STUDIO_ACTION_OPTIONS.CALL: {
        node = new FlowActionCall(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionMessages,
          flowAction.flowActionVoiceCallParameter,
          flowAction.continueOnFailure
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.MESSAGE: {
        node = new FlowActionMessage(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionMessages,
          flowAction.continueOnFailure
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.WHATSAPP: {
        node = new FlowActionWhatsapp(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionMessages,
          flowAction.flowActionWhatsAppParameters,
          flowAction.continueOnFailure
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.CCP: {
        node = new FlowActionCcp(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionMessages,
          flowAction.flowActionCCPParameter
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.EMAIL: {
        node = new FlowActionEmail(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionMessages,
          flowAction.continueOnFailure
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.DELAY: {
        node = new FlowActionDelay(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionDelayParameter,
          flowAction.continueOnFailure
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.SEND_DOCUMENT: {
        node = new FlowActionSignature(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.flowActionMessages,
          flowAction.flowActionSignatureParameter,
          flowAction.continueOnFailure
        );
        break;
      }
      default: {
        node = new FlowAction(
          flowAction.flowActionId,
          flowAction.flowId,
          this.flowType,
          flowAction.canvasPositionX,
          flowAction.canvasPositionY,
          flowAction.actionType,
          flowAction.children,
          flowAction.enable,
          flowAction.description,
          flowAction.continueOnFailure
        );
      }
    }
    return node;
  }

  getNodes(flowActions) {
    this.nodes = flowActions;
  }

  async onDrop(event) {
    event.preventDefault();
    const dragType = event.dataTransfer.getData('dragType');
    switch (dragType) {
      case 'dead':
      case 'link':
      case 'details':
        break;
      default:
        await this.onDropNode(event);
        break;
    }
    this.calcEdges();
  }

  async createNode(actionType, canvasPositionX, canvasPositionY) {
    let node: FlowAction;
    const description = this.getDescription(actionType);
    const persistedNode = new FlowAction(
      null,
      this.flowId,
      this.flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      [],
      true,
      description,
      true
    );
    const flowActionId = await this.flowService.addAction(persistedNode).toPromise();
    switch (actionType) {
      case STUDIO_ACTION_OPTIONS.CALL: {
        node = new FlowActionCall(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.MESSAGE: {
        node = new FlowActionMessage(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.CCP: {
        node = new FlowActionCcp(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.WHATSAPP: {
        node = new FlowActionWhatsapp(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.EMAIL: {
        node = new FlowActionEmail(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.DELAY: {
        node = new FlowActionDelay(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      case STUDIO_ACTION_OPTIONS.SEND_DOCUMENT: {
        node = new FlowActionSignature(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          description
        );
        break;
      }
      default: {
        node = new FlowAction(
          flowActionId,
          this.flowId,
          this.flowType,
          canvasPositionX,
          canvasPositionY,
          actionType,
          [],
          true,
          '',
          true
        );
      }
    }
    this.getChildrenReferences();
    this.uiService.toggleGlobalSpinner(false);
    return node;
  }

  getDescription(actionType) {
    let ofType = 0;
    this.nodes.forEach((node) => {
      if (node.actionType === actionType) {
        ofType++;
      }
    });
    return `${STUDIO_OPTIONS_LABELS[actionType]}${ofType ? ' ' + (ofType + 1) : ''}`;
  }

  async onDropNode(event) {
    const actionType = parseInt(event.dataTransfer.getData('actionType'), 10);
    const flowActionId = parseInt(event.dataTransfer.getData('flowActionId'), 10);

    // eslint-disable-next-line
    const top = event.clientY - this.droppableCanvas.nativeElement.offsetParent['offsetTop'];
    const left = event.clientX - this.droppableCanvas.nativeElement.offsetLeft;

    if (!flowActionId) {
      this.uiService.toggleGlobalSpinner(true);
      const newNode = await this.createNode(actionType, left, top);
      this.nodes.push(newNode);
      this.calcEdges();
    } else {
      this.nodes = this.nodes.map((nd) => {
        if (nd.flowActionId === flowActionId) {
          nd.canvasPositionX = left;
          nd.canvasPositionY = top;
        }
        return nd;
      });
      const updNode = this.nodes.find((nd) => nd.flowActionId === flowActionId);
      this.calcEdges();
      if (updNode) {
        await this.flowService.updatedAction(updNode).toPromise();
      }
    }
  }

  allowDrop(event) {
    event.preventDefault();
  }

  nodeSelected(selectedNode) {
    this.selected = this.selected === selectedNode.flowActionId ? null : selectedNode.flowActionId;
    if (this.selected) {
      this.selectedNode = selectedNode;
      this.studioService.setNodeDetails(this.selectedNode);
    } else {
      this.studioService.setNodeDetails(null);
      this.selectedNode = null;
    }
  }

  edgeSelected(edgeId) {
    this.selected = this.selected === edgeId ? null : edgeId;
    this.selectedNode = null;
    this.studioService.setNodeDetails(null);
  }

  async edgeDeleted(edgeId) {
    const edge = this.edges.find((ed) => ed.edgeId === edgeId);
    if (edge) {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: {
            message: `Do you want to delete the selected Element?`,
          },
        })
        .afterClosed()
        .subscribe(async (confirm: boolean) => {
          if (confirm) {
            const node = this.nodes.find((nd) => nd.flowActionId === edge.startNodeId);
            node.children = node.children?.filter((child) => child.flowActionId !== edge.endNodeId);
            this.calcEdges();
            await this.flowService.deleteRelationship(edge.startNodeId, edge.endNodeId).toPromise();
          }
        });
    }
  }

  async addLink(targetNode) {
    if (this.receivingLinks.get(targetNode.flowActionId)) {
      this.snackBar.open(`Flow must be lineal`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    if (targetNode.actionType === STUDIO_ACTION_OPTIONS.START) {
      this.snackBar.open(`Starting action cannot receive a link`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const node = this.nodes.find((nod) => nod.flowActionId === this.selected);
    if (!node) {
      return;
    }
    if (node.children && Array.isArray(node.children)) {
      if (node.children.some((nod) => nod.flowActionId === targetNode.flowActionId)) {
        return;
      }
      node.children.push(targetNode);
    } else {
      node.children = [targetNode];
    }

    this.calcEdges();
    await this.flowService.postRelationship(node.flowActionId, targetNode.flowActionId).toPromise();
  }

  calcEdges() {
    this.receivingLinks = new Map<number, boolean>();
    this.edges = [];
    this.nodes.forEach((node) => {
      if (node.children) {
        node.children.forEach((child) => {
          this.receivingLinks.set(child.flowActionId, true);
          this.edges.push({
            start: [node.canvasPositionX, node.canvasPositionY],
            end: [child.canvasPositionX, child.canvasPositionY],
            startNodeId: node.flowActionId,
            endNodeId: child.flowActionId,
            edgeId: `${node.flowActionId}-${child.flowActionId}`,
          });
        });
      }
    });
    this.getConnectedNodes();
  }

  deleteNode(node) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Element?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.flowService.deleteAction(node.flowActionId).subscribe((result) => {
            this.nodes = this.nodes.map((nd) => {
              if (nd.children && Array.isArray(nd.children)) {
                nd.children = nd.children.filter((nc) => nc.flowActionId !== node.flowActionId);
              }
              return nd;
            });
            this.nodes = this.nodes.filter((nd) => nd.flowActionId !== node.flowActionId);
            this.calcEdges();
          });
        }
      });
  }

  ngAfterViewInit(): void {}
}
