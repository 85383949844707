import { HttpHeaders } from '@angular/common/http';
import { EMPTY, OperatorFunction, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

export class ServiceUtils {
  public static getRequestOptions(acceptCachedResponse: boolean): RequestOptions {
    const options: RequestOptions = {};
    options.headers = new HttpHeaders();
    if (!acceptCachedResponse) {
      options.headers = options.headers.append('cache-control', 'no-cache');
    }
    return options;
  }
}

interface RequestOptions {
  headers?: HttpHeaders;
}

export function handleCachedResponse<T>(): OperatorFunction<T, T> {
  return catchError((err) => {
    if (err.status === 304) {
      return EMPTY;
    } else {
      return throwError(err);
    }
  });
}
