<div class="title">
  Negotiations in {{ slot.slotName }}
  <mat-icon (click)="onClose()">close</mat-icon>
</div>
<div class="body">
  <div class="filter-paginator-container">
    <mat-form-field class="filter">
      <input
        matInput
        placeholder="Filter by Contact or Description"
        [(ngModel)]="filterString"
        (change)="onFilterChange($event)"
        autocomplete="off"
      />
      <mat-icon matSuffix *ngIf="filterString?.length > 0" (click)="onClearFilter()">close</mat-icon>
    </mat-form-field>
    <div class="filter-extra-buttons">
      <mat-icon (click)="exportNegotiations()" matTooltip="Export">save_alt</mat-icon>
    </div>
    <mat-paginator
      [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [length]="totalCountLength"
      (page)="onChangePageSizeOrIndex($event)"
      aria-label="Select page"
    >
    </mat-paginator>
  </div>
  <div class="table-container">
    <table mat-table [dataSource]="dataSource" matSort class="table" fixedLayout>
      <ng-container matColumnDef="CompletedCheck">
        <th mat-header-cell *matHeaderCellDef class="th-completed-negotiation"></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon *ngIf="showCheck(element)" class="completed-negotiation">check</mat-icon>
          <mat-icon *ngIf="showError(element)" class="error-negotiation" [matTooltip]="showErrorTooltip(element)">error</mat-icon>
          <mat-icon *ngIf="showExpired(element)" class="expired-negotiation" [matTooltip]="'This negotiation expired'">event_busy</mat-icon>
        </td>
      </ng-container>
      <ng-container matColumnDef="Favorite">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-favorite-negotiation"></th>
        <td mat-cell *matCellDef="let element" matTooltip="Toggle Favorite" (click)="onToggleFavorite(element)">
          <mat-icon class="favorite-negotiation" [ngClass]="{ 'favorite-negotiation-enabled': element.favorite }"
            >label_important</mat-icon
          >
        </td>
      </ng-container>
      <ng-container matColumnDef="ContactName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-contact">Contact</th>
        <td mat-cell *matCellDef="let element">{{ element.contactName }} {{ element.contactLastName }}</td>
      </ng-container>
      <ng-container matColumnDef="Description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-description">Description</th>
        <td mat-cell *matCellDef="let element" [matTooltip]="element.description">{{ element.description }}</td>
      </ng-container>
      <ng-container matColumnDef="DocumentsQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-documents-qty">Documents</th>
        <td mat-cell *matCellDef="let element">{{ element.documentsQty }}</td>
      </ng-container>
      <ng-container matColumnDef="OptionsQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-options-qty">Options</th>
        <td mat-cell *matCellDef="let element">{{ element.optionsQty }}</td>
      </ng-container>
      <ng-container matColumnDef="LastModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-last-modified">Last Modified</th>
        <td mat-cell *matCellDef="let element">{{ element.lastModified | date : 'MM/dd/yyyy hh:mm a' }}</td>
      </ng-container>
      <ng-container matColumnDef="ContactStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-contact-status">Contact Status</th>
        <td mat-cell *matCellDef="let element" class="td-contact-status">
          <div class="reference">
            <span
              class="chip"
              [class.reversed]="element.contactStatus >= 1"
              [class.error]="element.contactStatus >= 1 && showError(element, 'S')"
              [class.current]="shouldGlow(element, 'S')"
              [matTooltip]="contactStatusText(element, 'S')"
              >S</span
            >
            <span
              class="chip"
              [class.reversed]="element.contactStatus >= 2 && element.callDuration"
              [class.error]="element.contactStatus >= 2 && element.callDuration && showError(element)"
              [class.current]="shouldGlow(element, 'T')"
              [matTooltip]="contactStatusText(element, 'T')"
              >T</span
            >
            <span
              class="chip"
              [class.reversed]="element.contactStatus >= 3"
              [class.error]="element.contactStatus >= 3 && showError(element)"
              [class.current]="shouldGlow(element, 'V')"
              [matTooltip]="contactStatusText(element, 'V')"
              >V</span
            >
            <span
              class="chip"
              [class.reversed]="element.contactStatus == 4"
              [class.error]="element.contactStatus == 4 && showError(element)"
              [class.current]="shouldGlow(element, 'B')"
              [matTooltip]="contactStatusText(element, 'B')"
              >B</span
            >
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="SignatureStatus">
        <th mat-header-cell *matHeaderCellDef class="th-signature-status">Signature Status</th>
        <td mat-cell *matCellDef="let element" class="td-signature-status">
          <div>
            <div class="reference" *ngIf="element.requiresSignature">
              <span
                class="chip"
                [class.reversed]="element.signatureStatus >= 1"
                [class.error]="element.signatureStatus >= 1 && showError(element)"
                [class.current]="shouldGlow(element, 'R')"
                [matTooltip]="contactStatusText(element, 'R')"
                >R</span
              >
              <span
                class="chip"
                [class.reversed]="element.signatureStatus == 2"
                [class.error]="element.signatureStatus == 2 && showError(element)"
                [class.current]="shouldGlow(element, 'C')"
                [matTooltip]="contactStatusText(element, 'C')"
                >C</span
              >
            </div>
            <div class="reference" *ngIf="element.requiresSignature">
              <span></span>
              <span></span>
            </div>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Actions">
        <th mat-header-cell *matHeaderCellDef class="th-actions">Actions</th>
        <td mat-cell *matCellDef="let element" class="td-actions">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon class="action-icon hoverable" aria-label="Options">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onCancelNegotiation(element)" *ngIf="element.status !== 4">
              <mat-icon class="action-icon hoverable" aria-label="Cancel Negotiation">cancel</mat-icon>
              <span>Cancel Negotiation</span>
            </button>
            <button mat-menu-item (click)="onRestart(element)" *ngIf="showRestart(element)">
              <mat-icon class="action-icon hoverable" aria-label="Restart Negotiation">restart_alt</mat-icon>
              <span>Restart Negotiation</span>
            </button>
            <button mat-menu-item (click)="onRemoveFromSlot(element)">
              <mat-icon class="action-icon hoverable" aria-label="Remove from Channel">remove_circle</mat-icon>
              <span>Remove from Channel</span>
            </button>
            <button mat-menu-item (click)="onToggleFavorite(element)">
              <mat-icon class="action-icon hoverable" aria-label="Toggle Favorite">label_important</mat-icon>
              <span>{{ element.favorite ? 'Unset as Favorite' : 'Set as Favorite' }}</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>
      <ng-container matColumnDef="UnreadMsgQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-messages">Messages</th>
        <td mat-cell *matCellDef="let element" class="td-messages">
          <span style="position: relative" class="message-icons-container">
            <span style="position: relative">
              <mat-icon class="hoverable" (click)="showMessages(element)">question_answer</mat-icon>
              <span *ngIf="element.unreadMsgQty != 0" class="messages-counter" (click)="showMessages(element)">
                {{ element.unreadMsgQty }}
              </span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="UnreadSmsQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-sms">SMS</th>
        <td mat-cell *matCellDef="let element" class="td-messages">
          <span style="position: relative" class="message-icons-container">
            <span style="position: relative">
              <mat-icon class="hoverable" (click)="showSmsMessages(element)">textsms</mat-icon>
              <span *ngIf="element.unreadSmsQty != 0" class="messages-counter" (click)="showSmsMessages(element)">
                {{ element.unreadSmsQty }}
              </span>
            </span>
          </span>
        </td>
      </ng-container>
      <ng-container matColumnDef="UnreadWpQty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-whatsapp">Whatsapp</th>
        <td mat-cell *matCellDef="let element" class="td-messages">
          <span style="position: relative" class="message-icons-container">
            <span style="position: relative">
              <mat-icon
                class="hoverable pointer"
                (click)="showWhatsappMessages(element)"
                svgIcon="whatsapp-svg"
              ></mat-icon>
              <span *ngIf="element.unreadWpQty != 0" class="messages-counter" (click)="showWhatsappMessages(element)">
                {{ element.unreadWpQty }}</span
              >
            </span>
          </span>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>
</div>
<div class="footer">
  <div class="reference">
    <div class="chip reversed">S</div>
    <span class="ref-text">Sent</span>
  </div>
  <div class="reference">
    <div class="chip reversed">T</div>
    <span class="ref-text">Taken</span>
  </div>
  <div class="reference">
    <div class="chip reversed">V</div>
    <span class="ref-text">Viewed</span>
  </div>
  <div class="reference">
    <div class="chip reversed">B</div>
    <span class="ref-text">Bidden</span>
  </div>
  <div class="reference" *ngIf="showSignatureStatus">
    <div class="chip reversed">R</div>
    <span class="ref-text">Requested</span>
  </div>
  <div class="reference" *ngIf="showSignatureStatus">
    <div class="chip reversed">C</div>
    <span class="ref-text">Completed</span>
  </div>
</div>
