<div
  style="position: absolute; z-index: 2"
  [style.top]="startY + yDiff - 2 * remValue + 'px'"
  [style.left]="startX - xDiff + 2 * remValue + 'px'"
  [style.transform]="'rotate(' + angle + 'deg)'"
  class="arrow-container"
  [class.selected]="selected"
  (click)="onSelected()"
>
  <svg [attr.width]="width - 4 * remValue + 'px'" [attr.height]="4 * remValue + 'px'">
    <defs>
      <marker id="arrowhead" markerWidth="4" markerHeight="7" refX="0" refY="3.5" orient="auto">
        <polygon points="0 2, 3 3.5, 0 5" />
      </marker>
    </defs>
    <line
      [attr.x1]="1.5 * remValue + 'px'"
      [attr.y1]="2 * remValue + 'px'"
      [attr.x2]="width - 6 * remValue + 'px'"
      [attr.y2]="2 * remValue + 'px'"
      stroke="#000"
      stroke-width="8"
      marker-end="url(#arrowhead)"
    />
  </svg>
  <div class="arrow-actions" *ngIf="selected" [style.transform]="'rotate(' + -angle + 'deg)'">
    <div class="action-icon" (click)="deleteLinkAction()">
      <mat-icon>delete</mat-icon>
    </div>
  </div>
</div>
