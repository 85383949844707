<div class="title">
  {{ message }}
</div>
<div class="details-container">
  <div *ngIf="data?.currentSubscription" class="details">
    <app-subscription-card [fromSettings]="true" [subscriptionInfo]="data?.currentSubscription"></app-subscription-card>
  </div>
  <div *ngIf="data?.currentSubscription">
    <mat-icon
      *ngIf="data?.currentSubscription.subscriptionType > data?.choosenSubscription.subscriptionType"
      class="transition-icon red"
      >keyboard_double_arrow_down</mat-icon
    >
    <mat-icon
      class="transition-icon green"
      *ngIf="data?.currentSubscription.subscriptionType < data?.choosenSubscription.subscriptionType"
      >keyboard_double_arrow_up</mat-icon
    >
  </div>
  <div class="details">
    <app-subscription-card [fromSettings]="true" [subscriptionInfo]="data?.choosenSubscription"></app-subscription-card>
  </div>
</div>
<ol class="billing-details">
</ol>
<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="data?.onConfirm()" cdkFocusInitial>Confirm</button>
  <button class="sgnd-btn sgnd-btn-cancel" (click)="onClose()">Cancel</button>
</div>
