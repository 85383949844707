import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { WhatsappOptionTemplateContentExpanded } from 'app/model/whatsapp-option-template-content-expanded';
import { WhatsappOptionTemplateContentStatus } from 'app/model/whatsapp-option-template-content-status';
import { LanguageService } from 'app/services/language.service';
import { StudioService } from 'app/services/studio.service';

@Component({
  selector: 'app-review-whatsapp-template-content-dialog',
  templateUrl: './review-whatsapp-template-content-dialog.component.html',
  styleUrls: ['./review-whatsapp-template-content-dialog.component.css']
})
export class ReviewWhatsappTemplateContentDialogComponent implements OnInit {

  templateContent: WhatsappOptionTemplateContentExpanded;
  allLangs: { [key: string]: string } = {};

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<ReviewWhatsappTemplateContentDialogComponent>,
    private studioService: StudioService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private data: WhatsappOptionTemplateContentExpanded
  ) {
    this.templateContent = this.data;
  }

  ngOnInit(): void {
    this.loadLanguages();
  }

  onCancel() {
    this.dialogRef.close(true);
  }

  onAccept() {
    this.dialog.open(ConfirmDialogComponent, {
      data: { message: `Do you want to Approve the template?` }
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.studioService.reviewWhatsappOptionTemplate(
          this.templateContent.id,
          WhatsappOptionTemplateContentStatus.Approved,
          this.templateContent.whatsappOptionTemplateTemplateSid
        ).subscribe(() => {
          this.snackBar.open(`The review has been marked as 'Approved'.`, null, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
          });
          this.dialogRef.close(true);
        });
      }
    },
      (err) => {
        console.error(err);
      }
    );
  }

  onReject() {
    this.dialog.open(ConfirmDialogComponent, {
      data: { message: `Do you want to Reject the template?` }
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.studioService.reviewWhatsappOptionTemplate(this.templateContent.id,
          WhatsappOptionTemplateContentStatus.Rejected, null
        ).subscribe(() => {
          this.snackBar.open(`The review has been marked as 'Rejected'.`, null, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
          });
          this.dialogRef.close(true);
        });
      }
    },
      (err) => {
        console.error(err);
      }
    );
  }

  private async loadLanguages() {
    const languages = await this.languageService.getLanguages();
    this.allLangs = Object.fromEntries(languages.map(x => [x.languageCode, x.name]));
  }
}
