export class DynamicContactColumn {
    public dynamicContactColumnId: string;
    public name: string;
    public type: string;
    public companyId: any;
    
    constructor(input) {
        this.dynamicContactColumnId = input.dynamicContactColumnId;
        this.name = input.name;
        this.type = input.type;
        this.companyId = input.companyId;
    }
}
