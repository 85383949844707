/* eslint-disable */

export enum NegotiationStatus {
  DRAFT = 0,
  READY = 1,
  IN_PROGRESS = 2,
  COMPLETED = 3,
  ERROR = 4,
  CANCELED = 5,
  READY_TO_CONTINUE = 6,
  PAUSED = 7,
  RETRYING_ACTION = 8,
  EXPIRED = 9
}

export const NEGOTIATION_STATUS_DESCRIPTIONS = {
  [NegotiationStatus.DRAFT]: `Just a Draft that Won't run until told to do so`,
  DRAFT: `Just a Draft that Won't run until told to do so`,
  [NegotiationStatus.READY]: 'Will run asap',
  READY: 'Will run asap',
  [NegotiationStatus.IN_PROGRESS]: 'Currently running',
  IN_PROGRESS: 'Currently running',
  [NegotiationStatus.COMPLETED]: 'Flow Execution has successfully reached the end',
  COMPLETED: 'Flow Execution has successfully reached the end',
  [NegotiationStatus.ERROR]: 'Something went wrong during execution',
  ERROR: 'Something went wrong during execution',
  [NegotiationStatus.CANCELED]: 'A user manually canceled the negotiation',
  CANCELED: 'A user manually canceled the negotiation',
  [NegotiationStatus.READY_TO_CONTINUE]: 'A user manually interrupted the negotiation',
  READY_TO_CONTINUE: 'A user manually interrupted the negotiation',
  [NegotiationStatus.PAUSED]: 'Already started, Will continue to run asap',
  PAUSED: 'Already started, Will continue to run asap',
  [NegotiationStatus.RETRYING_ACTION]: `Something didn't go as planned with a third party service and we're retrying the last performed action`,
  RETRYING_ACTION: `Something didn't go as planned with a third party service and we're retrying the last performed action`,
  [NegotiationStatus.EXPIRED]: `A negotiation that didn't finish before it's due date`,
  EXPIRED: `A negotiation that didn't finish before it's due date`
};
