export class NegotiationFlowAction {
    id: number;
    negotiationId: number;
    flowActionId: number;
    enable: boolean;

    constructor(negotiationFlowAction: NegotiationFlowActionDto) {
        this.id = negotiationFlowAction?.id;
        this.negotiationId = negotiationFlowAction?.negotiationId;
        this.flowActionId = negotiationFlowAction?.flowActionId;
        this.enable = negotiationFlowAction?.enable;
    }
}

export class NegotiationFlowActionDto {
    id: number;
    negotiationId: number;
    flowActionId: number;
    enable: boolean;
    description: string;

    constructor(negotiationFlowAction: NegotiationFlowAction) {
        this.id = negotiationFlowAction?.id;
        this.negotiationId = negotiationFlowAction?.negotiationId;
        this.flowActionId = negotiationFlowAction?.flowActionId;
        this.enable = negotiationFlowAction?.enable;
    }
}