import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccountsReceivableDocument, AccountsReceivableDocumentDto } from 'app/model/documents/accounts-receivable-document';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class DocumentsApiService {
  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Documents';
  }

  getDocumentsList(): Observable<AccountsReceivableDocument[]> {
    return this.httpClient.get<AccountsReceivableDocument[]>(this.baseURL);
  }

  saveDocument(document: AccountsReceivableDocument): Observable<any> {
    const documentDto = new AccountsReceivableDocumentDto(document);
    return this.httpClient.post<AccountsReceivableDocumentDto>(this.baseURL, documentDto);
  }

  updateDocument(document: AccountsReceivableDocument): Observable<any> {
    const documentDto = new AccountsReceivableDocumentDto(document);
    return this.httpClient.put<AccountsReceivableDocumentDto>(this.baseURL + '/' + document.id, documentDto);
  }

  deleteDocuments(documentIds: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: documentIds
    };
    return this.httpClient.delete<any>(this.baseURL, options);
  }

}
