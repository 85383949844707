<section class="billing-screen">
  <mat-card class="mat-elevation-z2" *ngIf="!selectedPlan && !companyId" [@slideLeftAnimationLeave]>
    <app-billing-subscriptions (subscriptionSelected)="onSubscriptionSelected($event)"></app-billing-subscriptions>
  </mat-card>
  <mat-card
    class="mat-elevation-z2"
    *ngIf="(companyId || selectedPlan) && !(needsRelogin || showSuccess)"
    [@slideLeftAnimation]
  >
    <span *ngIf="!selectedPlan && companyId" class="add-credit-title"> Add Credit </span>
    <app-billing-details
      [showAmount]="companyId"
      [subscriptionType]="selectedPlan?.subscriptionType"
      (completeSubscription)="completeSubscription()"
      (completeAddingFunds)="completeAddingFunds()"
    ></app-billing-details>
  </mat-card>
  <mat-card class="mat-elevation-z2 subscreen-container" *ngIf="needsRelogin" [@slideLeftAnimation]>
    <app-billing-timer></app-billing-timer>
  </mat-card>
  <mat-card class="mat-elevation-z2 subscreen-container" *ngIf="showSuccess" [@slideLeftAnimation]>
    <app-billing-success></app-billing-success>
  </mat-card>
</section>
