<section class="billing-details-component">
  <form
    name="tokenform"
    id="tokenform"
    *ngIf="iframeUrl"
    [formGroup]="billingForm"
    (ngSubmit)="onSubmit()"
    class="flex"
  >
    <div class="detail-columns">
      <div>
        <h2>Payment Details</h2>
        <div class="line-item">
          <label for="chname" id="chnamel">Card Holder Name</label>
          <br />
          <input
            id="chname"
            name="chname"
            size="19"
            type="text"
            maxlength="100"
            autocomplete="off"
            title="Card Holder Name"
            aria-label="Card Holder Name"
            formControlName="chname"
          />
        </div>
        <iframe
          id="tokenFrame"
          name="tokenFrame"
          [src]="iframeUrl"
          frameborder="0"
          width="420"
          height="212"
          (load)="loadedIFrame()"
        ></iframe>
        <div class="line-item" *ngIf="showAmount">
          <label for="amount" id="amountl">Amount <span class="min-amount">(min $20)</span></label>
          <br />
          <input
            id="amount"
            name="amount"
            type="number"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Amount"
            aria-label="Amount"
            formControlName="amount"
            min="minPaymentValue"
          />
        </div>
        <input type="hidden" [value]="token" />
      </div>
      <div name="billingform" id="billingform" *ngIf="token" [@inOutAnimation]>
        <h2>Billing Details</h2>
        <div class="line-item">
          <label for="bdaddress" id="bdaddressl">Address*</label>
          <br />
          <input
            id="bdaddress"
            name="bdaddress"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Address"
            aria-label="Address"
            formControlName="bdaddress"
          />
        </div>
        <div class="line-item">
          <label for="bdpc" id="bdpcl">Postal Code*</label>
          <br />
          <input
            id="bdpc"
            name="bdpc"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Postal Code"
            aria-label="Postal Code"
            formControlName="bdpc"
          />
        </div>
        <div class="line-item">
          <label for="bdcity" id="bdcityl">City*</label>
          <br />
          <input
            id="bdcity"
            name="bdcity"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="City"
            aria-label="City"
            formControlName="bdcity"
          />
        </div>
        <div class="line-item">
          <label for="bdregion" id="bdregionl">Region*</label>
          <br />
          <input
            id="bdregion"
            name="bdregion"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Region"
            aria-label="Region"
            formControlName="bdregion"
          />
        </div>
        <div class="line-item">
          <label for="bdcountry" id="bdcountryl">Country*</label>
          <br />
          <input
            id="bdcountry"
            name="bdcountry"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Country"
            aria-label="Country"
            formControlName="bdcountry"
          />
        </div>
        <div class="line-item">
          <label for="bdphone" id="bdphonel">Phone*</label>
          <br />
          <input
            id="bdphone"
            name="bdphone"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Phone"
            aria-label="Phone"
            formControlName="bdphone"
            required
          />
        </div>
        <div class="line-item">
          <label for="bdemail" id="bdemaill">Email*</label>
          <br />
          <input
            id="bdemail"
            name="bdemail"
            size="19"
            maxlength="100"
            autocomplete="off"
            title="Email"
            aria-label="Email"
            formControlName="bdemail"
          />
        </div>
      </div>
    </div>
    <div class="confirm-container">
      <button mat-button type="submit" *ngIf="billingForm.valid" [@appearOpacityAnimation]>Confirm</button>
    </div>
  </form>
</section>
