<div #loaded class="sd-tab-nav">
  <div class="menus-container">
    <a
      *ngFor="let menu of menus"
      class="menu-item"
      [ngClass]="selectedMenu.key == menu.key ? 'sd-menu menu-selected' : 'sd-menu'"
      (click)="onContactModeSelect(menu)"
    >
      <mat-icon class="menu-icon">{{ menu.icon }}</mat-icon>
      {{ menu.title }}
      </a>
  </div>
</div>

<div *ngIf="selectedMenu.key == 'contacts'">
  <app-list-bulk-edit
    [options]="contactsMenuOptions"
    [filterOptions]="filterOptions"
    [defaultFilter]="defaultFilter"
  ></app-list-bulk-edit>
  <div class="filters-list-container">
    <div class="list-container">
      <app-contacts-grid
        (selectedContacts)="updateContactsSelection($event)"
        (refresh)="refreshContacts($event)"
        [reset]="resetGrid"
        [setState]="setGridState"
      ></app-contacts-grid>
    </div>
  </div>
</div>

<div *ngIf="selectedMenu.key == 'contacts-groups'">
  <app-list-bulk-edit
  [options]="contactsGroupsMenuOptions"
  [filterOptions]="filterOptions"
  [defaultFilter]="defaultFilter"
></app-list-bulk-edit>
<div class="filters-list-container">
  <div class="list-container">
    <app-contact-groups-grid
      (selectedContactsGroupEvent)="updateContactsGroupSelection($event)"
      [contactsGroupsChanged]="contactsGroupsChangedSubject.asObservable()"
    ></app-contact-groups-grid>
  </div>
</div>
</div>