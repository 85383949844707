import { Component, OnInit } from '@angular/core';
import { CreditsDTO } from 'app/services/credits/credits-dto';
import { CreditsService } from 'app/services/credits/credits.service';

@Component({
  selector: 'app-negotiation-counter',
  templateUrl: './negotiation-counter.component.html',
  styleUrls: ['./negotiation-counter.component.css']
})
export class NegotiationCounterComponent implements OnInit {
  public credits: CreditsDTO;

  constructor(
    private creditsService: CreditsService
  ) {
    this.creditsService.credits.subscribe(value => {
      if(value) {
        this.credits = value;
      }
    });
  }

  ngOnInit(): void { }

  colorToUse() {
    var useGreen = this.credits && this.credits.credit && this.credits.credit > 0;
    return useGreen ? 'limegreen' : 'indianred';
  }
}
