import { NgModule } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
@NgModule({
    imports: [MatIconModule]
})
export class IconModule {
    private path = '/assets';
    constructor(
        private domSanitizer: DomSanitizer,
        public matIconRegistry: MatIconRegistry) {
        this.matIconRegistry
            .addSvgIcon('whatsapp-svg', this.setPath(`${this.path}/whatsapp.svg`));
    }
    private setPath(url: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    }
}