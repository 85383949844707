import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WhatsAppContentTemplate } from 'app/model/content-template';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ContentTemplateApiService {
  private baseURL: string;
  public contentExtensions = {
    // Text
    "text/plain": [".txt", ".log"],
    "text/html": [".html", ".htm"],
    // JSON
    "application/json": [".json"],
    // XML
    "application/xml": [".xml"],
    // Images
    "image/jpeg": [".jpg", ".jpeg"],
    "image/png": [".png"],
    "image/gif": [".gif"],
    "image/bmp": [".bmp"],
    // Videos
    "video/mp4": [".mp4"],
    "video/mpeg": [".mpeg", ".mpg"],
    "video/quicktime": [".mov"],
    "video/x-msvideo": [".avi"],
    // Documents
    "application/pdf": [".pdf"],
    "application/msword": [".doc"],
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": [".docx"],
    "application/vnd.ms-excel": [".xls"],
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [".xlsx"],
    "application/vnd.ms-powerpoint": [".ppt"],
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": [".pptx"],
    // Audio
    "audio/mpeg": [".mp3"],
    "audio/wav": [".wav"],
    "audio/ogg": [".ogg"],
    "audio/aac": [".aac"]
    // Add more content types and extensions as needed
  };

  // Combine all extensions into a single array
  private allExtensions = Object.values(this.contentExtensions).flat();

  constructor(private http: HttpClient, private noAuthHttpClient: HttpClient, private handler: HttpBackend) {
    this.noAuthHttpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'wp-template';
  }

  /**
   * returns: audio, video, image, other
   */
  public getLinkFileType(url: string): string {
    let validResponses = ['audio', 'video', 'image', 'other'];
    let result = validResponses[validResponses.length - 1];
    try {
      if(url && url.length) {
        let linkExtension = this.allExtensions.find(x => url.includes(x));
        let usedExtensionType = Object.keys(this.contentExtensions).find(extensionType => {
          return JSON.stringify(this.contentExtensions[extensionType]).includes(linkExtension);
        });
        usedExtensionType = usedExtensionType.split('/')[0];
        if(validResponses.includes(usedExtensionType)) {
          result = usedExtensionType;
        }
      }
    } catch(err) {
      // Ignore errors
    }
    return result;
  }

  public getAllTemplates() {
    return this.http.get(`${this.baseURL}/all`);
  }

  public createTemplate(contentTemplate: WhatsAppContentTemplate) {
    return this.http.post(`${this.baseURL}/request-template`, contentTemplate);
  }

  /**
   * Returns true if the given link is a link to a file
   * and false otherwise.
   */
  public verifyLink(url: string): Observable<boolean> {
    let obs2return = new Observable<boolean>(obs => {
      try {
        if(this.linkHasAVariable(url)) {
          let isFileLink = this.linkVerificationWithVariables(url);
          let containsExtension = this.linkContainsExtension(url);
          obs.next(isFileLink && containsExtension);
          obs.complete();
        } else {
          this.noAuthHttpClient.head(url, { observe: 'response' }).subscribe(response => { 
            let isValidLink = response.ok; //!!response.headers.get('content-type')?.startsWith('application');
            let containsExtension = this.linkContainsExtension(url);
            obs.next(isValidLink && containsExtension);
            obs.complete();
          });
        }
      } catch(err) {
        obs.next(false);
        obs.complete();
      }
    });
    return obs2return;
  }

  private linkContainsExtension(url: string): boolean {
    return !!this.allExtensions.find(x => url.includes(x));
  }

  private linkHasAVariable(link: string): boolean {
    return link && link.length && link.includes('{{') && link.includes('}}');
  }

  private getExtension(link: string): string {
    let extension = null;
    if(link && link.length) {
      let parts = link.split('.');
      extension = parts[parts.length - 1];
    }
    return extension;
  }

  private linkVerificationWithVariables(link: string): boolean {
    let extension = this.getExtension(link);
    return !!(extension && extension.length);
  }

}
