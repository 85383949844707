import { Injectable } from '@angular/core';
import { MonitorNegotiationTotals } from 'app/model/monitor-negotiation-totals';
import { MonitorSlot } from 'app/model/monitor-slot';
import { PaginatedDataResponse } from 'app/model/pagination';
import { PaginatedSlotsDataRequest } from 'app/model/slot';
import { FilterPaginatorComponent } from 'core/components/filter-paginator/filter-paginator.component';
import { BehaviorSubject, Observable, Subject, zip } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { MonitorApiService } from './monitor-api.service';

@Injectable({
  providedIn: 'root',
})
export class MonitorService {
  public $slots = new BehaviorSubject<PaginatedDataResponse<MonitorSlot>>(null);

  constructor(private monitorApiService: MonitorApiService) {}

  public getNegotiationsList(
    slotId: number,
    ownedOnly: boolean,
    pageSize: number,
    pageNumber: number,
    sortedBy: string,
    sortDirection: string,
    textFilter: string
  ): Observable<MonitorNegotiationTotals> {
    return this.monitorApiService.getNegotiationsList(
      slotId,
      ownedOnly,
      pageSize,
      pageNumber,
      sortedBy,
      sortDirection,
      textFilter
    );
  }

  public refreshSlotsData(filter: PaginatedSlotsDataRequest): Observable<unknown> {
    filter.pageSize ??= FilterPaginatorComponent.DEFAULT_PAGE_SIZE;
    filter.pageNumber ??= 1;

    const finished = new Subject();
    this.monitorApiService
      .getSlotsList(filter)
      .pipe(finalize(() => finished.next()))
      .subscribe({
        next: (res: PaginatedDataResponse<MonitorSlot>) => {
          this.$slots.next(res);
        },
        error: (err) => {
          console.error(err);
          this.$slots.next(null);
        },
      });

    return finished;
  }

  public exportSlotDetails(fileName, slotId) {
    return this.monitorApiService.exportSlotDetails(fileName, {
      slotId,
      ownedOnly: false,
      sortDirection: '',
    });
  }
}
