import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'app/model/company';
import { Auth0ManagementService } from 'app/services/auth0-management.service';
import { CompanyService } from 'app/services/company.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-company-credentials-create',
  templateUrl: './company-credentials-create.component.html',
  styleUrls: ['./company-credentials-create.component.css']
})
export class CompanyCredentialsCreateComponent implements OnInit {

  public company: Company;
  public clientSecret: string;

  constructor(
    public dialogRef: MatDialogRef<CompanyCredentialsCreateComponent>,
    public auth0ManagementService: Auth0ManagementService,
    public companyService: CompanyService,
    public uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data != null) {
      this.company = data.company;
      this.clientSecret = data.clientSecret;
    }
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(true);
  }

}
