<div class="accounts-body">
    <div *ngIf="messageText" class="message-text">{{messageText}}</div>
    <div *ngIf="negotiationWasBidden(); else nonBidden" class="bidden-msg">
        You have already submitted your selection for this negotiaiton.
    </div>
    <ng-template #nonBidden>
        <table mat-table [dataSource]="accountsDS" class="mat-elevation-z8">
            <ng-container matColumnDef="select">
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()"
                        (change)="$event ? selection.toggle(row) : null"
                        [checked]="selection.isSelected(row)">
                    </mat-checkbox>
                </td>
            </ng-container>
            <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let element">{{element.description}}</td>
            </ng-container>
            <ng-container matColumnDef="dueDate">
                <th mat-header-cell *matHeaderCellDef>Due Date</th>
                <td mat-cell *matCellDef="let element">{{element.dueDate | date: 'yyyy/MM/dd'}}</td>
            </ng-container>
            <ng-container matColumnDef="amount">
                <th mat-header-cell *matHeaderCellDef>Amount</th>
                <td mat-cell *matCellDef="let element">{{element.amount | currency}}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selection.toggle(row)"></tr>
        </table>
        <div class="reg-container">
            <button mat-raised-button (click)="previous()" [disabled]="page === 1"><</button>
            <span class="page">{{page}}</span>
            <button mat-raised-button (click)="next()" [disabled]="!hasNext">></button>
        </div>
        <div class="reg-container">
            <strong>Total: </strong>
            <span style="margin-left: 1rem;">{{total | currency}}</span>
        </div>
        <div class="reg-container">
            <button
                mat-raised-button
                style="background-color: #757e89; color: white; min-width: 10rem;"
                (click)="paySelection()"
                [disabled]="this.selection.selected.length === 0"
            >
                Pay
            </button>
        </div>
    </ng-template>
</div>
