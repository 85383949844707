import { Component, OnDestroy, OnInit, ViewChild, ElementRef } from '@angular/core';
import { IntermediateService } from 'app/services/intermediate.service';
import { FlowConfiguration } from 'app/model/flow-configuration';
import { ActivatedRoute } from '@angular/router';
import { NegotiationMessageService } from 'app/services/negotiation-message.service';
import downloadFileResponse from 'app/functions/fileFunctions';
import { AttachmentsService } from 'app/services/attachments.service';
import { MatDialog } from '@angular/material/dialog';
import { IntermediateMessagesModalComponent } from '../common/intermediate-messages-modal/intermediate-messages-modal.component';

@Component({
  selector: 'app-intermediate',
  templateUrl: './intermediate.component.html',
  styleUrls: ['./intermediate.component.css'],
})
export class IntermediateComponent implements OnInit, OnDestroy {
  @ViewChild('messagesRef') messagesRefContent: ElementRef;
  public company: FlowConfiguration = null;
  public allowed = false;
  public negotiationId: number;
  public authCode: string;
  public unreadMessages = {};
  public files = [];
  public showFiles = false;
  private authCodeCache = 'intermediate-negotiation';
  private negotiation = null;

  constructor(
    public dialog: MatDialog,
    private intermediateService: IntermediateService,
    private route: ActivatedRoute,
    private messagesService: NegotiationMessageService,
    private attachmentsService: AttachmentsService
  ) {
    this.intermediateService.clearFiles();
  }

  async ngOnInit(): Promise<void> {
    this.intermediateService.$files.subscribe((files) => {
      this.files = files;
    });
    this.route.firstChild.params.subscribe((params) => {
      this.negotiationId = Number.parseInt(params.negotiationId, 10);
      const response = this.intermediateService.getFlowConfiguration(this.negotiationId);

      response.subscribe((res) => {
        if (!res) {
          return;
        }
        this.company = res;
        const authCode = localStorage.getItem(`${this.authCodeCache}-${this.negotiationId}`);
        if (authCode) {
          this.authCode = authCode;
          return this.submitCode();
        }
        if (!res.requiresAuthCode) {
          this.intermediateService.getData(this.negotiationId, null);
          this.allowed = true;
        }
        if (res.primaryColor) {
          this.messagesService.setPrimaryBackgroundColor(res.primaryColor);
        }
        if (res.secondaryColor) {
          this.messagesService.setSecondaryColor(res.secondaryColor);
        }
      });
    });
    this.intermediateService.$negotiationItself.subscribe((neg) => {
      this.negotiation = neg;
      this.messagesService.selectNegotiation(neg);
      this.messagesService.getMessagesIntermediate(this.negotiationId);
      this.intermediateService.getFiles(this.negotiationId);
    });
    this.messagesService.$unreadMessages.subscribe((val) => {
      this.unreadMessages = val;
    });
  }

  openMessages() {
    this.unreadMessages = {};
    this.dialog.open(IntermediateMessagesModalComponent);
  }

  submitCode() {
    this.intermediateService.getData(this.negotiationId, this.authCode);
    this.intermediateService.negotiations.subscribe((res) => {
      if (res) {
        this.allowed = true;
        this.messagesService.getMessagesIntermediate(this.negotiationId);
        localStorage.setItem(`${this.authCodeCache}-${this.negotiationId}`, this.authCode);
      }
    });
  }

  onShowFiles() {
    this.showFiles = true;
  }

  onHideFiles() {
    this.showFiles = false;
  }

  preventClosing(event) {
    event.stopPropagation();
  }

  onDownloadFile(file) {
    this.attachmentsService
      .getAttachmentForNegotiation(this.negotiationId, file.id)
      .then((data) => downloadFileResponse(data, file.originalName));
  }

  ngOnDestroy() {
    this.messagesService.clearIntervals();
  }
}
