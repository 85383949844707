import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { AccountsReceivableDocument } from 'app/model/documents/accounts-receivable-document';
import { DocumentsService } from 'app/services/documents.service';
import { AddAccountsReceivableDocumentComponent } from '../add-accounts-receivable-document/add-accounts-receivable-document.component';

@Component({
  selector: 'app-accounts-receivable-documents-grid',
  templateUrl: './accounts-receivable-documents-grid.component.html',
  styleUrls: ['./accounts-receivable-documents-grid.component.css'],
})
export class AccountsReceivableDocumentsGridComponent implements OnInit, OnDestroy {
  public documents: Array<AccountsReceivableDocument> = [];

  dataSource;
  displayedColumns = ['description', 'externalId', 'debtor', 'amount', 'date', 'dueDate', 'actions'];

  constructor(public documentsService: DocumentsService, public dialog: MatDialog, private snackBar: MatSnackBar) {
  }

  ngOnInit(): void {
    this.documentsService.documentsSubject.subscribe((values: AccountsReceivableDocument[]) => {
      this.documents = values;
      this.dataSource = new MatTableDataSource<AccountsReceivableDocument>(this.documents);
    });
  }

  public onAdd() {
    this.dialog.open(AddAccountsReceivableDocumentComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: null,
    });
  }

  public onEdit(negotiation) {
    this.dialog.open(AddAccountsReceivableDocumentComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: negotiation,
    });
  }

  public onDelete(document: AccountsReceivableDocument) {
    this.documentsService.deleteDocument(document);
    this.snackBar.open(`Document deleted successfully.`, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  ngOnDestroy(): void { }
}
