<div class="message-dialog-container">
  <div class="title" [style.background-color]="data.titleBackgroundColor" [style.color]="data.titleTextColor">{{data.title}}</div>
  <div class="message">{{ data.message }}</div>
  <app-html-editor [(value)]="body"></app-html-editor>
  <div class="form-fields-container">
    <div class="buttons-container">
      <button
        class="sgnd-btn sgnd-btn-ok"
        (click)="confirm()"
        cdkFocusInitial
        [style.background-color]="data.buttonBackgroundColor"
        [style.color]="data.buttonTextColor"
      >
        {{ data.acceptButtonTitle }}
      </button>
      <button
        class="sgnd-btn sgnd-btn-cancel"
        (click)="closeDialog()"
        [style.background-color]="data.altButtonBackgroundColor"
        [style.color]="data.altButtonTextColor"
      >
      {{ data.cancelButtonTitle }}
      </button>
    </div>
  </div>
</div>