import { Injectable } from '@angular/core';
import { CompanyPermission } from 'app/model/company-permission';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { CompanyPermissionsApiService } from './company-permissions-api.service';

@Injectable()
export class CompanyPermissionsService {

  public userCompanyPermissions$ = new BehaviorSubject<CompanyPermission[]>([]);

  constructor(private apiService: CompanyPermissionsApiService) { }

  public loadUserCompanyPermissions(): void {
    this.apiService.getForCurrentUser().subscribe(permissions => {
      this.userCompanyPermissions$.next(permissions);
    });
  }

  public getAll() {
    return this.apiService.getAll();
  }

  public getByCompanyId(companyId: number): Observable<CompanyPermission[]> {
    return this.apiService.getByCompanyId(companyId);
  }

  public addPermissions(companyId: number, permissionIds: number[]): Observable<void> {
    return this.apiService.addPermissions(companyId, permissionIds);
  }

  public removePermissions(companyId: number, permissionIds: number[]): Observable<void> {
    return this.apiService.removePermissions(companyId, permissionIds);
  }

}
