import { PaginatedDataRequest } from './pagination';

export class ContactsGroup {
  public id: number;
  public name: string;
  public lastModified: Date;
  public lastModifiedBy: string;
  public created: Date;
  public createdBy: string;
  public companyId: number;

  constructor(contactsGroup: ContactsGroup) {
    this.id = contactsGroup?.id;
    this.name = contactsGroup?.name;
    this.lastModified = contactsGroup?.lastModified;
    this.lastModifiedBy = contactsGroup?.lastModifiedBy;
    this.created = contactsGroup?.created;
    this.createdBy = contactsGroup?.createdBy;
    this.companyId = contactsGroup?.companyId;
  }
}

export class ContactsGroupDto {
  public id: number;
  public name: string;
  public lastModified: Date;
  public lastModifiedBy: string;
  public created: Date;
  public createdBy: string;
  public companyId: number;

  constructor(contactsGroup: ContactsGroup) {
    this.id = contactsGroup?.id;
    this.name = contactsGroup?.name;
    this.lastModified = contactsGroup?.lastModified;
    this.lastModifiedBy = contactsGroup?.lastModifiedBy;
    this.created = contactsGroup?.created;
    this.createdBy = contactsGroup?.createdBy;
    this.companyId = contactsGroup?.companyId;
  }
}
