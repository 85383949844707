import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from 'app/model/language';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
providedIn: 'root'
})
export class LanguageApiService {
    private baseURL: string;

    constructor(
        private httpClient: HttpClient,
        private noAuthHttpClient: HttpClient,
        private handler: HttpBackend
    ) {
        this.noAuthHttpClient = new HttpClient(this.handler);
        this.baseURL = `${environment.apiUrl.replace(/\/$/, '')}/api/languages`;
    }

    public getAllLanguages(): Observable<Language[]> {
        return this.httpClient.get<Language[]>(`${this.baseURL}/all`);
    }

    public getCompanyLanguages(): Observable<Language[]> {
        return this.httpClient.get<Language[]>(`${this.baseURL}/company`);
    }

    public getAnonymousCompanyLanguages(companyId: number): Observable<Language[]> {
        return this.noAuthHttpClient.get<Language[]>(`${this.baseURL}/company/${companyId}`);
    }

    public setCompanyLanguages(enabledLanguages: string[]): Observable<unknown> {
        const body = {
            enabledLanguages: enabledLanguages
        }
        return this.httpClient.post(`${this.baseURL}/company`, body);
    }
}