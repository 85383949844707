import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Sender from 'app/model/sender';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SendersApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Senders';
  }

  getSender(companyId) {
    return this.httpClient.get(this.baseURL + `/${companyId}`);
  }

  updateSender(sender: Sender) {
    return this.httpClient.put(this.baseURL, sender);
  }

  postSender(sender: Sender) {
    return this.httpClient.post(this.baseURL, sender);
  }

  deleteSender(senderId) {
    return this.httpClient.delete(this.baseURL + `/${senderId}`);
  }
}
