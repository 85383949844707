import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CreditsDTO } from './credits-dto';
import { CreditsApiService } from './credits-api.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class CreditsService {
  public credits = new BehaviorSubject<CreditsDTO>(null);

  constructor(private snackBar: MatSnackBar, private creditsApiService: CreditsApiService) {
    this.updateCredits();
  }

  /**
   * Updates the current credit reference everywhere
   */
  public updateCredits(): Observable<CreditsDTO> {
    const obs = this.creditsApiService.getCredits();
    obs.subscribe((result) => {
      if (result) {
        this.credits.next(result);
      }
    });
    return obs;
  }

  /**
   * Returns true if there's credit available.
   */
  public creditAvailable(): boolean {
    const isAvailable = this.credits.value.remainingNegotiations > 0;
    return isAvailable;
  }

  /**
   * Returns true if there's credit available.
   * Also, if there aren't enough credits, an error message is displayed.
   */
  public checkCreditWithAlert(): boolean {
    const isAvailable = this.creditAvailable();
    if (!isAvailable) {
      this.snackBar.open(`You don't have enough credits to perform the selected action `, null, {
        panelClass: ['error-alert-snackbar'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    }
    return isAvailable;
  }
}
