<div class="title">Change password</div>
<form class="form-fields-container" [formGroup]="form" (ngSubmit)="onSubmit()" autocomplete="off">
  <div class="form-group">
    <label>New Password</label>
    <input mat-input formControlName="newPwd" id="newPwd" type="password" class="form-control" />
    <div class="alert alert-danger" *ngIf="newPwd.touched && newPwd.invalid">
      <div *ngIf="newPwd.errors.required">Password is required.</div>
    </div>
  </div>

  <div class="form-group">
    <label>Confirm Password</label>
    <input mat-input formControlName="confirmPwd" id="confirmPwd" type="password" class="form-control" />
    <div class="alert alert-danger" *ngIf="confirmPwd.touched && confirmPwd.invalid">
      <div *ngIf="confirmPwd.errors.required">Confirm Password is required.</div>
    </div>
    <div class="alert alert-danger" *ngIf="confirmPwd.valid && form.invalid && form.errors?.pwdsDontMatch">
      Passwords do not match.
    </div>
  </div>

  <div class="password-hints">
    <div [class.invalid]="!regOneLower.test(form.value.newPwd)">
      <mat-icon *ngIf="regOneLower.test(form.value.newPwd); else noLower" class="pass-icon">check</mat-icon>
      <ng-template #noLower><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One lower case
    </div>
    <div [class.invalid]="!regOneUpper.test(form.value.newPwd)">
      <mat-icon *ngIf="regOneUpper.test(form.value.newPwd); else noUpper">check</mat-icon>
      <ng-template #noUpper><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One upper case
    </div>
    <div [class.invalid]="!regOneNumber.test(form.value.newPwd)">
      <mat-icon *ngIf="regOneNumber.test(form.value.newPwd); else noNumber">check</mat-icon>
      <ng-template #noNumber><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One number
    </div>
    <div [class.invalid]="!regOneSpecial.test(form.value.newPwd)">
      <mat-icon *ngIf="regOneSpecial.test(form.value.newPwd); else noSpecial">check</mat-icon>
      <ng-template #noSpecial><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One special character [!@#$%^&*]
    </div>
    <div [class.invalid]="!regAtLeastEight.test(form.value.newPwd)">
      <mat-icon *ngIf="regAtLeastEight.test(form.value.newPwd); else noEight">check</mat-icon>
      <ng-template #noEight><mat-icon class="pass-icon">close</mat-icon></ng-template>
      Minimum 8 characters
    </div>
  </div>

  <div class="buttons-container">
    <button class="sgnd-btn sgnd-btn-ok" [disabled]="!form.valid" type="submit">Save</button>
    <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
  </div>
</form>
