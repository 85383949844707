import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class Auth0ManagementApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Auth0Management';
  }

  public generateClientCredentials(companyId: number): Observable<any> {
    return this.http.post<any>(`${this.baseURL}/client_credentials/${companyId}`, null);
  }

  public deleteClientCredentials(companyId: number): Observable<any> {
    return this.http.delete<any>(`${this.baseURL}/client_credentials/${companyId}`);
  }

  public userPasswordChange(newPw: string) {
    return this.http.patch<any>(`${this.baseURL}/user/password_change`, { newPw: newPw });
  }

}
