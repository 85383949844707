import { Component, Input, OnInit } from '@angular/core';

@Component({
  // eslint-disable-next-line
  selector: 'whatsapp-icon',
  templateUrl: './whatsapp-icon.component.html',
  styleUrls: ['./whatsapp-icon.component.css'],
})
export class WhatsappIconComponent implements OnInit {
  @Input() size = '4rem';
  @Input() fill = 'white';
  @Input() className = '';
  @Input() isLegacy: boolean = true;

  constructor() {}

  ngOnInit(): void {}
}
