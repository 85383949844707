<form
  class="new-user-modal-container"
  name="newUserForm"
  id="newUserForm"
  [formGroup]="newUserForm"
  (ngSubmit)="onSubmit()"
>
  <div class="title">
    <h1>Registration Details</h1>
    <mat-icon (click)="onClose()">close</mat-icon>
  </div>
  <div class="detail-line">
    <label form="email" id="emailLabel">Email</label>
    <input
      id="email"
      name="email"
      autocomplete="off"
      title="Email"
      aria-label="Email"
      formControlName="email"
      type="email"
    />
  </div>
  <div class="detail-line">
    <label form="repeatEmail" id="repeatEmailLabel">Repeat Email</label>
    <input
      id="repeatEmail"
      name="repeatEmail"
      autocomplete="off"
      title="Repeat Email"
      aria-label="Repeat Email"
      formControlName="repeatEmail"
      type="email"
    />
  </div>
  <div class="detail-line">
    <label form="password" id="passwordLabel">Password</label>
    <input
      id="password"
      name="password"
      autocomplete="off"
      title="Password"
      aria-label="Password"
      formControlName="password"
      type="password"
    />
  </div>
  <div class="detail-line">
    <label form="repeatPassword" id="repeatPasswordLabel">Repeat Password</label>
    <input
      id="repeatPassword"
      name="repeatPassword"
      autocomplete="off"
      title="Repeat Password"
      aria-label="Repeat Password"
      formControlName="repeatPassword"
      type="password"
    />
  </div>
  <div class="password-hints">
    <div [class.invalid]="!regOneLower.test(newUserForm.value.password)">
      <mat-icon *ngIf="regOneLower.test(newUserForm.value.password); else noLower" class="pass-icon">check</mat-icon>
      <ng-template #noLower><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One lower case
    </div>
    <div [class.invalid]="!regOneUpper.test(newUserForm.value.password)">
      <mat-icon *ngIf="regOneUpper.test(newUserForm.value.password); else noUpper">check</mat-icon>
      <ng-template #noUpper><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One upper case
    </div>
    <div [class.invalid]="!regOneNumber.test(newUserForm.value.password)">
      <mat-icon *ngIf="regOneNumber.test(newUserForm.value.password); else noNumber">check</mat-icon>
      <ng-template #noNumber><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One number
    </div>
    <div [class.invalid]="!regOneSpecial.test(newUserForm.value.password)">
      <mat-icon *ngIf="regOneSpecial.test(newUserForm.value.password); else noSpecial">check</mat-icon>
      <ng-template #noSpecial><mat-icon class="pass-icon">close</mat-icon></ng-template>
      One special character [!@#$%^&*]
    </div>
    <div [class.invalid]="!regAtLeastEight.test(newUserForm.value.password)">
      <mat-icon *ngIf="regAtLeastEight.test(newUserForm.value.password); else noEight">check</mat-icon>
      <ng-template #noEight><mat-icon class="pass-icon">close</mat-icon></ng-template>
      Minimum 8 characters
    </div>
  </div>
  <div class="error-messages">
    <div class="detail-line error">
      {{ newUserForm.errors?.email_error }}
    </div>
    <div class="detail-line error">
      {{ newUserForm.errors?.password_error }}
    </div>
  </div>
  <div class="confirm-container" *ngIf="newUserForm.valid" [@appearOpacityAnimation]>
    <button mat-button class="sgnd-btn sgnd-btn-ok" type="submit">Confirm</button>
  </div>
</form>
