import { Component, OnInit } from '@angular/core';
import { ChildrenOutletContexts, RouterOutlet } from '@angular/router';
import { fader } from 'app/animations/routeChildrenAnimation';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.css'],
  animations: [fader],
})
export class DealComponent implements OnInit {
  constructor(private contexts: ChildrenOutletContexts) {}

  ngOnInit(): void {}

  prepareRoute(outlet: any) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }

  getRouteAnimationData() {
    // eslint-disable-next-line
    return this.contexts.getContext('primary')?.route?.snapshot?.data?.['animation'];
  }
}
