<app-list-bulk-edit
  [options]="menuOptions"
  [filterOptions]="filterOptions"
  [defaultFilter]="defaultFilter"
></app-list-bulk-edit>
<div class="filters-list-container">
  <div class="list-container">
    <mat-card class="slots-card">
      <div class="slots-container">
        <form>
          <mat-form-field class="form-field-no-padding" appearance="fill">
            <mat-label>Channel</mat-label>
            <input
              type="text"
              placeholder="Pick one"
              matInput
              [formControl]="slotControl"
              [matAutocomplete]="matSlotAutocomplete"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #matSlotAutocomplete="matAutocomplete"
              [formControl]="slotControl"
              [displayWith]="slotDisplayFn.bind(this)"
              (closed)="onCloseSlotSelect()"
            >
              <mat-option *ngFor="let slotElement of filteredSlotOptions | async" [value]="slotElement?.key">
                {{ (slotElement?.isDataInjestor ? 'DI-' : ' ') + slotElement?.title + ' : ' + slotElement?.count }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <div *ngIf="selectedSlot?.isDataInjestor" class="data-injestor-element">
          <div>DI</div>
        </div>
        <div *ngIf="selectedSlot" class="flex">
          <div class="negotiations-label">negotiations</div>
          <div class="negotiations-count">{{ selectedSlot.count }}</div>
        </div>
        <div style="flex: 1" *ngIf="selectedSlot">
          <button
            *ngFor="let menuItem of selectedSlot.actions"
            mat-button
            class="action-menu-option"
            (click)="menuItem.action()"
            [matTooltip]="menuItem.name"
          >
            <mat-icon>{{ menuItem.icon }}</mat-icon>
          </button>
        </div>
        <div style="flex: 1">
          <mat-icon
            style="font-size: 2.5rem; float: right; height: 2.5rem; width: 2.5rem"
            matTooltip="Add Channel"
            (click)="onSlotsSelected()"
            >add_circle_outline</mat-icon
          >
        </div>
      </div>
    </mat-card>
    <app-negotiations-grid
      (selectedNegotiations)="updateNegotiationsSelection($event)"
      (negotiationToStart)="onStartNegotiation($event)"
      (negotiationToStop)="onCancelNegotiation($event)"
      (negotiationToResume)="onResumeNegotiation($event)"
      (negotiationToPause)="onPauseNegotiation($event)"
      (negotiationToRestart)="onRestartNegotiation($event)"
      (negotiationToAddToSlot)="onAddNegotiationToSlot($event)"
      (negotiationToDelete)="onDeleteNegotiation($event)"
      (refresh)="onRefreshGrid($event)"
      [reset]="resetGrid"
      [setState]="setGridState"
    ></app-negotiations-grid>
  </div>
</div>
