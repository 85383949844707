<div class="mdi-container">
  <div class="mdi-component" *ngIf="companyFlowConfiguration">
    <div class="mdi-header company-header" [style.background]="companyFlowConfiguration.primaryColor">
      <img
        *ngIf="companyFlowConfiguration && companyFlowConfiguration.iconUrl"
        [src]="companyFlowConfiguration.iconUrl"
        style="height: 2.6rem"
      />
    </div>
    <div class="mdi-content">
      <form [formGroup]="contactCreateForm" (ngSubmit)="onSubmit()" autocomplete="off">
        <div class="form-fields-container">
          <input
            matInput
            formControlName="name"
            type="text"
            placeholder="Name"
            required
            autocomplete="off"
            style="display: none"
          />
          <mat-form-field appearance="fill" class="regular-field">
            <mat-label>{{ 'contact-subscription.name' | translate }}</mat-label>
            <input
              matInput
              formControlName="name"
              type="text"
              [placeholder]="'contact-subscription.name' | translate"
              required
              autocomplete="nope"
            />
          </mat-form-field>
          <mat-form-field appearance="fill" class="regular-field">
            <mat-label>{{ 'contact-subscription.last-name' | translate }}</mat-label>
            <input
              matInput
              formControlName="lastName"
              type="text"
              [placeholder]="'contact-subscription.last-name' | translate"
              required
              autocomplete="nope"
            />
          </mat-form-field>
          <mat-form-field *ngIf="companyId != 19" appearance="fill" class="regular-field">
            <mat-label>{{ 'contact-subscription.language' | translate }}</mat-label>
            <mat-select name="language" formControlName="language" required>
              <mat-option *ngFor="let item of allLangs | keyvalue" [value]="item.key"> {{ item.value }} </mat-option>
            </mat-select>
          </mat-form-field>

          <app-phone-picker
            (phoneChanged)="onPhoneChanged($event)"
            [isMobile]="true"
            [companyId]="companyId"
          ></app-phone-picker>

          <mat-form-field appearance="fill" class="regular-field">
            <mat-label>{{ 'contact-subscription.email' | translate }}</mat-label>
            <input
              matInput
              formControlName="email"
              type="text"
              [placeholder]="'contact-subscription.email' | translate"
              [pattern]="emailRegexp"
              required
              autocomplete="nope"
            />
          </mat-form-field>
          <app-data-ingestor-slots-selection (slotChecked)="onCheckedSlot($event)"></app-data-ingestor-slots-selection>
          <div class="buttons-container">
            <button
              class="sgnd-btn sgnd-btn-ok"
              [style.background]="getBtnStyleColor()"
              [style.color]="companyFlowConfiguration.secondaryColor"
              [disabled]="!formIsValid()"
              type="submit"
            >
              {{ 'contact-subscription.subscribe' | translate }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
