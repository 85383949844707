import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyCredentials } from 'app/model/company-credentials';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyCredentialsApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Companies/credentials';
  }

  public getCompanyCredentials(): Observable<CompanyCredentials> {
    return this.http.get<CompanyCredentials>(`${this.baseURL}/`);
  }

}
