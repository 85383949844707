import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BillingService } from 'app/services/billing.service';
import { CreditsService } from 'app/services/credits/credits.service';

@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css'],
})
export class PaymentComponent implements OnInit {
  upgradeToSubscription;

  constructor(private billingService: BillingService, private router: Router, private creditsService: CreditsService) {
    this.upgradeToSubscription = this.billingService.upgradeToSubscription;
  }

  ngOnInit(): void {
    if (this.upgradeToSubscription === null) {
      this.router.navigate(['settings/subscription']);
    }
  }

  onCompleteSubscription() {
    this.router.navigate(['settings/subscription']);
  }

  onCompleteAddingFunds() {
    this.creditsService.updateCredits();
    this.router.navigate(['settings/subscription']);
  }
}
