<form>
  <div class="studio-menu-bar-container">
    <button mat-button class="studio-menu-option" (click)="onNewFlow()" matTooltip="New">
      <mat-icon>add_to_photos</mat-icon>
    </button>
    <button mat-button class="studio-menu-option" (click)="onEditFlow()" matTooltip="Change Name">
      <mat-icon>edit</mat-icon>
    </button>
    <button mat-button class="studio-menu-option" (click)="onSaveAs()" matTooltip="Save As">
      <mat-icon>save_as</mat-icon>
    </button>
    <button mat-button class="studio-menu-option" (click)="onDelete()" matTooltip="Delete">
      <mat-icon>delete_forever</mat-icon>
    </button>
    <button mat-button class="studio-menu-option" (click)="onExportFlow()" matTooltip="Export">
      <mat-icon>save_alt</mat-icon>
    </button>
    <div class="divInputFilters">
      <input
        class="flow-selection-input inputNegotiationTypes"
        type="text"
        placeholder="Negotiation Type"
        aria-label="Number"
        matInput
        [formControl]="negotiationTypeControl"
        [matAutocomplete]="negotiationTypeAuto"
      />
      <mat-autocomplete
        autoActiveFirstOption
        #negotiationTypeAuto="matAutocomplete"
        (optionSelected)="onNegotiationTypeSelection($event)"
        [displayWith]="negotiationTypeDisplayFn.bind(this)"
      >
        <mat-option [value]="null"> (All Negotiation Types) </mat-option>
        <mat-option *ngFor="let option of filteredNegotiationTypeOptions | async" [value]="option">
          {{ capitalize(option.value.toLowerCase()) }}
        </mat-option>
      </mat-autocomplete>
      <input
        class="flow-selection-input inputFlows"
        type="text"
        placeholder="Flows"
        aria-label="Number"
        matInput
        [formControl]="flowControl"
        [matAutocomplete]="flowAuto"
      />
      <mat-autocomplete autoActiveFirstOption #flowAuto="matAutocomplete" [displayWith]="flowDisplayFn">
        <mat-option *ngFor="let option of filteredFlowsOptions | async" [value]="option">
          {{ option.name }}
        </mat-option>
      </mat-autocomplete>
    </div>
  </div>
</form>
