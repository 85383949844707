import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { DataIngestorSlot } from 'app/model/data-ingestor/di-slot';
import { ContactSuscription } from 'app/model/data-ingestor/contact-suscription';

@Injectable({
  providedIn: 'root'
})
export class DataIngestionService {
  private baseURL: string;
  public dataIngestors =  new BehaviorSubject<Array<DataIngestorSlot>>([]);

  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
  ) {
    this.httpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Slots/';
  }

  public loadDataIngestorSlots(companyId: number): Observable<any> {
    let obs = this.httpClient.get(this.baseURL + 'data-ingestor/' + companyId);
    obs.subscribe((values: Array<DataIngestorSlot>) => {
      this.dataIngestors.next(values);
    });
    return obs;
  }

  public subscribeContact(suscription: ContactSuscription): Observable<any> {
    return this.httpClient.post(this.baseURL + 'data-ingestor/subscribe', suscription);
  }

  public getCheckedSlots() {
    return this.dataIngestors.value.filter(s => s.checked === true);
  }
}
