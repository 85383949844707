import { NegotiationOption } from 'app/model/negotiation-option';
import { AccountsReceivableDocument } from './documents/accounts-receivable-document';
import { Negotiation } from './negotiation';
import { NegotiationType } from './negotiation-type';
import { PaginatedDataRequest } from './pagination';
import { SlotStatus } from './slot-status';

export class Slot {
  public slotId: number;
  public name: string;
  public status: SlotStatus;
  public started: boolean;
  public negotiationIds: number[];
  public flowId?: number;
  public negotiationCount?: number;
  public isDataIngestor?: boolean;
  public autoSubscribe?: boolean;
  public autoTrigger?: boolean;
  public slotOptions?: NegotiationOption[];
  public negotiations?: Negotiation[];
  public negotiationType?: NegotiationType;
  public negotiationsQty?: number;
  public hasSchedule?: boolean;
  public expirationDate?: Date;
  public accountsPayableDocuments: AccountsReceivableDocument[];

  constructor(slot: Slot) {
    this.slotId = slot?.slotId;
    this.name = slot?.name;
    this.status = slot?.status;
    this.started = slot?.started;
    this.negotiationIds = slot?.negotiationIds;
    this.flowId = slot?.flowId;
    this.negotiationCount = slot?.negotiations?.length || slot?.negotiationsQty;
    this.isDataIngestor = slot?.isDataIngestor;
    this.autoSubscribe = slot?.autoSubscribe;
    this.autoTrigger = slot?.autoTrigger;
    this.slotOptions = slot?.slotOptions;
    this.negotiationType = this.getValidNegotiationType(slot);
    this.hasSchedule = slot?.hasSchedule;
    this.expirationDate = slot?.expirationDate;
    this.accountsPayableDocuments = slot?.accountsPayableDocuments;
  }

  getValidNegotiationType(slot: any) {
    if(slot && slot.negotiationType !== null && slot.negotiationType !== undefined) { // zero is a valid value
      return Number(slot?.negotiationType);
    }
    else return null;
  }
}

export class UpdateSlotDto {
  public slotId: number;
  public name: string;
  public flowId?: number;
  public isDataIngestor?: boolean;
  public autoSubscribe?: boolean;
  public autoTrigger?: boolean;
  public slotOptions?: NegotiationOption[];
  public negotiationType?: NegotiationType;
  public hasSchedule?: boolean;
  public expirationDate?: Date;
  public accountsPayableDocuments: AccountsReceivableDocument[];

  constructor(slot: Slot) {
    this.slotId = slot?.slotId;
    this.name = slot?.name;
    this.autoSubscribe = slot?.autoSubscribe;
    this.autoTrigger = slot?.autoTrigger;
    this.isDataIngestor = slot?.isDataIngestor;
    this.slotOptions = slot?.slotOptions;
    this.flowId = slot?.flowId;
    this.negotiationType = slot?.negotiationType;
    this.hasSchedule = slot?.hasSchedule;
    this.expirationDate = slot?.expirationDate;
    this.accountsPayableDocuments = slot?.accountsPayableDocuments;
  }
}

export class CreateSlotDTO {
  public name: string;
  public flowId?: number;
  public negotiationIds: number[];
  public autoSubscribe?: boolean;
  public autoTrigger?: boolean;
  public isDataIngestor?: boolean;
  public slotOptions?: NegotiationOption[];
  public negotiationType?: NegotiationType;
  public expirationDate?: Date;
  public accountsPayableDocuments: AccountsReceivableDocument[];

  constructor(slot: Slot) {
    this.name = slot?.name;
    this.negotiationIds = slot?.negotiationIds;
    this.autoSubscribe = slot?.autoSubscribe;
    this.autoTrigger = slot?.autoTrigger;
    this.isDataIngestor = slot?.isDataIngestor;
    this.slotOptions = slot?.slotOptions;
    this.flowId = slot?.flowId;
    this.negotiationType = slot?.negotiationType;
    this.expirationDate = slot?.expirationDate;
    this.accountsPayableDocuments = slot?.accountsPayableDocuments;
  }
}

export interface PaginatedSlotsDataRequest extends PaginatedDataRequest {
  ownedOnly?: boolean;
  resetPageNumber?: boolean;
}
