import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { appearOpacityAnimation } from 'app/animations/appearOpacityAnimation';
import Subscriptions from 'src/assets/subscription-options.json';

@Component({
  selector: 'app-billing-subscriptions',
  templateUrl: './billing-subscriptions.component.html',
  styleUrls: ['./billing-subscriptions.component.css'],
  animations: [appearOpacityAnimation],
})
export class BillingSubscriptionsComponent implements OnInit {
  @Output() subscriptionSelected = new EventEmitter<any>();

  subscriptions = [];
  selected = null;

  constructor() {
    this.subscriptions = Subscriptions;
  }

  ngOnInit(): void {}

  onCardSelected(index) {
    this.selected = this.selected === index ? null : index;
  }

  onNext() {
    this.subscriptionSelected.emit(this.selected);
  }
}
