import { Injectable } from '@angular/core';
import { ContactsGroup, ContactsGroupDto } from 'app/model/contacts-group';
import { Observable } from 'rxjs';
import { ContactsApiService } from './contacts-api.service';
import { ContactsGroupApiService } from './contacts-group-api.service';

@Injectable()
export class ContactsGroupService {
  constructor(
    private contactsGroupApiService: ContactsGroupApiService,
    private contactsApiService: ContactsApiService
  ) {}

  public saveContactsGroup(contactsGroup: ContactsGroup): Observable<any> {
    return this.contactsGroupApiService.saveContactsGroup(contactsGroup);
  }

  public updateContactsGroup(contactsGroup: ContactsGroup): Observable<any> {
    return this.contactsGroupApiService.updateContactsGroup(contactsGroup);
  }

  public deleteContactsGroup(contactsGroupId: number): Observable<any> {
    return this.contactsGroupApiService.deleteContactsGroup(contactsGroupId);
  }

  public getContactsGroups(): Observable<ContactsGroupDto[]> {
    return this.contactsGroupApiService.getContactsGroups();
  }

  public addContactsToGroup(contactsGroupId: number, contactIds: number[]) {
    return this.contactsGroupApiService.addContactsToGroup(contactsGroupId, contactIds);
  }

  public removeContactsFromGroup(contactsGroupId: number, contactIds: number[]) {
    return this.contactsGroupApiService.removeContactsFromGroup(contactsGroupId, contactIds);
  }

  public exportContactGroup(fileName, contactsGroupId) {
    return this.contactsApiService.exportContactGroup(fileName, {
      contactsGroupId,
      excludeContactsFromGroup: null,
      sortDirection: '',
    });
  }
}
