import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class UIService {
  public showNavBars = new BehaviorSubject<boolean>(true);
  public showGlobalSpinner = new BehaviorSubject<boolean>(false);
  public showCollapsedLateralBar = new BehaviorSubject<boolean>(false);
  private readonly lateralBarCollapsed = 'LATERAL_BAR_COLLAPSED';

  constructor(private dialog: MatDialog) {
    const lateralBarCollapsed = localStorage.getItem(this.lateralBarCollapsed) === 'true';
    this.showCollapsedLateralBar.next(lateralBarCollapsed);
  }

  toggleNavBars(showSearchBar: boolean) {
    this.showNavBars.next(showSearchBar);
  }

  toggleGlobalSpinner(value: boolean, seconds: number = 0) {
    if (seconds) {
      setTimeout(() => {
        this.showGlobalSpinner.next(value);
      }, seconds * 1000);
    } else {
      this.showGlobalSpinner.next(value);
    }
  }

  showMessage(message) {
    this.dialog.open(AlertComponent, { data: message });
  }

  toggleLateralBarCollapsed() {
    const currentValue = this.showCollapsedLateralBar.getValue();
    this.showCollapsedLateralBar.next(!currentValue);
    localStorage.setItem(this.lateralBarCollapsed, currentValue ? 'false' : 'true');
  }
}
