import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { fromEvent, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'filter-paginator',
  templateUrl: './filter-paginator.component.html',
  styleUrls: ['./filter-paginator.component.css'],
})
export class FilterPaginatorComponent implements OnInit {
  @Input() placeholder = '';
  @Input() length: number;
  @Input() reset: Subject<FilterPaginatorResetMode>;
  @Input() setState: Subject<FilterPageEvent>;
  @Input() pageSize: number = FilterPaginatorComponent.DEFAULT_PAGE_SIZE;
  @Input() pageSizeOptions: number[] = [5, 10, 25, 50, 100];
  @Output() filterPage = new EventEmitter<FilterPageEvent>();
  @ViewChild('filterInput', { static: true }) filterInput: ElementRef;

  public static readonly DEFAULT_PAGE_SIZE = 25;
  public pageIndex = 0;
  public filterString: string;

  ngOnInit() {
    // debounce the filter input
    fromEvent(this.filterInput.nativeElement, 'input')
      .pipe(debounceTime(150))
      .subscribe((_: unknown) => {
        this.pageIndex = 0;
        this.emitFilterPageEvent();
      });

    // handle the reset event
    if (this.reset) {
      this.reset.subscribe((mode: FilterPaginatorResetMode) => {
        mode ??= FilterPaginatorResetMode.ALL;
        if (mode === FilterPaginatorResetMode.ALL || mode === FilterPaginatorResetMode.FILTER) {
          this.filterString = '';
        }
        if (mode === FilterPaginatorResetMode.ALL || mode === FilterPaginatorResetMode.PAGINATOR) {
          this.pageIndex = 0;
        }
      });
    }

    // update the internal filter and paginator state
    if (this.setState) {
      this.setState.subscribe((state: FilterPageEvent) => {
        if (state) {
          this.filterString = state.filterString;
          this.pageIndex = state.pageNumber - 1; // the backend uses a 1-based page number
          this.pageSize = state.pageSize;
          this.emitFilterPageEvent();
        }
      });
    }

    // emit a filter page event to publish the initial values of the filter and paginator
    this.emitFilterPageEvent();
  }

  public onClearFilter() {
    this.filterString = '';
    this.pageIndex = 0;
    this.emitFilterPageEvent();
  }

  public onPageEvent(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.pageIndex = event.pageIndex;
    this.emitFilterPageEvent();
  }

  private emitFilterPageEvent() {
    const value: FilterPageEvent = {
      pageSize: this.pageSize,
      pageNumber: this.pageIndex + 1, // the backend expects a 1-based page number
      filterString: this.filterString,
    };
    this.filterPage.emit(value);
  }
}

export interface FilterPageEvent {
  pageSize?: number;
  pageNumber?: number;
  filterString?: string;
}

export enum FilterPaginatorResetMode {
  ALL,
  FILTER,
  PAGINATOR,
}
