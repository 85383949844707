<div style="display: flex; justify-content: space-between">
  <mat-form-field appearance="fill" style="width: 30%">
    <mat-label>{{ 'phone-picker.country-code' | translate }}</mat-label>
    <input
      type="text"
      [placeholder]="'phone-picker.country-code' | translate"
      aria-label="Number"
      matInput
      [formControl]="countryCodeFrom"
      [matAutocomplete]="auto"
      [autocomplete]="isMobile ? 'nope' : 'off'"
      required
      (ngModelChange)="onCodeChange($event)"
    />
    <mat-autocomplete #auto="matAutocomplete">
      <mat-option *ngFor="let country of filteredOptions | async" [value]="country.phoneCode">
        <span class="flag-icon flag-icon-{{ country.isoCode }} flag-icon-squared"></span>
        ({{ country.phoneCode }}) {{ country.name }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field appearance="fill" style="width: 69%">
    <mat-label>{{ 'phone-picker.phone-number' | translate }}</mat-label>
    <input
      matInput
      type="number"
      [placeholder]="'phone-picker.phone-number' | translate"
      [pattern]="phoneRegexp"
      required
      [autocomplete]="isMobile ? 'nope' : 'off'"
      (change)="onLocalChange($event)"
    />
  </mat-form-field>
</div>
