<div class="settings-card-container">
  <mat-card class="custom-card" *ngIf="cred">
    <mat-card-header class="custom-card-header">
      <div>
        <!-- <img mat-card-sm-image [src]="user?.picture" /> -->
      </div>
      <mat-card-title [style.display]="'flex'" class="break-word"> Credential </mat-card-title>
    </mat-card-header>
    <div class="lines">
      <strong>Company Id</strong>
      <div>{{ cred.companyId }}</div>
      <strong>Company Name</strong>
      <div>{{ cred.name }}</div>
      <strong>Client Id</strong>
      <div>{{ cred.clientId }}</div>
    </div>
    <div class="user-fields-container">
      <button class="sgnd-btn sgnd-btn-ok bg-red" (click)="onDeleteCredentials()">Remove Credential</button>
    </div>
  </mat-card>
  <div>&nbsp;</div>
  <div class="user-fields-container" *ngIf="!cred">
    <button class="sgnd-btn sgnd-btn-ok bg-green" (click)="onGenerateCredentials()">Add Credential</button>
  </div>
</div>
