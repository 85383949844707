import { CompanySubscription } from './company-subscription';

export class Company {
    public companyId: number;
    public name: string;
    public companySubscription: CompanySubscription[];

    constructor(companyId: number, name: string) {
        this.name = name;
        this.companyId = companyId;
    }
}
