import { Component, OnInit } from '@angular/core';
import { slideLeftAnimation, slideLeftAnimationLeave } from 'app/animations/slideLeftAnimation';
import { CompanySettingsService } from 'app/components/settings';
import { AnalyticsService } from 'app/services/analytics.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-billing-screen',
  templateUrl: './billing-screen.component.html',
  styleUrls: ['./billing-screen.component.css'],
  animations: [slideLeftAnimation, slideLeftAnimationLeave],
})
export class BillingScreenComponent implements OnInit {
  companyId = null;
  selectedPlan = null;
  needsRelogin = false;
  showSuccess = false;

  constructor(
    private companySettingsService: CompanySettingsService,
    private uiService: UIService,
    private analyticsService: AnalyticsService
  ) {}

  ngOnInit(): void {
    this.companySettingsService.$companyData.subscribe((companyData) => {
      this.companyId = companyData?.companyId;
    });
    this.uiService.toggleGlobalSpinner(false);
    this.analyticsService.countBillingVisit();
  }

  onSubscriptionSelected(subscriptionOption) {
    this.selectedPlan = subscriptionOption;
  }

  completeSubscription() {
    this.needsRelogin = true;
  }

  completeAddingFunds() {
    this.showSuccess = true;
  }
}
