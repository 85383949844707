<div class="settings-card-container">
    <div class="settings-card-title">Email Settings</div>

    <div class="selector-and-message-container">
        <mat-form-field appearance="fill">
            <mat-label>Config Type</mat-label>
            <mat-select [(ngModel)]="currentEmailConfigType">
                <mat-option *ngFor="let emailConfigType of emailConfigTypes" [value]="emailConfigType">
                {{emailConfigType.label}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="currentEmailConfigType" class="description-message">{{currentEmailConfigType.description}}</div>
    </div>

    <div *ngIf="currentEmailConfigType" class="email-settings-container">
        <div *ngIf="currentEmailConfigType.allowedFields?.length" class="param-title">Parameters</div>
        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('srcAlias')">
            <mat-form-field class="regular-field">
                <mat-label>Source Alias</mat-label>
                <input matInput type="text" placeholder="Source Alias" autocomplete="off" [(ngModel)]="data.srcAlias"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.srcAlias" matTooltipPosition="right">info</mat-icon>
        </div>

        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('srcEmail')">
            <mat-form-field class="regular-field">
                <mat-label>Source Email</mat-label>
                <input matInput type="text" placeholder="Source Email" autocomplete="off" [(ngModel)]="data.srcEmail"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.srcEmail" matTooltipPosition="right">info</mat-icon>
        </div>
        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('sgApiKey')">
            <mat-form-field class="regular-field">
                <mat-label>Sendgrid Api Key</mat-label>
                <input matInput type="text" placeholder="Sendgrid Api Key" autocomplete="off" [(ngModel)]="data.sgApiKey"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.sgApiKey" matTooltipPosition="right">info</mat-icon>
        </div>
        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('host')">
            <mat-form-field class="regular-field">
                <mat-label>Smtp Server Host</mat-label>
                <input matInput type="text" placeholder="Host" autocomplete="off" [(ngModel)]="data.host"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.host" matTooltipPosition="right">info</mat-icon>
        </div>
        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('port')">
            <mat-form-field class="regular-field">
                <mat-label>Smtp Server Port</mat-label>
                <input matInput type="text" placeholder="Port" autocomplete="off" [(ngModel)]="data.port"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.port" matTooltipPosition="right">info</mat-icon>
        </div>
        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('username')">
            <mat-form-field class="regular-field">
                <mat-label>Smtp Server Username</mat-label>
                <input matInput type="text" placeholder="User Name" autocomplete="off" [(ngModel)]="data.username"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.username" matTooltipPosition="right">info</mat-icon>
        </div>
        <div class="email-field-container" *ngIf="currentEmailConfigType.allowedFields.includes('password')">
            <mat-form-field class="regular-field">
                <mat-label>Smtp Server Password</mat-label>
                <input matInput type="text" placeholder="Password" autocomplete="off" [(ngModel)]="data.password"/>
            </mat-form-field>
            <mat-icon class="field-tooltip" [matTooltip]="tooltips.password" matTooltipPosition="right">info</mat-icon>
        </div>

    </div>

    <div class="btns">
        <button class="btn btn-secondary restore-btn" (click)="onRestore()">Restore</button>
        <button class="btn btn-secondary" (click)="onTest()">Test</button>
        <button class="btn btn-primary save-btn" (click)="onSave()">Save</button>
    </div>
</div>
