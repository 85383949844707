import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CreateTemplateRequest, IInnerFormComponent, TwilioContentActionType, TwilioContentCategories, TwilioContentTemplateAction, TwilioContentTemplateTypes, TwilioLanguages } from '../model';
import NEGOTIATION_VARIABLES from 'app/model/studio-negotiation-variables';
import { TwilioMediaUrlComponent } from '../type-based-components';

/**
 * Allows the user to customize a WhatsApp Content Template parameters.
 */
@Component({
  selector: 'app-content-template-form',
  templateUrl: './content-template-form.component.html',
  styleUrls: ['./content-template-form.component.css'],
})
export class ContentTemplateFormComponent implements OnInit {
  @Input() public contentType: string;
  @Output() public formData = new EventEmitter<CreateTemplateRequest>();
  @Output() public onCancelled = new EventEmitter<string>();
  @Output() public onBack = new EventEmitter<string>();
  @ViewChild('mediaUrl') public mediaUrl: IInnerFormComponent;

  public variables: string[] = [];
  public languages: ILanguage[] = [];
  public templateCreateForm: FormGroup;
  public nextIsDisabled: boolean = true;
  public showActionBtns: boolean = false;
  public templateTypes = TwilioContentTemplateTypes;
  public variableValues: INegotiationVariable[] = NEGOTIATION_VARIABLES;

  constructor(
    private formBuilder: FormBuilder,
  ) {
    this.setupLanguagesMap();
    this.buildTemplateCreateForm();
  }

  ngOnInit(): void { }

  /**
   * TODO: Finish this feature...
   */
  private setupActions() {
    if(this.contentType === TwilioContentTemplateTypes.CALL_TO_ACTION) {
      this.showActionBtns = true;
      return [new TwilioContentTemplateAction()];
    }
    return [];
  }

  /**
   * Returns a map where keys are language names and
   * values are the characters that Twilio uses to
   * recognize those languages.
   */
  private setupLanguagesMap() {
    let langList = [];
    let langKeys = Object.keys(TwilioLanguages);
    langKeys.forEach(langKey => {
      let capitalLangKey = langKey.charAt(0).toUpperCase() + langKey.substring(1).toLowerCase();
      langList.push({name: capitalLangKey, code: TwilioLanguages[langKey]});
    });
    this.languages = langList;
  }


  /**
   * Builds the basic form used in this component.
   */
  private buildTemplateCreateForm() {
    this.templateCreateForm = this.formBuilder.group({
      name: '',
      lang: TwilioLanguages.ENGLISH,
      body: '',
      category: TwilioContentCategories.MARKETING,
      contentType: this.contentType,
      variables: {}
      //,actions: this.setupActions()
    });
  }

  /**
   * Adds a variable to the list of variables and also to the body field.
   */
  public addVariable() {
    let index = this.variables.length + 1;
    this.variables.push('contactName');
    let value2Set = this.templateCreateForm.value.body + '{{' + (index) + '}}';
    this.templateCreateForm.get('body').setValue(value2Set);
    // TODO: Fix issue grafico con el display de variables asignadas.
  }

  public updateVariable(index: number, event: IVariable): void {
    this.variables[index] = event.value;
  }

  /**
   * Removes a variable from both the variables list and the body.
   */
  public onRemoveVariable(index: number): void {
    if(index !== -1) {
      let bodyValue = this.templateCreateForm.value.body;
      bodyValue = bodyValue.replace('{{' + (index + 1) + '}}', ''); // Delete current index
      if(this.variables.length > index) {
        // Decrease higher indexes
        for(let i = index + 1; i < this.variables.length; i++) {
          bodyValue = bodyValue.replace('{{' + (i + 1) + '}}', '{{' + i + '}}');
        }
      }
      // Persist changes
      this.templateCreateForm.get('body').setValue(bodyValue);
      this.clearVariableOnSubComponents(index);
      this.variables.splice(index, 1);
    }
  }

  public clearVariableOnSubComponents(index: number) {
    this.mediaUrl?.clearVariable(index);
  }

  public onSubmit() {
    let request = new CreateTemplateRequest();
    request.name = this.templateCreateForm.value.name;
    request.lang = this.templateCreateForm.value.lang;
    request.body = this.templateCreateForm.value.body;
    request.variables = this.variables;
    this.addDataFromInnerForm(request);
    this.formData.emit(request);
  }

  private addDataFromInnerForm(request: CreateTemplateRequest) {
    this.mediaUrl?.addData(request);
  }
  
  public onCancel() {
    this.onCancelled.emit(null);
  }

  public onNavigateBackwards() {
    this.onBack.emit(null);
  }

  updateDisabledNextStatus() {
    let mediaFormIsValid = this.validateMediaInput();
    console.log('mediaFormIsValid', mediaFormIsValid);
    this.nextIsDisabled = !this.templateCreateForm.valid || !mediaFormIsValid;
  }

  validateMediaInput() {
    let existsAndItsValid = this.contentType === this.templateTypes.MEDIA && this.mediaUrl && this.mediaUrl.isFormValid();
    let doesntExist = !this.mediaUrl;
    return doesntExist || existsAndItsValid;
  }

  public onStatusUpdate(event: any) {
    this.updateDisabledNextStatus();
  }

  public onPreviewSubmit(event: any) {
    console.log(event);
  }
}

interface ILanguage {
  name: string;
  code: string;
} 

interface IVariable {
  index: number;
  value: string;
}

interface INegotiationVariable {
  name: string;
  value: string;
}
