import { Component, Inject, Input, OnInit } from '@angular/core';
import { ContentTemplateType, CreateTemplateRequest, TwilioContentTemplateTypes } from '../model';
import { NEGOTIATION_VARIABLE_SAMPLES } from 'app/model/studio-negotiation-variables';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { WhatsAppContentTemplate } from 'app/model/content-template';

@Component({
  selector: 'app-content-template-small-preview',
  templateUrl: './content-template-small-preview.component.html',
  styleUrls: ['./content-template-small-preview.component.css'],
})
export class ContentTemplateSmallPreviewComponent implements OnInit {
  @Input() public contentData: CreateTemplateRequest;
  @Input() public selectedType: ContentTemplateType;
  public sampleBody: string;

  constructor(
    public dialogRef: MatDialogRef<ContentTemplateSmallPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WhatsAppContentTemplate
  ) {
    let type = <TwilioContentTemplateTypes> data.type;
    this.contentData = Object.assign(new CreateTemplateRequest(), data);
    if(data && data.variables) {
      let strVariables = (<any>data.variables).replaceAll('",}', '"}');
      let objVariables = JSON.parse(strVariables);
      this.contentData.variables = Object.values(objVariables);
    }
    this.selectedType = Object.assign(new ContentTemplateType(), {type});
  }

  ngOnInit(): void {
    if(this.contentData) {
      this.setSampleBody();
    }
  }

  public setSampleBody() {
    this.sampleBody = this.contentData?.body;
    this.contentData.variables.forEach((x, index) => {
      this.sampleBody = this.sampleBody.replace('{{'+(index+1)+'}}', '{{'+x+'}}');
    });
    Object.keys(NEGOTIATION_VARIABLE_SAMPLES).forEach(key => {
      this.sampleBody = this.sampleBody.replace('{{'+(key)+'}}', NEGOTIATION_VARIABLE_SAMPLES[key]);
    });
  }

  public onClose() {
    this.dialogRef.close();
  }
}
