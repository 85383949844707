import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Attachments';
  }

  public getAttachmentsBySlot(slotId: any) {
    return this.httpClient.get(`${this.baseURL}/Slot/${slotId}`);
  }

  public getAttachmentsByNegotiation(negotiationId: any) {
    return this.httpClient.get(`${this.baseURL}/Negotiation/${negotiationId}`);
  }

  public postAttachmentBySlot(slotId, file: File) {
    const fd = new FormData();
    fd.append('File', file, file.name);
    return this.httpClient.post(`${this.baseURL}/Slot/${slotId}`, fd);
  }

  public postAttachmentByNegotiation(negotiationId, file: File) {
    const fd = new FormData();
    fd.append('File', file, file.name);
    return this.httpClient.post(`${this.baseURL}/Negotiation/${negotiationId}`, fd);
  }

  public getAttachmentForSlot(slotId, attachmentId) {
    return this.httpClient.post(
      `${this.baseURL}/Slot/${slotId}/Attachment/${attachmentId}`,
      {},
      { responseType: 'blob' }
    );
  }

  public getAttachmentForNegotiation(negotiationId, attachmentId) {
    return this.httpClient.post(
      `${this.baseURL}/Negotiation/${negotiationId}/Attachment/${attachmentId}`,
      {},
      { responseType: 'blob' }
    );
  }

  public deleteAttachmentForSlot(slotId, attachmentId) {
    return this.httpClient.delete(`${this.baseURL}/Slot/${slotId}/Attachment/${attachmentId}`);
  }

  public deleteAttachmentForNegotiation(negotiationId, attachmentId) {
    return this.httpClient.delete(`${this.baseURL}/Negotiation/${negotiationId}/Attachment/${attachmentId}`);
  }

  public postAttachmentByFlowActionSignatureParameter(flowId: number, file: File) {
    const fd = new FormData();
    fd.append('File', file, file.name);
    return this.httpClient.post(`${this.baseURL}/Signature/${flowId}`, fd);
  }
}
