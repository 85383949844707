import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { BillingService } from 'app/services/billing.service';
import { CreditsService } from 'app/services/credits/credits.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-billing-settings',
  templateUrl: './billing-settings.component.html',
  styleUrls: ['./billing-settings.component.css'],
})
export class BillingSettingsComponent implements OnInit {
  public credits = null;
  public currentSubscription = null;

  constructor(
    private billingService: BillingService,
    private creditsService: CreditsService,
    private router: Router,
    private snackBar: MatSnackBar,
    private uiService: UIService,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.billingService.$subscriptionDetails.subscribe((val) => {
      this.currentSubscription = val;
    });
    this.creditsService.credits.subscribe((val) => {
      this.credits = val;
    });
  }

  onAddCredit(): void {
    this.billingService.setUpgradeToSubscription({ subscriptionType: -1 });
    this.router.navigate(['settings/payment']);
  }

  cancelSubscription() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Are you sure you want to cancel your subscription?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.uiService.toggleGlobalSpinner(true);
          this.billingService
            .cancelSubscription()
            .toPromise()
            .then((res: any) => {
              if (res?.success === false && res?.message) {
                this.snackBar.open(`Error: ${res.message}`, null, {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 3000,
                });
              }
              this.billingService.loadSubscriptionDetails();
            })
            .catch((err) => {
              this.snackBar.open(`Error: ${err}`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            })
            .finally(() => {
              this.uiService.toggleGlobalSpinner(false);
            });
        }
      });
  }
}
