import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactsWithTotalCountDto } from 'app/model/contacts-with-total-count-dto';
import { PaginatedDataResponse } from 'app/model/pagination';
import { Observable } from 'rxjs';
import { Contact, ContactDto, PaginatedContactsDataRequest } from 'src/app/model/contact';
import { environment } from 'src/environments/environment';
import { ServiceUtils } from './service-utils';

@Injectable()
export class ContactsApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Contacts';
  }

  getContactsList(acceptCachedResponse: boolean = false): Observable<ContactDto[]> {
    const options = ServiceUtils.getRequestOptions(acceptCachedResponse);
    return this.httpClient.get<Contact[]>(this.baseURL, options);
  }

  getMyContactsList(acceptCachedResponse: boolean = false): Observable<ContactDto[]> {
    const options = ServiceUtils.getRequestOptions(acceptCachedResponse);
    return this.httpClient.get<Contact[]>(this.baseURL + '/mine', options);
  }

  saveContact(contact: Contact): Observable<any> {
    const contactDto = new ContactDto(contact);
    return this.httpClient.post<ContactDto>(this.baseURL, contactDto);
  }

  updateContact(contact: Contact): Observable<any> {
    const contactDto = new ContactDto(contact);
    return this.httpClient.put<ContactDto>(this.baseURL + '/' + contact.contactId, contactDto);
  }

  postUploadContacts(formData: FormData, contactsGroupId: number): Observable<any> {
    let queryParam = '';
    if (contactsGroupId != null) {
      queryParam = `?contactsGroupId=${contactsGroupId}`;
    }
    return this.httpClient.post<File>(this.baseURL + `/LoadFromExcel${queryParam}`, formData);
  }

  deleteContacts(contactIds: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: contactIds,
    };
    return this.httpClient.delete<any>(this.baseURL, options);
  }

  getPaginatedContactsList(
    filter: PaginatedContactsDataRequest,
    acceptCachedResponse: boolean = false
  ): Observable<PaginatedDataResponse<ContactDto>> {
    const options = ServiceUtils.getRequestOptions(acceptCachedResponse);
    return this.httpClient.post<PaginatedDataResponse<ContactDto>>(this.baseURL + '/Search', filter, options);
  }

  getPaginatedContactsListByGroup(
    contactsGroupId: number,
    excludeContactsFromGroup: number,
    pageSize: number,
    pageNumber: number,
    sortedBy: string,
    sortDirection: string,
    textFilter: string
  ): Observable<ContactsWithTotalCountDto> {
    const body = {
      contactsGroupId,
      excludeContactsFromGroup,
      pageSize,
      pageNumber,
      textFilter,
      sortedBy,
      sortDirection,
    };
    return this.httpClient.post<ContactsWithTotalCountDto>(`${this.baseURL}/Search/contactsgroup`, body);
  }

  exportContacts(fileName, params) {
    return this.httpClient.post(`${this.baseURL}/Search-export/${fileName}`, params, { responseType: 'text' });
  }

  exportContactGroup(fileName, params) {
    return this.httpClient.post(`${this.baseURL}/Search/contactsgroup-export/${fileName}`, params, {
      responseType: 'text',
    });
  }
}
