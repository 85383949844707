<div class="title">Create Document</div>

<form [formGroup]="documentCreateForm" (ngSubmit)="onSubmit()">
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" type="text" placeholder="Description" required autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>External Id</mat-label>
            <input matInput formControlName="externalId" type="text" placeholder="External Id" required autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Debtor</mat-label>
            <input matInput formControlName="debtor" type="text" placeholder="Debtor" required autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Amount</mat-label>
            <input matInput formControlName="amount" type="number" placeholder="Amount" required autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Date</mat-label>
            <input matInput formControlName="date" type="date" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Due Date</mat-label>
            <input matInput formControlName="dueDate" type="date" required>
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!documentCreateForm.valid"
                type="submit">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>