import { Injectable } from '@angular/core';
import { Language } from 'app/model/language';
import { LanguageApiService } from './language-api.service';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
    // local language cache
    private _languages: Language[];

    constructor(
        private languageApiService: LanguageApiService
    ) {
    }

    public async getLanguagesAnonymously(companyId: number): Promise<Language[]> {
        if (this._languages == null) {
            this._languages = await this.languageApiService.getAnonymousCompanyLanguages(companyId).toPromise();
        }
        return this._languages;
    }

    public async getLanguages(): Promise<Language[]> {
        if (this._languages == null) {
            this._languages = await this.languageApiService.getCompanyLanguages().toPromise();
        }
        return this._languages;
    }
    
    public async getAllLanguages(): Promise<Language[]> {
        return this.languageApiService.getAllLanguages().toPromise();
    }

    public async setCompanyLanguages(enabledLanguages: string[]) {
        await this.languageApiService.setCompanyLanguages(enabledLanguages).toPromise();
        // clear the cache
        this._languages = null;
    }
}