import { Injectable } from '@angular/core';
import { CompanyParameter } from 'app/model/company-parameter';
import { Observable } from 'rxjs';
import { CompanyParametersApiService } from './company-parameters-api.service';

@Injectable()
export class CompanyParametersService {

  constructor(private apiService: CompanyParametersApiService) { }

  public getAllByParameterId(parameterId: string): Observable<CompanyParameter[]> {
    return this.apiService.getAllByParameterId(parameterId);
  }

}
