import { Injectable } from '@angular/core';
import { Flow } from 'app/model/flow';
import { FlowAction } from 'app/model/flow-action';
import { NegotiationType } from 'app/model/negotiation-type';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from 'src/core/services/user.service';
import { FlowsApiService } from './flows-api.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class FlowsService {
  public flows = new BehaviorSubject<Flow[]>([]);
  public $plainFlow = new BehaviorSubject<any>(null);
  public $expandedFlow = new BehaviorSubject<any>(null);

  constructor(private userService: UserService, private flowsApiService: FlowsApiService) {
    this.userService.currentUserAsync().subscribe((currentUser) => {
      this.reloadFlowsList().subscribe(() => {
        console.log('FlowsService - Initial Flows list load.');
      });
    });
  }

  public reloadFlowsList(): Observable<void> {
    const observable = new Observable<void>((observer) => {
      this.flowsApiService.getFlowsList().subscribe(
        (res) => {
          this.flows.next(res.map((x) => Object.assign(new Flow(), x)));
          observer.next();
        },
        (err) => {
          observer.error(err);
        }
      );
    });
    return observable;
  }

  public selectFlow(flow) {
    if (typeof flow !== 'string') {
      this.$expandedFlow.next(null);
      this.$plainFlow.next(flow);
      if (flow) {
        this.getExpandedFlow(flow.flowId);
      }
    }
  }

  public getFilteredFlows(flowType: NegotiationType) {
    return this.flows.value.filter((x) => x.type == flowType);
  }

  public saveFlow(flow: Flow): Observable<number> {
    return this.flowsApiService.saveFlow(flow);
  }

  public updateFlow(flow: Flow): Observable<any> {
    return this.flowsApiService.updateFlow(flow);
  }

  public deleteFlow(flowId: number): Observable<any> {
    return this.flowsApiService.deleteFlow(flowId);
  }

  public deleteFlows(flowIds: Array<number>): Observable<any> {
    return this.flowsApiService.deleteFlows(flowIds);
  }

  public getExpandedFlow(flowId) {
    return this.flowsApiService.getExpandedFlow(flowId).subscribe((expandedFlow) => {
      this.$expandedFlow.next(expandedFlow);
    });
  }

  public getExportFlow(flowId: number) {
    return this.flowsApiService.getExportFlow(flowId);
  }

  public addAction(flowAction: FlowAction) {
    return this.flowsApiService.saveAction(flowAction);
  }

  public updatedAction(flowAction: FlowAction) {
    return this.flowsApiService.updateAction(flowAction);
  }

  public deleteAction(flowActionId: number) {
    return this.flowsApiService.deleteAction(flowActionId);
  }

  public postRelationship(fromId, toId) {
    return this.flowsApiService.postRelationship(fromId, toId);
  }

  public deleteRelationship(fromId, toId) {
    return this.flowsApiService.deletetRelationship(fromId, toId);
  }

  public cloneFlow(flowId: number, flowName: string): Observable<number> {
    return this.flowsApiService.cloneFlow(flowId, flowName);
  }

  public saveMessages(
    messages: [
      {
        flowActionId: number;
        language: string;
        content: string;
        flowActionMessageId: string;
        index?: number;
        extraContent?: string;
      }
    ]
  ) {
    const promises = [];
    messages.forEach((msg) => {
      let promise = null;
      if (!msg.flowActionMessageId) {
        promise = this.flowsApiService
          .postMessage(msg.flowActionId, msg.language, msg.content, msg.index, msg.extraContent)
          .toPromise();
      } else {
        promise = this.flowsApiService
          .saveMessage(msg.language, msg.content, msg.flowActionMessageId, msg.index, msg.extraContent)
          .toPromise();
      }
      promises.push(promise);
    });
    return Promise.all(promises).then(
      (res) => {
        this.refreshCurrentFlow();
        return res;
      },
      () => {
        throw new Error('An error occurred while saving messages');
      }
    );
  }

  public postCallProperties(callProperties) {
    return this.flowsApiService.postCallProperties(callProperties).pipe(this.refreshCurrentFlowTap());
  }

  public saveCallProperties(callProperties) {
    return this.flowsApiService.saveCallProperties(callProperties).pipe(this.refreshCurrentFlowTap());
  }

  public postDelayProperties(delayProperties) {
    return this.flowsApiService.postDelayProperties(delayProperties).pipe(this.refreshCurrentFlowTap());
  }

  public saveDelayProperties(delayProperties) {
    return this.flowsApiService.saveDelayProperties(delayProperties).pipe(this.refreshCurrentFlowTap());
  }

  public createCcpProperties(ccpProperties) {
    return this.flowsApiService.postCcpProperties(ccpProperties).pipe(this.refreshCurrentFlowTap());
  }

  public updateCcpProperties(ccpProperties) {
    return this.flowsApiService.putCcpProperties(ccpProperties).pipe(this.refreshCurrentFlowTap());
  }

  public createSignatureProperties(signatureProperties) {
    return this.flowsApiService.postSignatureProperties(signatureProperties).pipe(this.refreshCurrentFlowTap());
  }

  public updateSignatureProperties(signatureProperties) {
    return this.flowsApiService.putSignatureProperties(signatureProperties).pipe(this.refreshCurrentFlowTap());
  }

  public saveWhatsappProperties(whatsappProperties) {
    const promises = [];
    whatsappProperties.forEach((prop) => {
      if (prop.propertyValue) {
        promises.push(
          this.flowsApiService
            .postWhatsappProperties(prop)
            .toPromise()
            .catch((err) => {
              throw new Error('Saving whatsapp properties');
            })
        );
      }
    });

    return Promise.all(promises).then(
      (res) => {
        this.refreshCurrentFlow();
        return res;
      },
      (err) => {
        throw new Error(err ? err : 'An error occurred');
      }
    );
  }

  private refreshCurrentFlowTap() {
    return tap(() => {
      this.refreshCurrentFlow();
    });
  }

  private refreshCurrentFlow() {
    if (this.$expandedFlow.value?.flowId) {
      this.getExpandedFlow(this.$expandedFlow.value.flowId);
    }
  }
}
