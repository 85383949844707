import { Injectable } from '@angular/core';
import { CompanyCredentials } from 'app/model/company-credentials';
import { Observable } from 'rxjs';
import { CompanyCredentialsApiService } from './company-credentials-api.service';

@Injectable()
export class CompanyCredentialsService {

  constructor(private apiService: CompanyCredentialsApiService) { }

  public getCompanyCredentials(): Observable<CompanyCredentials> {
    return this.apiService.getCompanyCredentials();
  }

}
