import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateContent } from 'app/model/whatsapp-option-template-content';
import { LanguageService } from 'app/services/language.service';

@Component({
  selector: 'app-edit-whatsapp-template-language-dialog',
  templateUrl: './edit-whatsapp-template-language-dialog.component.html',
  styleUrls: ['./edit-whatsapp-template-language-dialog.component.css'],
})
export class EditWhatsappTemplateLanguageDialogComponent implements OnInit {

  contentCreateForm: FormGroup;
  whatsappTemplateContent: WhatsappOptionTemplateContent;
  availableLanguagesList: { [key: string]: string } = {};
  selectedLanguage: string;

  constructor(
    public dialogRef: MatDialogRef<EditWhatsappTemplateLanguageDialogComponent>,
    private formBuilder: FormBuilder,
    private languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: WhatsappOptionTemplate
  ) { 
  }

  ngOnInit(): void {
    this.loadLanguages();
    this.whatsappTemplateContent = new WhatsappOptionTemplateContent(this.selectedLanguage, '', 0);
    this.buildTemplateContentCreateForm(this.whatsappTemplateContent);
  }

  buildTemplateContentCreateForm(whatsappTemplateContent: WhatsappOptionTemplateContent) {
    this.contentCreateForm = this.formBuilder.group({
      language: whatsappTemplateContent.language,
      content: whatsappTemplateContent.content
    });
  }

  onSubmit(): void {
    const templateContent = new WhatsappOptionTemplateContent(this.contentCreateForm.value.language, this.contentCreateForm.value.content, 0);
    this.data.whatsappOptionTemplateContents.push(templateContent);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  private async loadLanguages() {
    const languages = await this.languageService.getLanguages();
    const availableLanguages = languages.filter(x => !this.data.whatsappOptionTemplateContents.some(y => y.language === x.languageCode))
    this.availableLanguagesList = Object.fromEntries(availableLanguages.map(x => [x.languageCode, x.name]));
    this.selectedLanguage = availableLanguages[0].languageCode;
  }
}
