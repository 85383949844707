import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation, PaginatedNegotiationsDataRequest } from 'app/model/negotiation';
import { Slot } from 'app/model/slot';
import { NegotiationsService } from 'app/services/negotiations.service';
import { SlotsService } from 'app/services/slots.service';
import { AddNegotiationComponent } from '../add-negotiation/add-negotiation.component';
import { FlowStoperComponent } from '../flows/flow-stoper/flow-stoper.component';
import { FlowResumerComponent } from '../flows/flow-resumer/flow-resumer.component';
import { FlowPauserComponent } from '../flows/flow-pauser/flow-pauser.component';
import { AddSlotComponent } from '../add-slot/add-slot.component';
import { ScheduleSlotComponent } from '../schedule-slot/schedule-slot.component';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { FlowRestarterComponent } from '../flows/flow-restarter/flow-restarter.component';
import { AddNegotiationToSlotComponent } from '../add-negotiation-to-slot/add-negotiation-to-slot.component';
import { SlotStatus } from 'app/model/slot-status';
import { CreditsService } from 'app/services/credits/credits.service';
import { TableAction } from 'app/model/table-action';
import { LateralListSection } from 'app/model/lateral-list-section';
import { HttpErrorResponse } from '@angular/common/http';
import { UploadNegotiationsFileComponent } from '../upload-negotiations-file/upload-negotiations-file.component';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { FilterPaginatorComponent } from 'core/components/filter-paginator/filter-paginator.component';
import { StorageService } from 'app/services/persistence.service';
import { UIService } from 'core/services/ui.service';
import { StartSlotComponent } from '../start-slot/start-slot.component';
import { NegotiationStatus } from 'app/model/negotiation-status';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import { CompanySettingsService } from 'app/components/settings';

@Component({
  selector: 'app-negotiations-screen',
  templateUrl: './negotiations-screen.component.html',
  styleUrls: ['./negotiations-screen.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class NegotiationsScreenComponent implements OnInit, OnDestroy {
  menuOptions: TableAction[] = [
    {
      title: 'New',
      key: 'new',
      action: this.onNew.bind(this),
      icon: 'add_to_photos',
    },
    {
      title: 'Upload',
      key: 'upload',
      action: this.onUpload.bind(this),
      icon: 'upload_file',
    },
    {
      title: 'Delete Selected',
      key: 'delete',
      action: this.onDeleteSelected.bind(this),
      icon: 'delete_forever',
    },
    {
      title: 'Remove Selected',
      key: 'remove_selected',
      action: this.onRemoveSelected.bind(this),
      icon: 'cancel',
    },
  ];
  filterOptions: TableAction[] = [
    {
      title: 'All',
      action: this.showAllNegotiations.bind(this),
      collapsedIcon: 'density_small',
    },
    {
      title: 'Owned',
      action: this.showMyNegotiations.bind(this),
      collapsedIcon: 'person',
    },
  ];

  lateralListSection: LateralListSection = {
    title: 'Channels',
    icon: 'folder_open',
    rightIcon: 'add_circle_outline',
    extraAction: this.onSlotsSelected.bind(this),
    elements: [],
  };

  selectedNegotiations: Negotiation[];
  slotControl = new FormControl(null);
  filteredSlotOptions: Observable<any[]>;
  slots: Slot[];
  slotElements: any[];
  selectedSlot: any;
  defaultFilter = 'All';
  public resetGrid: Subject<boolean> = new Subject();
  public setGridState: BehaviorSubject<PaginatedNegotiationsDataRequest> = new BehaviorSubject(null);

  private refreshNegotiationsTimerId: NodeJS.Timer;
  private currentRequest: PaginatedNegotiationsDataRequest;

  private readonly UPDATE_TIME = 20 * 1000;
  private readonly STORAGE_CURRENT_REQUEST = 'negotiations.currentRequest';
  private processRefreshEvent = true;
  private companyId = null;

  private companySettingsSub: Subscription = null;

  constructor(
    public negotiationService: NegotiationsService,
    public slotsService: SlotsService,
    public companySettingsService: CompanySettingsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private creditService: CreditsService,
    private storageService: StorageService,
    private uiService: UIService
  ) {
    this.companySettingsSub = this.companySettingsService.$companyData.subscribe((val) => {
      this.companyId = val?.companyId;
    });
  }

  filterSlots(value) {
    let filtered = this.slotElements;
    if (value == null) {
      return filtered;
    }
    const lowered = value ? value.toLowerCase?.() : '';
    if (typeof value === 'string') {
      this.selectedSlot = null;
      filtered = this.slotElements.filter((el) => this.getFullName(el).toLowerCase().includes(lowered));
    } else {
      // eslint-disable-next-line
      filtered = filtered.filter((options) => options.key == value.key);
    }
    return filtered;
  }

  getFullName(slot) {
    return `${slot.isDataInjestor ? 'DI-' : ''}${slot.title}`;
  }

  initFilters() {
    this.filteredSlotOptions = this.slotControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterSlots(value || ''))
    );
  }

  onCloseSlotSelect() {
    const realValue = this.slotControl.value;
    this.initFilters();
    this.slotControl.setValue(realValue);
  }

  ngOnInit(): void {
    this.initFilters();
    this.currentRequest = this.storageService.getSessionItem(this.STORAGE_CURRENT_REQUEST);
    this.defaultFilter = this.currentRequest?.mineOnly ? 'Owned' : 'All';
    if (this.currentRequest != null) {
      this.negotiationService.setSlot(this.currentRequest.slot);
      this.negotiationService.setOwnerFiltered(this.currentRequest.mineOnly);
      // ignore the initial event with default values
      this.processRefreshEvent = false;
      this.setGridState.next(this.currentRequest);
    } else {
      this.showAllNegotiations();
    }
    this.negotiationService.$currentSlotInfo.subscribe((val) => {
      if (this.selectedSlot && this.selectedSlot.key && this.selectedSlot.key === val?.slotId && this.companyId === 2) {
        const action = this.selectedSlot.actions.find((act) => act.key === 'has_schedule');
        action.icon = val.hasSchedule ? 'alarm_on' : 'schedule_send';
      }
    });
    this.slotsService.getData();
    this.slotsService.slots.subscribe((values: Slot[]) => this.onSlotReload(values));
  }

  ngOnDestroy(): void {
    this.clearDataRefreshInterval();
    this.companySettingsSub?.unsubscribe();
    this.storageService.setSessionItem(this.STORAGE_CURRENT_REQUEST, this.currentRequest);
  }

  slotDisplayFn(nt: any): string {
    // eslint-disable-next-line
    const el = this.slotElements.find((se) => se.key == nt);
    this.selectSlot(el?.key);
    return el ? (el.isDataInjestor ? 'DI-' : ' ') + el.title : '';
  }

  selectSlot(value) {
    // eslint-disable-next-line
    const el = this.slotElements.find((slot) => slot.key == value);
    if (el) {
      this.selectedSlot = el;
      this.selectedSlot.titleAction();
    }
  }

  onNew() {
    this.dialog.open(AddNegotiationComponent, {
      panelClass: 'custom-dialog-container',
      width: '60vw',
      maxWidth: '900px',
      minWidth: '500px',
    });
  }

  onRemoveSelected() {
    if (this.selectedNegotiations.length === 0) {
      this.snackBar.open(`No Negotiations marked for removal.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to remove the selected Negotiations from their respective channels?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          const selectedIds = this.selectedNegotiations.map((negotiation) => negotiation.negotiationId);
          this.negotiationService.removeManyNegotiations(selectedIds).subscribe(() => {
            this.refreshNegotiations(this.currentRequest);
            this.snackBar.open(`Negotiations removed successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  disableBtns() {
    return !this.selectedNegotiations || !this.selectedNegotiations.length;
  }

  onDeleteSelected() {
    if (this.selectedNegotiations.length === 0) {
      this.snackBar.open(`No Negotiations marked for deletion.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Negotiations?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          const selectedIds = this.selectedNegotiations.map((negotiation) => negotiation.negotiationId);
          this.negotiationService.deleteNegotiations(selectedIds).subscribe(() => {
            this.refreshNegotiations(this.currentRequest);
            this.slotsService.getData();
            this.snackBar.open(`Selected Negotiations deleted successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  /**
   * Runs basic validations before showing the slot creation popup.
   */
  validateSlotCreation(): boolean {
    /*if (this.selectedNegotiations.length === 0) {
      this.snackBar.open(`No Negotiations marked for a new slot.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return false;
    }*/
    const negotiationWithSlotId = this.selectedNegotiations.find((negotiation) => negotiation.slotId != null);
    if (negotiationWithSlotId) {
      this.snackBar.open(`Negotiation ${negotiationWithSlotId.description} is already assigned to a Channel.`, null, {
        panelClass: ['error-alert-snackbar'],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return false;
    }
    return true;
  }

  onSlotsSelected() {
    if (this.validateSlotCreation()) {
      const selectedIds = this.selectedNegotiations.map((negotiation) => negotiation.negotiationId);
      const firstSelected =
        this.selectedNegotiations && this.selectedNegotiations.length > 0 && this.selectedNegotiations[0]
          ? this.selectedNegotiations[0]
          : null;
      this.dialog.open(AddSlotComponent, {
        panelClass: 'custom-dialog-container',
        minWidth: '500px',
        data: {
          negotiationType: firstSelected?.negotiationType,
          flowId: firstSelected?.flowId,
          slot: null,
          selectedIds,
        },
      });
    }
  }

  public onEditSlot(slot: Slot) {
    this.dialog.open(AddSlotComponent, {
      panelClass: 'custom-dialog-container',
      minWidth: '500px',
      data: { slot, selectedIds: slot.negotiationIds, flowId: slot.flowId },
    });
  }

  public onSlotsScheduled(slot: Slot) {
    this.dialog.open(ScheduleSlotComponent, {
      panelClass: 'custom-dialog-container',
      minWidth: '500px',
      data: {
        slot,
      },
    });
  }

  isSelected(key: number) {
    const currentSlot = this.negotiationService.getSlot();
    return currentSlot && key === currentSlot;
  }

  updateNegotiationsSelection(negotiations: Negotiation[]) {
    this.selectedNegotiations = negotiations;
  }

  public async showNegotiationBySlot(slots: Slot) {
    this.showNegotiations(false, slots.slotId);
  }

  public async showAllNegotiations() {
    this.showNegotiations(false);
  }

  public async showMyNegotiations() {
    this.showNegotiations(true);
  }

  public async onRefreshGrid(dataRequest: PaginatedNegotiationsDataRequest) {
    if (this.processRefreshEvent) {
      this.restartDataRefreshInterval();
      this.refreshNegotiations(dataRequest);
    } else {
      this.processRefreshEvent = true;
    }
  }

  public hardRefreshNegotiations() {
    this.refreshNegotiations(this.currentRequest, false);
  }

  /**
   * Opens a Dialog to Start a negotiation flow.
   */
  public onStartNegotiation(negotiation: Negotiation) {
    this.uiService.toggleGlobalSpinner(true);
    this.checkNegotiationExecutionCost(negotiation).subscribe(
      (value) => {
        this.uiService.toggleGlobalSpinner(false);
        this.dialog
          .open(ConfirmDialogComponent, {
            data: { message: `Do you want to start the selected Negotiation?\n It will cost up to $` + value },
          })
          .afterClosed()
          .subscribe((confirm: boolean) => {
            if (confirm) {
              this.uiService.toggleGlobalSpinner(true);
              if (this.creditService.checkCreditWithAlert()) {
                this.negotiationService.startNegotiation(negotiation.negotiationId, negotiation.flowId).subscribe(
                  (response) => {
                    this.uiService.toggleGlobalSpinner(false);
                    this.snackBar.open('Negotiation started successfully.', null, {
                      horizontalPosition: 'center',
                      verticalPosition: 'bottom',
                      duration: 3000,
                    });
                    this.refreshNegotiations(this.currentRequest);
                    this.creditService.updateCredits();
                  },
                  (errorResponse: HttpErrorResponse) => {
                    this.uiService.toggleGlobalSpinner(false);
                    console.error(errorResponse.error);
                    this.showMessage('There was an error while trying to start the Negotiation.');
                  }
                );
              }
            }
          });
      },
      (errorResponse: HttpErrorResponse) => {
        this.uiService.toggleGlobalSpinner(false);
        this.showMessage('Error: There is something wrong with the flow associated to this Negotiation.');
      }
    );
  }

  /**
   * Opens a dialog to stop a negotiation flow.
   */
  public onCancelNegotiation(negotiation: Negotiation) {
    this.dialog.open(
      FlowStoperComponent,
      this.getDialogConfig({ negotiation, onRefresh: this.hardRefreshNegotiations.bind(this) })
    );
  }

  /**
   * Opens a Dialog to resume a negotiation flow.
   */
  public onResumeNegotiation(negotiation: Negotiation) {
    this.dialog.open(
      FlowResumerComponent,
      this.getDialogConfig({ negotiation, onRefresh: this.hardRefreshNegotiations.bind(this) })
    );
  }

  /**
   * Opens a dialog to pause a negotiation flow.
   */
  public onPauseNegotiation(negotiation: Negotiation) {
    this.dialog.open(
      FlowPauserComponent,
      this.getDialogConfig({ negotiation, onRefresh: this.hardRefreshNegotiations.bind(this) })
    );
  }

  /**
   * Opens a dialog to restart a negotiation flow.
   */
  public onRestartNegotiation(negotiation: Negotiation) {
    this.uiService.toggleGlobalSpinner(true);
    this.checkNegotiationExecutionCost(negotiation).subscribe((value) => {
      this.uiService.toggleGlobalSpinner(false);
      negotiation.amount = value;
      this.dialog.open(
        FlowRestarterComponent,
        this.getDialogConfig({ negotiation, onRefresh: this.hardRefreshNegotiations.bind(this) })
      );
    });
  }

  public onAddNegotiationToSlot(negotiation: Negotiation) {
    this.dialog.open(
      AddNegotiationToSlotComponent,
      this.getDialogConfig({ negotiation, onRefresh: () => this.hardRefreshNegotiations.bind(this) })
    );
    this.slotsService.getData();
  }

  public onDeleteNegotiation(negotiation: Negotiation) {
    this.slotsService.getData();
  }

  public showStartSlot(slot: Slot): boolean {
    return slot.status === SlotStatus.NOT_STARTED;
  }

  public showRestartSlot(slot: Slot): boolean {
    return slot.started;
  }

  public showStopSlot(slot: Slot): boolean {
    const stoppableStatuses = [SlotStatus.STARTED, SlotStatus.PENDING];
    return stoppableStatuses.includes(slot.status);
  }

  public showAddToSlot(negotiation: Negotiation): boolean {
    return !negotiation.slotId;
  }

  public onStartSlot(slot: Slot) {
    this.uiService.toggleGlobalSpinner(true);
    this.checkSlotExecutionCost(slot).subscribe(
      (value) => {
        this.uiService.toggleGlobalSpinner(false);
        this.dialog
          .open(StartSlotComponent, {
            data: { message: `Do you want to run the selected Channel? \nIt will cost up to $` + value },
          })
          .afterClosed()
          .subscribe((confirmation: Array<NegotiationStatus>) => {
            if (confirmation && this.creditService.checkCreditWithAlert()) {
              this.uiService.toggleGlobalSpinner(true);
              this.slotsService.startSlot(slot.slotId, confirmation).subscribe(
                (response) => {
                  this.creditService.updateCredits();
                  this.slotsService.getData();
                  this.refreshNegotiations(this.currentRequest);
                  this.uiService.toggleGlobalSpinner(false);
                  this.showMessage(`Channel started successfully.`);
                },
                (errorResponse) => {
                  this.uiService.toggleGlobalSpinner(false);
                  this.showMessage(`Error: ${errorResponse.error}`);
                }
              );
            }
          });
      },
      (errorResponse) => {
        this.uiService.toggleGlobalSpinner(false);
        this.showMessage(`Error: There is something wrong with the flow associated to this channel`);
      }
    );
  }

  public onRestartSlot(slot: Slot) {
    this.onStartSlot(slot);
  }

  public onDeleteSlot(slot: Slot) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Channel?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.uiService.toggleGlobalSpinner(true);
          this.slotsService.deleteSlot(slot.slotId).subscribe(
            () => {
              this.slotsService.getData();
              this.refreshNegotiations(this.currentRequest);
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`Channel deleted successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorResponse) => {
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`Error: ${errorResponse.error}`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  public onStopSlot(slot: Slot) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to stop the execution of the selected Channel?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.uiService.toggleGlobalSpinner(true);
          this.slotsService.stopSlot(slot.slotId).subscribe(
            () => {
              this.slotsService.getData();
              this.refreshNegotiations(this.currentRequest);
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`Channel execution stopped successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorResponse) => {
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`Error: ${errorResponse.error}`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  onUpload() {
    this.dialog.open(UploadNegotiationsFileComponent, {
      panelClass: 'custom-dialog-container',
      width: '800px',
    });
  }

  private showMessage(message: string) {
    this.snackBar.open(message, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 7000,
    });
  }

  private onSlotReload(values: Slot[]) {
    this.slots = values;
    if (values !== null && values !== undefined) {
      let totalNegotiations = 0;
      const elements = values.map((slot) => {
        let actions = [];
        if (slot.slotId !== -1) {
          // TODO: Enable once schedules are working properly
          if (this.companyId === 2) {
            actions.push({
              icon: slot.hasSchedule ? 'alarm_on' : 'schedule_send',
              key: 'has_schedule',
              name: slot.hasSchedule ? 'Scheduled' : 'Schedule',
              action: this.onSlotsScheduled.bind(this, slot),
            });
          }
          if (this.showStartSlot(slot)) {
            actions.push({
              icon: 'play_circle',
              key: 'start_slot',
              name: 'Run Channel',
              action: this.onStartSlot.bind(this, slot),
            });
          }
          if (this.showRestartSlot(slot)) {
            actions.push({
              icon: 'play_circle',
              key: 'restart_slot',
              name: 'Run Channel',
              action: this.onRestartSlot.bind(this, slot),
            });
          }
          if (this.showStopSlot(slot)) {
            actions.push({
              icon: 'stop_cricle',
              key: 'stop',
              name: 'Stop',
              action: this.onStopSlot.bind(this, slot),
            });
          }
          actions = actions.concat([
            {
              icon: 'edit',
              name: 'Edit',
              key: 'edit',
              action: this.onEditSlot.bind(this, slot),
            },
            {
              icon: 'delete_outline',
              name: 'Delete',
              key: 'edit',
              action: this.onDeleteSlot.bind(this, slot),
            },
          ]);
        }
        totalNegotiations += slot.negotiationCount ? slot.negotiationCount : 0;
        return {
          isDataInjestor: slot.isDataIngestor,
          key: slot.slotId,
          title: slot.name,
          titleAction: this.showNegotiationBySlot.bind(this, slot),
          actions,
          count: slot.negotiationCount,
        };
      });
      elements.unshift({
        isDataInjestor: false,
        key: null,
        title: 'All Channels',
        titleAction: this.showAllNegotiations.bind(this),
        actions: [],
        count: totalNegotiations,
      });
      this.slotElements = elements;
      this.lateralListSection.elements = elements;
      this.slotControl.setValue(this.slotControl.value);
    }
  }

  private showNegotiations(mineOnly: boolean, slotId: number = null) {
    this.negotiationService.setSlot(slotId);
    this.negotiationService.setOwnerFiltered(mineOnly);
    this.refreshNegotiations();
    this.resetGrid.next(true);
    this.restartDataRefreshInterval();
  }

  private restartDataRefreshInterval() {
    this.clearDataRefreshInterval();
    this.refreshNegotiationsTimerId = setInterval(() => {
      this.refreshNegotiations(this.currentRequest, false);
    }, this.UPDATE_TIME);
  }

  private refreshNegotiations(
    dataRequest: PaginatedNegotiationsDataRequest = {},
    acceptCachedResponse: boolean = false
  ) {
    // apply default values
    dataRequest.pageSize ??= this.currentRequest?.pageSize ?? FilterPaginatorComponent.DEFAULT_PAGE_SIZE;
    dataRequest.pageNumber ??= 1;
    dataRequest.slot ??= this.negotiationService.getSlot() ?? 0;
    dataRequest.mineOnly ??= this.negotiationService.getOwnerFiltered() ?? false;
    dataRequest.sortedBy ??= this.currentRequest?.sortedBy;
    dataRequest.sortDirection ??= this.currentRequest?.sortDirection;
    this.currentRequest = dataRequest;
    this.negotiationService.getPaginatedData(dataRequest, acceptCachedResponse);
  }

  private checkSlotExecutionCost(slot: Slot): Observable<number> {
    return this.slotsService.calculateExecutionCost({ slotId: slot.slotId });
  }

  private checkNegotiationExecutionCost(negotiation: Negotiation): Observable<number> {
    return this.slotsService.calculateExecutionCost({ negotiationId: negotiation.negotiationId });
  }

  /**
   * Returns formatting settings shared across different dialog components.
   */
  private getDialogConfig(data: { negotiation: Negotiation; onRefresh: any }) {
    return {
      panelClass: 'custom-dialog-container',
      width: '40vw',
      maxWidth: '800px',
      minWidth: '400px',
      data,
    };
  }

  private clearDataRefreshInterval() {
    if (this.refreshNegotiationsTimerId != null) {
      clearInterval(this.refreshNegotiationsTimerId);
    }
  }
}
