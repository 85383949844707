<div class="title">Add Negotiation to Channel</div>

<div>
    <div class="flow-container">
        <div class="dropdown-container">
            <span class="dropdown-title">Choose a Channel to wich you'd like to assign this Negotiation</span>
            <span class="dropdown-title" style="color: red" *ngIf="!availableSlots || !availableSlots.length">
                There are not any channels that match this Negotiation's Flow.
            </span>

            <mat-form-field appearance="fill" class="dropdown-itself">
                <mat-label>Channel</mat-label>
                <mat-select [(value)]="selectedSlot" [disabled]="!availableSlots || !availableSlots.length">
                    <mat-option *ngFor="let slot of availableSlots" [value]="slot"> {{slot.name}} </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="add-negotiation-buttons">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!selectedSlot || processing"
                (click)="onSubmit()">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</div>