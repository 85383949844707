<div class="table-container" *ngIf="metrics?.length > 0">
  <div class="table-title">Analytics</div>
  <div class="flex">
    <mat-card *ngFor="let metric of metrics" style="text-align: center;">
      <mat-card-title style="font-size: 1rem;">{{ metric.metric }}</mat-card-title>
      <mat-card-subtitle style="font-weight: bold">{{ metric.value }} {{ metric.unit }}</mat-card-subtitle>
      <button mat-button class="sgnd-btn sgnd-btn-ok" (click)="onClear()">Clear</button>
    </mat-card>
  </div>
</div>
