<div class="title">Call Preview</div>
<div class="call-preview-container">
    <div class="text-container">
        <span>Please provide a phone number so that you receive a phone call as a preview of the message configured for the current language.</span>
        <span class="warning-text">The cost of the call will be discounted from your account.</span>
    </div>
    <div class="call-preview-buttons">
        <button class="sgnd-btn sgnd-btn-ok" (click)="onContinue()">Run Call Preview</button>
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
</div>
