import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { BaseDocument } from 'app/model/documents/base-document';

@Injectable()
export class DocumentsService {
  /**
   * public description: string;
  public externalId: string;
  public debtor: string;
  public amount: number;
  public date: Date;
  public dueDate: Date;
   */
  public documentsSubject = new BehaviorSubject<BaseDocument[]>([]);

  public setDocuments(documents: BaseDocument[]) {
    this.documentsSubject.next(documents);
  }

  public initDefault() {
    this.setDocuments([]);
  }

  public getDocuments(): BaseDocument[] {
    return this.documentsSubject.value;
  }

  public deleteDocument(document: BaseDocument): void {
    const newDocs = this.getDocuments().filter((element) => !isEqual(element, document));
    this.setDocuments(newDocs);
  }
}
