<div class="paginator-container">
    <div for="rows">Rows per page:</div>
    <div >&nbsp;10 &nbsp;</div>
    <div>1-10 of 25</div>
    <div class="paginator-buttons">
        <div><i class="material-icons">skip_previous</i></div>
        <div><i class="material-icons">keyboard_arrow_left</i></div>
        <div><i class="material-icons">keyboard_arrow_right</i></div>
        <div><i class="material-icons">skip_next</i></div>
    </div>
</div>