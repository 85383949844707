import { Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from 'app/model/flow-action-call';
import { FlowActionEmail } from 'app/model/flow-action-email';
import { NegotiationType } from 'app/model/negotiation-type';
import { ACCOUNTS_RECEIVABLE_VARIABLES, NegotiationVariable, OPTIONS_VARIABLES } from 'app/model/studio-negotiation-variables';
import { FlowsService } from 'app/services/flows.service';
import { LanguageService } from 'app/services/language.service';
import { HtmlEditorComponent } from 'core/components/html-editor/html-editor.component';
import { UIService } from 'core/services/ui.service';
import { InsertableTextAreaComponent } from '../InsertableTextArea';

@Component({
  selector: 'app-email-details',
  templateUrl: './email-details.component.html',
  styleUrls: ['./email-details.component.css'],
})
export class EmailDetailsComponent implements OnChanges, OnInit {
  @Input() node: FlowActionEmail;
  @ViewChild('subjectField') subjectField: InsertableTextAreaComponent;
  @ViewChild('bodyField') bodyField: HtmlEditorComponent;

  public description = '';
  public languages: string[];
  public language: string;
  public subjectMessage: ActionMessage;
  public bodyMessage: ActionMessage;
  public customVars: NegotiationVariable[] = [{ name: 'CCP Link QR code', value: 'QrCcpLink' }];

  private readonly SUBJECT_INDEX: number = 0;
  private readonly BODY_INDEX: number = 1;
  private flowActionMessages: ActionMessage[] = [];

  constructor(
    private uiService: UIService,
    private flowService: FlowsService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar
  ) {
  }

  ngOnInit(): void {
    this.loadFlowVariables();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  onLanguageSelect() {
    this.updateMessages();
  }

  insertText(text: string) {
    // identify which text field has the focus
    if (this.subjectField.selectedElement != null) {
      this.subjectField.insertText(text);
    } else if (this.bodyField.hasFocus()) {
      this.bodyField.insertText(text);
    }
  }

  saveMessages() {
    // update the flow action
    this.uiService.toggleGlobalSpinner(true);
    const promises: Array<Promise<any>> = [];
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    const saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        throw err;
      });
    promises.push(saveActionPromise);

    // update the flow action messages
    const messages = [];
    this.flowActionMessages.forEach((msg) =>
      messages.push({
        flowActionId: this.node.flowActionId,
        language: msg.language,
        content: msg.content,
        flowActionMessageId: msg.flowActionMessageId,
        index: msg.index,
      })
    );
    const saveMessagesPromise = this.flowService
      .saveMessages(
        messages as [
          { flowActionId: number; language: string; content: string; flowActionMessageId: string; index: number }
        ]
      )
      .then(() => {
        this.node.flowActionMessages.forEach((msg) => {
          const newMessage = messages.find((nmsg) => nmsg.flowActionMessageId === msg.flowActionMessageId);
          msg.content = newMessage.content;
        });
      })
      .catch((err) => {
        this.flowActionMessages.forEach((msg) => {
          const realMessage = this.node.flowActionMessages.find(
            (rmsg) => rmsg.flowActionMessageId === msg.flowActionMessageId
          );
          msg.content = realMessage.content;
        });
        throw err;
      });
    promises.push(saveMessagesPromise);
    Promise.all(promises)
      .then(() => {})
      .catch((err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'snack-error',
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  private async init() {
    await this.loadLanguages();
    this.node.flowActionMessages ??= [];
    this.flowActionMessages = this.node.flowActionMessages.map((msg) => ActionMessage.cloneMsg(msg));
    if (this.flowActionMessages.length > 0) {
      this.language = this.flowActionMessages[0].language;
    }
    this.description = this.node.description;
    this.updateMessages();
  }

  private updateMessages() {
    this.subjectMessage = this.getOrCreateMessage(this.SUBJECT_INDEX);
    this.bodyMessage = this.getOrCreateMessage(this.BODY_INDEX);
  }

  private loadFlowVariables() {
    if (this.node.flowType === NegotiationType.OPTIONS) {
      this.customVars.push(...OPTIONS_VARIABLES);
    }
    else if (this.node.flowType === NegotiationType.ACCOUNTS_RECEIVABLE) {
      this.customVars.push(...ACCOUNTS_RECEIVABLE_VARIABLES);
    }
  }

  private getOrCreateMessage(index: number): ActionMessage {
    let message = this.flowActionMessages.find((x) => x.language === this.language && x.index === index);
    if (message == null) {
      message = new ActionMessage(this.language, '', '');
      message.index = index;
      this.flowActionMessages.push(message);
    }
    return message;
  }

  private async loadLanguages() {
    this.languages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.language = this.languages[0];
  }
}
