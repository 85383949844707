import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation } from 'app/model/negotiation';
import { NegotiationsService } from 'app/services/negotiations.service';

@Component({
  selector: 'app-flow-pauser',
  templateUrl: './flow-pauser.component.html',
  styleUrls: ['./flow-pauser.component.css'],
})
export class FlowPauserComponent implements OnInit {
  public processing = false;

  constructor(
    private snackBar: MatSnackBar,
    private negotiationService: NegotiationsService,
    @Inject(MAT_DIALOG_DATA) public data: { negotiation: Negotiation; onRefresh: any },
    public dialogRef: MatDialogRef<FlowPauserComponent>
  ) {}

  ngOnInit(): void {}

  onSubmit() {
    this.processing = true;
    this.negotiationService.pauseNegotiation(this.data.negotiation?.negotiationId).subscribe((response) => {
      this.snackBar.open('Negotiation Paused successfully.', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      this.data.onRefresh?.();
      this.processing = false;
      this.dialogRef.close();
      this.negotiationService.getData();
    });
  }

  onCancel() {
    this.dialogRef.close();
  }
}
