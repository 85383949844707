import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { NegotiationOption } from 'app/model/negotiation-option';
import { NegotiationOptionsService } from 'app/services/negotiation-options.service';
import { AddOptionComponent } from '../add-option/add-option.component';

@Component({
  selector: 'app-options-grid',
  templateUrl: './options-grid.component.html',
  styleUrls: ['./options-grid.component.css'],
})
export class OptionsGridComponent implements OnInit, OnDestroy {
  public negotiationOptions: Array<NegotiationOption> = [];

  dataSource;
  displayedColumns = ['title', 'description', 'actions'];

  constructor(
    public negotiationOptionsService: NegotiationOptionsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.negotiationOptionsService.negotiationOptionsSubject.subscribe((values: NegotiationOption[]) => {
      this.negotiationOptions = values;
      this.dataSource = new MatTableDataSource<NegotiationOption>(this.negotiationOptions);
    });
  }

  public onAdd() {
    this.dialog.open(AddOptionComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: null,
    });
  }

  public onEdit(negotiation) {
    this.dialog.open(AddOptionComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: negotiation,
    });
  }

  public onDelete(negotiationOption: NegotiationOption) {
    this.negotiationOptionsService.deleteNegotiationOptions(negotiationOption);
    this.snackBar.open(`Negotiation Option deleted successfully.`, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  ngOnDestroy(): void {}
}
