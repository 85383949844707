<div class="table-container">
    <div class="table-title-section">
        <label class="table-title">Company API Credentials</label>
        <button mat-button (click)="onNewClient()" title="New">
            <mat-icon>add_to_photos</mat-icon>
        </button>
    </div>
    <table mat-table [dataSource]="contentDataSource" class="table" fixedLayout>
        <ng-container matColumnDef="companyId">
            <th mat-header-cell *matHeaderCellDef class="th-company-id">Company Id</th>
            <td mat-cell *matCellDef="let element" class="td-company-id">{{element.companyId}}</td>
        </ng-container>
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef class="th-company-name">Company Name</th>
            <td mat-cell *matCellDef="let element" class="td-company-name">{{element.company.name}}</td>
        </ng-container>
        <ng-container matColumnDef="apiClientId">
            <th mat-header-cell *matHeaderCellDef>Client Id</th>
            <td mat-cell *matCellDef="let element" class="td-api-client-id">
                {{ element.parameterValue }}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="th-actions">Actions</th>
            <td mat-cell *matCellDef="let element" class="td-actions">
                <button mat-button (click)="onDeleteClient(element)" type="button" class="button-delete">
                    <mat-icon>delete</mat-icon>
                </button>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedContentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedContentColumns"></tr>
    </table>
</div>