import { Component, OnInit } from '@angular/core';
import { ContactStatus } from 'app/model/contact-status';
import { NegotiationDto } from 'app/model/negotiation';
import { NegotiationOption } from 'app/model/negotiation-option';
import { IntermediateService } from 'app/services/intermediate.service';

@Component({
  selector: 'app-negotiation-intermediate',
  templateUrl: './negotiation-intermediate.component.html',
  styleUrls: ['./negotiation-intermediate.component.css'],
})
export class NegotiationIntermediateComponent implements OnInit {
  slotOptions: NegotiationOption[];
  negotiationOptions: NegotiationOption[];
  contactStatuses = ContactStatus;
  negotiation: NegotiationDto;

  constructor(private intermediateService: IntermediateService) {}

  ngOnInit(): void {
    this.intermediateService.$negotiationOptions.subscribe((options) => {
      this.negotiationOptions = options;
    });
    this.intermediateService.$slotOptions.subscribe((options) => {
      this.slotOptions = options;
    });
    this.intermediateService.$negotiationItself.subscribe(neg => {
      this.negotiation = neg;
    });
  }

  selectAction(option: NegotiationOption) {
    this.intermediateService.flowConfiguration.subscribe((res) => {
      this.intermediateService.selectOption(option, res.redirectUrl);
    });
  }

  slotSelectAction(option: NegotiationOption) {
    this.intermediateService.flowConfiguration.subscribe((res) => {
      this.intermediateService.selectOption(option, res.redirectUrl, true);
    });
  }

  showTxtMsg() {
    return this.negotiation && this.negotiation.messageText &&
      this.negotiation.contactId !== this.contactStatuses.BIDDEN;
  }

  negotiationWasBidden() {
    return this.negotiation && this.negotiation.contactStatus === this.contactStatuses.BIDDEN;
  }
}
