import { MatDialog } from '@angular/material/dialog';
import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FlowAction } from 'app/model/flow-action';
import { ActionMessage, FlowActionCall } from 'app/model/flow-action-call';
import { FlowsService } from 'app/services/flows.service';
import { InsertableTextAreaComponent } from '../InsertableTextArea';
import { CallPreviewComponent } from './call-preview/call-preview.component';
import { TextToSpeechService } from 'app/services/text-to-speech.service';
import { UIService } from 'core/services/ui.service';
import { LanguageService } from 'app/services/language.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NegotiationType } from 'app/model/negotiation-type';
import {
  ACCOUNTS_RECEIVABLE_VARIABLES,
  NegotiationVariable,
  OPTIONS_VARIABLES,
} from 'app/model/studio-negotiation-variables';

@Component({
  selector: 'app-call-details',
  templateUrl: './call-details.component.html',
  styleUrls: ['./call-details.component.css'],
})
export class CallDetailsComponent implements OnChanges {
  @ViewChild('voiceField') voiceField: InsertableTextAreaComponent;
  @ViewChild('textField') textField: InsertableTextAreaComponent;
  @Input() node: FlowActionCall;
  @Output() nodeChange = new EventEmitter<FlowAction>();

  languages: string[];
  language: string;
  voices = [];
  voice = null;
  selectedMessage: ActionMessage = null;
  flowActionMessages: ActionMessage[] = [];
  customVars: NegotiationVariable[] = [];

  description: string;
  parametersId: string | number | null = null;
  retry = false;
  retryAttempts: number | string = '1';
  retryOnAbsent = false;
  retryOnFailed = false;
  retryFrequency: number | string = '0.5';
  all = false;

  constructor(
    private dialog: MatDialog,
    private uiService: UIService,
    private flowService: FlowsService,
    private ttsService: TextToSpeechService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * In cases the flow action is the same type the component won't be destroyed, so it won't trigger all the changes,
   * checking this we meake sure the changes will be triggered when initializing the element and when the flowActionId changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  async init() {
    await this.loadLanguages();
    await this.loadVoices();
    this.flowActionMessages = [];
    let didSetDefault = false;
    this.node.flowActionMessages.forEach(async (msg) => {
      const clonedMessage = ActionMessage.cloneMsg(msg);
      this.flowActionMessages.push(clonedMessage);
      if (!didSetDefault && clonedMessage && clonedMessage.content.length > 0) {
        this.language = clonedMessage.language;
        this.voice = clonedMessage.extraContent;
        this.selectedMessage = clonedMessage;
        didSetDefault = true;
        await this.loadVoices();
      }
    });
    if (!didSetDefault && this.flowActionMessages?.length > 0) {
      this.language = this.flowActionMessages[0].language;
      this.voice = this.flowActionMessages[0].extraContent;
    }
    this.description = this.node.description;
    if (!this.flowActionMessages || this.flowActionMessages?.length === 0) {
      this.flowActionMessages = [new ActionMessage(this.language, '', this.voice)];
    }
    if (!didSetDefault) {
      this.selectedMessage = this.flowActionMessages[0];
    }

    this.loadEvents();
    this.loadFlowVariables();
  }

  onCallPreview() {
    this.dialog.open(CallPreviewComponent, {
      panelClass: 'custom-dialog-container',
      width: '35vw',
      maxWidth: '900px',
      minWidth: '250px',
      data: this.selectedMessage,
    });
  }

  async onLanguageSelect() {
    await this.loadVoices();
    const index = this.flowActionMessages.findIndex((msg) => msg.language === this.language);
    let message = null;
    if (index === -1) {
      message = new ActionMessage(this.language, '', this.voice);
      this.flowActionMessages.push(message);
    } else {
      message = this.flowActionMessages[index];
      this.voice = message.extraContent;
    }
    this.selectedMessage = message;
  }

  onVoiceSelect() {
    this.selectedMessage.extraContent = this.voice;
  }

  insertText(text) {
    this.textField.insertText(text);
  }

  clickCheckbox(event) {
    event.stopPropagation();
  }

  onCheckboxSelect(event) {
    if (event.source.name === 'all') {
      this.retryOnAbsent = event.source.name === 'all' && this.all;
      this.retryOnFailed = event.source.name === 'all' && this.all;
    }
  }

  loadEvents() {
    this.all = false;
    if (this.node.flowActionVoiceCallParameter != null) {
      const { id, retry, retryAttempts, retryFrequency, retryOnAbsent, retryOnFailed } = FlowActionCall.getProperties(
        this.node.flowActionVoiceCallParameter
      );
      this.parametersId = id;
      this.retry = retry;
      this.retryAttempts = `${retryAttempts}`;
      this.retryFrequency = `${retryFrequency}`;
      this.retryOnAbsent = retryOnAbsent;
      this.retryOnFailed = retryOnFailed;
    } else {
      this.parametersId = null;
      this.retry = false;
      this.retryAttempts = '1';
      this.retryOnAbsent = false;
      this.retryOnFailed = false;
      this.retryFrequency = '0.5';
    }
    if (this.retryOnAbsent && this.retryOnFailed) {
      this.all = true;
    }
  }

  saveMessages() {
    /**
     * Save the messages for all the translations, if any fails then show all the original messages,
     * does the same with parameters, but this does not need to check anything, just if the call was correct
     * TODO: Check when just some fail and rollback only those. Make validations, debouncing and loading indicator.
     */
    this.uiService.toggleGlobalSpinner(true);
    const promises: Array<Promise<any>> = [];
    const messages = [];
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    const saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        throw err;
      });
    promises.push(saveActionPromise);

    this.flowActionMessages.forEach((msg) =>
      messages.push({
        flowActionId: this.node.flowActionId,
        language: msg.language,
        content: msg.content,
        extraContent: msg.extraContent,
        flowActionMessageId: msg.flowActionMessageId,
      })
    );
    const saveMessagesPromise = this.flowService
      .saveMessages(
        messages as [
          { flowActionId: number; language: string; content: string; flowActionMessageId: string; extraContent: string }
        ]
      )
      .then(() => {
        this.node.flowActionMessages.forEach((msg) => {
          const newMessage = messages.find((nmsg) => nmsg.flowActionMessageId === msg.flowActionMessageId);
          msg.content = newMessage.content;
          msg.extraContent = newMessage.extraContent;
        });
      })
      .catch(() => {
        this.flowActionMessages.forEach((msg) => {
          const realMessage = this.node.flowActionMessages.find(
            (rmsg) => rmsg.flowActionMessageId === msg.flowActionMessageId
          );
          msg.content = realMessage.content;
        });
      });
    promises.push(saveMessagesPromise);

    const callProps = {
      retry: this.retry,
      retryAttempts: this.retryAttempts,
      retryOnAbsent: this.retryOnAbsent,
      retryOnFailed: this.retryOnFailed,
      retryFrequency: this.retryFrequency,
    };
    /**
     * If there is a parameters id means that it was previously created. If not create them.
     */
    let savePropertiesPromise;
    if (this.parametersId) {
      // eslint-disable-next-line
      callProps['id'] = this.parametersId;
      savePropertiesPromise = this.flowService
        .saveCallProperties(callProps)
        .toPromise()
        .then(() => {
          this.node.flowActionVoiceCallParameter.retry = this.retry;
          this.node.flowActionVoiceCallParameter.retryAttempts = this.retryAttempts;
          this.node.flowActionVoiceCallParameter.retryOnAbsent = this.retryOnAbsent;
          this.node.flowActionVoiceCallParameter.retryOnFailed = this.retryOnFailed;
          this.node.flowActionVoiceCallParameter.retryFrequency = this.retryFrequency;
        })
        .catch((err) => {
          this.retry = this.node.flowActionVoiceCallParameter.retry;
          this.retryAttempts = this.node.flowActionVoiceCallParameter.retryAttempts;
          this.retryOnAbsent = this.node.flowActionVoiceCallParameter.retryOnAbsent;
          this.retryOnFailed = this.node.flowActionVoiceCallParameter.retryOnFailed;
          this.retryFrequency = this.node.flowActionVoiceCallParameter.retryFrequency;
          throw err;
        });
    } else {
      // eslint-disable-next-line
      callProps['flowActionId'] = this.node.flowActionId;
      savePropertiesPromise = this.flowService
        .postCallProperties(callProps)
        .toPromise()
        .then((newId: string | number) => {
          this.parametersId = newId;
          this.node.flowActionVoiceCallParameter.id = newId;
          this.node.flowActionVoiceCallParameter.retry = this.retry;
          this.node.flowActionVoiceCallParameter.retryAttempts = this.retryAttempts;
          this.node.flowActionVoiceCallParameter.retryOnAbsent = this.retryOnAbsent;
          this.node.flowActionVoiceCallParameter.retryOnFailed = this.retryOnFailed;
          this.node.flowActionVoiceCallParameter.retryFrequency = this.retryFrequency;
        })
        .catch((err) => {
          this.retry = this.node.flowActionVoiceCallParameter.retry;
          this.retryAttempts = this.node.flowActionVoiceCallParameter.retryAttempts;
          this.retryOnAbsent = this.node.flowActionVoiceCallParameter.retryOnAbsent;
          this.retryOnFailed = this.node.flowActionVoiceCallParameter.retryOnFailed;
          this.retryFrequency = this.node.flowActionVoiceCallParameter.retryFrequency;
          throw err;
        });
    }
    promises.push(savePropertiesPromise);
    Promise.all(promises)
      .then(() => {})
      .catch((err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'snack-error',
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  private loadFlowVariables() {
    if (this.node.flowType === NegotiationType.OPTIONS) {
      this.customVars.push(...OPTIONS_VARIABLES);
    } else if (this.node.flowType === NegotiationType.ACCOUNTS_RECEIVABLE) {
      this.customVars.push(...ACCOUNTS_RECEIVABLE_VARIABLES);
    }
  }

  private async loadVoices() {
    this.voices = await this.ttsService.getAvailableVoices(this.language);
    if (this.selectedMessage?.extraContent) {
      this.voice = this.selectedMessage.extraContent;
    } else {
      this.voice = this.voices[0];
    }
  }

  private async loadLanguages() {
    this.languages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.language = this.languages[0];
  }
}
