import { Component, ElementRef, Inject, OnChanges, OnDestroy, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation } from 'app/model/negotiation';
import { TwilioWhatsappStatus, TWILIO_WHATSAPP_MAPPING } from 'app/model/TwilioWhatsappStatus';
import { WhatsappMessageService } from 'app/services/whatsapp-message.service';

/* eslint-disable */
@Component({
  selector: 'app-whatsapp-messages-modal',
  templateUrl: './whatsapp-messages-modal.component.html',
  styleUrls: ['./whatsapp-messages-modal.component.css'],
})
export class WhatsappMessagesModalComponent implements OnInit, OnDestroy {
  @ViewChild('messagesRef') messagesRefContent: ElementRef;
  public showModal = false;
  public messages = [];
  public contactName: string | null = '';
  public contactId: number;
  public contactPhone: string | null = '';
  public messageText = '';
  public primaryColor: string;
  public secondaryColor: string;
  public whatsappStatusMapping = TWILIO_WHATSAPP_MAPPING;
  public whatsappStatus = TwilioWhatsappStatus;
  private readonly UPDATE_MESSAGES_INTERVAL_TIME = 30 * 1000;
  private updateMessagesInterval: NodeJS.Timer;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialogRef<WhatsappMessagesModalComponent>,
    @Inject(MAT_DIALOG_DATA) public negotiation: Negotiation,
    private whatsappMessageService: WhatsappMessageService
  ) {
    this.contactId = this.negotiation.contactId;
    this.contactName = this.negotiation.contactName;
    this.contactPhone = this.negotiation.contactPhone;
  }

  ngOnInit(): void {
    this.updateMessages();
    this.updateMessagesInterval = setInterval(() => this.updateMessages(), this.UPDATE_MESSAGES_INTERVAL_TIME);
    this.whatsappMessageService.markMessagesAsRead(this.contactId).subscribe(() => {});
  }

  ngOnDestroy(): void {
    if (this.updateMessagesInterval) {
      clearInterval(this.updateMessagesInterval);
    }
  }

  public close() {
    this.whatsappMessageService.markMessagesAsRead(this.contactId).subscribe(() => {});
    this.dialog.close();
  }

  updateMessages() {
    this.whatsappMessageService.getMessages(this.negotiation?.contactId).subscribe((contactMessages) => {
      const oldMessages = this.messages;
      this.messages = contactMessages;
      if (oldMessages?.length !== contactMessages?.length) {
        setTimeout(() => this.scrollToBottom(), 1);
      }
    });
  }

  sendMessage() {
    const now = new Date().toISOString();
    if (!this.messageText || this.messageText.trim().length === 0) {
      return;
    }
    this.whatsappMessageService.postMessage(this.negotiation?.contactId, this.messageText).subscribe(
      (response) => {
        this.messageText = '';
        this.updateMessages();
      },
      (err) => {
        console.error(err);
        this.snackBar.open('The message could not be delivered due to an error.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  scrollToBottom() {
    try {
      this.messagesRefContent.nativeElement.scrollTop = this.messagesRefContent.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
