export class NegotiationOption {
    id: string;
    title: string;
    description: string;

    constructor(negotiationOption: NegotiationOption) {
        this.id = negotiationOption?.id;
        this.title = negotiationOption?.title;
        this.description = negotiationOption?.description;
    }
}
