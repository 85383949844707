<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Description</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="full-width-textarea">
    <input matInput [(ngModel)]="description" />
  </mat-form-field>
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      Signature
    </mat-panel-title>
  </mat-expansion-panel-header>
  <span class="signature-section">
    <mat-checkbox labelPosition="before" [(ngModel)]="useSignature">Use signature
    </mat-checkbox>
    <mat-checkbox labelPosition="before" [(ngModel)]="useNonSignedEmail">Use non
      signed email
    </mat-checkbox>
  </span>
</mat-expansion-panel>

<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Text</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-checkbox [disabled]="node.flowActionSignatureParameter.attachmentId != null" [(ngModel)]="usePdfDocument">Use PDF document
  </mat-checkbox>
  <div class="document-section" *ngIf="node.flowActionSignatureParameter.usePdfDocument != true">
    <mat-form-field>
      <mat-form-label>Language</mat-form-label>
      <mat-select [(value)]="language" (selectionChange)="onLanguageSelect()">
        <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
      </mat-select>
    </mat-form-field>
    <app-html-editor #textField [(value)]="currentMessage.content" *ngIf="currentMessage"></app-html-editor>
  </div>
  <div class="document-section" *ngIf="usePdfDocument == true">
    <div class="upload-document-button-container">
      <input #fileInput type="file" (change)="handleFileChange($event)" hidden accept="application/pdf" />
      <input class="upload-file-name-text-input" matInput type="text" [(ngModel)]="attachmentName" value="{name}"
        placeholder="Select a document..." readonly>
      <button type="button" class="browse-file-btn" mat-stroked-button (click)="fileInput.click()">Browse</button>
    </div>
  </div>
</mat-expansion-panel>
<app-negotiation-variables  [customVars]="customVars" (selectOption)="insertText($event)"></app-negotiation-variables>
<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="save()">Save</button>
</div>