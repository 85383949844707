import { Injectable } from '@angular/core';
import { ContentTemplateApiService } from './content-template-api.service';
import { WhatsAppContentTemplate } from 'app/model/content-template';
import { BehaviorSubject, Observable } from 'rxjs';
import { UIService } from 'core/services/ui.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContentTemplateType, TwilioContentTemplateTypes } from 'app/components/deal/studio/content-template';

@Injectable({
  providedIn: 'root',
})
export class ContentTemplateService {
    public availableTemplates = new BehaviorSubject<WhatsAppContentTemplate[]>([]);
    public typesList: ContentTemplateType[] = [
        {name: 'Text', description: 'A simple text message.', icon: '', type: TwilioContentTemplateTypes.TEXT},
        //{name: 'Call to Action', description: 'A text message with predefined answers shown as buttons', icon: '', type: TwilioContentTemplateTypes.CALL_TO_ACTION},
        {name: 'Media', description: 'A text message with an image, video or a file', icon: '', type: TwilioContentTemplateTypes.MEDIA}
    ];
    
    constructor(
        private uiService: UIService,
        private snackBar: MatSnackBar,
        private apiService: ContentTemplateApiService
    ) {
        this.loadTemplates();
    }

    /**
     * Returns the available list of ContentTemplateTypes.
     */
    public getTypesList() {
        return JSON.parse(JSON.stringify(this.typesList)); // Deep copy before returning
    }

    /**
     * Creates a new content template in both twilio and in the DB.
     */
    public createTemplate(contentTemplate: WhatsAppContentTemplate): Observable<WhatsAppContentTemplate> {
        let obs = this.observeResult<WhatsAppContentTemplate>(() => {
            return this.apiService.createTemplate(contentTemplate);
        }, ()=>{
            this.realodAsync();
            this.showSuccessMsg(`New Template created successfully.`);
        });
        return obs;
    }

    /**
     * Reloads templates in half a second.
     */
    public realodAsync() {
        setTimeout(() => {
            console.log('reloading templates');
            this.loadTemplates();
        }, 500);
    }

    /**
     * Shows a success toast with the given message.
     */
    private showSuccessMsg(msg: string): void {
        this.snackBar.open(msg, null, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 4000,
        });
    }

    /**
     * Reads all available templates for the current company from the DB,
     * receiving their updated status from twilio.
     */
    private loadTemplates(): void {
        this.observeResult<WhatsAppContentTemplate[]>(() => {
            return this.apiService.getAllTemplates();
        }).subscribe(result => {
            if(result) {
                this.availableTemplates.next(result);
            }
        });
    }

    /**
     * Used to automate error handling, spinner and toasts.
     */
    private observeResult<T>(function2Encapsulate: () => Observable<any>, successCallback: Function = null): Observable<T> {
        this.uiService.toggleGlobalSpinner(true);
        let obs2return = new Observable<T>((obs) => {
            function2Encapsulate()
            .subscribe(
                response => {
                    this.uiService.toggleGlobalSpinner(false);
                    obs.next(response);
                    obs.complete();
                    successCallback && successCallback();
                },
                err => {
                    this.handleError(err, TemplateRequestReason.CREATE);
                    obs.next(null);
                    obs.complete();
                }
            );
        });
        return obs2return;
    }

    /**
     * Shows the proper error and hides the spinner.
     */
    private handleError(err, reason: TemplateRequestReason) {
        let errorText = 'Unknown error';
        switch(reason) {
            case TemplateRequestReason.CREATE:
                errorText = 'Error while attempting to create a new template.';
                break;
            case TemplateRequestReason.READ_ALL:
                errorText = 'Error while attempting to read available templates.';
                break;
        }
        console.error(err);
        this.snackBar.open(errorText, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 8000,
        });
        this.uiService.toggleGlobalSpinner(false);
    }

    /**
     * Returns true if the given link is a link to a file
     * and false otherwise.
     */
    public isValidFileUrl(fileUrl: string): Observable<boolean> {
        return this.apiService.verifyLink(fileUrl);
    }
}

enum TemplateRequestReason {
    CREATE = 'create',
    READ_ALL = 'read all'
}
