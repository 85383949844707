import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation } from 'app/model/negotiation';
import { CreditsService } from 'app/services/credits/credits.service';
import { NegotiationsService } from 'app/services/negotiations.service';

@Component({
  selector: 'app-flow-restarter',
  templateUrl: './flow-restarter.component.html',
  styleUrls: ['./flow-restarter.component.css'],
})
export class FlowRestarterComponent implements OnInit {
  public processing = false;
  public amount: number = undefined;

  constructor(
    private snackBar: MatSnackBar,
    private creditService: CreditsService,
    private negotiationService: NegotiationsService,
    @Inject(MAT_DIALOG_DATA) public data: { negotiation: Negotiation; onRefresh: any },
    public dialogRef: MatDialogRef<FlowRestarterComponent>
  ) {
    this.amount = this.data.negotiation?.amount;
  }

  ngOnInit(): void {}

  onSubmit() {
    if (this.creditService.checkCreditWithAlert()) {
      this.processing = true;
      this.negotiationService.restartNegotiation(this.data.negotiation?.negotiationId).subscribe((response) => {
        this.snackBar.open('Negotiation restarted successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.data.onRefresh?.();
        this.processing = false;
        this.dialogRef.close();
        this.negotiationService.getData();
        this.creditService.updateCredits();
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }
}
