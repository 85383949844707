import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.css'],
})
export class AutocompleteComponent implements OnInit {

  @Input()
  public placeholder: string;
  @Input()
  public options: any = [];
  @Input()
  public text: string = null;
  @Input()
  public maxSuggestions = 6;
  @Output()
  public cancel = new EventEmitter();
  @Output()
  public selected = new EventEmitter();
  public inputValue: string;
  public optionsToDisplay: any;

  constructor() {}

  ngOnInit(): void {
    this.inputValue = this.text ? this.text : null;
  }

  onCancel() {
    this.inputValue = undefined;
    this.cancel.emit();
  }

  onChange(value: any) {
    this.updateOptions();
  }

  updateOptions() {
    // filter by txt and blklst
    const tempArr = this.options.filter((x) => {
      let hasTxt = false;
      if (this.inputValue) {
        Object.keys(x).forEach((key) => {
          hasTxt =
            hasTxt ||
            (x[key] &&
              x[key].toLowerCase &&
              x[key].toLowerCase().includes(this.inputValue.toLowerCase()));
        });
      }
      return hasTxt || !this.inputValue;
    });

    // sort (not working)
    tempArr.sort((a, b) => a.name < b.name);

    // limit
    this.optionsToDisplay = tempArr.splice(0, this.maxSuggestions);
  }

  onSelected(option: any) {
    this.inputValue = option.name;
    this.optionsToDisplay = [];
    this.selected.emit(option);
  }
}
