import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import AnalyticMetric from 'app/model/AnalyticsMetrics';
import { AnalyticsService } from 'app/services/analytics.service';

@Component({
  selector: 'app-admin-analytics',
  templateUrl: './admin-analytics.component.html',
  styleUrls: ['./admin-analytics.component.css'],
})
export class AdminAnalyticsComponent implements OnInit {
  metrics: AnalyticMetric[];

  constructor(private analyticsService: AnalyticsService, private snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.analyticsService.$metrics.subscribe((val: AnalyticMetric[]) => (this.metrics = val));
    this.analyticsService.getMetrics();
  }

  onClear() {
    this.analyticsService.clearBillingVisits().subscribe(
      () => {
        this.analyticsService.getMetrics();
      },
      (err) => {
        this.snackBar.open(err?.message ? err.message : err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }
}
