import { Component, OnDestroy, OnInit } from '@angular/core';
import { UIService } from 'core/services/ui.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ReceivableAccountsService } from 'app/services/receivable-accounts.service';

@Component({
  selector: 'app-accounts-receivable',
  templateUrl: './accounts-receivable.component.html',
  styleUrls: ['./accounts-receivable.component.css']
})
export class AccountsReceivableComponent implements OnInit, OnDestroy {

  accountsDS = new MatTableDataSource<any>([]);
  messageText: string;
  displayedColumns = ['select', 'description', 'dueDate', 'amount'];
  selection = new SelectionModel<any>(true, []);
  hasNext = false;
  page = 1;
  results = 10;
  total = 0;

  constructor(
    private uiService: UIService,
    private receivableAccountsService: ReceivableAccountsService
  ) {
    this.uiService.toggleNavBars(false);
  }

  ngOnInit(): void {
    this.receivableAccountsService.$negotiationData.subscribe(negotiationData => {
      this.messageText = negotiationData.messageText;
    });
    this.receivableAccountsService.$receivableAccounts.subscribe(accountsPayableDocuments => {
      this.accountsDS = new MatTableDataSource<any>(accountsPayableDocuments);
      this.selection.clear();
    });
    this.receivableAccountsService.$page.subscribe(value => {
      this.page = value;
    });
    this.receivableAccountsService.$hasNext.subscribe(value => {
      this.hasNext = value;
    });
    this.selection.changed.subscribe(() => {
      this.total = 0;
      this.selection.selected.forEach(acc => this.total += acc.amount);
    });
    this.receivableAccountsService.initReceivableAccounts();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.accountsDS.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }
    this.selection.select(...this.accountsDS.data);
  }

  ngOnDestroy(): void {
    this.uiService.toggleNavBars(true);
  }

  next() {
    this.receivableAccountsService.loadNext();
  }

  previous() {
    this.receivableAccountsService.loadPrevious();
  }

  paySelection() {
    this.receivableAccountsService.payAccounts(this.selection.selected);
  }

  negotiationWasBidden() {
    // Checks if at least one document has been set as something other than new
    return this.accountsDS.data && this.accountsDS.data.find(x => x && x.status !== 0);
  }

}
