<app-list-bulk-edit
  [options]="menuOptions"
  [filterOptions]="filterOptions"
  [defaultFilter]="defaultFilter"
></app-list-bulk-edit>
<div class="filters-list-container">
  <div class="list-container">
    <app-monitor-grid (selectedSlots)="setSelected($event)" [reload]="reloadGrid"></app-monitor-grid>
  </div>
</div>
