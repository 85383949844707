import { Injectable, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class UserService implements OnInit {
  public currentUser: any = {};
  $currentUser = new BehaviorSubject<any>(null);

  constructor(
    private auth: AuthService
  ) {
    this.auth.user$.subscribe(currentUser => {
      this.currentUser = currentUser ? currentUser : this.currentUser;
      this.$currentUser.next(this.currentUser);
    });
  }

  isAdminUser(user: any = null) {
    if(!user) {
      user = this.currentUser;
    }
    let validDomains = ['@signedeal.com', '@solvmate.net'];
    let isAdminDomain = false;
    validDomains.forEach(domain => {
      isAdminDomain = isAdminDomain || (user?.email && user?.email.includes(domain))
    });
    return isAdminDomain;
  }

  ngOnInit(): void { }

  currentUserAsync(): Observable<any> {
    let observable = new Observable<any>(observer => {
      // TODO: Company should be obtained from user claims or at
      // least call the api to get this data from a given token.
      observer.next(this.currentUser);
    });
    return observable;
  }
}
