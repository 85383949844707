<div
  class="canvas-node-container"
  [class.selected]="selected"
  [class.dragover]="draggingOver"
  [class.disconnected]="!isConnected"
  [style.top]="'calc(' + node?.canvasPositionY + 'px - 2rem)'"
  [style.left]="'calc(' + node?.canvasPositionX + 'px - 2rem)'"
  draggable="true"
  (dragstart)="onDragStart($event)"
  (dragover)="onDragOver(true)"
  (dragleave)="onDragOver(false)"
  (click)="onSelected()"
  (drop)="onDropLink($event)"
>
  <mat-icon *ngIf="node?.actionType !== whatsappAction && node?.actionType !== whatsappTemplateAction"
    class="node-icon" [style.opacity]="isConnected ? 1 : 0.7">{{
    actionIcons[node?.actionType]
  }}</mat-icon>
  
  <!-- Legacy Whatsapp icon -->
  <whatsapp-icon
    *ngIf="node?.actionType === whatsappAction"
    size="4rem"
    fill="#101a35"
    [style.opacity]="isConnected ? 1 : 0.7"
  ></whatsapp-icon>

  <!-- New Whatsapp icon -->
  <whatsapp-icon
    *ngIf="node?.actionType === whatsappTemplateAction"
    size="4rem"
    fill="#101a35"
    [isLegacy]="false"
    [style.opacity]="isConnected ? 1 : 0.7"
  ></whatsapp-icon>

  <div class="action-icons-container" *ngIf="selected">
    <div
      *ngIf="!node.children || node?.children.length === 0"
      (dragstart)="dragRelationshipStart($event)"
      draggable="true"
      class="action-icon"
    >
      <mat-icon>link</mat-icon>
    </div>
    <div
      *ngIf="node?.actionType !== startAction"
      (dragstart)="deadDrag()"
      draggable="true"
      class="action-icon"
      (click)="deleteNodeAction()"
    >
      <mat-icon>delete</mat-icon>
    </div>
  </div>
  <div *ngIf="!selected && !isConnected" class="action-icons-container">
    <div class="action-icon broken-link">
      <mat-icon matTooltip="This action needs to be accessible from the start action to run with the flow."
        >link_off</mat-icon
      >
    </div>
  </div>
</div>
