import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService } from '@auth0/auth0-angular';
import { appearOpacityAnimation } from 'app/animations/appearOpacityAnimation';
import { inOutAnimation } from 'app/animations/inOutAnimation';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { CompanySettingsService } from 'app/components/settings';
import { PaymentService } from 'app/services/payment.service';
import { UIService } from 'core/services/ui.service';
import { environment } from 'environments/environment';
import { NewUserRegistrationComponent } from '../new-user-registration/new-user-registration.component';

@Component({
  selector: 'app-billing-details',
  templateUrl: './billing-details.component.html',
  styleUrls: ['./billing-details.component.css'],
  animations: [appearOpacityAnimation, inOutAnimation],
})
export class BillingDetailsComponent implements OnInit, OnDestroy {
  @Input() showAmount = false;
  @Input() subscriptionType = null;
  @Input() isSubscription = null;

  @Output() completeSubscription = new EventEmitter();
  @Output() completeAddingFunds = new EventEmitter();

  public minPaymentValue = 20;

  token = '';
  expiry = '';
  companyId = null;
  iframeUrl = null;
  saveFunctionReference = null;
  isLogged = false;

  iframeStyles = `
    .error { color: red; border-color: red; }
    input { width: 400px; }
    label {
      font: 400 14px/20px Roboto,"Helvetica Neue", sans-serif;
      font-size: 1.2rem;
      margin-bottom: -16px; 
      display: block;
    }
    input, select {
      margin-bottom: 0.75rem;
      border-radius: 0.25rem;
      border: solid 1px lightgray;
      height: 32px;
    }
    input#cccvvfield {
      margin-bottom: -16px;
    }
    select { width: 150px; height: 37px; }
  `;

  billingForm = this.formBuilder.group({
    chname: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    amount: new FormControl(this.minPaymentValue, [
      Validators.required,
      Validators.maxLength(10),
      Validators.min(this.minPaymentValue),
    ]),
    bdaddress: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    bdpc: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    bdcity: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    bdregion: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    bdcountry: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    bdphone: new FormControl('', [Validators.required, Validators.maxLength(100)]),
    bdemail: new FormControl('', [Validators.required, Validators.email]),
  });

  constructor(
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private paymentService: PaymentService,
    private companySettingsService: CompanySettingsService,
    private uiService: UIService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    const baseUrl = environment.production
      ? 'https://fts.cardconnect.com/itoke/ajax-tokenizer.html'
      : 'https://fts-uat.cardconnect.com/itoke/ajax-tokenizer.html';
    this.iframeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
      `${baseUrl}?invalidcreditcardevent=true
      &invalidcvvevent=true&invalidexpiryevent=true&useexpiry=true&usecvv=true&formatinput=true&css=${encodeURIComponent(
        this.iframeStyles
      )}`
    );

    this.authService.isAuthenticated$.subscribe((val) => (this.isLogged = val));

    /**
     * Function reference to be able to remove the listerner and prevent getting it duplicated.
     * Adding the binding creates a new function with the same body and ensures the correct context.
     */
    this.saveFunctionReference = this.saveToken.bind(this);
  }

  loadedIFrame() {
    this.uiService.toggleGlobalSpinner(false);
  }

  saveToken(event) {
    const data = JSON.parse(event.data);
    if (data.validationError) {
      this.token = null;
      return;
    }
    if (data.message) {
      this.token = data.message;
      this.expiry = data.expiry;
    }
  }

  ngOnInit(): void {
    this.uiService.toggleGlobalSpinner(true);
    window.addEventListener('message', this.saveFunctionReference, false);
    this.companySettingsService.$companyData.subscribe((data) => {
      this.companyId = data?.companyId;
    });
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.saveFunctionReference, false);
  }

  onSubmit() {
    if (this.billingForm.valid) {
      const body = {
        expiration: this.expiry,
        account: this.token,
        name: this.billingForm.value.chname,
        address: this.billingForm.value.bdaddress,
        city: this.billingForm.value.bdcity,
        postalCode: this.billingForm.value.bdcity,
        region: this.billingForm.value.bdregion,
        country: this.billingForm.value.bdcountry,
        phone: this.billingForm.value.bdphone,
        email: this.billingForm.value.bdemail,
      };
      if (!this.isLogged) {
        this.dialog.open(NewUserRegistrationComponent, {
          data: { billingInfo: body, onSubmit: () => this.completeSubscription.emit() },
        });
        return;
      }

      this.uiService.toggleGlobalSpinner(true);
      if (this.companyId && !this.isSubscription) {
        this.paymentService
          .addCredit({
            ...body,
            amount: this.billingForm.value.amount,
          })
          .subscribe({
            next: (result: any) => {
              if (result && result.success) {
                this.completeAddingFunds.emit();
                this.uiService.toggleGlobalSpinner(false);
              } else {
                this.dialog.open(AlertComponent, {
                  data:
                    'Error: ' + (result?.message ? result?.message : 'Something went wrong when attempting to pay.'),
                });
                this.uiService.toggleGlobalSpinner(false);
              }
            },
            error: (err) => {
              this.dialog.open(AlertComponent, {
                data: err?.message ? err?.message : err,
              });
              this.uiService.toggleGlobalSpinner(false);
            },
          });
      } else {
        this.paymentService
          .postNewSubscription({
            ...body,
            subscriptionType: this.subscriptionType,
          })
          .subscribe({
            next: (result: any) => {
              if (result && result.success) {
                this.completeSubscription.emit();
                this.uiService.toggleGlobalSpinner(false);
              } else {
                this.dialog.open(AlertComponent, {
                  data:
                    'Error: ' + (result?.message ? result?.message : 'Something went wrong when attempting to pay.'),
                });
                this.uiService.toggleGlobalSpinner(false);
              }
            },
            error: (err) => {
              this.dialog.open(AlertComponent, {
                data: err?.message ? err?.message : err,
              });
              this.uiService.toggleGlobalSpinner(false);
              this.uiService.toggleGlobalSpinner(false);
            },
          });
      }
      console.log(this.billingForm.value);
    }
  }
}
