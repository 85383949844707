<div class="title">Template content</div>

<form [formGroup]="contentCreateForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Language</mat-label>
            <mat-select name="language" formControlName="language" required>
                <mat-option *ngFor="let item of availableLanguagesList | keyvalue" [value]="item.key"> {{item.value}} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Content</mat-label>
            <textarea matInput formControlName="content" type="text" rows="5"
                placeholder="Place here the content of your template" required></textarea>
        </mat-form-field>

        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!contentCreateForm.valid" type="submit">Accept</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>