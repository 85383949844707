import { Component, OnInit } from '@angular/core';
import { CreditsService } from 'app/services/credits/credits.service';

@Component({
  selector: 'app-billing-success',
  templateUrl: './billing-success.component.html',
  styleUrls: ['./billing-success.component.css'],
})
export class BillingSuccessComponent implements OnInit {
  constructor(private creditsService: CreditsService) {}

  ngOnInit(): void {
    this.creditsService.updateCredits();
  }
}
