<div class="messages-modal-container">
  <div class="title" [style.background]="primaryColor" [style.color]="secondaryColor">
    <h1>Messages from {{ contactName }}</h1>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="messages-modal-content" #messagesRef>
    <ul class="messages-list">
      <li *ngFor="let message of messages">
        <div
          class="message-line"
          [class.sender]="(isAdmin && message.sender === 0) || (!isAdmin && message.sender === 1)"
        >
          <div class="message-text">
            {{ message.content }}
          </div>
          <div class="timestamp">
            {{ message.timestamp | date: 'shortTime' }}
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="input-container">
    <textarea
      rows="3"
      style="resize: none"
      [(ngModel)]="messageText"
      (keyup.enter)="sendMessage()"
      appMyAutofocus
    ></textarea>
    <button
      class="send-button"
      (click)="sendMessage()"
      [style.background]="primaryColor"
      [style.color]="secondaryColor"
    >
      Send
    </button>
  </div>
</div>
