<div class="title">Generate API credentials</div>

<div class="fields-container">
    <mat-form-field appearance="fill" class="regular-field" *ngIf="!isGeneratedCredentials">
        <mat-label>Select a Company</mat-label>
        <mat-select name="company" [(value)]="company" required>
            <mat-option *ngFor="let company of companies" [value]="company"> {{company.name}} </mat-option>
        </mat-select>
    </mat-form-field>

    <div *ngIf="isGeneratedCredentials">
        <mat-label>Here is the client secret for {{company.name}}. It won't be shown again, so please store it in a secure place:</mat-label>
        <mat-label class="client-secret-text">{{clientSecret}}</mat-label>
    </div>

    <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-ok" [disabled]="company == null" type="button"
            (click)="onGenerateCredentials()" *ngIf="!isGeneratedCredentials">Generate</button>
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onClose()">Close</button>
    </div>
</div>