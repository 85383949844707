import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/notifications';
  }

  public sendSupportEmail(message: string): Observable<any> {
    const url = this.baseURL + '/support-email';
    const body = {
        content: message
    };
    return this.httpClient.post(url, body);
  } 
}
