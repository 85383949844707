<div class="full-settings-container" *ngIf="companyUpdateForm">
    <form [formGroup]="companyUpdateForm" (ngSubmit)="onSubmit()" class="the-form">
        <div class="form-fields-container">
            <span class="settings-title">Company Settings</span>

            <div class="form-actual-fields">
                <div class="company-field-container">
                    <mat-form-field class="regular-field">
                        <mat-label>Name</mat-label>
                        <input matInput formControlName="name" type="text" placeholder="Name" required autocomplete="off"/>
                    </mat-form-field>
                    <mat-icon class="message-tooltip" [matTooltip]="tooltips.name" matTooltipPosition="right">info</mat-icon>
                </div>
                <div class="company-field-container">
                    <mat-form-field class="regular-field">
                        <mat-label>Redirect Url</mat-label>
                        <input matInput formControlName="redirectUrl" type="url" placeholder="Redirect Url" autocomplete="off"/>
                    </mat-form-field>
                    <mat-icon class="message-tooltip" [matTooltip]="tooltips.redirectUrl" matTooltipPosition="right">info</mat-icon>
                </div>
                <div class="company-field-container">
                    <mat-form-field class="regular-field">
                        <mat-label>Icon Url</mat-label>
                        <input matInput formControlName="icon" type="url" placeholder="Icon Url" autocomplete="off"/>
                    </mat-form-field>
                    <mat-icon class="message-tooltip" [matTooltip]="tooltips.icon" matTooltipPosition="right">info</mat-icon>
                </div>
                <div class="company-field-container">
                    <mat-form-field class="regular-field">
                        <mat-label>Primary Color</mat-label>
                        <input matInput formControlName="primaryColor" type="color" placeholder="Primary Color" autocomplete="off"/>
                    </mat-form-field>
                    <mat-icon class="message-tooltip" [matTooltip]="tooltips.primaryColor" matTooltipPosition="right">info</mat-icon>
                </div>
                <div class="company-field-container">
                    <mat-form-field class="regular-field">
                        <mat-label>Secondary Color</mat-label>
                        <input matInput formControlName="secondaryColor" type="color" placeholder="Secondary Color" autocomplete="off"/>
                    </mat-form-field>
                    <mat-icon class="message-tooltip" [matTooltip]="tooltips.secondaryColor" matTooltipPosition="right">info</mat-icon>
                </div>
                <div class="company-field-container">
                    <div class="regular-field">
                        <label>Languages</label>
                        <div class="language-container">
                            <label *ngFor="let item of languages" class="language-item" [title]="item.name">
                                <input type="checkbox" [value]="item.languageCode" [(ngModel)]="item.enabled" [ngModelOptions]="{standalone: true}">{{item.languageCode}}
                            </label>
                        </div>
                    </div>
                    <mat-icon class="message-tooltip" [matTooltip]="tooltips.languages" matTooltipPosition="right">info</mat-icon>
                </div>
            </div>

            <div class="buttons-container">
                <button class="sgnd-btn sgnd-btn-ok" [disabled]="disableSubmit()" type="submit">Save</button>
            </div>
        </div>
    </form>
    
    <div class="user-settings-container">
        <span class="settings-title user-settings-title">User Settings</span>
        <div class="user-fields-container">
            <button class="sgnd-btn sgnd-btn-ok change-pass-btn" (click)="onPasswordChange()">Change password</button>
        </div>
        <div>&nbsp;</div>
    </div>
</div>
