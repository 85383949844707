<div class="settings-card-container" *ngIf="companyUpdateForm">
  <div class="settings-card-title">Company Settings</div>
  <form [formGroup]="companyUpdateForm" (ngSubmit)="onSubmit()" class="the-form">
    <div class="form-fields-container">
      <div class="form-actual-fields">
        <div class="appeareance">
          <div>
            <div class="company-field-container">
              <mat-form-field class="regular-field">
                <mat-label>Name</mat-label>
                <input matInput formControlName="name" type="text" placeholder="Name" required autocomplete="off" />
              </mat-form-field>
              <mat-icon class="message-tooltip" [matTooltip]="tooltips.name" matTooltipPosition="right">info</mat-icon>
            </div>
            <div class="company-field-container">
              <mat-form-field class="regular-field">
                <mat-label>Redirect Url</mat-label>
                <input
                  matInput
                  formControlName="redirectUrl"
                  type="url"
                  placeholder="Redirect Url"
                  autocomplete="off"
                />
              </mat-form-field>
              <mat-icon class="message-tooltip" [matTooltip]="tooltips.redirectUrl" matTooltipPosition="right"
                >info</mat-icon
              >
            </div>
            <div class="company-field-container">
              <mat-form-field class="regular-field">
                <mat-label>Icon Url</mat-label>
                <input matInput formControlName="icon" type="url" placeholder="Icon Url" autocomplete="off" />
              </mat-form-field>
              <mat-icon class="message-tooltip" [matTooltip]="tooltips.icon" matTooltipPosition="right">info</mat-icon>
            </div>
            <div class="settings-card-subtitle">Colors</div>
            <div class="colours">
              <div>
                <div [matTooltip]="tooltips.primaryColor" matTooltipPosition="right" class="pointer bold">Primary</div>
                <input
                  matInput
                  formControlName="primaryColor"
                  type="color"
                  placeholder="Primary Color"
                  autocomplete="off"
                  class="color-picker"
                />
              </div>
              <div class="company-field-container">
                <div [matTooltip]="tooltips.secondaryColor" matTooltipPosition="right" class="pointer bold">
                  Secondary
                </div>
                <input
                  matInput
                  formControlName="secondaryColor"
                  type="color"
                  placeholder="Secondary Color"
                  autocomplete="off"
                  class="color-picker"
                />
              </div>
            </div>
          </div>

          <app-modal-sample
            [primaryColor]="companyUpdateForm.get('primaryColor').value"
            [secondaryColor]="companyUpdateForm.get('secondaryColor').value"
            [imgUrl]="companyUpdateForm.get('icon').value"
            [redirectUrl]="companyUpdateForm.get('redirectUrl').value"
          ></app-modal-sample>

          <div class="regular-field">
            <div [matTooltip]="tooltips.languages" matTooltipPosition="right" class="settings-card-subtitle">
              Languages
            </div>
            <div class="languages-container">
              <label *ngFor="let item of languages" class="language-item" [title]="item.name">
                <input
                  type="checkbox"
                  [value]="item.languageCode"
                  [(ngModel)]="item.enabled"
                  [ngModelOptions]="{ standalone: true }"
                />{{ item.languageCode }}
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-ok" [disabled]="disableSubmit()" type="submit">Save</button>
      </div>
    </div>
  </form>
</div>
