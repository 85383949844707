import { FlowAction } from './flow-action';

export class ActionMessage {
  index = 0;
  language: string;
  content: string;
  extraContent?: string;
  flowActionMessageId: number;

  constructor(language: string, content: string, extraContent = ' ') {
    this.language = language;
    this.content = content;
    this.extraContent = extraContent;
  }

  static cloneMsg({ index, language, content, extraContent = ' ', flowActionMessageId }) {
    return {
      index,
      language,
      content,
      extraContent,
      flowActionMessageId,
    } as ActionMessage;
  }
}

export class ActionCallProperties {
  id: null | string | number = null;
  retry = false;
  retryAttempts: number | string = 1;
  retryFrequency: number | string = 0.5;
  retryOnAbsent = false;
  retryOnFailed = false;
}

export class FlowActionCall extends FlowAction {
  flowActionVoiceCallParameter: ActionCallProperties = new ActionCallProperties();
  flowActionMessages: ActionMessage[] = [];

  constructor(
    flowActionId: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable,
    description: string,
    messages = [],
    flowActionVoiceCallParameter = new ActionCallProperties(),
    continueOnFailure = true
  ) {
    super(
      flowActionId,
      flowId,
      flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      children,
      enable,
      description,
      continueOnFailure
    );
    this.flowActionMessages = messages;
    this.flowActionVoiceCallParameter = flowActionVoiceCallParameter;
  }

  // Get unreferenced properties
  static getProperties({ id, retry, retryAttempts, retryFrequency, retryOnAbsent, retryOnFailed }) {
    const properties = {
      id,
      retry,
      retryAttempts,
      retryFrequency,
      retryOnAbsent,
      retryOnFailed,
    };
    return properties;
  }

  // get unreferenced Flow Action
  static getFlowAction(call: FlowActionCall) {
    const flowAction = {
      flowActionProperties: FlowActionCall.getProperties(call.flowActionVoiceCallParameter),
      flowActionMessages: call.flowActionMessages.map((msg) => ActionMessage.cloneMsg(msg)),
    };
    return flowAction;
  }
}
