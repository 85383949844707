<div class="documentation-screen-container">
    <div class="index-container">
        <div *ngFor="let doc of documentation" class="index-documentation-container">
            <div class="index-doc-name index-txt"
                (click)="scrollToElement(doc.getHtmlId())"
                matTooltip="{{doc?.name}}" matTooltipPosition="right">
                {{doc?.name}}
            </div>
            <div *ngIf="doc && doc.entries && doc.entries.length">
                <div *ngFor="let entry of doc.entries">
                    <div class="index-entry-title index-txt index-subtxt"
                        *ngIf="entry?.title"
                        (click)="scrollToElement(entry.getHtmlId())"
                        matTooltip="{{entry?.title}}" matTooltipPosition="right">
                        {{entry?.title}}
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="documentation-content-container">
        <div *ngFor="let doc of documentation" class="documentation-container">
            <div class="doc-name" [id]="doc.getHtmlId()">{{doc?.name}}</div>
            <div *ngIf="doc && doc.entries && doc.entries.length">
                <div *ngFor="let entry of doc.entries">
                    <div class="entry-title" *ngIf="entry?.title" [id]="entry.getHtmlId()">{{entry?.title}}</div>
                    <div class="entry-content" *ngIf="entry?.content">{{entry?.content}}</div>
                    <mat-tab-group *ngIf="entry && entry.samples && entry.samples.length">
                        <mat-tab *ngFor="let sample of entry.samples" label="{{sample?.language}}">
                            <div class="sample-content">
                                <button (click)="copyToClipboard(sample.content)" class="copy-btn">Copy</button>
                                <pre><code>{{sample?.content}}</code></pre>
                            </div>
                        </mat-tab>
                    </mat-tab-group>
                </div>
            </div>
        </div>
    </div>
</div>

