<div class="contact-upload">
    <div class="title">Upload Contacts</div>
    <div class="contact-upload-container">
        <label class="upload-label-title">
            Get your contacts from a file
        </label>
        <label class="upload-label-description">
            Upload contacts in XLS and XLSX format only.
        </label>
        <label class="upload-label-input">
            Select a file to upload
        </label>
        <div class="upload-contact-input">
            <input #fileInput type="file" (change)="setChosenFile($event)" hidden
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
            <input class="upload-file-name-text-input" matInput type="text" [(ngModel)]="chosenFileName" value="{name}"
                placeholder="No file selected" readonly>
            <button type="button" class="browse-file-btn" mat-stroked-button (click)="fileInput.click()">Browse</button>
        </div>

        <label class="select-contacts-group-label">
            Optionally, assign them to a Group.
        </label>
        <mat-form-field appearance="fill" class="select-contacts-group-container">
            <mat-select [(value)]="selectedContactsGroup">
                <mat-option>(None)</mat-option>
                <mat-option *ngFor="let contactsGroup of contactsGroups" [value]="contactsGroup">{{ contactsGroup.name }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <div class="upload-contact-buttons">
            <button class="sgnd-btn sgnd-btn-ok" type="button" (click)="uploadFile()"
                [disabled]="!chosenFile">Upload</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
        <div class="download-sample-file">
            <a mat-button href="assets/contacts_sample.xlsx" class="download-sample-file-btn" target="_blank">Download a
                sample file</a>
        </div>
    </div>
</div>