<div
  class="contacts-grid-container"
  cdkDropListGroup
  [class.hidden-grid]="isLoadingGroups || !contactsGroups || contactsGroups.length === 0"
>
  <mat-card [class.collapsed]="isCollapsed" [@collapseInOut]="collapseStr" class="groups-list-container">
    <div class="expand-button">
      <i
        class="material-icons-outlined pointer"
        style="font-size: 2rem"
        (click)="toggleCollapsed()"
        [matTooltip]="isCollapsed ? 'Expand' : 'Collapse'"
        >{{ isCollapsed ? 'arrow_circle_right' : 'arrow_circle_left' }}</i
      >
    </div>
    <div class="options-cards-container">
      <mat-card-header>
        <mat-card-title>Contacts Groups</mat-card-title>
      </mat-card-header>
      <mat-selection-list
        class="contacts-groups-selection-list"
        [multiple]="false"
        cdkDropList
        [cdkDropListData]="contactsGroups"
      >
        <mat-list-item
          class="pointer"
          cdkDrag
          [cdkDragData]="group"
          [cdkDragDisabled]="!selectedSlot"
          *ngFor="let group of contactsGroups"
          (click)="onSelectContactGroup(group)"
          [value]="group"
          [class.mat-list-single-selected-option]="group.id == selectedContactsGroup?.id"
        >
          <div matListItemTitle class="flex-1">
            {{ group.name }}
          </div>
          <mat-icon
            *ngIf="group.id == selectedContactsGroup?.id"
            class="material-icons-outlined"
            (click)="onClickGroupEdit($event, group)"
            >edit</mat-icon
          >
        </mat-list-item>
      </mat-selection-list>
    </div>
  </mat-card>

  <div class="group-tables">
    <mat-card class="slots-card">
      <div class="flex items-center flex-1">
        <form>
          <mat-form-field class="form-field-no-padding" appearance="fill">
            <mat-label>Target Slot</mat-label>
            <input
              type="text"
              placeholder="All Channels"
              matInput
              [formControl]="targetSlotControl"
              [matAutocomplete]="matSlotAutocomplete"
            />
            <mat-autocomplete
              autoActiveFirstOption
              #matSlotAutocomplete="matAutocomplete"
              [displayWith]="slotDisplayFn.bind(this)"
              (closed)="onCloseSlotSelect()"
            >
              <mat-option *ngFor="let slotElement of filteredSlotOptions | async" [value]="slotElement">
                {{ (slotElement.isDataInjestor ? 'DI-' : ' ') + slotElement.title }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </form>
        <div
          *ngIf="selectedSlot"
          class="droppable-slot-container"
          cdkDropList
          (cdkDropListDropped)="onDropContactsGroupToChannel($event)"
          (click)="assignContactGroups()"
        >
          Click or drag here to assign the selected Contacts Group to the Channel
        </div>
      </div>
    </mat-card>

    <div class="contacts-tables" [class.hidden-grid]="!contactsGroups || !selectedContactsGroup">
      <mat-card>
        <mat-card-header>
          <mat-card-title>Available Contacts</mat-card-title>
        </mat-card-header>
        <div class="filter-paginator-container">
          <mat-form-field class="filter">
            <input
              matInput
              placeholder="Filter by last name, name or email"
              [(ngModel)]="availableContactsFilterString"
              (change)="onAvailableContactsFilterChange($event)"
              autocomplete="off"
            />
            <mat-icon
              matSuffix
              *ngIf="availableContactsFilterString?.length > 0"
              (click)="onClearAvailableContactsFilter()"
              >close</mat-icon
            >
          </mat-form-field>
          <mat-paginator
            #availableContactsPaginator
            [pageSize]="availableContactsPageSize"
            [pageSizeOptions]="availableContactsPageSizeOptions"
            [length]="availableContactsTotalCountLength"
            (page)="onChangeAvailableContactsPageSizeOrIndex($event)"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="availableContactsDataSource"
            matSort
            #availableContactsTbSort="matSort"
            class="table"
            fixedLayout
          >
            <ng-container matColumnDef="Selection">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? toggleAllAvailableRows() : null"
                  [checked]="availableContactsSelection.hasValue() && isAllAvailableSelected()"
                  [indeterminate]="availableContactsSelection.hasValue() && !isAllAvailableSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? availableContactsSelection.toggle(row) : null"
                  [checked]="availableContactsSelection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="ContactName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-contact">Contact</th>
              <td mat-cell *matCellDef="let element" matTooltip="{{ element.name }} {{ element.lastName }}">
                {{ element.name }} {{ element.lastName }}
              </td>
            </ng-container>
            <ng-container matColumnDef="ContactEmail">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-email">Email</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.email">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="ContactPhone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-phone">Phone</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.phone">{{ element.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="LastModified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-last-modified">Last Modified</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.lastModified">
                {{ element.lastModified | date : 'MM/dd/yyyy hh:mm a' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-card>

      <mat-card>
        <div class="buttons-container">
          <button class="sgnd-btn sgnd-btn-ok assign-btn" (click)="onAssignContacts()">
            Assign <mat-icon>arrow_right</mat-icon>
          </button>
          <button class="sgnd-btn sgnd-btn-ok remove-btn" (click)="onUnassignContacts()">
            <mat-icon>arrow_left</mat-icon> Remove
          </button>
        </div>
      </mat-card>

      <mat-card>
        <mat-card-header>
          <mat-card-title>{{ selectedContactsGroup?.name }} Contacts</mat-card-title>
        </mat-card-header>
        <div class="filter-paginator-container">
          <mat-form-field class="filter">
            <input
              matInput
              placeholder="Filter by last name, name or email"
              [(ngModel)]="assignedContactsFilterString"
              (change)="onAssignedContactsFilterChange($event)"
              autocomplete="off"
            />
            <mat-icon
              matSuffix
              *ngIf="assignedContactsFilterString?.length > 0"
              (click)="onClearAssignedContactsFilter()"
              >close</mat-icon
            >
          </mat-form-field>
          <div class="filter-extra-buttons">
            <mat-icon (click)="exportContacts(selectedContactsGroup)" matTooltip="Export">save_alt</mat-icon>
          </div>
          <mat-paginator
            #assignedContactsPaginator
            [pageSize]="assignedContactsPageSize"
            [pageSizeOptions]="assignedContactsPageSizeOptions"
            [length]="assignedContactsTotalCountLength"
            (page)="onChangeAssignedContactsPageSizeOrIndex($event)"
            aria-label="Select page"
          >
          </mat-paginator>
        </div>
        <div class="table-container">
          <table
            mat-table
            [dataSource]="assignedContactsDataSource"
            matSort
            #assignedContactsTbSort="matSort"
            class="table"
            fixedLayout
          >
            <ng-container matColumnDef="Selection">
              <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                  (change)="$event ? toggleAllAssignedRows() : null"
                  [checked]="assignedContactsSelection.hasValue() && isAllAssignedSelected()"
                  [indeterminate]="assignedContactsSelection.hasValue() && !isAllAssignedSelected()"
                >
                </mat-checkbox>
              </th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? assignedContactsSelection.toggle(row) : null"
                  [checked]="assignedContactsSelection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>
            <ng-container matColumnDef="ContactName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-contact">Contact</th>
              <td mat-cell *matCellDef="let element">{{ element.name }} {{ element.lastName }}</td>
            </ng-container>
            <ng-container matColumnDef="ContactEmail">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-email">Email</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.contactEmail">{{ element.email }}</td>
            </ng-container>
            <ng-container matColumnDef="ContactPhone">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-phone">Phone</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.contactEmail">{{ element.phone }}</td>
            </ng-container>
            <ng-container matColumnDef="LastModified">
              <th mat-header-cell *matHeaderCellDef mat-sort-header class="th-last-modified">Last Modified</th>
              <td mat-cell *matCellDef="let element" [matTooltip]="element.lastModified">
                {{ element.lastModified | date : 'MM/dd/yyyy hh:mm a' }}
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
        </div>
      </mat-card>
    </div>
  </div>
</div>
<div class="empty-groups" *ngIf="!isLoadingGroups && (!contactsGroups || contactsGroups.length === 0)">
  There are not contact groups created
</div>
<div *ngIf="isLoadingGroups" class="groups-spinner">
  <mat-spinner color="primary"></mat-spinner>
</div>
