import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import Sender from 'app/model/sender';
import { SendersService } from 'app/services/senders.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-sender-modal',
  templateUrl: './sender-modal.component.html',
  styleUrls: ['./sender-modal.component.css'],
})
export class SenderModalComponent implements OnInit {
  title = 'Add New Sender';
  isNew = true;
  senderForm: FormGroup;

  phoneNumberRegex = /^[\+]?[\d]{1,3}[(]?[0-9]{1,4}[)]?[\d]{1,5}[\-\.\s]?[\d]{1,6}$/;
  accountRegex = /^[\+]?[\d]{1,3}[(]?[0-9]{1,4}[)]?[\d]{1,5}[\-\.\s]?[\d]{1,6}$/;

  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialogRef<SenderModalComponent>,
    public confirmationDialog: MatDialog,
    public uiService: UIService,
    private formBuilder: FormBuilder,
    private sendersService: SendersService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sender: Sender;
      companyId: number | string;
    }
  ) {
    if (data.sender) {
      this.title = 'Edit Sender';
      this.isNew = false;
      this.buildContactCreateForm(data.sender);
    } else {
      this.buildContactCreateForm({
        // eslint-disable-next-line
        number: '',
        account: '',
      });
    }
  }

  ngOnInit(): void {}

  buildContactCreateForm(sender: Sender) {
    this.senderForm = this.formBuilder.group({
      senderPhoneNumber: sender.number,
      twilioAccountId: sender.account,
      senderId: sender?.senderId,
    });
  }

  onConfirm() {
    this.uiService.toggleGlobalSpinner(true);
    if (this.isNew) {
      this.sendersService
        .addNewSender({
          ['number']: this.senderForm.value['senderPhoneNumber'],
          companyId: this.data.companyId,
          account: this.senderForm.value['twilioAccountId'],
        })
        .toPromise()
        .then(() => {
          this.uiService.showMessage('Sender added successfully');
          this.dialog.close(true);
        })
        .catch((err) => {
          this.uiService.showMessage(err?.message);
        })
        .finally(() => {
          this.uiService.toggleGlobalSpinner(false);
        });
    } else {
      this.sendersService
        .updateSender({
          ['number']: this.senderForm.value['senderPhoneNumber'],
          companyId: this.data.companyId,
          account: this.senderForm.value['twilioAccountId'],
          senderId: this.senderForm.value['senderId'],
        })
        .toPromise()
        .then(() => {
          this.uiService.showMessage('Sender updated successfully');
          this.dialog.close(true);
        })
        .catch((err) => {
          this.uiService.showMessage(err?.message);
        })
        .finally(() => {
          this.uiService.toggleGlobalSpinner(false);
        });
    }
  }

  onDelete() {
    this.confirmationDialog
      .open(ConfirmDialogComponent, {
        data: {
          // eslint-disable-next-line
          message: `Are you sure you want to delete the current sender?`,
        },
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.uiService.toggleGlobalSpinner(true);
          this.sendersService
            .deleteSender(this.data.sender?.senderId)
            .toPromise()
            .then(() => {
              this.uiService.showMessage('Sender deleted successfully');
            })
            .catch((err) => {
              this.uiService.showMessage(err?.message);
            })
            .finally(() => {
              this.uiService.toggleGlobalSpinner(false);
            });
        }
      });
  }

  onCancel() {
    this.dialog.close(false);
  }
}
