<div class="title">{{contactsGroup.id ? 'Update' : 'Create'}} Contacts Group</div>

<form [formGroup]="contactsGroupCreateForm" (ngSubmit)="onSubmit()" autocomplete="off" >
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" type="text" maxlength="60" placeholder="Name" required>
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!contactsGroupCreateForm.valid" type="submit">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>