import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Negotiation } from 'app/model/negotiation';
import { NegotiationsService } from 'src/app/services/negotiations.service';
import { ContactsService } from 'src/app/services/contacts.service';
import { NegotiationType } from 'app/model/negotiation-type';
import { formatDate } from '@angular/common';
import { DocumentsService } from 'app/services/documents.service';
import { AccountsReceivableDocument } from 'app/model/documents/accounts-receivable-document';
import { NegotiationOptionsService } from 'app/services/negotiation-options.service';
import { DemoDocument } from 'app/model/documents/demo-document';
import { UIService } from 'core/services/ui.service';
import { FlowsService } from 'app/services/flows.service';
import { Flow } from 'app/model/flow';
import { SlotsService } from 'app/services/slots.service';
import { AttachmentsService } from 'app/services/attachments.service';
import downloadFileResponse from 'app/functions/fileFunctions';

@Component({
  selector: 'app-add-negotiation',
  templateUrl: './add-negotiation.component.html',
  styleUrls: ['./add-negotiation.component.css'],
})
export class AddNegotiationComponent implements OnInit {
  negotiationCreateForm: FormGroup;
  public contacts: Array<{ key: number; name: string }> = [];
  public contactNameSelected;
  public negotiation: Negotiation;
  public availableFlows: Flow[];
  public flowActions = [];

  public docsOpenState = false;
  public files = [];

  constructor(
    public negotiationService: NegotiationsService,
    public contactsService: ContactsService,
    public documentsService: DocumentsService,
    public flowsService: FlowsService,
    private uiService: UIService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddNegotiationComponent>,
    public negotiationOptionsService: NegotiationOptionsService,
    @Inject(MAT_DIALOG_DATA) public data: Negotiation,
    private snackBar: MatSnackBar,
    public slotsService: SlotsService,
    public attachmentsService: AttachmentsService
  ) {
    this.attachmentsService.setFiles([]);
    if (data) {
      this.negotiation = new Negotiation(data);
      if (this.negotiation.negotiationType === NegotiationType.ACCOUNTS_RECEIVABLE) {
        this.documentsService.setDocuments(this.negotiation.accountsPayableDocuments);
      } else if (this.negotiation.negotiationType === NegotiationType.OPTIONS) {
        this.documentsService.setDocuments([this.negotiation.demoDocument]);
        this.negotiationOptionsService.setNegotiationOptions(this.negotiation.optionDocuments || []);
      }
      this.attachmentsService.getFilesByNegotiationId(this.negotiation.negotiationId);
      this.contactNameSelected = this.negotiation.contactName;
      if (this.negotiation.negotiationId) {
        this.negotiationService.getFlowActionsByNegotiation(this.negotiation.negotiationId, this.negotiation.flowId);
      } else {
        this.negotiationService.getNegotiationFlowActions(this.negotiation.flowId);
      }
    } else {
      this.documentsService.setDocuments([]);
      this.negotiationOptionsService.setNegotiationOptions([]);
      this.negotiation = new Negotiation(null);
      this.negotiation.slotId = negotiationService.getSlot();
    }
    this.buildNegotiationCreateForm(this.negotiation);
    contactsService.getData();
    this.flowsService.flows.subscribe((values) => {
      this.availableFlows = values;
    });
    this.negotiationService.$negotiationFlowActions.subscribe((values) => {
      this.flowActions = values;
    });
  }

  public onSelectFlow(flowId: number) {
    const flow = this.availableFlows.find((fl) => fl.flowId == flowId);
    this.negotiation.negotiationType = flow.type;
    this.negotiationService.setNegotiationFlowActions([]);
    this.negotiationService.getNegotiationFlowActions(flowId);
    this.negotiationCreateForm.patchValue({
      flowId: flow.flowId,
      negotiationType: flow != null ? flow.type.toString() : null,
    });
  }

  buildNegotiationCreateForm(negotiation: Negotiation) {
    this.negotiationCreateForm = this.formBuilder.group({
      description: negotiation.description,
      flowId: negotiation.flowId,
      negotiationType: negotiation.negotiationType != null ? negotiation.negotiationType.toString() : null,
      slotId: negotiation.slotId,
      contactId: negotiation.contactId,
      documentId: negotiation?.demoDocument?.externalId,
      documentDescription: negotiation?.demoDocument?.description,
      messageText: negotiation?.messageText,
      ccpLink: negotiation?.ccpLink,
      dueDate: negotiation.dueDate ? formatDate(negotiation.dueDate, 'yyyy-MM-dd', 'en') : null,
    });
  }

  onSubmit(): void {
    const negotiation = new Negotiation({
      ...this.negotiationCreateForm.value,
    });
    negotiation.negotiationId = this.negotiation.negotiationId;
    this.uiService.toggleGlobalSpinner(true);
    if (Number(negotiation.negotiationType) === NegotiationType.ACCOUNTS_RECEIVABLE) {
      const docList = this.documentsService.getDocuments();
      negotiation.accountsPayableDocuments = docList as AccountsReceivableDocument[];
    } else if (Number(negotiation.negotiationType) === NegotiationType.OPTIONS) {
      const docList = this.documentsService.getDocuments();
      negotiation.demoDocument = docList[0] as DemoDocument;
      negotiation.optionDocuments = this.negotiationOptionsService.getNegotiationOptions();
    }
    if (!negotiation.negotiationId) {
      this.saveNegotiation(negotiation);
    } else {
      this.updateNegotiation(negotiation);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  checkAction(flowAction, checked) {
    this.flowActions = this.flowActions.map((fa) => {
      if (fa.flowActionId === flowAction.flowActionId) {
        fa.enable = checked;
      }
      return fa;
    });
  }

  saveNegotiation(negotiation: Negotiation) {
    this.negotiationService.saveNegotiation(negotiation).subscribe(
      (negotiationId) => {
        this.updateNegotiation({
          ...negotiation,
          negotiationId,
        });
        this.attachmentsService
          .postFilesByNegotiation(negotiationId, this.files)
          .then(() => {
            this.toggleRefresh(negotiation);
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('New Negotiation added successfully.', null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
            this.dialogRef.close();
          })
          .catch((err) => {
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Error: ' + err, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
      },
      (error) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + error, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  updateNegotiation(negotiation: Negotiation) {
    const neg = {
      ...negotiation,
      negotiationFlowActions: this.flowActions.map((fa) => {
        fa.negotiationId = negotiation.negotiationId;
        return fa;
      }),
    };
    this.negotiationService.updateNegotiation(neg).subscribe(
      () => {
        this.attachmentsService
          .postFilesByNegotiation(neg.negotiationId, this.files)
          .then(() => {
            this.toggleRefresh(neg);
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Negotiation updated successfully.', null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
            this.dialogRef.close();
          })
          .catch((err) => {
            this.snackBar.open('Error: ' + err, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
      },
      (error) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + error, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  updateContactsSelection(contactOption) {
    this.negotiationCreateForm.get('contactId').setValue(contactOption.key);
  }

  ngOnInit(): void {
    if (!this.negotiation.negotiationId) {
      this.negotiationOptionsService.initDefault();
    }
    this.attachmentsService.$files.subscribe((files) => (this.files = files));
    if (!this.negotiation.negotiationId) {
      this.documentsService.initDefault();
    }
    this.contactsService.contacts.subscribe((values) => {
      this.contacts = values.map((x) => ({
        key: x.contactId,
        name: `${x.lastName} ${x.name}`,
      }));
    });
  }

  isOptionsNegotiation() {
    return this.negotiationCreateForm.get('negotiationType').value === '0';
  }

  isARNegotiation() {
    return this.negotiationCreateForm.get('negotiationType').value === '1';
  }

  is3PNegotiation() {
    return this.negotiationCreateForm.get('negotiationType').value === '2';
  }

  onFileSelected(file) {
    this.files = [...this.files, file];
  }

  onRemoveFile(file) {
    this.files = this.files.filter((f) => f !== file);
  }

  onDownloadFile(file) {
    this.attachmentsService
      .getAttachmentForNegotiation(this.negotiation.negotiationId, file.id)
      .then((data) => downloadFileResponse(data, file.originalName));
  }

  private toggleRefresh(negotiation: Negotiation) {
    const slotIdToUse = negotiation && negotiation.slotId ? negotiation.slotId : 0;
    this.negotiationService.getData(); // This method refreshes the negotiations list in the service.
    this.negotiationService.getPaginatedData({ slot: slotIdToUse, mineOnly: false }, false);
    this.slotsService.getData();
  }
}
