<section
  class="subscription-card-component"
  [class.selected]="isSelected"
  (click)="onSelection()"
  [class.from-settings]="fromSettings"
>
  <div class="card-header bold">
    {{ subscriptionInfo.title }}
  </div>
  <div class="card-body">
    <p>{{ subscriptionInfo.description }}</p>
    <p>
      <span class="sub-price">${{ subscriptionInfo.price }}</span>
      <span>/{{ subscriptionInfo.period }}</span>
      <br />
      <!--<span>{{ subscriptionInfo.annually }}</span>
      <br />-->
    </p>
    <p class="extra-info">{{ subscriptionInfo.users }}</p>
    <p class="extra-info">{{ subscriptionInfo.onboardingBonus }}</p>
    <p class="extra-info">{{ subscriptionInfo.senderOnboarding }}</p>
  </div>
  <div class="upgradeable-element" *ngIf="fromSettings && upgradeable && !isSelected">
    <button mat-button (click)="onChoose()">Choose</button>
  </div>
</section>
