import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ContactSuscription } from 'app/model/data-ingestor/contact-suscription';
import { SubscribingContact } from 'app/model/data-ingestor/subscribing-contact';
import { DataIngestionService } from 'app/services/data-ingestion.service';
import { IntermediateService } from 'app/services/intermediate.service';
import { LanguageService } from 'app/services/language.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-contact-suscription',
  templateUrl: './contact-suscription.component.html',
  styleUrls: ['./contact-suscription.component.css'],
})
export class ContactSuscriptionComponent implements OnInit {
  public emailRegexp =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  public contactCreateForm: FormGroup;
  public contact: SubscribingContact = new SubscribingContact(null);
  public allLangs: { [key: string]: string } = {};
  public companyFlowConfiguration;
  public phoneValue;
  public companyId = null;

  constructor(
    private uiService: UIService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private intermediateService: IntermediateService,
    private dataIngestionService: DataIngestionService,
    private languageService: LanguageService,
    private translateService: TranslateService
  ) {
    this.uiService.toggleGlobalSpinner(true);
    const companyId = this.route.snapshot.params['companyId'];
    this.companyId = companyId;
    this.intermediateService.flowConfiguration.subscribe((value) => {
      this.companyFlowConfiguration = value;
      this.buildContactCreateForm(this.contact);
      this.uiService.toggleGlobalSpinner(false);
    });
    this.intermediateService.getDefaultCompanyFlowConfiguration(companyId);
  }

  getBtnStyleColor() {
    if (this.companyId == 19) {
      return '#0db556';
    } else {
      return this.companyFlowConfiguration.primaryColor;
    }
  }

  ngOnInit(): void {
    this.loadLanguages();
    if (this.companyId == 19) {
      this.translateService.use('es-MX');
    }
  }

  buildContactCreateForm(contact: SubscribingContact) {
    this.contactCreateForm = this.formBuilder.group({
      contactId: contact.contactId,
      companyId: this.companyId,
      name: contact.name,
      lastName: contact.lastName,
      language: this.companyId == 19 ? 'es-ES' : contact.language,
      countryCode: contact.countryCode,
      localPhone: contact.localPhone,
      //phone: contact.phone,
      email: contact.email,
    });
  }

  public onPhoneChanged(value: string): void {
    this.phoneValue = value;
  }

  public formIsValid(): boolean {
    return !!(this.contactCreateForm.valid && this.phoneValue);
  }

  onSubmit(): void {
    this.contactCreateForm.value.phone = this.phoneValue;
    const suscription = new ContactSuscription(this.contactCreateForm.value);
    suscription.slots = this.dataIngestionService.getCheckedSlots();
    this.uiService.toggleGlobalSpinner(true);
    this.dataIngestionService.subscribeContact(suscription).subscribe(
      (result) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Suscribed successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.redirectToUrl();
      },
      (error) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + error, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  private redirectToUrl() {
    if(this.companyId == 19) {
      setTimeout(() => {
        window.parent.postMessage(this.companyFlowConfiguration.redirectUrl, '*');
      }, 3000);
    } else {
      setTimeout(() => {
        window.location.href = this.companyFlowConfiguration.redirectUrl;
      }, 3000);
    }
  }

  public onCheckedSlot(event) {}

  private async loadLanguages() {
    const languages = await this.languageService.getLanguagesAnonymously(this.companyId);
    this.allLangs = Object.fromEntries(languages.map((x) => [x.languageCode, x.name]));
  }
}
