import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-negotiation-progress',
  templateUrl: './negotiation-progress.component.html',
  styleUrls: ['./negotiation-progress.component.css'],
})
export class NegotiationProgressComponent implements OnInit {
  @Input() values = [];
  @Input() colors = ['#09cd09', '#d3d34f', '#ffa42f', '#5e2121', '#ff5a5a'];
  @Input() labels = [];

  constructor() {}

  ngOnInit(): void {}
}
