<div class="intermediate-container">
  <div *ngIf="showFiles" class="files-container" (click)="onHideFiles()">
    <div (click)="preventClosing($event)" class="files">
      <div class="files-title">
        <h2>Attachments</h2>
        <mat-icon (click)="onHideFiles()">close</mat-icon>
      </div>
      <app-files-list [files]="files" [onlyDownloads]="1" (downloadIt)="onDownloadFile($event)"></app-files-list>
    </div>
  </div>
  <div class="intermediate-component">
    <div class="intermediate-header" [style.background]="company?.primaryColor">
      <div class="header-image-container">
        <img *ngIf="company && company.iconUrl" [src]="company.iconUrl" style="height: 2.6rem" />
      </div>
      <span class="attachments-icon" *ngIf="files && files.length > 0">
        <mat-icon *ngIf="allowed" class="envelop-icon" [style.color]="company?.secondaryColor" (click)="onShowFiles()"
          >file_present</mat-icon
        >
        <span *ngIf="unreadMessages[negotiationId]" class="messages-counter" (click)="openMessages()">{{
          files.length
        }}</span>
      </span>
      <span style="position: relative">
        <mat-icon *ngIf="allowed" class="envelop-icon" [style.color]="company?.secondaryColor" (click)="openMessages()"
          >question_answer</mat-icon
        >
        <span *ngIf="unreadMessages[negotiationId]" class="messages-counter" (click)="openMessages()">{{
          unreadMessages[negotiationId]
        }}</span>
      </span>
    </div>
    <div class="intermediate-content">
      <router-outlet *ngIf="company && allowed"></router-outlet>

      <div class="form-fields-container" *ngIf="company && company.requiresAuthCode && !allowed">
        <mat-form-field appearance="fill" class="regular-field">
          <mat-label>Enter Code</mat-label>
          <input matInput [(ngModel)]="authCode" type="text" required class="int-input" />
        </mat-form-field>
        <div class="buttons-container">
          <button class="sgnd-btn sgnd-btn-ok" type="submit" (click)="submitCode()">Enter</button>
        </div>
      </div>
    </div>
    <div class="intermediate-footer" [style.background]="company?.primaryColor"></div>
  </div>
</div>
