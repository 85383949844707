import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateButton } from 'app/model/whatsapp-option-template-button';
import { WhatsappOptionTemplateContent } from 'app/model/whatsapp-option-template-content';
import { LanguageService } from 'app/services/language.service';
import { StudioService } from 'app/services/studio.service';
import { EditWhatsappTemplateButtonDialogComponent } from '../edit-whatsapp-template-button-dialog/edit-whatsapp-template-button-dialog.component';
import { EditWhatsappTemplateLanguageDialogComponent } from '../edit-whatsapp-template-language-dialog/edit-whatsapp-template-language-dialog.component';


@Component({
  selector: 'app-edit-whatsapp-template',
  templateUrl: './edit-whatsapp-template.component.html',
  styleUrls: ['./edit-whatsapp-template.component.css'],
})
export class EditWhatsappTemplateComponent implements OnInit {

  templateCreateForm: FormGroup;
  allLangs: { [key: string]: string } = {};
  displayedButtonsColumns = ['text', 'url', 'actions'];
  displayedContentColumns = ['title', 'content', 'actions'];
  public whatsappOptionTemplate: WhatsappOptionTemplate;
  public buttonsDataSource: MatTableDataSource<WhatsappOptionTemplateButton>;
  public contentDataSource: MatTableDataSource<WhatsappOptionTemplateContent>;
  public showContentSid: boolean;

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<EditWhatsappTemplateComponent>,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private studioService: StudioService,
    private languageService: LanguageService
  ) {
    this.whatsappOptionTemplate = new WhatsappOptionTemplate();
    this.buildTemplateCreateForm(this.whatsappOptionTemplate);
  }


  ngOnInit(): void {
    this.buttonsDataSource = new MatTableDataSource<WhatsappOptionTemplateButton>();
    this.contentDataSource = new MatTableDataSource<WhatsappOptionTemplateContent>();
    this.loadLanguages();
  }

  private buildTemplateCreateForm(whatsappOptionTemplate: WhatsappOptionTemplate) {
    this.templateCreateForm = this.formBuilder.group({
      templateCode: whatsappOptionTemplate.templateCode,
      templateLabel: whatsappOptionTemplate.templateLabel,
      variablesQty: whatsappOptionTemplate.variablesQty,
      contentSid: whatsappOptionTemplate.contentSid
    });
  }

  onAddContent() {
    const availableLanguagesList = Object.keys(this.allLangs)
      .filter(x => !this.whatsappOptionTemplate.whatsappOptionTemplateContents.some(y => y.language === x));
    if (availableLanguagesList.length == 0) {
      this.snackBar.open(`No more languages are available.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const langDialog = this.dialog.open(EditWhatsappTemplateLanguageDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: this.whatsappOptionTemplate
    });
    langDialog.afterClosed().subscribe(() => {
      this.contentDataSource.data = this.whatsappOptionTemplate.whatsappOptionTemplateContents;
    })
  }

  onRemoveContent(element) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Do you want to delete the content for the selected language?`,
      },
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        const index = this.whatsappOptionTemplate.whatsappOptionTemplateContents.indexOf(element);
        if (index !== -1) {
          this.whatsappOptionTemplate.whatsappOptionTemplateContents.splice(index, 1);
          this.contentDataSource.data = this.whatsappOptionTemplate.whatsappOptionTemplateContents;
        }
      }
    });
  }

  onAddButton() {
    if (this.whatsappOptionTemplate.whatsappOptionTemplateButtons.length == 2) {
      this.snackBar.open(`A max of 2 buttons is allowed per template.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const langDialog = this.dialog.open(EditWhatsappTemplateButtonDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: this.whatsappOptionTemplate
    });
    langDialog.afterClosed().subscribe(() => {
      this.buttonsDataSource.data = this.whatsappOptionTemplate.whatsappOptionTemplateButtons;
    });
  }

  onRemoveButton(element) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        message: `Do you want to delete the selected button?`,
      },
    }).afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        const index = this.whatsappOptionTemplate.whatsappOptionTemplateButtons.indexOf(element);
        if (index !== -1) {
          this.whatsappOptionTemplate.whatsappOptionTemplateButtons.splice(index, 1);
          this.buttonsDataSource.data = this.whatsappOptionTemplate.whatsappOptionTemplateButtons;
        }
      }
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

  onSubmit() {
    this.whatsappOptionTemplate.templateCode = this.templateCreateForm.value.templateCode;
    this.whatsappOptionTemplate.templateLabel = this.templateCreateForm.value.templateLabel;
    this.whatsappOptionTemplate.variablesQty = this.templateCreateForm.value.variablesQty;
    this.whatsappOptionTemplate.contentSid = this.templateCreateForm.value.contentSid;
    this.studioService.createWhatsappOptionTemplateForReview(this.whatsappOptionTemplate).subscribe(templateId => {
      this.snackBar.open(`Template successfully created and sent for review.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
      this.dialogRef.close(true);
    }, (errorResponse) => {
      this.snackBar.open(`Error: ${errorResponse.error}`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    });
  }

  private async loadLanguages() {
    const languages = await this.languageService.getLanguages();
    this.allLangs = Object.fromEntries(languages.map(x => [x.languageCode, x.name]));
  }
}
