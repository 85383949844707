import { LateralListElement } from './lateral-list-element';

export class LateralListSection {
  isDataInjestor?: boolean;
  title: string;
  icon: string;
  rightIcon: string;
  elements: LateralListElement[];
  extraAction = (...params) => {};
}
