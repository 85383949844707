import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'app/model/company';
import { CompanyPermission } from 'app/model/company-permission';
import { CompanyPermissionsService } from 'app/services/company-permissions.service';
import { UIService } from 'core/services/ui.service';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-company-enterprise-edit-permissions',
  templateUrl: './company-enterprise-edit-permissions.component.html',
  styleUrls: ['./company-enterprise-edit-permissions.component.css']
})
export class CompanyEnterpriseEditPermissionsComponent implements OnInit {

  public availablePermissionsDataSource: CompanyPermission[] = [];
  public assignedPermissionsDataSource: CompanyPermission[] = [];

  assignedPermissionsSelection = new SelectionModel<CompanyPermission>(true, []);
  availablePermissionsSelection = new SelectionModel<CompanyPermission>(true, []);
  public company: Company;

  public displayedColumns = ['Selection', 'PermissionName', 'PermissionDescription'];

  constructor(
    public dialogRef: MatDialogRef<CompanyEnterpriseEditPermissionsComponent>,
    private companyPermissionsService: CompanyPermissionsService,
    private snackBar: MatSnackBar,
    public uiService: UIService,
    @Inject(MAT_DIALOG_DATA) data: Company
  ) {
    this.company = data;
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this.loadData();
  }

  loadData() {
    const observableAllPermissions = this.companyPermissionsService.getAll();
    const observableCurrentPermissions = this.companyPermissionsService.getByCompanyId(this.company.companyId);
    forkJoin([
      observableAllPermissions, observableCurrentPermissions
    ]).subscribe({
      next: (res: Array<any>) => {
        const allPermissions: Array<CompanyPermission> = res[0];
        const assignedPermissions = res[1];
        this.availablePermissionsDataSource = allPermissions.filter(p => !assignedPermissions.map(ap => ap.id).includes(p.id));
        this.assignedPermissionsDataSource = assignedPermissions;
      },
      error: (err) => {
        console.error(err);
      },
    });
  };

  onAddPermissions() {
    if (this.availablePermissionsSelection.isEmpty()) {
      this.snackBar.open(`No Permissions have been selected.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const permissions = this.availablePermissionsSelection.selected;
    const permissionIds = permissions.map((c) => c.id);
    this.uiService.toggleGlobalSpinner(true);
    this.companyPermissionsService.addPermissions(this.company.companyId, permissionIds).subscribe(
      () => {
        this.availablePermissionsSelection.clear();
        this.loadData();
        this.uiService.toggleGlobalSpinner(false);
      },
      (err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  onRemovePermissions() {
    if (this.assignedPermissionsSelection.isEmpty()) {
      this.snackBar.open(`No Permissions have been selected.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const permissions = this.assignedPermissionsSelection.selected;
    const permissionIds = permissions.map((c) => c.id);
    this.uiService.toggleGlobalSpinner(true);
    this.companyPermissionsService.removePermissions(this.company.companyId, permissionIds).subscribe(
      () => {
        this.assignedPermissionsSelection.clear();
        this.loadData();
        this.uiService.toggleGlobalSpinner(false);
      },
      (err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  isAllAssignedSelected() {
    const numSelected = this.assignedPermissionsSelection.selected.length;
    const numRows = this.assignedPermissionsDataSource.length;
    return numSelected === numRows;
  }

  toggleAllAssignedRows() {
    if (this.isAllAssignedSelected()) {
      this.assignedPermissionsSelection.clear();
      return;
    }
    this.assignedPermissionsSelection.select(...this.assignedPermissionsDataSource);
  }

  isAllAvailableSelected() {
    const numSelected = this.availablePermissionsSelection.selected.length;
    const numRows = this.availablePermissionsDataSource.length;
    return numSelected === numRows;
  }

  toggleAllAvailableRows() {
    if (this.isAllAvailableSelected()) {
      this.availablePermissionsSelection.clear();
      return;
    }
    this.availablePermissionsSelection.select(...this.availablePermissionsDataSource);
  }

  onClose() {
    this.dialogRef.close();
  }

}
