import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-subscription-modal',
  templateUrl: './subscription-modal.component.html',
  styleUrls: ['./subscription-modal.component.css'],
})
export class SubscriptionModalComponent implements OnInit {
  message = '';
  constructor(
    private snackBar: MatSnackBar,
    public dialog: MatDialogRef<SubscriptionModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      currentSubscription: any;
      choosenSubscription: any;
      onConfirm: () => {};
    }
  ) {
    if (data.currentSubscription) {
      if (data.choosenSubscription?.subscriptionType > data.currentSubscription?.subscriptionType) {
        this.message = `Are you sure you want to upgrade to the ${data.choosenSubscription.title} plan`;
      } else {
        this.message = `Are you sure you want to downgrade to the ${data.choosenSubscription.title} plan`;
      }
    } else {
      this.message = `Are you sure you want to subscribe to the ${data.choosenSubscription.title} plan`;
    }
  }

  ngOnInit(): void {}

  onClose() {
    this.dialog.close();
  }
}
