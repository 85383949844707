import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { NegotiationOption } from 'app/model/negotiation-option';
import { FlowConfiguration } from 'app/model/flow-configuration';
import { NegotiationDto } from 'app/model/negotiation';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AccountsReceivableDocument } from 'app/model/documents/accounts-receivable-document';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { AttachmentsApiService } from './attachments-api.service';

@Injectable({
  providedIn: 'root',
})
export class IntermediateService {
  $negotiationOptions = new BehaviorSubject<NegotiationOption[]>([]);
  $slotOptions = new BehaviorSubject<NegotiationOption[]>([]);
  $negotiationItself = new BehaviorSubject<NegotiationDto>(null);
  $files = new BehaviorSubject([]);
  public flowConfiguration = new BehaviorSubject<FlowConfiguration>(null);
  public negotiations = new BehaviorSubject<NegotiationDto>(null);
  private negotiationId: number = null;
  private authCode: string = null;
  private baseURL: string;

  constructor(
    private httpClient: HttpClient,
    private handler: HttpBackend,
    private matDialog: MatDialog,
    private attachmentsService: AttachmentsApiService
  ) {
    this.httpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Negotiations';
  }

  getDefaultCompanyFlowConfiguration(companyId: number) {
    this.httpClient
      .post<FlowConfiguration>(`${this.baseURL}/default-flow-configuration/`, { companyId })
      .subscribe((res) => {
        this.flowConfiguration.next(res);
      });
  }

  clearFiles() {
    this.$files.next([]);
  }

  getFlowConfiguration(negotiationId?: number): Observable<FlowConfiguration> {
    this.negotiationId = negotiationId;
    this.httpClient
      .post<FlowConfiguration>(`${this.baseURL}/flowconfiguration/`, { negotiationId })
      .subscribe((res) => {
        this.flowConfiguration.next(res);
      });

    return this.flowConfiguration;
  }

  getFiles(negotiationId) {
    this.attachmentsService.getAttachmentsByNegotiation(negotiationId).subscribe((files: any[]) => {
      this.$files.next(files);
    });
  }

  getData(negotiationId: number, authCode: string) {
    this.authCode = authCode;
    this.getExpandedNegotiation(negotiationId, authCode).subscribe((res) => {
      this.$slotOptions.next(res.slot?.slotOptions);
      this.$negotiationOptions.next(res.optionDocuments);
      this.$negotiationItself.next(res);
      this.negotiations.next(res);
    });
  }

  getExpandedNegotiation(negotiationId: number, authCode: string): Observable<NegotiationDto> {
    return this.httpClient.post<NegotiationDto>(`${this.baseURL}/expanded/`, {
      negotiationId,
      authCode,
    });
  }

  selectOption(option: NegotiationOption, redirectUrl: string, isSlotOption = false) {
    this.matDialog
      .open(ConfirmDialogComponent, {
        data: {
          message: `Are you sure you want to select ${option.description}`,
          titleBackgroundColor: this.flowConfiguration.value.primaryColor,
          titleTextColor: this.flowConfiguration.value.secondaryColor,
          buttonBackgroundColor: this.flowConfiguration.value.primaryColor,
          buttonTextColor: this.flowConfiguration.value.secondaryColor
        },
      })
      .afterClosed()
      .subscribe((confirmed) => {
        if (confirmed) {
          this.httpClient
            .post<NegotiationDto>(`${this.baseURL}/demo/ready_to_continue`, {
              negotiationId: this.negotiationId,
              authCode: this.authCode,
              optionId: option.id,
              isSlotOption: isSlotOption,
            })
            .subscribe((res) => {
              this.moveToRedirectUrl();
            });
        }
      });
  }

  public moveToRedirectUrl() {
    window.location.href = this.flowConfiguration.value.redirectUrl;
  }

  payAccounts(accounts: Array<AccountsReceivableDocument>): Observable<any> {
    const docIds = accounts.map((i) => i.id);
    return this.httpClient.post<NegotiationDto>(`${this.baseURL}/accounts_receivable/ready_to_continue`, {
      negotiationId: this.negotiationId,
      authCode: this.authCode,
      documentIds: docIds,
    });
  }
}
