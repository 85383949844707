import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable, Optional } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export class JsonParser {
  parse(text: string): any {
    return JSON.parse(text);
  }
}

export class CustomJsonParser implements JsonParser {
  parse(text: string): any {
    if (text != null && text.length !== 0) {
      return JSON.parse(text, customReviver);
    } else {
      return text;
    }
  }
}

const customReviver = (key: string, value: any) => {
  if (typeof value === 'string') {
    const isDate = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:.\d*)?)(Z|([+-])(\d{2}):(\d{2}))$/.test(value);
    if (isDate) {
      return new Date(value);
    }
  }
  return value;
};

@Injectable()
export class JsonHttpInterceptor implements HttpInterceptor {
  constructor(@Optional() private jsonParser: JsonParser) {}

  intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return httpRequest.responseType === 'json' ? this.handleJsonResponses(httpRequest, next) : next.handle(httpRequest);
  }

  private handleJsonResponses(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(httpRequest.clone({ responseType: 'text' })).pipe(map((event) => this.parseResponse(event)));
  }

  private parseResponse(event: HttpEvent<any>): HttpEvent<any> {
    return event instanceof HttpResponse ? event.clone({ body: this.jsonParser.parse(event.body) }) : event;
  }
}
