import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceService {

  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/maintenance';
  }

  public deleteCcpLinks(deleteAll = false): Observable<any> {
    const url = this.baseURL + '/ccplink-cleanup' + (deleteAll ? '/all' : '');
    return this.httpClient.post(url, null);
  } 
}
