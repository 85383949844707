import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Company } from 'app/model/company';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Companies';
  }

  public createCompanyEnterpriseSubscription(name: string, userFullName: string, userCountry: string, userEmail: string, userPhone: string): Observable<any> {
    const body = {
      name,
      userFullName,
      userCountry,
      userEmail,
      userPhone
    };
    return this.http.post<any>(`${this.baseURL}/create-company-enterprise`, body);
  }

  public getAll(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.baseURL}`);
  }

  public getAllExpandedWithSubscriptions(): Observable<Company[]> {
    return this.http.get<Company[]>(`${this.baseURL}/expanded/subscriptions`);
  }

}
