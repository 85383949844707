import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

/**
 * data: {
 *      message: string,
 *      titleTextColor: string,
 *      titleBackgroundColor: string,
 *      buttonTextColor: string,
 *      buttonBackgroundColor: string,
 *      altButtonTextColor: string,
 *      altButtonBackgroundColor: string
 *   }
 */
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.css'],
})
export class ConfirmDialogComponent implements OnInit {
  public message: string;
  public titleBackgroundColor: string;
  public titleTextColor: string;
  public buttonBackgroundColor: string;
  public buttonTextColor: string;
  public altButtonBackgroundColor: string;
  public altButtonTextColor: string;

  constructor(public dialog: MatDialogRef<ConfirmDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.message = data.message;
    this.titleBackgroundColor = data.titleBackgroundColor;
    this.titleTextColor = data.titleTextColor;
    this.buttonBackgroundColor = data.buttonBackgroundColor;
    this.buttonTextColor = data.buttonTextColor;
    this.altButtonBackgroundColor = data.altButtonBackgroundColor;
    this.altButtonTextColor = data.altButtonTextColor;
  }

  closeDialog(): void {
    this.dialog.close(false);
  }
  confirm(): void {
    this.dialog.close(true);
  }

  ngOnInit() {}
}
