import { Injectable } from '@angular/core';
import { Flow } from 'app/model/flow';
import { NegotiationType } from 'app/model/negotiation-type';
import { Slot } from 'app/model/slot';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { UserService } from 'src/core/services/user.service';
import { SlotsApiService } from './slots-api.service';

@Injectable()
export class SlotsService {
  public slots = new BehaviorSubject<Slot[]>([]);

  constructor(private userService: UserService, private slotsApiService: SlotsApiService) {
    this.userService.currentUserAsync().subscribe((currentUser) => {
      this.getData();
    });
  }

  public getFilteredSlots(flowId: number) {
    const filteredSlots = this.slots.value.filter((s) => !!s.negotiationIds.find((n) => (<any>n).flowId === flowId));
    return filteredSlots;
  }

  getData() {
    this.slotsApiService.getSlotsList().subscribe((res) => {
      this.slots.next(res.map((x) => new Slot(x)));
    });
  }

  public createSlot(slot: Slot): Observable<any> {
    return this.slotsApiService.saveSimplifiedSlot(slot);
  }

  public updateSlot(slot: Slot): Observable<any> {
    return this.slotsApiService.updateSlot(slot);
  }

  public deleteSlot(slotId: number): Observable<any> {
    return this.slotsApiService.deleteSlot(slotId);
  }

  public cancelSlots(slotsIds: number[]): Observable<any> {
    return this.slotsApiService.cancelSlots(slotsIds);
  }

  public deleteSlots(slotsIds: number[]): Observable<any> {
    const fork = {};
    slotsIds.forEach((sid) => {
      fork[sid] = this.deleteSlot(sid);
    });
    return forkJoin(fork);
    // TODO this should be the one return this.slotsApiService.deleteSlots(slotsIds);
  }

  public startSlot(slotId: number, statuses: any): Observable<any> {
    return this.slotsApiService.startSlot(slotId, statuses);
  }

  public stopSlot(slotId: number): Observable<any> {
    return this.slotsApiService.stopSlot(slotId);
  }

  assignContactsToSlot(contactIds = [], slotId) {
    return this.slotsApiService.assignContactsToSlot(contactIds, slotId);
  }

  assignNegotiationsToSlot(negotiationIds = [], slotId) {
    return this.slotsApiService.assignNegotiationsToSlot(negotiationIds, slotId);
  }

  calculateExecutionCost(request): Observable<number> {
    return this.slotsApiService.calculateExecutionCost(request);
  }
}
