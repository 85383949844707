<div class="title">
    {{slot.slotName}}
    <mat-icon (click)="onClose()">close</mat-icon>
</div>
<section class="negotiations-chart">
    <ngx-charts-advanced-pie-chart
      [view]="view"
      [scheme]="colorScheme"
      [results]="single"
      [label]="'Total Negotiations'"
      >
    </ngx-charts-advanced-pie-chart>
</section>