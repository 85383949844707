<section class="studio-workplace" *ngIf="expandedFlow; else nonFlow">
  <div class="studio-actions-container">
    <div
      [name]="actionOption"
      *ngFor="let actionOption of actionOptions"
      class="action-container"
      draggable="true"
      (dragstart)="onDragStart($event)"
      (drag)="onDrag($event)"
      (dragend)="onDragEnd($event)"
    >
      <mat-icon *ngIf="actionIcons[actionOption] !== whatsappIcon && actionIcons[actionOption] !== whatsappTemplateIcon"
        class="action-icon">{{
        actionIcons[actionOption]
      }}</mat-icon>
      
      <whatsapp-icon *ngIf="actionIcons[actionOption] === whatsappIcon" className="action-icon"></whatsapp-icon>
      <whatsapp-icon *ngIf="actionIcons[actionOption] === whatsappTemplateIcon"
        [isLegacy]="false" className="action-icon"></whatsapp-icon>
    </div>
  </div>
  <app-dropable-canvas style="display: flex; flex: 1"></app-dropable-canvas>
</section>
<ng-template #nonFlow>
  <section class="studio-workplace disabled">
    <div class="studio-actions-container">
      <div *ngFor="let actionOption of actionOptions" class="action-container">
        <mat-icon *ngIf="actionIcons[actionOption] !== whatsappIcon && actionIcons[actionOption] !== whatsappTemplateIcon"
          class="action-icon">{{
          actionIcons[actionOption]
        }}</mat-icon>
        <whatsapp-icon *ngIf="actionIcons[actionOption] === whatsappIcon" className="action-icon"></whatsapp-icon>
        <whatsapp-icon *ngIf="actionIcons[actionOption] === whatsappTemplateIcon"
        [isLegacy]="false" className="action-icon"></whatsapp-icon>
      </div>
    </div>
    <app-dropable-canvas style="display: flex; flex: 1"></app-dropable-canvas>
  </section>
</ng-template>
