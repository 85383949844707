import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'statusParse',
})
export class StatusParsePipe implements PipeTransform {
  transform(value: unknown, ...args: unknown[]): unknown {
    let parsed = null;
    if (typeof value === 'string') {
      parsed = value
        .toLowerCase()
        .split('_')
        .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
        .join(' ');
    }
    return parsed ? parsed : value;
  }
}
