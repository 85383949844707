import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MonitorNegotiationTotals } from 'app/model/monitor-negotiation-totals';
import { MonitorSlot } from 'app/model/monitor-slot';
import { PaginatedDataResponse } from 'app/model/pagination';
import { PaginatedSlotsDataRequest } from 'app/model/slot';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MonitorApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api';
  }

  getSlotsList(filter: PaginatedSlotsDataRequest): Observable<PaginatedDataResponse<MonitorSlot>> {
    return this.httpClient.post<PaginatedDataResponse<MonitorSlot>>(`${this.baseURL}/Slots/monitor`, filter);
  }

  getNegotiationsList(
    slotId: number,
    ownedOnly: boolean,
    pageSize: number,
    pageNumber: number,
    sortedBy: string,
    sortDirection: string,
    textFilter: string
  ): Observable<MonitorNegotiationTotals> {
    const body = {
      slotId,
      ownedOnly,
      pageSize,
      pageNumber,
      textFilter,
      sortedBy,
      sortDirection,
    };
    return this.httpClient.post<MonitorNegotiationTotals>(`${this.baseURL}/Slots/monitor/slot-details`, body);
  }

  exportSlotDetails(fileName, params) {
    return this.httpClient.post(`${this.baseURL}/Slots/monitor/slot-details-export/${fileName}`, params, {
      responseType: 'text',
    });
  }
}
