<div class="nav-bar">
  <div class="nav-logo-container">
    <div class="nav-logo pointer" routerLink="/"></div>
  </div>

  <!-- <app-global-search-bar class="search-bar" *ngIf="auth.user$ | async as user"></app-global-search-bar> -->
  <span class="spacer"></span>
  <div class="company-user-container">
    <app-nav-company-widget></app-nav-company-widget>
    <a (click)="navigateToDocumentation()" class="help-btn">
      <mat-icon class="help-icon" [matTooltip]="'Read Documentation regarding SigneDeal\'s API'" matTooltipPosition="below">help</mat-icon>
    </a>
    <a (click)="showContactPopup()" class="help-btn">
      <mat-icon class="help-icon" [matTooltip]="'Contact Support'" matTooltipPosition="below">mail</mat-icon>
    </a>
    <app-nav-user-widget></app-nav-user-widget>
  </div>
</div>
