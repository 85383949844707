import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { STUDIO_ACTION_OPTIONS, STUDIO_ICONS } from 'app/model/studio-action-options';

@Component({
  selector: 'app-canvas-node',
  templateUrl: './canvas-node.component.html',
  styleUrls: ['./canvas-node.component.css'],
})
export class CanvasNodeComponent implements OnInit {
  @Input() node: any;
  @Input() selected: boolean;
  @Input() isConnected: boolean;

  @Output() nodeSelected = new EventEmitter();
  @Output() addLink = new EventEmitter();
  @Output() deleteNode = new EventEmitter();

  actionIcons = STUDIO_ICONS;
  startAction = STUDIO_ACTION_OPTIONS.START;
  whatsappAction = STUDIO_ACTION_OPTIONS.WHATSAPP;
  whatsappTemplateAction = STUDIO_ACTION_OPTIONS.WHATSAPP_TEMPLATE;

  draggingOver = false;
  constructor() {}

  ngOnInit(): void {}

  onDragStart(event) {
    event.dataTransfer.setData('flowActionId', this.node.flowActionId);
  }

  onSelected() {
    this.nodeSelected.emit(this.node);
  }

  onDropLink(event) {
    event.stopPropagation();
    const dragType = event.dataTransfer.getData('dragType');
    this.draggingOver = false;
    if (!this.selected && dragType === 'link') {
      this.addLink.emit(this.node);
    }
  }

  onDragOver(draggingOver) {
    this.draggingOver = draggingOver;
  }

  onDrag(event) {
    /*this.node.position.top = event.clientY - event.toElement.offsetTop;
    this.node.position.left = event.clientX - event.toElement.offsetLeft;*/
  }

  dragRelationshipStart(event) {
    event.stopPropagation();
    event.dataTransfer.setData('dragType', 'link');
  }

  deadDrag(event) {
    event.stopPropagation();
    event.dataTransfer.setData('dragType', 'dead');
  }

  deleteNodeAction() {
    this.deleteNode.emit(this.node);
  }
}
