<!--
<div class="container" *ngIf="auth.isLoading$ | async">
    <app-loading></app-loading>
</div>
-->

<div #loaded class="sd-tab-nav" *ngIf="showNavBar">
  <div class="menus-container">
    <a
      *ngFor="let menu of menus"
      class="menu-item"
      [ngClass]="isSelected(menu.key, menus) ? 'sd-menu menu-selected' : 'sd-menu'"
      [routerLink]="'/' + menu.key"
    >
      <mat-icon class="menu-icon">{{ menu.icon }}</mat-icon
      >{{ menu.title }}</a
    >
  </div>
  <div class="sd-extras">
    <app-negotiation-counter></app-negotiation-counter>
  </div>
</div>
