import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FlowActionMessageApiService } from 'app/services/flow-action-message-api.service';
import { ActionMessage } from 'app/model/flow-action-call';

@Component({
  selector: 'app-call-preview',
  templateUrl: './call-preview.component.html',
  styleUrls: ['./call-preview.component.css'],
})
export class CallPreviewComponent implements OnInit {
  constructor(
    private famService: FlowActionMessageApiService,
    private dialogRef: MatDialogRef<CallPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) private data: ActionMessage
  ) {}

  ngOnInit(): void {}

  public onContinue() {
    this.famService.runCallPreview(this.data.content, this.data.language, this.data.extraContent).subscribe(
      (result) => {
        this.dialogRef.close();
      },
      (err) => {
        this.dialogRef.close();
      }
    );
  }

  public onCancel() {
    this.dialogRef.close();
  }
}
