<div class="settings-card-container" *ngIf="user">
  <mat-card class="custom-card">
    <mat-card-header class="custom-card-header">
      <div>
        <img mat-card-sm-image [src]="user?.picture" />
      </div>
      <mat-card-title [style.display]="'flex'" class="break-word">
        <mat-icon matTooltip="Verified Email" [style.color]="'green'" *ngIf="user.email_verified"
          >verified_user</mat-icon
        >{{ user.name }}</mat-card-title
      >
      <mat-card-subtitle class="break-word">Email: {{ user.email }}</mat-card-subtitle>
      <mat-card-subtitle class="break-word">Last update: {{ user.updated_at | date: 'YYYY MMM dd - HH:mm' }}</mat-card-subtitle>
    </mat-card-header>
    <div class="user-fields-container">
      <button class="sgnd-btn sgnd-btn-ok" (click)="onPasswordChange()">Change password</button>
    </div>
  </mat-card>
  <div>&nbsp;</div>
</div>
