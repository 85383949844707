<div class="sample-container">
  <div [style.background-color]="primaryColor" class="sample-header">
    <a [href]="redirectUrl" target="_blank">
      <img [src]="imgUrl" class="sample-image" />
    </a>
    <div [style.color]="secondaryColor">Header</div>
  </div>
  <div class="sample-space"></div>
  <div class="sample-buttons-container">
    <div [style.background-color]="primaryColor" [style.color]="secondaryColor" class="sample-button">Button</div>
  </div>
</div>
