import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class WhatsappMessageApiService {
  baseURL: string;

  constructor(private http: HttpClient, private noAuthHttpClient: HttpClient, private handler: HttpBackend) {
    this.noAuthHttpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/TwilioWhatsappConversations';
  }

  public getWhatsappMessages(contactId: number) {
    return this.http.get(`${this.baseURL}/${contactId}`);
  }

  public postWhatsappMessage(message: any) {
    return this.http.post(`${this.baseURL}/sendwhatsappmessage`, message);
  }

  public markMessagesAsRead(contactId: number) {
    return this.http.post(`${this.baseURL}/markconversationasread`, { contactId: contactId });
  }

}
