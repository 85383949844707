import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class FlowActionMessageApiService {
  baseURL: string;

  constructor(private http: HttpClient, private noAuthHttpClient: HttpClient, private handler: HttpBackend) {
    this.noAuthHttpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionMessages';
  }

  public getFlowActionMessagesByFlowId(flowActionId): Observable<Object> {
    return this.http.get(`${this.baseURL}/by/${flowActionId}`);
  }

  public runCallPreview(content: string, language: string, voice: string): Observable<Object> {
    let body = {content, language, voice};
    return this.http.post(`${this.baseURL}/call-preview`, body);
  }

}
