import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from 'app/model/flow-action-call';
import { FlowActionCcp } from 'app/model/flow-action-ccp';
import { NegotiationType } from 'app/model/negotiation-type';
import {
  ACCOUNTS_RECEIVABLE_VARIABLES,
  NegotiationVariable,
  OPTIONS_VARIABLES,
} from 'app/model/studio-negotiation-variables';
import { FlowsService } from 'app/services/flows.service';
import { LanguageService } from 'app/services/language.service';
import { HtmlEditorComponent } from 'core/components/html-editor/html-editor.component';
import { UIService } from 'core/services/ui.service';
import { InsertableTextAreaComponent } from '../InsertableTextArea';

@Component({
  selector: 'app-ccp-details',
  templateUrl: './ccp-details.component.html',
  styleUrls: ['./ccp-details.component.css'],
})
export class CcpDetailsComponent implements OnChanges {
  @Input() node: FlowActionCcp;
  @ViewChild('subjectField') subjectField: InsertableTextAreaComponent;
  @ViewChild('bodyField') bodyField: HtmlEditorComponent;
  @Output() nodeChange = new EventEmitter<FlowAction>();

  description: string;
  parametersId: string | number | null = null;
  primaryColor: string;
  secondaryColor: string;
  logo: string;
  redirectUrl: string;
  use2FA: boolean;
  enableFiles: boolean;
  enabled: boolean;
  languages: string[];
  language: string;
  public subjectMessage: ActionMessage;
  public bodyMessage: ActionMessage;
  public customVars: NegotiationVariable[] = [{ name: '2FA Code', value: 'authCode' }];

  private readonly SUBJECT_INDEX: number = 0;
  private readonly BODY_INDEX: number = 1;
  private flowActionMessages: ActionMessage[] = [];

  constructor(
    private uiService: UIService,
    private flowService: FlowsService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * In cases the flow action is the same type the component won't be destroyed, so it won't trigger all the changes,
   * checking this we meake sure the changes will be triggered when initializing the element and when the flowActionId changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  async init() {
    await this.loadLanguages();
    this.node.flowActionMessages ??= [];
    this.flowActionMessages = this.node.flowActionMessages.map((msg) => ActionMessage.cloneMsg(msg));
    if (this.flowActionMessages.length > 0) {
      this.language = this.flowActionMessages[0].language;
    }
    this.description = this.node.description;
    this.updateMessages();
    this.loadEvents();
    this.loadFlowVariables();
  }

  onLanguageSelect() {
    this.updateMessages();
  }

  insertText(text) {
    // identify which text field has the focus
    if (this.subjectField.selectedElement != null) {
      this.subjectField.insertText(text);
    } else if (this.bodyField.hasFocus()) {
      this.bodyField.insertText(text);
    }
  }

  clickCheckbox(event) {
    event.stopPropagation();
  }

  loadEvents() {
    if (this.node.flowActionCCPParameter != null) {
      const { id, enableFiles, primaryColor, secondaryColor, logo, redirectUrl, use2FA } = FlowActionCcp.getProperties(
        this.node.flowActionCCPParameter
      );
      this.parametersId = id;
      this.enableFiles = enableFiles;
      this.primaryColor = primaryColor;
      this.secondaryColor = secondaryColor;
      this.logo = logo;
      this.redirectUrl = redirectUrl;
      this.use2FA = use2FA;
    } else {
      this.parametersId = null;
      this.enableFiles = false;
      this.primaryColor = null;
      this.secondaryColor = null;
      this.logo = null;
      this.redirectUrl = null;
      this.use2FA = false;
    }
  }

  saveMessages() {
    /**
     * Save the messages for all the translations, if any fails then show all the original messages,
     * does the same with parameters, but this does not need to check anything, just if the call was correct
     * TODO: Check when just some fail and rollback only those. Make validations, debouncing and loading indicator.
     */
    this.uiService.toggleGlobalSpinner(true);
    const promises: Array<Promise<any>> = [];
    const messages = [];
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    const saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        throw err;
      });
    promises.push(saveActionPromise);

    this.flowActionMessages.forEach((msg) =>
      messages.push({
        flowActionId: this.node.flowActionId,
        language: msg.language,
        content: msg.content,
        flowActionMessageId: msg.flowActionMessageId,
        index: msg.index,
      })
    );
    const saveMessagesPromise = this.flowService
      .saveMessages(
        messages as [
          { flowActionId: number; language: string; content: string; flowActionMessageId: string; index: number }
        ]
      )
      .then(() => {
        this.node.flowActionMessages.forEach((msg) => {
          const newMessage = messages.find((nmsg) => nmsg.flowActionMessageId === msg.flowActionMessageId);
          msg.content = newMessage.content;
        });
      })
      .catch((err) => {
        this.flowActionMessages.forEach((msg) => {
          const realMessage = this.node.flowActionMessages.find(
            (rmsg) => rmsg.flowActionMessageId === msg.flowActionMessageId
          );
          msg.content = realMessage.content;
        });
        throw err;
      });
    promises.push(saveMessagesPromise);

    const ccpProps = {
      enableFiles: this.enableFiles,
      primaryColor: this.primaryColor,
      secondaryColor: this.secondaryColor,
      logo: this.logo,
      redirectUrl: this.redirectUrl,
      use2FA: this.use2FA,
    };
    /**
     * If there is a parameters id means that it was previously created. If not create them.
     */
    let savePropertiesPromise;
    if (this.parametersId) {
      // eslint-disable-next-line
      ccpProps['id'] = this.parametersId;
      savePropertiesPromise = this.flowService
        .updateCcpProperties(ccpProps)
        .toPromise()
        .then(() => {
          this.node.flowActionCCPParameter.enableFiles = this.enableFiles;
          this.node.flowActionCCPParameter.primaryColor = this.primaryColor;
          this.node.flowActionCCPParameter.secondaryColor = this.secondaryColor;
          this.node.flowActionCCPParameter.logo = this.logo;
          this.node.flowActionCCPParameter.redirectUrl = this.redirectUrl;
          this.node.flowActionCCPParameter.use2FA = this.use2FA;
        })
        .catch((err) => {
          this.enableFiles = this.node.flowActionCCPParameter.enableFiles;
          this.primaryColor = this.node.flowActionCCPParameter.primaryColor;
          this.secondaryColor = this.node.flowActionCCPParameter.secondaryColor;
          this.logo = this.node.flowActionCCPParameter.logo;
          this.redirectUrl = this.node.flowActionCCPParameter.redirectUrl;
          this.use2FA = this.node.flowActionCCPParameter.use2FA;
          this.uiService.showMessage(err?.message ? err.message : err);
        });
    } else {
      // eslint-disable-next-line
      ccpProps['flowActionId'] = this.node.flowActionId;
      savePropertiesPromise = this.flowService
        .createCcpProperties(ccpProps)
        .toPromise()
        .then((newId: number) => {
          this.parametersId = newId;
          this.node.flowActionCCPParameter.id = newId;
          this.node.flowActionCCPParameter.enableFiles = this.enableFiles;
          this.node.flowActionCCPParameter.primaryColor = this.primaryColor;
          this.node.flowActionCCPParameter.secondaryColor = this.secondaryColor;
          this.node.flowActionCCPParameter.logo = this.logo;
          this.node.flowActionCCPParameter.redirectUrl = this.redirectUrl;
          this.node.flowActionCCPParameter.use2FA = this.use2FA;
        })
        .catch((err) => {
          this.enableFiles = this.node.flowActionCCPParameter.enableFiles;
          this.primaryColor = this.node.flowActionCCPParameter.primaryColor;
          this.secondaryColor = this.node.flowActionCCPParameter.secondaryColor;
          this.logo = this.node.flowActionCCPParameter.logo;
          this.redirectUrl = this.node.flowActionCCPParameter.redirectUrl;
          this.use2FA = this.node.flowActionCCPParameter.use2FA;
          this.uiService.showMessage(err?.message ? err.message : err);
        });
    }
    promises.push(savePropertiesPromise);
    Promise.all(promises)
      .then(() => {})
      .catch((err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'snack-error',
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  private loadFlowVariables() {
    if (this.node.flowType === NegotiationType.OPTIONS) {
      this.customVars.push(...OPTIONS_VARIABLES);
    } else if (this.node.flowType === NegotiationType.ACCOUNTS_RECEIVABLE) {
      this.customVars.push(...ACCOUNTS_RECEIVABLE_VARIABLES);
    }
  }

  private updateMessages() {
    this.subjectMessage = this.getOrCreateMessage(this.SUBJECT_INDEX);
    this.bodyMessage = this.getOrCreateMessage(this.BODY_INDEX);
  }

  private getOrCreateMessage(index: number): ActionMessage {
    let message = this.flowActionMessages.find((x) => x.language === this.language && x.index === index);
    if (message == null) {
      message = new ActionMessage(this.language, '', '');
      message.index = index;
      this.flowActionMessages.push(message);
    }
    return message;
  }

  private async loadLanguages() {
    this.languages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.language = this.languages[0];
  }
}
