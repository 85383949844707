import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { SimpleCompanySettings } from '../model/simple-company-settings';

@Injectable({
  providedIn: 'root',
})
export class CompanySettingsService implements OnInit {
  companySettings: SimpleCompanySettings;
  $companyData = new BehaviorSubject<any>(null);
  private baseURL: string = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Companies';

  constructor(private httpClient: HttpClient) {}

  public ngOnInit(): void {
    this.getSimpleCompanyParameters();
  }

  public getSimpleCompanyParameters() {
    return this.httpClient
      .get(this.baseURL + '/simple-parameters')
      .toPromise()
      .then((data) => this.$companyData.next(data));
  }

  public getParametersActivate() {
    return this.httpClient.get(this.baseURL + '/simple-parameters').toPromise();
  }

  public saveSimpleCompanySettings(companySettings: SimpleCompanySettings): Observable<any> {
    return this.httpClient.post<SimpleCompanySettings>(this.baseURL + '/simple-parameters', companySettings).pipe(
      tap(() => {
        this.getSimpleCompanyParameters();
      })
    );
  }
}
