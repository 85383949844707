import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Auth0ManagementApiService } from './auth0-management-api.service';

@Injectable()
export class Auth0ManagementService {

  constructor(private apiService: Auth0ManagementApiService) { }

  public generateClientCredentials(companyId: number): Observable<any> {
    return this.apiService.generateClientCredentials(companyId);
  }

  public deleteClientCredentials(companyId: number): Observable<any> {
    return this.apiService.deleteClientCredentials(companyId);
  }

  public userPasswordChange(newPw: string) {
    return this.apiService.userPasswordChange(newPw);
  }

}
