import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LanguageService } from 'app/services/language.service';
import { UIService } from 'core/services/ui.service';
import { Contact, PaginatedContactsDataRequest } from 'src/app/model/contact';
import { DynamicContactColumn } from 'src/app/model/dynamiccontactcolumn';
import { DynamicValue } from 'src/app/model/dynamicvalue';
import { ContactsService } from 'src/app/services/contacts.service';

@Component({
  selector: 'app-add-contact',
  templateUrl: './add-contact.component.html',
  styleUrls: ['./add-contact.component.css']
})
export class AddContactComponent implements OnInit {

  phoneRegexp = /^[\+]?[\d]{1,3}[(]?[0-9]{1,4}[)]?[\d]{1,5}[\-\.\s]?[\d]{1,6}$/;
  // eslint-disable-next-line
  emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  contactCreateForm;
  dynamicContactColumns;
  contact: Contact;
  allLangs: { [key: string]: string } = {};

  constructor(
    public contactService: ContactsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddContactComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Contact,
    private snackBar: MatSnackBar,
    private uiService: UIService,
    private languageService: LanguageService
  ) {
    if (data) {
      this.contact = new Contact(data);
    } else {
      this.contact = new Contact(null);
    }
    this.contactService.dynamicContactColumns.subscribe(res => {
      this.dynamicContactColumns = res.map(x => (new DynamicContactColumn(x)));
      this.buildContactCreateForm(this.contact);
    });
  }
  
  ngOnInit(): void {
    this.loadLanguages();
  }

  buildContactCreateForm(contact: Contact) {
    this.contactCreateForm = this.formBuilder.group({
      contactId: contact.contactId,
      companyId: contact.companyId,
      name: contact.name,
      lastName: contact.lastName,
      language: contact.language,
      phone: contact.phone,
      email: contact.email
    });
  }

  onSubmit(): void {
    const contact = new Contact(this.contactCreateForm.value);
    this.uiService.toggleGlobalSpinner(true);
    if (!contact.contactId) {
      this.saveContact(contact);
    } else {
      this.updateContact(contact);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  saveContact(contact: Contact) {
    const newDynamicValues = this.mapToDynamicValues(this.contactCreateForm);
    this.contactService.saveContact(contact, newDynamicValues).subscribe(contactId => {
      this.triggerRefresh();
      this.uiService.toggleGlobalSpinner(false);
      this.snackBar.open('New Contact added successfully.', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
      this.dialogRef.close();
    }, (error) => {
      let errorMsg = 'Error: ';
      if(error) {
        errorMsg += error.includes('already exists') ? `A contact with email ${contact.email} already exists.` : error;
      } else {
        errorMsg += 'Unknown error'
      }
      this.uiService.toggleGlobalSpinner(false);
      this.snackBar.open('Error - ' + errorMsg, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
    });
  }

  updateContact(contact: Contact) {
    this.contactService.updateContact(contact).subscribe(contactId => {
      this.triggerRefresh();
      this.uiService.toggleGlobalSpinner(false);

      this.snackBar.open('Contact updated successfully.', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
      this.dialogRef.close();
    }, (error) => {
      this.uiService.toggleGlobalSpinner(false);
      this.snackBar.open('Error: ' + error, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
    });
  }

  triggerRefresh() {
    this.contactService.getData(); // Used by negotiations screen
    this.contactService.getPaginatedData({ mineOnly: false, pageNumber: 1, pageSize: 25}, false); // Used in contacts Screen
  }

  mapToDynamicValues(form: any): Array<DynamicValue> {
    const result = new Array<DynamicValue>();
    this.dynamicContactColumns.forEach((dynCol: DynamicContactColumn) => {
      const dynVal = new DynamicValue({
        dynamicContactColumnId: dynCol.dynamicContactColumnId,
        value: form.value[dynCol.name]
      });
      result.push(dynVal);
    });
    return result;
  }

  private async loadLanguages() {
    const languages = await this.languageService.getLanguages();
    this.allLangs = Object.fromEntries(languages.map(x => [x.languageCode, x.name]));
  }
}
