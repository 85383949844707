<app-current-subscription *ngIf="currentSubscription?.subscriptionType !== 3"></app-current-subscription>
<div class="settings-card-container">
  <div class="settings-card-title">Credit</div>
  <div class="credit" *ngIf="credits?.credit">${{ credits.credit | number : '1.2-2' }}</div>
  <div class="user-fields-container">
    <button class="sgnd-btn sgnd-btn-ok bg-green" (click)="onAddCredit()">Add Credit</button>
  </div>
  <div>&nbsp;</div>
</div>
<div class="settings-card-container" *ngIf="currentSubscription && currentSubscription.subscriptionType !== 3">
  <div class="user-fields-container">
    <button class="sgnd-btn sgnd-btn-ok bg-red" (click)="cancelSubscription()">Cancel Subscription</button>
  </div>
</div>
