<div>
  <div class="add-documents-row">
    <label class="add-documents-label"> New </label>
    <mat-icon class="add-documents-icon" aria-label="Add document" (click)="onAdd()">add_circle_outline</mat-icon>
  </div>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="externalId">
      <th mat-header-cell *matHeaderCellDef>External Id</th>
      <td mat-cell *matCellDef="let element">{{ element.externalId }}</td>
    </ng-container>

    <ng-container matColumnDef="debtor">
      <th mat-header-cell *matHeaderCellDef>Debtor</th>
      <td mat-cell *matCellDef="let element">{{ element.debtor }}</td>
    </ng-container>

    <ng-container matColumnDef="amount">
      <th mat-header-cell *matHeaderCellDef>Amount</th>
      <td mat-cell *matCellDef="let element">{{ element.amount | currency }}</td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.date | date: 'yyyy/MM/dd' }}</td>
    </ng-container>

    <ng-container matColumnDef="dueDate">
      <th mat-header-cell *matHeaderCellDef>Due Date</th>
      <td mat-cell *matCellDef="let element">{{ element.dueDate | date: 'yyyy/MM/dd' }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon aria-label="Edit Negotiation" class="hoverable" (click)="onEdit(element)" matTooltip="Edit"
          >edit</mat-icon
        >
        <mat-icon aria-label="Delete Negotiation" class="hoverable" (click)="onDelete(element)" matTooltip="Delete"
          >delete_outline</mat-icon
        >
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
