import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-billing-timer',
  templateUrl: './billing-timer.component.html',
  styleUrls: ['./billing-timer.component.css'],
})
export class BillingTimerComponent implements OnInit {
  date = new Date();
  counter = 30;
  isLogged = false;

  constructor(public auth: AuthService, @Inject(DOCUMENT) private doc: Document) {
    this.auth.isAuthenticated$.subscribe((val) => (this.isLogged = val));
  }

  ngOnInit(): void {
    this.date.setMinutes(0);
    this.date.setSeconds(30);
    setInterval(() => {
      this.date.setSeconds(this.date.getSeconds() - 1);
      this.counter--;
      if (this.counter === 0) {
        this.auth.logout({ returnTo: this.doc.location.origin });
      }
    }, 1000);
  }
}
