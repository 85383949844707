import { Injectable } from '@angular/core';
import { PaymentApiService } from './payment-api.service';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  subscriptionId = null;

  constructor(private paymentApiService: PaymentApiService) {}

  postNewSubscription(subscription) {
    return this.paymentApiService.newSubscription(subscription);
  }

  addCredit(billingInfo) {
    return this.paymentApiService.addCredit(billingInfo);
  }

  addNewSubscription(billingInfo) {
    return this.paymentApiService.addNewSubscription(billingInfo);
  }

  setSubscriptionId(id) {
    this.subscriptionId = id;
  }
}
