import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from 'app/model/flow-action-call';
import { FlowActionMessage } from 'app/model/flow-action-message';
import { NegotiationType } from 'app/model/negotiation-type';
import {
  ACCOUNTS_RECEIVABLE_VARIABLES,
  NegotiationVariable,
  OPTIONS_VARIABLES,
} from 'app/model/studio-negotiation-variables';
import { FlowsService } from 'app/services/flows.service';
import { LanguageService } from 'app/services/language.service';
import { UIService } from 'core/services/ui.service';
import { InsertableTextAreaComponent } from '../InsertableTextArea';

@Component({
  selector: 'app-message-details',
  templateUrl: './message-details.component.html',
  styleUrls: ['./message-details.component.css'],
})
export class MessageDetailsComponent implements OnChanges {
  @ViewChild('textField') textField: InsertableTextAreaComponent;
  @Input() node: FlowActionMessage;
  @Output() nodeChange = new EventEmitter<FlowAction>();

  languages: string[];
  language: string;
  selectedMessage = null;
  flowActionMessages: ActionMessage[] = [];
  description: string;
  customVars: NegotiationVariable[] = [];

  constructor(
    private uiService: UIService,
    private flowService: FlowsService,
    private languageService: LanguageService,
    private snackBar: MatSnackBar
  ) {}

  /**
   * In cases the flow action is the same type the component won't be destroyed, so it won't trigger all the changes,
   * checking this we meake sure the changes will be triggered when initializing the element and when the flowActionId changes
   *
   * @param changes
   */
  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  async init() {
    await this.loadLanguages();
    this.flowActionMessages = [];
    this.node.flowActionMessages.forEach((msg) => {
      this.flowActionMessages.push(ActionMessage.cloneMsg(msg));
    });
    if (this.flowActionMessages?.length > 0) {
      this.language = this.flowActionMessages[0].language;
    }
    if (!this.flowActionMessages || this.flowActionMessages?.length === 0) {
      this.flowActionMessages = [new ActionMessage(this.language, '', 'Polly.Matthew')];
    }
    this.description = this.node.description;
    this.selectedMessage = this.flowActionMessages[0];
    this.loadFlowVariables();
  }

  onLanguageSelect() {
    const index = this.flowActionMessages.findIndex((msg) => msg.language === this.language);
    let message = null;
    if (index === -1) {
      message = new ActionMessage(this.language, '', 'Polly.Matthew');
      this.flowActionMessages.push(message);
    } else {
      message = this.flowActionMessages[index];
    }
    this.selectedMessage = message;
  }

  insertText(text) {
    this.textField.insertText(text);
  }

  saveMessages() {
    /**
     * Save the messages for all the translations, if any fails then show all the original messages,
     * does the same with parameters, but this does not need to check anything, just if the call was correct
     * TODO: Check when just some fail and rollback only those. Make validations, debouncing and loading indicator.
     */
    this.uiService.toggleGlobalSpinner(true);
    const promises: Array<Promise<any>> = [];
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    const saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        throw err;
      });
    promises.push(saveActionPromise);

    const messages = [];
    this.flowActionMessages.forEach((msg) =>
      messages.push({
        flowActionId: this.node.flowActionId,
        language: msg.language,
        content: msg.content,
        flowActionMessageId: msg.flowActionMessageId,
      })
    );
    const saveMessagesPromise = this.flowService
      .saveMessages(
        messages as [{ flowActionId: number; language: string; content: string; flowActionMessageId: string }]
      )
      .then(() => {
        this.node.flowActionMessages.forEach((msg) => {
          const newMessage = messages.find((nmsg) => nmsg.flowActionMessageId === msg.flowActionMessageId);
          msg.content = newMessage.content;
        });
      })
      .catch((err) => {
        this.flowActionMessages.forEach((msg) => {
          const realMessage = this.node.flowActionMessages.find(
            (rmsg) => rmsg.flowActionMessageId === msg.flowActionMessageId
          );
          msg.content = realMessage.content;
        });
        throw err;
      });
    promises.push(saveMessagesPromise);
    Promise.all(promises)
      .then(() => {})
      .catch((err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'snack-error',
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  private async loadLanguages() {
    this.languages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.language = this.languages[0];
  }

  private loadFlowVariables() {
    if (this.node.flowType === NegotiationType.OPTIONS) {
      this.customVars.push(...OPTIONS_VARIABLES);
    } else if (this.node.flowType === NegotiationType.ACCOUNTS_RECEIVABLE) {
      this.customVars.push(...ACCOUNTS_RECEIVABLE_VARIABLES);
    }
  }
}
