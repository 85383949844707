<mat-form-field appearance="fill" class="regular-field">
    <mat-label>Search {{placeholder}}</mat-label>
    <div class="input-container">
        <input matInput type="text" class="autocomplete-input" [(ngModel)]="inputValue"
            (ngModelChange)="onChange($event)" [placeholder]="placeholder" required autocomplete="off">
        <i class="material-icons cancel" (click)="onCancel()">cancel</i>
    </div>
    <div *ngIf="inputValue" class="options-container">
        <div *ngFor="let option of optionsToDisplay" (click)="onSelected(option)">
            {{option.name}}
        </div>
    </div>
</mat-form-field>