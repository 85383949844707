import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ServiceUtils } from 'app/services/service-utils';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EmailSettingsService {
  private baseURL: string = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/EmailSettings';

  constructor(private httpClient: HttpClient) {}

  public GetEmailSettings() : Observable<any> {
    const options = ServiceUtils.getRequestOptions(false);
    return this.httpClient.get<string>(this.baseURL, options);
  }

  public SaveEmailSettings(emailSettings) {
    return this.httpClient.post(this.baseURL, emailSettings);
  }

  public VerifyEmailSettings(emailSettings) {
    return this.httpClient.post(`${this.baseURL}/verify`, emailSettings);
  }

}
