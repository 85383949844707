<div>
    <div class="title">Request a template</div>
    
    <!-- Section 1: Select content type -->
    <app-content-template-type-selector *ngIf="stepNumber === 0"
      (onCancelled)="onCancel()"
      (onSelected)="onSelectedNext($event)"
    ></app-content-template-type-selector>

    <app-content-template-form *ngIf="stepNumber === 1 && selectedType?.type"
      [contentType]="selectedType.type"
      (onBack)="onBack()"
      (onCancelled)="onCancel()"
      (formData)="onFormNext($event)"
    ></app-content-template-form>

    <app-content-template-preview *ngIf="stepNumber === 2"
      [contentData]="data"
      [selectedType]="selectedType"
      (onBack)="onBack()"
      (onCancelled)="onCancel()"
      (onSubmit)="onPreviewSubmit($event)"
    ></app-content-template-preview>
    
  </div>