<form>
  <div class="bulk-menu-bar-container">
    <div style="flex: 1">
      <button
        *ngFor="let menuItem of options"
        mat-button
        class="bulk-menu-option"
        (click)="menuItem.action()"
        [matTooltip]="menuItem.title"
      >
        <mat-icon>{{ menuItem.icon }}</mat-icon>
      </button>
    </div>
    <mat-form-field appearance="fill" *ngIf="filterOptions.length > 0">
      <mat-label>Filter By</mat-label>
      <mat-select placeholder="All Channels" (selectionChange)="selectFilter($event)" [(value)]="selectedFilter">
        <mat-option *ngFor="let filterOption of filterOptions" [value]="filterOption.title">
          {{ filterOption.title }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</form>
<app-paginator></app-paginator>
