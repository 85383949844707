import { Injectable } from '@angular/core';
import { EventEmitter } from 'protractor';
import { BehaviorSubject, Observable } from 'rxjs';
import { AttachmentsApiService } from './attachments-api.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentsService {
  $files = new BehaviorSubject<any>([]);

  constructor(private attachmentApi: AttachmentsApiService) { }

  postFilesBySlot(slotId, files: File[]) {
    const promises = [];
    files
      .filter((f: any) => !f.id)
      .forEach((file) => {
        promises.push(this.attachmentApi.postAttachmentBySlot(slotId, file).toPromise());
      });
    this.$files.getValue().forEach((file) => {
      if (!files.find((f: any) => f.id === file.id)) {
        promises.push(this.attachmentApi.deleteAttachmentForSlot(slotId, file.id).toPromise());
      }
    });

    return Promise.allSettled(promises);
  }

  postFilesByNegotiation(negotiationId, files: File[]) {
    const promises = [];
    files
      .filter((f: any) => !f.id)
      .forEach((file) => {
        promises.push(this.attachmentApi.postAttachmentByNegotiation(negotiationId, file).toPromise());
      });
    this.$files.getValue().forEach((file) => {
      if (!files.find((f: any) => f.id === file.id)) {
        promises.push(this.attachmentApi.deleteAttachmentForNegotiation(negotiationId, file.id).toPromise());
      }
    });

    return Promise.allSettled(promises);
  }
  postAttachmentByFlowActionSignatureParameter(flowActionSignatureParameterId: number, file: File): Observable<any> {
    return this.attachmentApi.postAttachmentByFlowActionSignatureParameter(flowActionSignatureParameterId, file);
  }

  getFilesBySlot(slotId) {
    this.attachmentApi.getAttachmentsBySlot(slotId).subscribe((attachments) => {
      this.$files.next(attachments);
    });
  }

  getFilesByNegotiationId(negotiationId) {
    this.attachmentApi.getAttachmentsByNegotiation(negotiationId).subscribe((attachments) => {
      this.$files.next(attachments);
    });
  }

  getAttachmentForSlot(slotId, attachmentId) {
    return this.attachmentApi.getAttachmentForSlot(slotId, attachmentId).toPromise();
  }

  getAttachmentForNegotiation(negotiationId, attachmentId) {
    return this.attachmentApi.getAttachmentForNegotiation(negotiationId, attachmentId).toPromise();
  }

  setFiles(files) {
    this.$files.next(files);
  }
}
