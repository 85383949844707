import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { BillingService } from 'app/services/billing.service';
import { PaymentService } from 'app/services/payment.service';
import { UIService } from 'core/services/ui.service';
import Subscriptions from 'src/assets/subscription-options.json';
import { SubscriptionModalComponent } from '../subscription-modal/subscription-modal.component';

@Component({
  selector: 'app-current-subscription',
  templateUrl: './current-subscription.component.html',
  styleUrls: ['./current-subscription.component.css'],
})
export class CurrentSubscriptionComponent implements OnInit {
  currentSubscription = null;
  expired = false;
  subscriptions = Subscriptions;

  constructor(
    private billingService: BillingService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private uiService: UIService
  ) {}

  ngOnInit(): void {
    this.billingService.$subscriptionDetails.subscribe((value) => {
      if (value) {
        this.currentSubscription = this.subscriptions.find((sub) => sub.subscriptionType === value.subscriptionType);
        if (value.expiration) {
          this.expired = new Date(value.expiration).getTime() < Date.now();
        }
      } else {
        this.currentSubscription = null;
        this.expired = false;
      }
    });
  }

  onConfirmSubChange(sub) {
    if (!this.expired && this.currentSubscription) {
      this.uiService.toggleGlobalSpinner(true);
      this.billingService
        .updateSubscription(sub?.subscriptionType)
        .then(() => {})
        .catch((err) => {
          this.snackBar.open(err, null, {
            horizontalPosition: 'center',
            verticalPosition: 'bottom',
            duration: 3000,
            panelClass: 'snack-error',
          });
        })
        .finally(() => {
          this.dialog.closeAll();
          this.uiService.toggleGlobalSpinner(false);
        });
    } else {
      this.billingService.setUpgradeToSubscription(sub);
      this.dialog.closeAll();
      this.router.navigate(['settings/payment']);
    }
  }

  chooseSub(subscriptionChoosen) {
    this.dialog.open(SubscriptionModalComponent, {
      data: {
        currentSubscription: this.currentSubscription,
        choosenSubscription: subscriptionChoosen,
        onConfirm: this.onConfirmSubChange.bind(this, subscriptionChoosen),
      },
    });
  }
}
