<div class="billing-subscriptions-header">
  <h1>Signedeal NPA:</h1>
  <h2>Plans & Pricing</h2>
</div>

<div class="cards-container">
  <app-subscription-card
    *ngFor="let sub of subscriptions; let index"
    [subscriptionInfo]="sub"
    [isSelected]="index === selected"
    (selected)="onCardSelected($event)"
  ></app-subscription-card>
</div>

<div class="billing-details-container">
  <ol class="billing-details">
    <li>Prices are in US dollars and do not include communication credits beyond the monthly bonus.</li>
    <li>An Engagement is defined by an individual that is contacted during a campaign.</li>
    <li>Communication Bonus Credit is applied each month and must be consumed during that month.</li>
    <li>
      WhatsApp for Business onboarding services are included in order to register a business-specific WhatsApp sender.
      If not required, communications will be done via a generic "Signedeal" WhatsApp sender.
    </li>
  </ol>
</div>

<div *ngIf="selected" class="next-container" [@appearOpacityAnimation]>
  <button mat-button (click)="onNext()">Next <mat-icon>navigate_next</mat-icon></button>
</div>
