import { Component, OnInit } from '@angular/core';
import { CompanySettingsService } from 'app/components/settings';
import { SimpleCompanySettings } from 'app/components/settings/model/simple-company-settings';

@Component({
  selector: 'app-nav-company-widget',
  templateUrl: './nav-company-widget.component.html',
  styleUrls: ['./nav-company-widget.component.css']
})
export class NavCompanyWidgetComponent implements OnInit {
  public companyName: string;

  constructor(
    private companySettingsService: CompanySettingsService
  ) {
    this.companySettingsService.$companyData.subscribe((value: SimpleCompanySettings) => {
      if(value) {
        // console.log('company settings', value);
        this.companyName = value.name;
      }
    });
  }

  ngOnInit(): void {
  }

}
