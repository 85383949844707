import { CanvasNodePosition } from './canvas-node-position';

export class FlowAction {
  flowActionId: number;
  flowId: number;
  flowType: number;
  enable: boolean;
  description: string;
  position?: CanvasNodePosition;
  canvasPositionX: number;
  canvasPositionY: number;
  actionType: number;
  children: FlowAction[];
  continueOnFailure = true;

  constructor(
    flowActionId: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable: boolean,
    description: string,
    continueOnFailure: boolean
  ) {
    this.flowActionId = flowActionId;
    this.flowId = flowId;
    this.flowType = flowType;
    this.canvasPositionX = canvasPositionX;
    this.canvasPositionY = canvasPositionY;
    this.actionType = actionType;
    this.children = children;
    this.enable = enable;
    this.description = description;
    this.continueOnFailure = continueOnFailure;
  }

  static cloneFlowAction(flowAction: FlowAction): FlowAction {
    return {
      flowActionId: flowAction.flowActionId,
      flowId: flowAction.flowId,
      flowType: flowAction.flowType,
      enable: flowAction.enable,
      description: flowAction.description,
      canvasPositionX: flowAction.canvasPositionX,
      canvasPositionY: flowAction.canvasPositionY,
      actionType: flowAction.actionType,
      children: flowAction.children,
      continueOnFailure: flowAction.continueOnFailure,
    };
  }
}
