import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from './flow-action-call';

export class ActionSignatureProperties {
  id: null | number = null;
  flowActionId: number;
  usePdfDocument: boolean;
  useSignature: boolean;
  useNonSignedEmail: boolean;
  attachmentId: number;
  attachment: {
    id;
    originalName;
  };
}

export class FlowActionSignature extends FlowAction {
  flowActionSignatureParameter: ActionSignatureProperties = new ActionSignatureProperties();
  flowActionMessages: ActionMessage[] = [];

  constructor(
    flowActionId: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable: boolean,
    description: string,
    messages: ActionMessage[] = [],
    flowActionSignatureParameter = new ActionSignatureProperties(),
    continueOnFailure = true
  ) {
    super(
      flowActionId,
      flowId,
      flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      children,
      enable,
      description,
      continueOnFailure
    );
    this.flowActionMessages = messages;
    this.flowActionSignatureParameter = flowActionSignatureParameter || new ActionSignatureProperties();
  }

  // Get unreferenced properties
  static getProperties({
    id,
    flowActionId,
    usePdfDocument,
    useSignature,
    useNonSignedEmail,
    attachmentId,
    attachment,
  }) {
    const properties = {
      id,
      flowActionId,
      usePdfDocument,
      useSignature,
      useNonSignedEmail,
      attachmentId,
      attachment,
    };
    return properties;
  }

  getFlowAction(flowActionSignature: FlowActionSignature) {
    const flowAction = {
      flowActionSignatureParameter: FlowActionSignature.getProperties(flowActionSignature.flowActionSignatureParameter),
      flowActionMessages: this.flowActionMessages,
    };
    return flowAction;
  }
}
