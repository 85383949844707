import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class NegotiationMessageApiService {
  baseURL: string;

  constructor(private http: HttpClient, private noAuthHttpClient: HttpClient, private handler: HttpBackend) {
    this.noAuthHttpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/NegotiationMessages';
  }

  public getNegotiationMessagesView(negotiationIds: number[]) {
    return this.noAuthHttpClient.post(`${this.baseURL}/negotiation-status/view/multiple`, {
      negotiationIds,
    });
  }

  public getAdminUnreadQty(slotId) {
    return this.http.get(`${this.baseURL}/monitor/unread-qty/${slotId}`);
  }

  public getWhatsappUnreadQty(slotId: number) {
    return this.http.get(`${this.baseURL}/monitor/whatsapp-unread-qty/${slotId}`);
  }

  public getIntermediateUnreadQty(negotiationId) {
    const body = { negotiationId };
    return this.noAuthHttpClient.post(`${this.baseURL}/negotiation-status/unread-qty`, body);
  }

  public getNegotiationMessages(negotiationId) {
    return this.http.post(`${this.baseURL}/negotiation-status/${negotiationId}`, {});
  }

  public postMessage(negotiationMessage) {
    return this.noAuthHttpClient.post(`${this.baseURL}`, negotiationMessage);
  }

  public postMonitorMessage(message) {
    return this.http.post(`${this.baseURL}/negotiation-status`, message);
  }

  public getMonitorMessages(negotiationId) {
    return this.http.get(`${this.baseURL}/messages-for-monitor-negotiation/${negotiationId}`);
  }

  public updateMessage(messageId, read: boolean, replied: boolean) {
    return this.http.put(`${this.baseURL}/negotiation-status`, {
      messageId,
      read,
      replied,
    });
  }

  public updatedIntermediateMessage(messageId, read: boolean, replied: boolean, authorizationCode: string) {
    return this.noAuthHttpClient.put(`${this.baseURL}/negotiation-status/intermediate`, {
      messageId,
      read,
      replied,
      authorizationCode,
    });
  }

  public getPending(companyId) {
    this.http.post(`${this.baseURL}/pending`, { companyId });
  }
}
