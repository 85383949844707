import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.css']
})
export class AlertComponent implements OnInit {

  constructor(
    public dialog: MatDialogRef<AlertComponent>,
    @Inject(MAT_DIALOG_DATA) public message: string) { }

  confirm(): void {
      this.dialog.close(true);
  }

  ngOnInit(): void {
  }

}
