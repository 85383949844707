import { Injectable } from '@angular/core';
import Sender from 'app/model/sender';
import { SendersApiService } from './senders-api.service';

@Injectable({
  providedIn: 'root',
})
export class SendersService {
  constructor(private sendersApiService: SendersApiService) {}

  getSender(companyId) {
    return this.sendersApiService.getSender(companyId);
  }

  async getSenderById(companyId) {
    const senders: any = await this.getSender(companyId).toPromise();
    if (senders && senders.length > 0) {
      const sender = senders.find((el: Sender) => el.companyId === companyId);
      return sender;
    }
    return null;
  }

  addNewSender(sender) {
    return this.sendersApiService.postSender(sender);
  }

  updateSender(sender) {
    return this.sendersApiService.updateSender(sender);
  }

  deleteSender(senderId) {
    return this.sendersApiService.deleteSender(senderId);
  }
}
