export default class SubscriptionInfo {
  title: string;
  description: string;
  price: number;
  period: string;
  annually: string;
  users: string;
  onboardingBonus: string;
  senderOnboarding: string;
}
