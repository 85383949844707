<section
  (dragstart)="onDragInit($event)"
  (dragend)="onDrop($event)"
  (dblclick)="onDoubleClick($event)"
  draggable="true"
  class="node-details-container"
  *ngIf="node"
  [ngSwitch]="node?.actionType"
>
  <div class="detail-title section-header">
    <!-- Icons -->
    <mat-icon class="title-icon"
      *ngIf="node?.actionType !== actionTypes.WHATSAPP && node?.actionType !== actionTypes.WHATSAPP_TEMPLATE">
      {{ actionIcons[node?.actionType] }}
    </mat-icon>
    <whatsapp-icon
      *ngIf="node?.actionType === actionTypes.WHATSAPP"
      size="3rem" fill="rgb(33, 37, 41)"
    ></whatsapp-icon>
    <whatsapp-icon
      *ngIf="node?.actionType === actionTypes.WHATSAPP_TEMPLATE"
      size="3rem" fill="rgb(33, 37, 41)" [isLegacy]="false"
    ></whatsapp-icon>
    <!-- End of Icons -->

    <div class="node-title">{{ studioOptionsLabels[node?.actionType] }}</div>
    <div
      class="toggle-container"
      *ngIf="node?.actionType !== actionTypes.CCP && node?.actionType !== actionTypes.DELAY"
    >
      <mat-slide-toggle [checked]="node?.continueOnFailure" (change)="toggleContinueOnFailure($event)"
        >Continue on failure</mat-slide-toggle
      >
    </div>
    <button
      *ngIf="node?.actionType !== startAction"
      class="enabled-button"
      [class.is-enabled]="node?.enable"
      (click)="enableAction()"
    ></button>
  </div>
  <div draggable="true" (dragstart)="dontDrag($event)">
    <mat-accordion>
      <app-call-details *ngSwitchCase="actionTypes.CALL" [node]="node"></app-call-details>
      <app-message-details *ngSwitchCase="actionTypes.MESSAGE" [node]="node"></app-message-details>
      <app-content-template-details *ngSwitchCase="actionTypes.WHATSAPP_TEMPLATE" [node]="node"></app-content-template-details>
      <app-whatsapp-details *ngSwitchCase="actionTypes.WHATSAPP" [node]="node"></app-whatsapp-details>
      <app-email-details *ngSwitchCase="actionTypes.EMAIL" [node]="node">email stuff</app-email-details>
      <app-ccp-details *ngSwitchCase="actionTypes.CCP" [node]="node">ccp stuff</app-ccp-details>
      <app-signature-details *ngSwitchCase="actionTypes.SEND_DOCUMENT" [node]="node"
        >signature stuff</app-signature-details
      >
      <app-delay-details *ngSwitchCase="actionTypes.DELAY" [node]="node">delay stuff</app-delay-details>
    </mat-accordion>

    <div *ngSwitchDefault>
      {{ node.value }}
    </div>
  </div>
</section>
