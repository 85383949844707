// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import config from '../../auth_config.json';

const { domain, clientId, audience, apiUri, errorPath, scope } = config as {
  domain: string;
  clientId: string;
  audience?: string;
  apiUri: string;
  errorPath: string;
  scope: string;
};

export const environment = {
  production: false,
  apiUrl: 'https://negotiation-api.azurewebsites.net',
  //apiUrl: 'https://negotiation-api-prod.azurewebsites.net',
  //apiUrl: 'https://localhost:44377',
  auth: {
    domain,
    clientId,
    audience,
    redirectUri: window.location.origin + '/callback',
    errorPath,
    useRefreshTokens: true,
    scope
  },
  httpInterceptor: {
    allowedList: [
      {
        uri: `*`,
        tokenOptions: {
          audience,
          scope
        }
      }
    ]
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
