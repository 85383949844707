<div class="title" [style.background-color]="titleBackgroundColor" [style.color]="titleTextColor">Confirmation</div>
<div class="message">{{ message }}</div>
<div class="form-fields-container">
  <div class="buttons-container">
    <button
      class="sgnd-btn sgnd-btn-ok"
      (click)="confirm()"
      cdkFocusInitial
      [style.background-color]="buttonBackgroundColor"
      [style.color]="buttonTextColor"
    >
      Yes
    </button>
    <button
      class="sgnd-btn sgnd-btn-cancel"
      (click)="closeDialog()"
      [style.background-color]="altButtonBackgroundColor"
      [style.color]="altButtonTextColor"
    >
      No
    </button>
  </div>
</div>
