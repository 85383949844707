import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { CompanySettingsService } from 'app/components/settings';
import { CompanyPermissionsService } from 'app/services/company-permissions.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-tab-group',
  templateUrl: './tab-group.component.html',
  styleUrls: ['./tab-group.component.css'],
})
export class TabGroupComponent implements OnInit {
  public showNavBar = true;

  defaultMenus = [
    { key: 'contacts', title: 'Contacts', icon: 'contacts' },
    { key: 'channels', title: 'Channels', icon: 'handshake' },
    { key: 'monitor', title: 'Monitor', icon: 'monitor' },
    { key: 'studio', title: 'Studio', icon: 'bubble_chart' },
  ];
  menus = this.defaultMenus;

  private restrictedMenus = [{ key: 'billing', title: 'Billing', icon: 'attach_money' }];

  constructor(
    public auth: AuthService,
    private uiService: UIService,
    private companySettingsService: CompanySettingsService,
    private companyPermissions: CompanyPermissionsService
  ) {
    this.uiService.showNavBars.subscribe((value) => {
      this.showNavBar = value;
    });
    this.companySettingsService.$companyData.subscribe((data) => {
      if (data?.companyId) {
        this.menus = this.defaultMenus;
      } else {
        // uncomment next line to hide menu values based on company options
        this.menus = this.restrictedMenus;
      }
    });
  }

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.companySettingsService.getSimpleCompanyParameters();
        this.companyPermissions.loadUserCompanyPermissions();
      }
    });
  }

  isSelected(menuKey: string, algo: any[]) {
    const currentUrl = window.location.href;
    const select = currentUrl.toLowerCase().includes(`/${menuKey}`);
    if (select) {
      return true;
    }
    return currentUrl[currentUrl.length - 1] === '/' && menuKey === algo[0].key;
  }
}
