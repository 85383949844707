import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CKEditorComponent, ChangeEvent } from '@ckeditor/ckeditor5-angular';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-html-editor',
  template: '<ckeditor #editor [editor]="Editor" [(ngModel)]="value" (change)="onChange($event)"></ckeditor><br />',
})
export class HtmlEditorComponent {
  @Input() value = '';
  @Output() valueChange = new EventEmitter();
  @ViewChild('editor') editorComponent: CKEditorComponent;
  public Editor = ClassicEditor;

  public onChange(event: ChangeEvent) {
    this.valueChange.emit(this.value);
  }

  public hasFocus(): boolean {
    return this.editorComponent.editorInstance.editing.view.document.isFocused;
  }

  public insertText(text: string) {
    try {
      this.editorComponent.editorInstance.model.change((writer) => {
        const selection = this.editorComponent.editorInstance.model.document.selection;
        const currentAttributes = selection.getAttributes();
        const insertPosition = selection.focus;
        writer.insertText(text, currentAttributes, insertPosition);
      });
      this.valueChange.emit(this.value);
    } catch (err) {
      console.log('Error while inserting text');
    }
  }
}
