<div class="negotiation-upload">
  <div class="title">Upload Negotiations</div>
  <div class="negotiation-upload-container">
    <label class="upload-label-title"> Get your negotiations from a file </label>
    <label class="upload-label-description"> Upload negotiations in XLS and XLSX format only. </label>


    <mat-grid-list cols="2">

    <mat-form-field appearance="fill" class="regular-field">
      <mat-label>Negotiation Type</mat-label>

      <mat-select
        matInput
        name="negotiationType"
        ngDefaultControl
        (selectionChange)="onSelectNegotiationType($event.value)"
        required
      >
        <mat-option *ngFor="let flow of negotiationTypesOptions" [value]="flow.key">{{ flow.value }} </mat-option>
      </mat-select>
    </mat-form-field>
      <a
      mat-button
      href="assets/Demo SpreadSheet.xlsx"
      [hidden]="negotiationType !== 0"
      class="download-sample-file-btn"
      target="_blank"
      >Options sample file</a
      >
      <a
        mat-button
        href="assets/AccountsReceivable SpreadSheet.xlsx"
        [hidden]="negotiationType !== 1"
        class="download-sample-file-btn"
        target="_blank"
        >Accounts Receivable a sample file</a
      >


      <a
      mat-button
      href="assets/ThirdPartyNegotiations.xlsx"
      [hidden]="negotiationType !==2"
      class="download-sample-file-btn"
      target="_blank"
      >Third Party sample file</a
    >
  </mat-grid-list>

    <label class="upload-label-input"> Select a file to upload </label>
    <div class="upload-negotiation-input">
      <input
        #fileInput
        type="file"
        (change)="setChosenFile($event)"
        hidden
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />
      <input
        class="upload-file-name-text-input"
        matInput
        type="text"
        [(ngModel)]="chosenFileName"
        value="{name}"
        placeholder="No file selected"
        readonly
      />
      <button type="button" class="browse-file-btn" mat-stroked-button (click)="fileInput.click()">Browse</button>
    </div>

    <div class="upload-negotiation-buttons">
      <button class="sgnd-btn sgnd-btn-ok" type="button" (click)="uploadFile()" [disabled]="!chosenFile">Upload</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</div>
