import { Component, Input, OnInit } from '@angular/core';
import { TableAction } from 'app/model/table-action';

@Component({
  selector: 'app-lateral-action',
  templateUrl: './lateral-action.component.html',
  styleUrls: ['./lateral-action.component.css']
})
export class LateralActionComponent implements OnInit {

  @Input()
  option: TableAction;

  @Input()
  isSubOption = false;

  @Input()
  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {
  }

}
