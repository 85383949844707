import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PaymentApiService {
  baseURL = '';
  httpClientUnlogged = null;

  constructor(private httpClient: HttpClient, private handler: HttpBackend) {
    this.httpClientUnlogged = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/billing';
  }

  newSubscription(subscription) {
    return this.httpClient.post(this.baseURL + '/new-subscription', subscription);
  }

  addCredit(body) {
    return this.httpClient.post(this.baseURL + '/add-credit', body);
  }

  addNewSubscription(body) {
    return this.httpClientUnlogged.post(this.baseURL + '/add-new-subscription', body);
  }
}
