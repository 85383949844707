import { Component, OnInit } from '@angular/core';
import { FlowsService } from 'app/services/flows.service';
import { StudioService } from 'app/services/studio.service';

@Component({
  selector: 'app-studio',
  templateUrl: './studio.component.html',
  styleUrls: ['./studio.component.css'],
})
export class StudioComponent implements OnInit {
  constructor(private flowService: FlowsService, private studioService: StudioService) {}

  ngOnInit(): void {
    this.studioService.clear();
    this.flowService.selectFlow(null);
  }
}
