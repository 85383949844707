import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DemoDocument } from 'app/model/documents/demo-document';
import { DocumentsService } from 'app/services/documents.service';
import isEqual from 'lodash.isequal';

@Component({
  selector: 'app-add-demo-document',
  templateUrl: './add-demo-document.component.html',
  styleUrls: ['./add-demo-document.component.css'],
})
export class AddDemoDocumentComponent implements OnInit {
  @Output()
  documentEmitter = new EventEmitter<DemoDocument>();
  documentCreateForm;
  private document: DemoDocument;
  private editIndex: number;

  constructor(
    public documentsService: DocumentsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddDemoDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DemoDocument
  ) {
    if (data) {
      this.editIndex = this.documentsService.getDocuments().findIndex((element) => isEqual(element, data));
      this.document = new DemoDocument(data);
    } else {
      this.document = new DemoDocument(null);
    }
    this.buildForm(this.document);
  }

  buildForm(document: DemoDocument) {
    this.documentCreateForm = this.formBuilder.group({
      description: document.description
    });
  }

  onSubmit() {
    const document = new DemoDocument(this.documentCreateForm.value);
    const documents = this.documentsService.getDocuments();
    if (this.editIndex != null) {
      // As there is not possible to find a document without having an ID, replace by using its original position in the array.
      if (this.editIndex > -1) {
        documents.splice(this.editIndex.valueOf(), 1);
      }
    }
    documents.push(document);
    documents.sort((a, b) => a.description.localeCompare(b.description));
    this.documentsService.setDocuments(documents);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

  ngOnInit(): void { }
}
