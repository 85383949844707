export class WhatsAppContentTemplate {
    public id?: number;
    public companyId?: number;
    public name?: string;
    public lang?: string;
    public body?: string;
    public variables?: string;
    public actions?: string;
    public type?: string;
    public contentSid?: string;
    public category?: string;
    public contentType?: string;
    public status?: string;
}
