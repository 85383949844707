import { Component, OnInit } from '@angular/core';
import { debounceTime } from 'rxjs/operators';
import { UIService } from 'src/core/services/ui.service';

@Component({
  selector: 'app-global-spinner',
  templateUrl: './global-spinner.component.html',
  styleUrls: ['./global-spinner.component.css']
})
export class GlobalSpinnerComponent implements OnInit {
  showSpinner = false;

  constructor(
    private uiService: UIService
  ) {
    this.uiService.showGlobalSpinner.pipe(
        debounceTime(150) // avoid showing the spinner if it's only enabled for less than 150ms
    ).subscribe(value => {
      this.showSpinner = value;
    });
  }

  ngOnInit(): void {
  }

}
