<div class="title">Template button</div>

<form [formGroup]="templateButtonCreateForm" (ngSubmit)="onSubmit()" autocomplete="off">
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Text</mat-label>
            <input matInput formControlName="text" placeholder="Place here the text of your button" required
                maxlength="20"/>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>URL</mat-label>
            <input matInput formControlName="url" placeholder="Place here the URL of your button" required maxlength="2000"/>
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!templateButtonCreateForm.valid" type="submit">Accept</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>