import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ContactsApiService } from '../services/contacts-api.service';
import { DynamicContactColumnsApiService } from '../services/dynamiccontactcolumns-api.service';
import { Contact, ContactDto, PaginatedContactsDataRequest } from '../model/contact';
import { DynamicContactColumn } from '../model/dynamiccontactcolumn';
import { DynamicValuesApiService } from './dynamicvalues-api.service';
import { DynamicValue } from '../model/dynamicvalue';
import { UserService } from 'src/core/services/user.service';
import { PaginatedDataResponse } from 'app/model/pagination';
import { handleCachedResponse } from './service-utils';
import { defaultIfEmpty } from 'rxjs/operators';
import { ContactsWithTotalCountDto } from 'app/model/contacts-with-total-count-dto';

@Injectable()
export class ContactsService {
  public contacts = new BehaviorSubject<Contact[]>([]);
  public paginatedContacts = new BehaviorSubject<PaginatedDataResponse<ContactDto>>(null);
  public dynamicContactColumns = new BehaviorSubject<DynamicContactColumn[]>([]);

  constructor(
    private userService: UserService,
    private contactApiService: ContactsApiService,
    private dynamicColumnsApiService: DynamicContactColumnsApiService,
    private dynamicValuesApiSerrvice: DynamicValuesApiService
  ) {
    this.userService.currentUserAsync().subscribe((currentUser) => {
      // this.getData();
    });
  }

  getData(acceptCachedResponse: boolean = false) {
    this.getDataInternal(false, acceptCachedResponse);
  }

  getMyData(acceptCachedResponse: boolean = false) {
    this.getDataInternal(true, acceptCachedResponse);
  }

  getPaginatedData(filter: PaginatedContactsDataRequest, acceptCachedResponse: boolean = false) {
    forkJoin([
      // this.dynamicColumnsApiService.getDynamicContactColumnsList(),
      this.contactApiService.getPaginatedContactsList(filter, acceptCachedResponse).pipe(handleCachedResponse()),
    ]).subscribe({
      next: (res: Array<any>) => {
        // this.dynamicContactColumns.next(res[0].map((x) => new DynamicContactColumn(x)));
        // const contactsData = res[1] as PaginatedDataResponse<ContactDto>;
        const contactsData = res[0] as PaginatedDataResponse<ContactDto>;
        this.paginatedContacts.next(contactsData);
      },
      error: (err) => {
        console.log(err);
        this.paginatedContacts.next(null);
        this.dynamicContactColumns.next([]);
      },
    });
  }

  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  public saveContact(contact: Contact, dynamicValues: DynamicValue[]): Observable<any> {
    return new Observable((observer) => {
      this.contactApiService.saveContact(contact).subscribe(
        (resContactId) => {
          this.saveDynamicValues(dynamicValues, resContactId)
            .then((result) => {
              observer.next(resContactId);
              observer.complete();
            })
            .catch((errorResponse) => {
              const errorToReturn =
                typeof errorResponse.error === 'string' ? JSON.parse(errorResponse.error).title : errorResponse.error;
              observer.error(errorToReturn);
            });
        },
        (errorResponse) => {
          const errorToReturn =
            typeof errorResponse.error === 'string' ? JSON.parse(errorResponse.error).title : errorResponse.error;
          observer.error(errorToReturn);
        }
      );
    });
  }

  public updateContact(contact: Contact): Observable<any> {
    return this.contactApiService.updateContact(contact);
  }

  public deleteContact(contactId: number): Observable<any> {
    const contactIds = new Array();
    contactIds.push(contactId);
    return this.contactApiService.deleteContacts(contactIds);
  }

  public deleteContacts(contactIds: Array<number>): Observable<any> {
    return this.contactApiService.deleteContacts(contactIds);
  }

  async saveDynamicValues(dynamicValues: Array<DynamicValue>, contactId) {
    dynamicValues.forEach((dynVal) => {
      dynVal.contactId = contactId;
    });
    await this.dynamicValuesApiSerrvice.postDynamicValueList(dynamicValues, contactId).subscribe(async (result) => {
      console.log('POST postDynamicValueList result', result);
      this.getData();
    });
  }

  uploadExcel(formData: FormData, contactsGroupId: number): Observable<any> {
    return this.contactApiService.postUploadContacts(formData, contactsGroupId);
  }

  public getContactsGroupContacts(
    contactsGroupId: number,
    pageSize: number,
    pageNumber: number,
    sortedBy: string,
    sortDirection: string,
    textFilter: string
  ): Observable<ContactsWithTotalCountDto> {
    return this.contactApiService.getPaginatedContactsListByGroup(
      contactsGroupId,
      null,
      pageSize,
      pageNumber,
      sortedBy,
      sortDirection,
      textFilter
    );
  }

  public getMissingContactsGroupContacts(
    excludeContactsFromGroup: number,
    pageSize: number,
    pageNumber: number,
    sortedBy: string,
    sortDirection: string,
    textFilter: string
  ): Observable<ContactsWithTotalCountDto> {
    return this.contactApiService.getPaginatedContactsListByGroup(
      null,
      excludeContactsFromGroup,
      pageSize,
      pageNumber,
      sortedBy,
      sortDirection,
      textFilter
    );
  }

  public exportContacts() {
    return this.contactApiService.exportContacts('Contacts', {
      filterByParameter: ['LASTNAME', 'NAME', 'EMAIL'],
      mineOnly: false,
    });
  }

  private getDataInternal(mineOnly: boolean, acceptCachedResponse: boolean = false) {
    const contactsObservable: Observable<ContactDto[]> = mineOnly
      ? this.contactApiService.getMyContactsList(acceptCachedResponse)
      : this.contactApiService.getContactsList(acceptCachedResponse);

    forkJoin([
      // this.dynamicColumnsApiService.getDynamicContactColumnsList(),
      contactsObservable.pipe(
        handleCachedResponse(),
        defaultIfEmpty(null) // forkJoin doesn't emit anything if one of the observables is empty
      ),
    ]).subscribe({
      next: (res: Array<any>) => {
        /*const columns = res[0] as DynamicContactColumn[];
        if (columns) {
          this.dynamicContactColumns.next(columns.map((x) => new DynamicContactColumn(x)));
        }*/
        // const contacts = res[1] as ContactDto[];
        const contacts = res[0] as ContactDto[];
        if (contacts) {
          this.contacts.next(contacts.map((x) => new Contact(x)));
        }
      },
      error: (err) => {
        console.log(err);
        this.contacts.next(null);
        this.dynamicContactColumns.next([]);
      },
    });
  }
}
