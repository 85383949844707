<div class="table-container">
    <label class="table-title">Review Whatsapp Templates</label>
    <span *ngIf="!contentDataSource?.data.length" class="empty-table"> No new templates require validation</span>
    <table mat-table [dataSource]="contentDataSource" class="table" fixedLayout *ngIf="contentDataSource?.data.length">
        <ng-container matColumnDef="templateLabel">
            <th mat-header-cell *matHeaderCellDef class="th-template-label">Template</th>
            <td mat-cell *matCellDef="let element">{{element.whatsappOptionTemplateTemplateLabel}}</td>
        </ng-container>
        <ng-container matColumnDef="language">
            <th mat-header-cell *matHeaderCellDef class="th-language">Language</th>
            <td mat-cell *matCellDef="let element" class="td-language">{{allLangs[element.language]}}</td>
        </ng-container>
        <ng-container matColumnDef="content">
            <th mat-header-cell *matHeaderCellDef>Content</th>
            <td mat-cell *matCellDef="let element" class="td-template-content">
                {{ element.content }}</td>
        </ng-container>
        <ng-container matColumnDef="companyId">
            <th mat-header-cell *matHeaderCellDef class="th-company-id">Company Id</th>
            <td mat-cell *matCellDef="let element" class="td-company-id">{{element.companyId}}</td>
        </ng-container>
        <ng-container matColumnDef="companyName">
            <th mat-header-cell *matHeaderCellDef class="th-company-name">Company Name</th>
            <td mat-cell *matCellDef="let element" class="td-company-name">{{element.companyName}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef class="th-actions">Review</th>
            <td mat-cell *matCellDef="let element" class="td-actions">
                <mat-icon (click)="onReviewTemplateContent(element)">rate_review</mat-icon>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedContentColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedContentColumns"></tr>
    </table>
</div>