import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Contact } from 'src/app/model/contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { UIService } from 'core/services/ui.service';
import { PaginatedDataRequest } from 'app/model/pagination';
import { FilterPageEvent, FilterPaginatorResetMode } from 'core/components/filter-paginator/filter-paginator.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { Slot } from 'app/model/slot';
import { SlotsService } from 'app/services/slots.service';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';
import downloadFileResponse from 'app/functions/fileFunctions';

@Component({
  selector: 'app-contacts-grid',
  templateUrl: './contacts-grid.component.html',
  styleUrls: ['./contacts-grid.component.css'],
})
export class ContactsGridComponent implements OnInit {
  @Input()
  reset: Subject<boolean>;
  @Input()
  setState: Subject<PaginatedDataRequest>;
  @Output()
  selectedContacts = new EventEmitter<Contact[]>();
  @Output()
  refresh = new EventEmitter<PaginatedDataRequest>();
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  public contacts: Array<Contact> = [];
  // public dynamicContactColumns: Array<DynamicContactColumn> = [];
  public gridColumns: Array<IGridColumn> = [];

  slotElements: any[];
  selectedSlot: any;

  dataSource;
  displayedColumns = ['select', 'lastName', 'name', 'phone', 'email', 'lastModified', 'actions'];

  selection = new SelectionModel<Contact>(true, []);
  public resetFilterPaginator: Subject<FilterPaginatorResetMode> = new Subject();
  public setFilterState: BehaviorSubject<FilterPageEvent> = new BehaviorSubject(null);
  public dataLength: number;

  targetSlotControl = new FormControl('');
  filteredSlotOptions: Observable<any[]>;

  private isAllSelected = false;
  private currentRequest: PaginatedDataRequest;
  private processSortEvent = true;

  constructor(
    public contactsService: ContactsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private uiService: UIService,
    private slotsService: SlotsService
  ) {}

  emitSelectedContacts() {
    this.selectedContacts.emit(this.selection.selected);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected) {
      const currentSet = new Set(this.dataSource.data.map((el) => el.contactId));
      const filtered = this.selection.selected.filter((element) => !currentSet.has(element.contactId));
      this.selection.clear();
      this.selection.select(...filtered);
      this.isAllSelected = false;
    } else {
      const filteredData = this.dataSource.data.filter(
        (x) => !this.selection.selected.some((y) => y.contactId === x.contactId)
      );
      this.selection.select(...filteredData);
      this.isAllSelected = true;
    }
  }

  checkAllSelected(contacts = []) {
    const selectedMap = new Map(contacts.map((con) => [con.contactId, false]));
    this.selection.selected.forEach((el) => {
      if (selectedMap.has(el.contactId)) {
        selectedMap.set(el.contactId, true);
      }
    });
    if ([...selectedMap.values()].some((val) => val === false)) {
      this.isAllSelected = false;
    } else {
      this.isAllSelected = true;
    }
  }

  filterSlots(value) {
    let filtered = this.slotElements;
    if (value == null) {
      return filtered;
    }
    const lowered = value ? value.toLowerCase?.() : '';
    if (typeof value === 'string') {
      this.selectedSlot = null;

      filtered = this.slotElements.filter((el) => this.getFullName(el).toLowerCase().includes(lowered));
    } else {
      this.selectedSlot = value;
      // eslint-disable-next-line
      filtered = filtered.filter((options) => options.key == value.key);
    }
    return filtered;
  }

  getFullName(slot) {
    return `${slot.isDataInjestor ? 'DI-' : ''}${slot.title}`;
  }

  ngOnInit(): void {
    this.filteredSlotOptions = this.targetSlotControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterSlots(value || ''))
    );
    this.contactsService.paginatedContacts.subscribe((paginatedContacts) => {
      if (paginatedContacts) {
        this.contacts = paginatedContacts.data.map((x) => new Contact(x));
        this.dataLength = paginatedContacts.totalCount;
      } else {
        this.contacts = [];
        this.dataLength = 0;
      }
      this.dataSource = new MatTableDataSource<Contact>(this.contacts);
      this.checkAllSelected(this.contacts);
      const selected = this.selection.selected;
      this.selection.clear();
      this.selection.select(...selected);
      this.selectedContacts.emit(this.selection.selected);
      this.uiService.toggleGlobalSpinner(false);
    });
    // reset grid
    this.reset.subscribe(() => {
      this.resetFilterPaginator.next(FilterPaginatorResetMode.ALL);
    });
    // change internal state
    this.setState.subscribe((state: PaginatedDataRequest) => {
      if (state) {
        this.currentRequest = state;
        if (state.sortedBy && state.sortDirection) {
          this.processSortEvent = false;
          this.matSort.sort({
            id: state.sortedBy,
            start: state.sortDirection.toLowerCase() as any,
            disableClear: false,
          });
        }

        // forward the state to the filter and paginator component
        this.setFilterState.next({
          filterString: state.filterString,
          pageSize: state.pageSize,
          pageNumber: state.pageNumber,
        });
      }
    });
    this.slotsService.slots.subscribe((values: Slot[]) => this.onSlotReload(values));
  }

  toggleSelection(contact) {
    // TODO: check this and do it right
    if (this.isContactSelected(contact)) {
      const selected = this.selection.selected.filter((x) => x.contactId !== contact.contactId);
      this.selection.clear();
      this.selection.select(...selected);
    } else {
      this.selection.select(contact);
    }
  }

  isContactSelected(contact: Contact) {
    return this.selection.selected.some((sel) => sel.contactId === contact.contactId);
  }

  public onSlotReload(values: Slot[]) {
    if (values !== null && values !== undefined) {
      const elements = values.map((slot) => ({
        isDataInjestor: slot.isDataIngestor,
        key: slot.slotId,
        title: slot.name,
        count: slot.negotiationCount,
      }));
      this.slotElements = elements;
      this.targetSlotControl.setValue(this.targetSlotControl.value);
    }
  }

  public onEdit(contact) {
    this.dialog.open(AddContactComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: contact,
    });
  }

  public onDelete(contact) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Contact?` },
      })
      .afterClosed()
      .subscribe(
        (confirm: boolean) => {
          if (confirm) {
            // TODO: all this should be moved to the contacts-screen page, the grid should only emit a "delete" event
            this.uiService.toggleGlobalSpinner(true);
            this.contactsService.deleteContact(contact.contactId).subscribe(
              () => {
                this.sendDataRequest(this.currentRequest);
                this.uiService.toggleGlobalSpinner(false);
                this.snackBar.open(`Contact deleted successfully.`, null, {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 3000,
                });
              },
              (err) => {
                this.uiService.toggleGlobalSpinner(false);
                this.snackBar.open(`Unable to delete contact.`, null, {
                  horizontalPosition: 'center',
                  verticalPosition: 'bottom',
                  duration: 3000,
                });
              }
            );
          }
        },
        (err) => {
          this.uiService.toggleGlobalSpinner(false);
        }
      );
  }

  public onFilterPage(event: FilterPageEvent) {
    const dataRequest: PaginatedDataRequest = {
      pageSize: event.pageSize,
      pageNumber: event.pageNumber,
      filterByParameter: ['LASTNAME', 'NAME', 'EMAIL'],
      filterString: event.filterString,
      sortedBy: this.currentRequest?.sortedBy,
      sortDirection: this.currentRequest?.sortDirection,
    };
    this.sendDataRequest(dataRequest);
  }

  public sortContacts(sort: Sort) {
    if (this.processSortEvent) {
      this.resetFilterPaginator.next(FilterPaginatorResetMode.PAGINATOR);
      if (this.currentRequest) {
        this.currentRequest.pageNumber = 1;
        this.currentRequest.sortedBy = sort.active;
        this.currentRequest.sortDirection = sort.direction.toUpperCase();
        this.sendDataRequest(this.currentRequest);
      }
    } else {
      this.processSortEvent = true;
    }
  }

  selectSlot(value) {
    const el = this.slotElements.find((slot) => slot.key === value);
    if (el) {
      this.selectedSlot = el;
      this.selectedSlot.titleAction();
    }
  }

  assignContactsClick() {
    if (this.selection.selected.length === 0) {
      this.dialog.open(AlertComponent, {
        data: 'You must select at least one contact',
      });
    } else {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: { message: `Are you sure you want to create negotiations for these contacts in the selected channel?` },
        })
        .afterClosed()
        .subscribe(
          (confirm: boolean) => {
            if (confirm) {
              this.uiService.toggleGlobalSpinner(true);
              const contactIds = this.selection.selected.map((contact) => contact.contactId);
              this.slotsService.assignContactsToSlot(contactIds, this.selectedSlot).subscribe(
                (res) => {
                  this.uiService.toggleGlobalSpinner(false);
                  this.showMessage('New Negotiations were successfully created');
                },
                (err) => {
                  this.uiService.toggleGlobalSpinner(false);
                  this.showMessage('Unable to create new negotiations for the selected contacts');
                  let errorMsg = null;
                  try {
                    errorMsg = JSON.parse(err.error).title;
                  } catch (er) {
                    errorMsg = err.message ? err.message : err;
                  }
                  this.dialog.open(AlertComponent, {
                    data: errorMsg,
                  });
                }
              );
            }
          },
          (err) => {
            this.uiService.toggleGlobalSpinner(false);
            this.showMessage('Unable to create new negotiations for the selected contacts');
          }
        );
    }
  }

  assignContacts($event) {
    if (this.selection.selected.length === 0) {
      this.selection.select($event.item.data);
    }
    this.assignContactsClick();
  }

  slotDisplayFn(nt: any): string {
    // eslint-disable-next-line
    const el = this.slotElements.find((se) => se.key == nt);
    this.selectedSlot = el?.key;
    return el ? (el.isDataInjestor ? 'DI-' : ' ') + el.title : '';
  }

  exportContacts() {
    this.contactsService
      .exportContacts()
      .toPromise()
      .then((res) => {
        const data: Blob = new Blob([res], {
          type: 'text/csv;charset=utf-8',
        });
        downloadFileResponse(data, 'contacts.csv');
      })
      .catch((err) => this.uiService.showMessage(err?.message));
  }

  private sendDataRequest(dataRequest: PaginatedDataRequest) {
    this.currentRequest = dataRequest;
    this.uiService.toggleGlobalSpinner(true);
    this.refresh.emit(dataRequest);
  }

  private showMessage(message: string) {
    this.snackBar.open(message, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 8000,
    });
  }
}

export interface IGridColumn {
  name: string;
  caption: string;
  isDynamic: boolean;
  dynamicContactColumnId: any;
}
