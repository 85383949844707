import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateButton } from 'app/model/whatsapp-option-template-button';

@Component({
  selector: 'app-edit-whatsapp-template-button-dialog',
  templateUrl: './edit-whatsapp-template-button-dialog.component.html',
  styleUrls: ['./edit-whatsapp-template-button-dialog.component.css'],
})
export class EditWhatsappTemplateButtonDialogComponent implements OnInit {

  templateButtonCreateForm: FormGroup;
  whatsappTemplateButton: WhatsappOptionTemplateButton;

  constructor(
    public dialogRef: MatDialogRef<EditWhatsappTemplateButtonDialogComponent>,
    private formBuilder: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data: WhatsappOptionTemplate
  ) {
    this.whatsappTemplateButton = new WhatsappOptionTemplateButton('', '');
    this.buildTemplateButtonCreateForm(this.whatsappTemplateButton);
  }


  ngOnInit(): void { }

  buildTemplateButtonCreateForm(whatsappTemplateButton: WhatsappOptionTemplateButton) {
    this.templateButtonCreateForm = this.formBuilder.group({
      text: whatsappTemplateButton.text,
      url: whatsappTemplateButton.url
    });
  }

  onSubmit(): void {
    const templateButton = new WhatsappOptionTemplateButton(this.templateButtonCreateForm.value.text, this.templateButtonCreateForm.value.url);
    this.data.whatsappOptionTemplateButtons.push(templateButton);
    this.dialogRef.close();
  }

  onCancel() {
    this.dialogRef.close();
  }

}
