import { Injectable } from '@angular/core';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateContentExpanded } from 'app/model/whatsapp-option-template-content-expanded';
import { WhatsappOptionTemplateContentStatus } from 'app/model/whatsapp-option-template-content-status';
import { WhatsappParameterOption } from 'app/model/whatsapp-parameter-option';
import { BehaviorSubject, Observable } from 'rxjs';
import { StudioApiService } from './studio.api.service';

const whatsappParameterOptions = [
  new WhatsappParameterOption('negotiationId', 'Negotiation Id'),
  new WhatsappParameterOption('negotiationOwner', 'Negotiation Owner'),
  new WhatsappParameterOption('negotiationOwnerCompany', 'Negotiation Owner Company'),
  new WhatsappParameterOption('negotiationExpirationDate', 'Negotiation Expiration Date'),
  new WhatsappParameterOption('negotiationDescription', 'Negotiation Description'),
  new WhatsappParameterOption('ccpLink', 'CCP Link'),
  new WhatsappParameterOption('contactName', 'Contact First Name'),
  new WhatsappParameterOption('contactLastName', 'Contact Last Name'),
  new WhatsappParameterOption('contactFullName', 'Contact Full Name'),
  new WhatsappParameterOption('contactMessage', 'Contact Message'),
  new WhatsappParameterOption('externalDocumentId', 'External Document Id'),
  new WhatsappParameterOption('selectedOptionTitle', 'Selected Option Title'),
  new WhatsappParameterOption('selectedOptionDescription', 'Selected Option Description'),
];

@Injectable({
  providedIn: 'root',
})
export class StudioService {
  $nodeDetails = new BehaviorSubject<any>(null);
  $isLoadingInfo = new BehaviorSubject<boolean>(false);

  constructor(private studioApiService: StudioApiService) {
  }

  getWhatsappTemplates(): Observable<WhatsappOptionTemplate[]> {
    return this.studioApiService.getWhatsappOptionTemplatesList();
  }

  getPendingWhatsappOptionTemplateContentsList(): Observable<WhatsappOptionTemplateContentExpanded[]> {
    return this.studioApiService.getPendingWhatsappOptionTemplateContentsList();
  }

  createWhatsappOptionTemplateForReview(whatsappOptionTemplate: WhatsappOptionTemplate): Observable<number> {
    return this.studioApiService.createWhatsappOptionTemplateForReview(whatsappOptionTemplate);
  }

  reviewWhatsappOptionTemplate(
    whatsappOptionTemplateContentId: number,
    status: WhatsappOptionTemplateContentStatus,
    contentSid: string
  ): Observable<void> {
    return this.studioApiService.reviewWhatsappOptionTemplate(whatsappOptionTemplateContentId, status, contentSid);
  }

  getWhatsappParameterOptions(): WhatsappParameterOption[] {
    return whatsappParameterOptions;
  }

  clear() {
    this.$nodeDetails.next(null);
  }

  setNodeDetails(node) {
    this.$isLoadingInfo.next(true);
    this.$nodeDetails.next(null);
    this.$nodeDetails.next(node);
    this.$isLoadingInfo.next(false);
  }
}
