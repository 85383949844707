import { HttpStatusCode } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Flow } from 'app/model/flow';
import { FlowsService } from 'app/services/flows.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { NegotiationType } from 'src/app/model/negotiation-type';
import { AddFlowComponent } from '../add-flow/add-flow.component';
import { CloneFlowComponent } from '../clone-flow/clone-flow.component';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { DomSanitizer } from '@angular/platform-browser';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-studio-menu-bar',
  templateUrl: './studio-menu-bar.component.html',
  styleUrls: ['./studio-menu-bar.component.css'],
})
export class StudioMenuBarComponent implements OnInit {
  public filteredNegotiationTypeOptions: Observable<string[]>;
  public negotiationTypeControl = new FormControl();
  public filteredFlowsOptions: Observable<Flow[]>;
  public flowControl = new FormControl();
  public downloadUrl;

  // Negotiation Types
  private allNegotiationTypes = NegotiationType;
  private negotiationTypesOptions: { [key: string]: string }[];

  // Flows
  private flowsOptions: Flow[];

  constructor(
    private uiService: UIService,
    private flowsService: FlowsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {
    this.negotiationTypesOptions = Object.keys(this.allNegotiationTypes)
      .filter((k) => !isNaN(Number(k)) && Number(k) < 3)
      .map((key) => ({ key, value: this.allNegotiationTypes[key] }));
  }

  ngOnInit() {
    this.filteredNegotiationTypeOptions = this.negotiationTypeControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterNegotiationTypeOptions(value))
    );
    this.flowsService.flows.subscribe((flows) => this.setFlows(flows));
    this.flowControl.valueChanges.subscribe((changes) => this.flowsService.selectFlow(changes));
  }

  onNegotiationTypeSelection(event) {
    this.flowControl.setValue(null);
  }

  negotiationTypeDisplayFn(nt: any): string {
    if (nt == null) {
      return '(All Negotiation Types)';
    }
    return this.capitalize(NegotiationType[nt.key].toLowerCase());
  }

  flowDisplayFn(flow: Flow): string {
    if (flow != null) {
      return flow.name;
    }
    return null;
  }

  onNewFlow() {
    this.dialog.open(AddFlowComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: { formControl: this.flowControl, isEdit: false },
    });
  }

  onEditFlow() {
    if (!this.isFlowSelected()) {
      return;
    }
    this.dialog.open(AddFlowComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: { formControl: this.flowControl, isEdit: true },
    });
  }

  onSaveAs() {
    if (!this.isFlowSelected()) {
      return;
    }
    const value = this.flowControl.value;
    this.dialog.open(CloneFlowComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: { flowId: value.flowId },
    });
  }

  async onExportFlow() {
    if (!this.isFlowSelected()) {
      return;
    }
    const confirmExport = await this.confirmAction('Do you want to export the selected Flow?');
    if (!confirmExport) {
      return;
    }
    this.flowsService.getExportFlow(this.flowControl.value.flowId).subscribe((flow) => {
      this.generateFile(flow);
    });
  }

  generateFile(flow: Flow) {
    const sJson = JSON.stringify(flow);
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=UTF-8,' + encodeURIComponent(sJson));
    const timestamp = new Date().getTime();
    element.setAttribute('download', `export_flow_${timestamp}.json`);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click(); //simulate click
    document.body.removeChild(element);
  }

  async onDelete() {
    if (!this.isFlowSelected()) {
      return;
    }
    const confirmDelete = await this.confirmAction('Are you sure you want to delete the selected Flow?');
    if (!confirmDelete) {
      return;
    }
    try {
      this.uiService.toggleGlobalSpinner(true);
      const flow = this.flowControl.value;
      await this.flowsService.deleteFlow(flow.flowId).toPromise();
      // remove from dropdown list
      this.flowControl.setValue(null);
      const filteredFlows = this.flowsOptions.filter((x) => x.flowId !== flow.flowId);
      // TODO: the following line should be "this.setFlows(filteredFlows)" but this component is not reloading the flows on init
      this.flowsService.flows.next(filteredFlows);
    } catch (err) {
      if (err.status === HttpStatusCode.Unauthorized) {
        this.showError('You are not authorized to delete the selected Flow.');
      } else if (err.status === HttpStatusCode.Conflict) {
        this.showError('The selected Flow is in use and cannot be deleted.');
      } else {
        this.showError('Unable to delete Flow. Some negotiations are probably using it.');
      }
    } finally {
      this.uiService.toggleGlobalSpinner(false);
    }
  }

  capitalize(text: string, splitStr: string = '_'): string {
    return text && splitStr
      ? text
          .toLowerCase()
          .split(splitStr)
          .map((x) => x.charAt(0).toUpperCase() + x.substring(1, x.length))
          .join(' ')
      : text;
  }

  private setFlows(flows: Flow[]) {
    this.flowsOptions = flows;
    this.filteredFlowsOptions = this.flowControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterFlowOptions(value))
    );
  }

  private async confirmAction(message: string): Promise<boolean> {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
      data: { message },
    });
    const result = await dialogRef.afterClosed().toPromise();
    return result ?? false;
  }

  private isFlowSelected(): boolean {
    const value = this.flowControl.value;
    if (value == null || typeof value == 'string') {
      this.showError('No Flow is selected.');
      return false;
    } else {
      return true;
    }
  }

  private showError(message: string) {
    this.snackBar.open(message, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
  }

  private _filterNegotiationTypeOptions(value: any): any[] {
    let filtered = this.negotiationTypesOptions;
    if (value == null) {
      return filtered;
    }
    if (typeof value === 'string') {
      if (value.length !== 0) {
        filtered = filtered.filter((options) => options.value.toLowerCase().includes(value.toLowerCase()));
      }
    } else {
      filtered = filtered.filter((options) => options.key.toLowerCase().includes(value.key.toLowerCase()));
    }
    return filtered;
  }

  private filterFlowOptions(value: any): Flow[] {
    const selectedNegotiationType = this.negotiationTypeControl.value;
    let filtered = this.flowsOptions;
    if (selectedNegotiationType != null) {
      filtered = filtered.filter((flow) => flow.type == selectedNegotiationType.key);
    }
    if (value != null) {
      if (typeof value === 'string') {
        filtered = filtered.filter((flow) => flow.name.toLowerCase().includes(value.toLowerCase()));
      } else {
        filtered = filtered.filter((flow) => flow.name.toLowerCase().includes(value.name.toLowerCase()));
      }
    }
    return filtered;
  }
}
