import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Company } from 'app/model/company';
import { CompanySubscriptionType } from 'app/model/company-subscription-type';
import { CompanyService } from 'app/services/company.service';
import { SendersService } from 'app/services/senders.service';
import { UIService } from 'core/services/ui.service';
import { CompanyEnterpriseCreateComponent } from '../company-enterprise-create/company-enterprise-create.component';
import { CompanyEnterpriseEditPermissionsComponent } from '../company-enterprise-edit-permissions/company-enterprise-edit-permissions.component';
import { SenderModalComponent } from '../sender-modal/sender-modal.component';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css'],
})
export class CompanyListComponent implements OnInit {
  displayedContentColumns = ['companyId', 'companyName', 'companySubscriptionType', 'companyActions'];
  public contentDataSource: MatTableDataSource<Company>;
  companySubscriptionType = CompanySubscriptionType;

  constructor(
    public dialog: MatDialog,
    private companyService: CompanyService,
    public uiService: UIService,
    private sendersService: SendersService
  ) { }

  ngOnInit(): void {
    this.contentDataSource = new MatTableDataSource<Company>();
    this.loadData();
  }

  public onNewCompany() {
    const dialogRef = this.dialog.open(CompanyEnterpriseCreateComponent);
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.loadData();
      }
    });
  }

  public openSender(company) {
    this.uiService.toggleGlobalSpinner(true);
    this.sendersService
      .getSenderById(company.companyId)
      .then((res) => {
        this.dialog.open(SenderModalComponent, {
          panelClass: 'custom-dialog-container',
          width: '500px',
          data: {
            sender: res,
            companyId: company.companyId,
          },
        });
      })
      .catch((err) => {
        this.uiService.showMessage(err?.message);
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  private loadData() {
    this.companyService.getAllExpandedWithSubscriptions().subscribe((companies) => {
      this.contentDataSource.data = companies;
    });
  }

  public onEditCompanyEnterprisePermissions(company: Company) {
    const dialogRef = this.dialog.open(CompanyEnterpriseEditPermissionsComponent, {
      data: company
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.loadData();
      }
    });
  }
}
