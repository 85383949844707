<div class="table-container">
  <div class="table-title-section">
    <label class="table-title">Companies</label>
    <button mat-button (click)="onNewCompany()" title="New Company (Enterprise subscription)">
      <mat-icon>add_to_photos</mat-icon>
    </button>
  </div>
  <table mat-table [dataSource]="contentDataSource" class="table" fixedLayout>
    <ng-container matColumnDef="companyId">
      <th mat-header-cell *matHeaderCellDef class="th-company-id">Company Id</th>
      <td mat-cell *matCellDef="let element" class="td-company-id">{{ element.companyId }}</td>
    </ng-container>
    <ng-container matColumnDef="companyName">
      <th mat-header-cell *matHeaderCellDef class="th-company-name">Company Name</th>
      <td mat-cell *matCellDef="let element" class="td-company-name">{{ element.name }}</td>
    </ng-container>
    <ng-container matColumnDef="companySubscriptionType">
      <th mat-header-cell *matHeaderCellDef class="th-company-subscription-type">Subscription Type</th>
      <td mat-cell *matCellDef="let element" class="td-company-subscription-type">
        {{
        element.companySubscriptions[0] != null
        ? companySubscriptionType[element.companySubscriptions[0].companySubscriptionType]
        : ''
        }}
      </td>
    </ng-container>
    <ng-container matColumnDef="companyActions">
      <th mat-header-cell *matHeaderCellDef class="th-company-actions">Actions</th>
      <td mat-cell *matCellDef="let element" class="th-company-actions">
        <mat-icon (click)="openSender(element)" matTooltip="Sender">phone</mat-icon>
        <mat-icon (click)="onEditCompanyEnterprisePermissions(element)" matTooltip="Edit permissions (Enterprise subscriptions)"
          *ngIf="companySubscriptionType[element.companySubscriptions[0]?.companySubscriptionType] == 'ENTERPRISE'">lock_open</mat-icon>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedContentColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedContentColumns"></tr>
  </table>
</div>