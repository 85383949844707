import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ContentTemplateType, CreateTemplateRequest } from '../model';
import { NEGOTIATION_VARIABLE_SAMPLES } from 'app/model/studio-negotiation-variables';
import { ContentTemplateApiService } from 'app/services/content-template';

@Component({
  selector: 'app-content-template-preview',
  templateUrl: './content-template-preview.component.html',
  styleUrls: ['./content-template-preview.component.css'],
})
export class ContentTemplatePreviewComponent implements OnInit {
  @Input() public contentData: CreateTemplateRequest;
  @Input() public selectedType: ContentTemplateType;
  @Output() public onCancelled = new EventEmitter<string>();
  @Output() public onSubmit = new EventEmitter<string>();
  @Output() public onBack = new EventEmitter<string>();
  public sampleBody: string;
  public linkType: string;

  constructor(
    private contentTemplateApiService: ContentTemplateApiService
  ) { }

  ngOnInit(): void {
    if(this.contentData) {
      this.setSampleBody();
      this.setLinkFileType();
      // console.log(this.contentData.media);
    }
  }

  public setSampleBody() {
    this.sampleBody = this.contentData?.body;
    this.contentData.variables.forEach((x, index) => {
      this.sampleBody = this.sampleBody.replace('{{'+(index+1)+'}}', '{{'+x+'}}');
    });
    Object.keys(NEGOTIATION_VARIABLE_SAMPLES).forEach(key => {
      this.sampleBody = this.sampleBody.replace('{{'+(key)+'}}', NEGOTIATION_VARIABLE_SAMPLES[key]);
    });
  }

  public onSubmitTemplate() {this.onSubmit.emit(null);}
  public onNavigateBackwards() {this.onBack.emit(null);}
  public onCancel() {this.onCancelled.emit(null);}
  public setLinkFileType() {
    this.linkType = this.contentTemplateApiService.getLinkFileType(this.contentData?.media);
  }
}
