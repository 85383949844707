import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';
import { DocumentedFeature } from 'app/model/documentation/documented-feature';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DocumentationEntry } from 'app/model/documentation/documentation-entry';
import { CompanySettingsService } from 'app/components/settings';
import { SimpleCompanySettings } from 'app/components/settings/model/simple-company-settings';

@Component({
  selector: 'app-documentation-details',
  templateUrl: './documentation-details.component.html',
  styleUrls: ['./documentation-details.component.css'],
})
export class DocumentationDetailsComponent implements OnInit {
  private baseUrl = window.location.origin + '/mdi/';
  public documentation: Array<DocumentedFeature>;
  public url: string = undefined;

  constructor(
    private clipboard: Clipboard,
    private snackBar: MatSnackBar,
    private companySettingsService: CompanySettingsService
  ) {
    this.documentation = this.GetHardcodedDocumentation();
    this.companySettingsService.$companyData.subscribe((companySettings: SimpleCompanySettings) => {
      if (companySettings?.companyId) {
        this.url = this.baseUrl + companySettings?.companyId;
        this.addIframeDocumentation();
      }
    });
  }

  ngOnInit(): void {}

  public copyToClipboard(content: string) {
    this.clipboard.copy(content);
    this.snackBar.open(`Content has been copied to clipboard`, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 5000,
    });
  }

  public scrollToElement(id: string) {
    let elem = document.getElementById(id);
    elem.scrollIntoView({ behavior: 'smooth' });
  }

  private GetHardcodedDocumentation() {
    let rawAbstract = {
      name: '1. API Documentation',
      entries: [
        {
          title: '',
          content:
            'There are different API endpoints that can be called depending on the type of security used. The most secure and therefore less restrictive one is by using client credentials to get a JWT (JSON Web Token), and then using that JWT to call other endpoints. These credentials are intended to be used in a backend service, since they’re composed of a ClientId and a ClientSecret, and the secret values can only be properly secured in a private server, which is not the case of frontend applications. For cases where a backend server is not available, API Key based endpoints are provided, but these are less secure and have limited features.',
          samples: [],
        },
      ],
    };
    let abstract = Object.assign(new DocumentedFeature(), rawAbstract);
    abstract.entries = abstract.entries.map((rawEntry) => Object.assign(new DocumentationEntry(), rawEntry));

    let rawClientCredentials = {
      name: '2. Endpoints based in Client Credentials',
      entries: [
        {
          title: '2.1 Obtaining a JWT',
          content:
            'These credentials can be obtained by contacting SigneDeal’s team. These should be stored in a backend server’s configuration or a database to prevent them from being read by unintended third parties. Once in possession of these credentials, a JWT can be obtained by calling SigneDeal’s API as follows:',
          samples: [
            {
              language: 'cURL',
              content: `
          curl --location --request POST 'https://negotiation-api-prod.azurewebsites.net/api/channels/get-token'
          --header 'Content-Type: application/json'
          --data-raw '{
              "clientId": "{{ClientIdGoesHere}}",
              "clientSecret": "{{ClientSecretGoesHere}}"
          }'`,
            },
            {
              language: 'C# - RestSharp',
              content: `
            var client = new RestClient("https://negotiation-api-prod.azurewebsites.net/api/channels/get-token");
            client.Timeout = -1;
            var request = new RestRequest(Method.POST);
            request.AddHeader("Content-Type", "application/json");
            var body = @"{
            " + "\\n" +
            @"    ""clientId"": ""{{ClientIdGoesHere}}"",
            " + "\\n" +
            @"    ""clientSecret"": ""{{ClientSecretGoesHere}}""
            " + "\\n" +
            @"}";
            request.AddParameter("application/json", body,  ParameterType.RequestBody);
            IRestResponse response = client.Execute(request);
            Console.WriteLine(response.Content);`,
            },
            {
              language: 'JavaScript - jQuery',
              content: `
            var settings = {
              "url": "https://negotiation-api-prod.azurewebsites.net/api/channels/get-token",
              "method": "POST",
              "timeout": 0,
              "headers": {
                "Content-Type": "application/json"
              },
              "data": JSON.stringify({
                "clientId": "{{ClientIdGoesHere}}",
                "clientSecret": "{{ClientSecretGoesHere}}"
              }),
            };
            
            $.ajax(settings).done(function (response) {
              console.log(response);
            });`,
            },
            {
              language: 'NodeJs - Request',
              content: `
            var request = require('request');
            var options = {
              'method': 'POST',
              'url': 'https://negotiation-api-prod.azurewebsites.net/api/channels/get-token',
              'headers': {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({
                "clientId": "{{ClientIdGoesHere}}",
                "clientSecret": "{{ClientSecretGoesHere}}"
              })
            
            };
            request(options, function (error, response) {
              if (error) throw new Error(error);
              console.log(response.body);
            });
            `,
            },
            {
              language: 'Python - http.client',
              content: `
            import http.client
            import json
            
            conn = http.client.HTTPSConnection("negotiation-api-prod.azurewebsites.net")
            payload = json.dumps({
              "clientId": "{{ClientIdGoesHere}}",
              "clientSecret": "{{ClientSecretGoesHere}}"
            })
            headers = {
              'Content-Type': 'application/json'
            }
            conn.request("POST", "/api/channels/get-token", payload, headers)
            res = conn.getresponse()
            data = res.read()
            print(data.decode("utf-8"))`,
            },
          ],
        },
        {
          title: '',
          content:
            'The obtained response contains an access token and the remaining time during which this token will be valid, expressed in seconds. An example of this response is shown as follows:',
          samples: [
            {
              language: 'JSON',
              content: `
          {
            "access_token": "{{TokenGoesHere}}",
            "token_type": "Bearer",
            "expires_in": "86400"
          }`,
            },
          ],
        },
        {
          title: '',
          content:
            'The API will return the same token in subsequent calls until the obtained token is no longer valid. Also, the remaining time (expires_in) will be updated on each new response.',
          samples: [],
        },
        {
          title: '2.2 Obtaining the current list of Data Ingestor Channels',
          content:
            'After configuring a Channel as a Data Ingestor, a list of all channels with this configuration can be retrieved through an API call with a JWT. A sample of this API call is shown below.',
          samples: [
            {
              language: 'cURL',
              content: `
          curl --location --request GET 'https://negotiation-api-prod.azurewebsites.net/api/channels/subscriptions'
          --header 'Authorization: {{TokenGoesHere}}'`,
            },
            {
              language: 'C# - RestSharp',
              content: `
          var client = new RestClient("https://negotiation-api-prod.azurewebsites.net/api/channels/subscriptions");
          client.Timeout = -1;
          var request = new RestRequest(Method.GET);
          request.AddHeader("Authorization", "{{TokenGoesHere}}");
          IRestResponse response = client.Execute(request);
          Console.WriteLine(response.Content);`,
            },
            {
              language: 'JavaScript - jQuery',
              content: `
          var settings = {
            "url": "https://negotiation-api-prod.azurewebsites.net/api/channels/subscriptions",
            "method": "GET",
            "timeout": 0,
            "headers": {
              "Authorization": "{{TokenGoesHere}}"
            },
          };
          
          $.ajax(settings).done(function (response) {
            console.log(response);
          });`,
            },
            {
              language: 'NodeJs - Request',
              content: `
          var request = require('request');
          var options = {
            'method': 'GET',
            'url': 'https://negotiation-api-prod.azurewebsites.net/api/channels/subscriptions',
            'headers': {
              'Authorization': '{{TokenGoesHere}}'
            }
          };
          request(options, function (error, response) {
            if (error) throw new Error(error);
            console.log(response.body);
          });
`,
            },
            {
              language: 'Python - http.client',
              content: `
          import http.client

          conn = http.client.HTTPSConnection("negotiation-api-prod.azurewebsites.net")
          payload = ''
          headers = {
            'Authorization': '{{TokenGoesHere}}'
          }
          conn.request("GET", "/api/channels/subscriptions", payload, headers)
          res = conn.getresponse()
          data = res.read()
          print(data.decode("utf-8"))`,
            },
          ],
        },
        {
          title: '',
          content:
            'A typical response to that API call looks as follows. In this response, a slot is a different way of naming a channel.',
          samples: [
            {
              language: 'JSON',
              content: `
          [{
            "slotId": "1564",
            "name": "GYM & SPA"
          }, {
              "slotId": "1565",
              "name": "ENTERTAINMENT"
          }]`,
            },
          ],
        },
        {
          title: '2.3 Subscribing a Contact to a Channel/Ingesting Contacts',
          content:
            'Using the channel names obtained from the response in point 2.2, a contact can be subscribed to an existing Data Ingestor Channel. New contacts and existing ones can be used. The contact email is used to distinguish new contacts from old ones, so sending a request with an email that already exists will result in all other contact fields being updated. As for subscriptions, this endpoint can only add new subscriptions, it cannot delete existing ones, so calling it multiple times for a given contact with the same channel name will result in the channels flow running multiple times for that contact. This API call can be done as follows:',
          samples: [
            {
              language: 'cURL',
              content: `
          curl --location --request POST 'https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions'
          --header 'Content-Type: application/json'
          --header 'Authorization: {{TokenGoesHere}}'
          --data-raw '{
              "name": "John",
              "lastName": "Doe",
              "email": "johndoesample@gmail.com",
              "phone": "+1781231234",
              "language": "en-US",
              "channels": ["GYM & SPA", "ENTERTAINMENT"]
          }'`,
            },
            {
              language: 'C# - RestSharp',
              content: `
          var client = new RestClient("https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions");
          client.Timeout = -1;
          var request = new RestRequest(Method.POST);
          request.AddHeader("Content-Type", "application/json");
          request.AddHeader("Authorization", "{{TokenGoesHere}}");
          var body = @"{
          " + "\\n" +
          @"    ""name"": ""John"",
          " + "\\n" +
          @"    ""lastName"": ""Doe"",
          " + "\\n" +
          @"    ""email"": ""johndoesample@gmail.com"",
          " + "\\n" +
          @"    ""phone"": ""+1781231234"",
          " + "\\n" +
          @"    ""language"": ""en-US"",
          " + "\\n" +
          @"    ""channels"": [""GYM & SPA"", ""ENTERTAINMENT""]
          " + "\\n" +
          @"}";
          request.AddParameter("application/json", body,  ParameterType.RequestBody);
          IRestResponse response = client.Execute(request);
          Console.WriteLine(response.Content);`,
            },
            {
              language: 'JavaScript - jQuery',
              content: `
          var settings = {
            "url": "https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions",
            "method": "POST",
            "timeout": 0,
            "headers": {
              "Content-Type": "application/json",
              "Authorization": "{{TokenGoesHere}}"
            },
            "data": JSON.stringify({
              "name": "John",
              "lastName": "Doe",
              "email": "johndoesample@gmail.com",
              "phone": "+1781231234",
              "language": "en-US",
              "channels": [
                "GYM & SPA",
                "ENTERTAINMENT"
              ]
            }),
          };
          
          $.ajax(settings).done(function (response) {
            console.log(response);
          });`,
            },
            {
              language: 'NodeJs - Request',
              content: `
          var request = require('request');
          var options = {
            'method': 'POST',
            'url': 'https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions',
            'headers': {
              'Content-Type': 'application/json',
              'Authorization': '{{TokenGoesHere}}'
            },
            body: JSON.stringify({
              "name": "John",
              "lastName": "Doe",
              "email": "johndoesample@gmail.com",
              "phone": "+1781231234",
              "language": "en-US",
              "channels": [
                "GYM & SPA",
                "ENTERTAINMENT"
              ]
            })

          };
          request(options, function (error, response) {
            if (error) throw new Error(error);
            console.log(response.body);
          });`,
            },
            {
              language: 'Python - http.client',
              content: `
          import http.client
          import json

          conn = http.client.HTTPSConnection("negotiation-api-prod.azurewebsites.net")
          payload = json.dumps({
            "name": "John",
            "lastName": "Doe",
            "email": "johndoesample@gmail.com",
            "phone": "+1781231234",
            "language": "en-US",
            "channels": [
              "GYM & SPA",
              "ENTERTAINMENT"
            ]
          })
          headers = {
            'Content-Type': 'application/json',
            'Authorization': '{{TokenGoesHere}}'
          }
          conn.request("POST", "/api/Channels/subscriptions", payload, headers)
          res = conn.getresponse()
          data = res.read()
          print(data.decode("utf-8"))`,
            },
          ],
        },
        {
          title: '',
          content:
            'Although this endpoint is not intended to ingest contacts without subscribing them to a channel, if the channels list in the JSON request is empty, the provided contact data will be used to either create or update a contact. Finally, the response to this call is an empty one, the success or failure of it can be checked through the status code.',
          samples: [],
        },
      ],
    };
    let clientCredentials = Object.assign(new DocumentedFeature(), rawClientCredentials);
    clientCredentials.entries = clientCredentials.entries.map((rawEntry) =>
      Object.assign(new DocumentationEntry(), rawEntry)
    );

    let rawApiKeys = {
      name: '3. Endpoints Based in API Keys',
      entries: [
        {
          title: '3.1 Subscribing a Contact to a Channel/Ingesting Contacts through API Key',
          content:
            'Currently, for security reasons, there’s only one endpoint that is API key based. This endpoint was created as an option to be used from a frontend application if no API was available, but it’s recommended to rather use a version based on client credentials through an API.',
          samples: [],
        },
        {
          title: '',
          content:
            'Using the channel names obtained from the Web User Interface, a contact can be subscribed to an existing Data Ingestor Channel. New contacts and existing ones can be used. The contact email is used to distinguish new contacts from old ones, so sending a request with an email that already exists will result in all other contact fields being updated. As for subscriptions, this endpoint can only add new subscriptions, it cannot delete existing ones, so calling it multiple times for a given contact with the same channel name will result in the channels flow running multiple times for that contact. This API call can be done as follows:',
          samples: [
            {
              language: 'cURL',
              content: `
          curl --location -g --request POST 'https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions/{{ApiKeyGoesHere}}'
          --header 'Content-Type: application/json'
          --data-raw '{
              "name": "John",
              "lastName": "Doe",
              "email": "johndoesample@gmail.com",
              "phone": "+1781231234",
              "language": "en-US",
              "channels": ["GYM & SPA", "ENTERTAINMENT"]
          }'`,
            },
            {
              language: 'C# - RestSharp',
              content: `
          var client = new RestClient("https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions/{{ApiKeyGoesHere}}");
          client.Timeout = -1;
          var request = new RestRequest(Method.POST);
          request.AddHeader("Content-Type", "application/json");
          var body = @"{
          " + "\\n" +
          @"    ""name"": ""John"",
          " + "\\n" +
          @"    ""lastName"": ""Doe"",
          " + "\\n" +
          @"    ""email"": ""johndoesample@gmail.com"",
          " + "\\n" +
          @"    ""phone"": ""+1781231234"",
          " + "\\n" +
          @"    ""language"": ""en-US"",
          " + "\\n" +
          @"    ""channels"": [""GYM & SPA"", ""ENTERTAINMENT""]
          " + "\\n" +
          @"}";
          request.AddParameter("application/json", body,  ParameterType.RequestBody);
          IRestResponse response = client.Execute(request);
          Console.WriteLine(response.Content);`,
            },
            {
              language: 'JavaScript - jQuery',
              content: `
          var settings = {
            "url": "https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions/{{ApiKeyGoesHere}}",
            "method": "POST",
            "timeout": 0,
            "headers": {
              "Content-Type": "application/json"
            },
            "data": JSON.stringify({
              "name": "John",
              "lastName": "Doe",
              "email": "johndoesample@gmail.com",
              "phone": "+1781231234",
              "language": "en-US",
              "channels": [
                "GYM & SPA",
                "ENTERTAINMENT"
              ]
            }),
          };
          
          $.ajax(settings).done(function (response) {
            console.log(response);
          });`,
            },
            {
              language: 'NodeJs - Request',
              content: `
          var request = require('request');
          var options = {
            'method': 'POST',
            'url': 'https://negotiation-api-prod.azurewebsites.net/api/Channels/subscriptions/{{ApiKeyGoesHere}}',
            'headers': {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              "name": "John",
              "lastName": "Doe",
              "email": "johndoesample@gmail.com",
              "phone": "+1781231234",
              "language": "en-US",
              "channels": [
                "GYM & SPA",
                "ENTERTAINMENT"
              ]
            })

          };
          request(options, function (error, response) {
            if (error) throw new Error(error);
            console.log(response.body);
          });`,
            },
            {
              language: 'Python - http.client',
              content: `
          import http.client
          import json

          conn = http.client.HTTPSConnection("negotiation-api-prod.azurewebsites.net")
          payload = json.dumps({
            "name": "John",
            "lastName": "Doe",
            "email": "johndoesample@gmail.com",
            "phone": "+1781231234",
            "language": "en-US",
            "channels": [
              "GYM & SPA",
              "ENTERTAINMENT"
            ]
          })
          headers = {
            'Content-Type': 'application/json'
          }
          conn.request("POST", "/api/Channels/subscriptions/{{ApiKeyGoesHere}}", payload, headers)
          res = conn.getresponse()
          data = res.read()
          print(data.decode("utf-8"))`,
            },
          ],
        },
        {
          title: '',
          content:
            'Although this endpoint is not intended to ingest contacts without subscribing them to a channel, if the channels list in the JSON request is empty, the provided contact data will be used to either create or update a contact. The response to this call is an empty one, the success or failure of it can be checked through the status code.',
          samples: [],
        },
        {
          title: '',
          content:
            'This endpoint provides the same functionality as the one described in section 2.3 but using an API Key in the URL instead of a JWT among the headers. The only exception is that Auto Trigger Channels will not be automatically executed, since the security of this endpoint can potentially be compromised in the case that it is used from a pure frontend solution.',
          samples: [],
        },
      ],
    };
    let apiKeys = Object.assign(new DocumentedFeature(), rawApiKeys);
    apiKeys.entries = apiKeys.entries.map((rawEntry) => Object.assign(new DocumentationEntry(), rawEntry));

    return [abstract, clientCredentials, apiKeys];
  }

  private addIframeDocumentation() {
    let rawInsertingIframe = {
      name: '4. Data Ingestor in an iframe',
      entries: [
        {
          title: '4.1 Basic usage',
          content:
            'To show the data ingestor inside your own website, just copy the code below into the html of your website. The "height" value can be changed. If you use "vh" as units, it will be relative to the height of your browser size. If you use "px" as units it will be a fixed size.',
          samples: [
            {
              language: 'HTML',
              content: `
            <iframe src="${this.url}" style="width:500px; height: 90vh; border-radius:5px;"></iframe>
          `,
            },
          ],
        },
        {
          title: '4.2 with message listener',
          content:
            'To redirect to the landing page when successfully sending the info from the data ingestor in a frame, just can do as in the following options.',
          samples: [
            {
              language: 'HTML',
              content: `
              <script>
                function onIframeMessage(message) {
                  if (message.origin === 'https://slots.signedeal-negotiations.com') {
                    window.location.href = message.data;
                  }
                }        
                window.addEventListener("message", onIframeMessage);
              </script>
              `,
            },
            {
              language: 'Javascript',
              content: `
              function onIframeMessage(message) {
                if (message.origin === 'https://slots.signedeal-negotiations.com') {
                  window.location.href = message.data;
                }
              }        
              window.addEventListener("message", onIframeMessage);
              `,
            },
          ],
        },
        {
          title: '',
          content:
            'Do not forget to unsubscribe from the listener if you added it to a particular component and the component is destroyed at some point',
          samples: [
            {
              language: 'HTML',
              content: `
            <script>
              window.removeEventListener("message", onIframeMessage);
            </script>
            `,
            },
            {
              language: 'Javascript',
              content: `
              window.removeEventListener("message", onIframeMessage);
            `,
            },
          ],
        },
      ],
    };
    const insertingIframe = Object.assign(new DocumentedFeature(), rawInsertingIframe);
    insertingIframe.entries = insertingIframe.entries.map((rawEntry) =>
      Object.assign(new DocumentationEntry(), rawEntry)
    );

    this.documentation.push(insertingIframe);
  }
}
