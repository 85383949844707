import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Schedule } from 'app/model/schedule';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ScheduleApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Scheduler';
  }

  getSlotSchedules(slot: number): Observable<Schedule> {
    return this.httpClient.get<Schedule>(this.baseURL + '/slot/' + slot);

  }

  saveSchedule(schedule: Schedule): Observable<number> {
    return this.httpClient.post<number>(`${this.baseURL}/`, schedule);
  }

  deleteSchedule(slot: number): Observable<any> {
    return this.httpClient.delete<number>(this.baseURL + '/slot/' + slot);
  }

}
