import { Component, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from 'app/model/flow-action-call';
import { ActionSignatureProperties, FlowActionSignature } from 'app/model/flow-action-signature';
import { NegotiationType } from 'app/model/negotiation-type';
import {
  ACCOUNTS_RECEIVABLE_VARIABLES,
  NegotiationVariable,
  OPTIONS_VARIABLES,
} from 'app/model/studio-negotiation-variables';
import { AttachmentsService } from 'app/services/attachments.service';
import { FlowsService } from 'app/services/flows.service';
import { LanguageService } from 'app/services/language.service';
import { HtmlEditorComponent } from 'core/components/html-editor/html-editor.component';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-signature-details',
  templateUrl: './signature-details.component.html',
  styleUrls: ['./signature-details.component.css'],
})
export class SignatureDetailsComponent implements OnChanges {
  @Input() node: FlowActionSignature;
  @ViewChild('textField') textField: HtmlEditorComponent;

  public description = '';
  public languages: string[];
  public language: string;
  public currentMessage: ActionMessage;
  public chosenFile: File;
  public parametersId: number | null = null;
  public flowActionId: number;
  public usePdfDocument: boolean;
  public useSignature: boolean;
  public useNonSignedEmail: boolean;
  public attachmentId: number;
  public attachmentName: string;
  public customVars: NegotiationVariable[] = [];

  private flowActionMessages: ActionMessage[] = [];

  constructor(
    private attachmentsService: AttachmentsService,
    private uiService: UIService,
    private snackBar: MatSnackBar,
    private flowService: FlowsService,
    private languageService: LanguageService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  loadParameters() {
    if (this.node.flowActionSignatureParameter != null) {
      const { id, flowActionId, usePdfDocument, useSignature, useNonSignedEmail, attachmentId, attachment } =
        FlowActionSignature.getProperties(this.node.flowActionSignatureParameter);
      this.parametersId = id;
      this.flowActionId = flowActionId;
      this.usePdfDocument = usePdfDocument;
      this.useSignature = useSignature;
      this.useNonSignedEmail = useNonSignedEmail;
      this.attachmentId = attachmentId;
      this.attachmentName = attachment?.originalName;
    } else {
      this.parametersId = null;
      this.flowActionId = null;
      this.usePdfDocument = false;
      this.useSignature = false;
      this.useNonSignedEmail = false;
      this.attachmentId = null;
      this.attachmentName = null;
    }
  }

  onLanguageSelect() {
    this.updateMessage();
  }

  handleFileChange(fileInput: Event) {
    const control: any = fileInput.target;
    if (!control.files || control.length === 0) {
      this.chosenFile = null;
      this.attachmentName = null;
    } else {
      this.chosenFile = control.files[0];
      this.attachmentName = this.chosenFile.name;
    }
  }

  insertText(text: string) {
    if (this.usePdfDocument) {
      this.snackBar.open('Negotiation variables are not available when using a PDF document', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    this.textField.insertText(text);
  }

  async save() {
    // update the flow action
    this.uiService.toggleGlobalSpinner(true);
    const promises: Array<Promise<any>> = [];
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    const saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        throw err;
      });
    promises.push(saveActionPromise);

    // update the flow action messages
    const messages = [];
    this.flowActionMessages.forEach((msg) =>
      messages.push({
        flowActionId: this.node.flowActionId,
        language: msg.language,
        content: msg.content,
        flowActionMessageId: msg.flowActionMessageId,
      })
    );
    const saveMessagesPromise = this.flowService
      .saveMessages(
        messages as [{ flowActionId: number; language: string; content: string; flowActionMessageId: string }]
      )
      .then(() => {
        this.node.flowActionMessages.forEach((msg) => {
          const newMessage = messages.find((nmsg) => nmsg.flowActionMessageId === msg.flowActionMessageId);
          msg.content = newMessage.content;
        });
      })
      .catch((err) => {
        this.flowActionMessages.forEach((msg) => {
          const realMessage = this.node.flowActionMessages.find(
            (rmsg) => rmsg.flowActionMessageId === msg.flowActionMessageId
          );
          msg.content = realMessage.content;
        });
        throw err;
      });
    promises.push(saveMessagesPromise);

    // Here goes the code to save the signature parameters.
    const signatureProps: ActionSignatureProperties = {
      id: null,
      attachmentId: null,
      attachment: {
        id: null,
        originalName: null,
      },
      usePdfDocument: this.usePdfDocument,
      useSignature: this.useSignature,
      useNonSignedEmail: this.useNonSignedEmail,
      flowActionId: this.node.flowActionId,
    };
    if (this.parametersId) {
      signatureProps.id = this.parametersId;
      await this.flowService
        .updateSignatureProperties(signatureProps)
        .toPromise()
        .then(() => {
          this.node.flowActionSignatureParameter.usePdfDocument = this.usePdfDocument;
          this.node.flowActionSignatureParameter.useSignature = this.useSignature;
          this.node.flowActionSignatureParameter.useNonSignedEmail = this.useNonSignedEmail;
        })
        .catch((err) => {
          this.usePdfDocument = this.node.flowActionSignatureParameter.usePdfDocument;
          this.useSignature = this.node.flowActionSignatureParameter.useSignature;
          this.useNonSignedEmail = this.node.flowActionSignatureParameter.useNonSignedEmail;
          this.uiService.showMessage(err?.message ? err.message : err);
        });
    } else {
      await this.flowService
        .createSignatureProperties(signatureProps)
        .toPromise()
        .then((newId: number) => {
          this.parametersId = newId;
          this.node.flowActionSignatureParameter.id = newId;
          this.node.flowActionSignatureParameter.usePdfDocument = this.usePdfDocument;
          this.node.flowActionSignatureParameter.useSignature = this.useSignature;
          this.node.flowActionSignatureParameter.useNonSignedEmail = this.useNonSignedEmail;
        })
        .catch((err) => {
          this.usePdfDocument = this.node.flowActionSignatureParameter.usePdfDocument;
          this.useSignature = this.node.flowActionSignatureParameter.useSignature;
          this.useNonSignedEmail = this.node.flowActionSignatureParameter.useNonSignedEmail;
          this.uiService.showMessage(err?.message ? err.message : err);
        });
    }
    // End code to save the signature parameters.
    if (this.usePdfDocument === true) {
      const saveSignatureAttachmentPromise = this.attachmentsService
        .postAttachmentByFlowActionSignatureParameter(this.node.flowActionSignatureParameter.id, this.chosenFile)
        .toPromise()
        .then(() => {
          console.log(`Document saved successfully.`);
        })
        .catch((err) => {
          console.error('Error saving document.');
          this.uiService.showMessage(err?.message ? err.message : err);
        });
      promises.push(saveSignatureAttachmentPromise);
    }
    Promise.all(promises)
      .then(() => {})
      .catch((err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'snack-error',
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }
  private updateMessage() {
    let message = this.flowActionMessages.find((x) => x.language === this.language);
    if (message == null) {
      message = new ActionMessage(this.language, '', '');
      this.flowActionMessages.push(message);
    }
    this.currentMessage = message;
  }

  private async init() {
    this.loadFlowVariables();
    await this.loadLanguages();
    this.node.flowActionMessages ??= [];
    this.flowActionMessages = this.node.flowActionMessages.map((msg) => ActionMessage.cloneMsg(msg));
    if (this.flowActionMessages.length > 0) {
      this.language = this.flowActionMessages[0].language;
    }
    this.description = this.node.description;
    this.updateMessage();
    this.loadParameters();
  }

  private loadFlowVariables() {
    if (this.node.flowType === NegotiationType.OPTIONS) {
      this.customVars.push(...OPTIONS_VARIABLES);
    } else if (this.node.flowType === NegotiationType.ACCOUNTS_RECEIVABLE) {
      this.customVars.push(...ACCOUNTS_RECEIVABLE_VARIABLES);
    }
  }

  private async loadLanguages() {
    this.languages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.language = this.languages[0];
  }
}
