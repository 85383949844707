import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { MaintenanceService } from 'app/services/maintenance.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-rebrandly-links',
  templateUrl: './rebrandly-links.component.html',
  styleUrls: ['./rebrandly-links.component.css'],
})
export class RebrandlyLinksComponent implements OnInit {
  public disableDelete: boolean;

  constructor(public maintenanceService: MaintenanceService, public dialog: MatDialog) {
    this.maintenanceService = maintenanceService;
  }

  ngOnInit(): void {
    this.disableDelete = !environment.production;
  }

  public async onDelete(deleteAll: boolean) {
    try {
      const deleteWhat = deleteAll ? 'all' : 'invalid';
      const confirmDialog = this.dialog.open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete ${deleteWhat} Rebrandly links?` },
      });
      const confirmed = await confirmDialog.afterClosed().toPromise();
      if (confirmed) {
        await this.maintenanceService.deleteCcpLinks(deleteAll).toPromise();
      }
    } catch (err) {
      console.error(err);
    }
  }
}
