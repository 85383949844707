export enum TwilioLanguages {
    // AFRIKAANS = 'af',
    // ARABIC = 'ar',
    // CATALAN = 'ca',
    // CROATIAN = 'hr',
    // CZECH = 'cs',
    // DANISH = 'da',
    // DUTCH = 'nl',
    ENGLISH = 'en',
    // ENGLISH_AMERICAN = 'en',
    // ENGLISH_BRITISH = 'en-GB',
    // ESTONIAN = 'et',
    // FINNISH = 'fi',
    FRENCH = 'fr',
    GERMAN = 'de',
    // GREEK = 'el',
    // HEBREW = 'he',
    // HINDI = 'hi',
    // HUNGARIAN = 'hu',
    // INDONESIAN = 'id',
    // ITALIAN = 'it',
    // JAPANESE = 'ja',
    // KANNADA = 'kn',
    // KOREAN = 'ko',
    // LITHUANIAN = 'lt',
    // MALAY = 'ms',
    // MARATHI = 'mr',
    // NORWEGIAN = 'nb',
    // POLISH = 'pl',
    // PORTUGUESE_BRAZILIAN = 'pt-BR',
    PORTUGUESE = 'pt',
    // ROMANIAN = 'ro',
    // RUSSIAN = 'ru',
    // SLOVAK = 'sk',
    SPANISH = 'es',
    // SWEDISH = 'sv',
    // TAGALOG = 'tl',
    // TELUGU = 'te',
    // THAI = 'th',
    // TURKISH = 'tr',
    // UKRAINIAN = 'uk',
    // VIETNAMESE = 'vi',
}

