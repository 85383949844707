import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyParameter } from 'app/model/company-parameter';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyParametersApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Companies/companyparameters';
  }

  public getAllByParameterId(parameterId: string): Observable<CompanyParameter[]> {
    return this.http.get<CompanyParameter[]>(`${this.baseURL}/all/${parameterId}`);
  }

}
