import { TwilioContentActionType } from "./twilio-content-action-type";

export class TwilioContentTemplateAction {
    public type: TwilioContentActionType;    // URL
    public title: string;   // Check Flight Status
    public url: string;     // https://owlair.com/{{5}}
    public phone: string;   // +18005551234

    constructor() {
        this.type = TwilioContentActionType.URL;
    }
}
