import { NegotiationType } from './negotiation-type';

export class Flow {
    public flowId: number;
    public companyId: string;
    public type: NegotiationType;
    public name: string;
    public created: Date;
    public CreatedBy: string;
    public lastModified: Date;
    public lastModifiedBy: string;

    constructor(flow?: Flow) {
        this.flowId = flow?.flowId;
        this.companyId = flow?.companyId;
        this.type = flow?.type;
        this.name = flow?.name;
        this.created = flow?.created;
        this.CreatedBy = flow?.CreatedBy;
        this.lastModified = flow?.lastModified;
        this.lastModifiedBy = flow?.lastModifiedBy;
    }

}
