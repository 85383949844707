import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import SubscriptionInfo from 'app/model/subscription-info';

@Component({
  selector: 'app-subscription-card',
  templateUrl: './subscription-card.component.html',
  styleUrls: ['./subscription-card.component.css'],
})
export class SubscriptionCardComponent implements OnInit {
  @Input() subscriptionInfo: SubscriptionInfo;
  @Input() isSelected = false;
  @Input() fromSettings = false;
  @Input() upgradeable = false;
  @Output() selected = new EventEmitter<any>();
  @Output() chooseSubscription = new EventEmitter<any>();

  constructor() {}

  ngOnInit(): void {}

  onSelection() {
    this.selected.emit(this.subscriptionInfo);
  }

  onChoose() {
    this.chooseSubscription.emit(this.subscriptionInfo);
  }
}
