import { Component, OnInit, Inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { appearOpacityAnimation } from 'app/animations/appearOpacityAnimation';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { PaymentService } from 'app/services/payment.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-new-user-registration',
  templateUrl: './new-user-registration.component.html',
  styleUrls: ['./new-user-registration.component.css'],
  animations: [appearOpacityAnimation],
})
export class NewUserRegistrationComponent implements OnInit {
  passwordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
  regOneLower = new RegExp('(?=.*[a-z])');
  regOneUpper = new RegExp('(?=.*[A-Z])');
  regOneNumber = new RegExp('(?=.*[0-9])');
  regOneSpecial = new RegExp('(?=.*[!@#$%^&*])');
  regAtLeastEight = new RegExp('(?=.{8,})');

  newUserForm = this.formBuilder.group({
    email: new FormControl('', [Validators.required, Validators.email]),
    repeatEmail: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.pattern(this.passwordRegex),
    ]),
    repeatPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
  });

  constructor(
    private formBuilder: FormBuilder,
    private uiService: UIService,
    private paymentService: PaymentService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<NewUserRegistrationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.newUserForm.addValidators([
      this.passwordValidator(this.newUserForm.get('password'), this.newUserForm.get('repeatPassword')),
      this.emailValidator(this.newUserForm.get('email'), this.newUserForm.get('repeatEmail')),
    ]);
  }

  ngOnInit(): void {}

  passwordValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if (controlOne.value !== controlTwo.value) {
        // eslint-disable-next-line
        return { password_error: 'Password: Values do not match' };
      }
      return null;
    };
  }

  emailValidator(controlOne: AbstractControl, controlTwo: AbstractControl) {
    return () => {
      if (controlOne.value !== controlTwo.value) {
        // eslint-disable-next-line
        return { email_error: 'Email: Values do not match' };
      }
      return null;
    };
  }

  onClose() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.newUserForm.valid) {
      const body = {
        ...this.data.billingInfo,
        email: this.newUserForm.value.email,
        password: this.newUserForm.value.password,
      };
      this.uiService.toggleGlobalSpinner(true);

      this.paymentService
        .addNewSubscription({
          ...body,
        })
        .subscribe({
          next: (result: any) => {
            if (result && result.success) {
              // TODO: Redirect here
              this.dialogRef.close();
              this.data.onSubmit?.();
              this.uiService.toggleGlobalSpinner(false);
            } else {
              this.dialog.open(AlertComponent, {
                data: 'Error: ' + (result?.message ? result?.message : 'Something went wrong when attempting to pay.'),
              });
            }
            this.uiService.toggleGlobalSpinner(false);
          },
          error: (err) => {
            this.dialog.open(AlertComponent, {
              data: err?.message ? err?.message : err,
            });
            this.uiService.toggleGlobalSpinner(false);
          },
        });
    }
  }
}
