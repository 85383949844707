import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ContactsGroup, ContactsGroupDto } from 'app/model/contacts-group';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class ContactsGroupApiService {
  private baseURL: string;

  constructor(
    private httpClient: HttpClient
  ) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/ContactsGroups';
  }

  public getContactsGroups(): Observable<ContactsGroupDto[]> {
    return this.httpClient.get<ContactsGroup[]>(this.baseURL);
  }

  saveContactsGroup(contactsGroup: ContactsGroup): Observable<any> {
    const contactsGroupDto = new ContactsGroupDto(contactsGroup);
    return this.httpClient.post<ContactsGroupDto>(this.baseURL, contactsGroupDto);
  }

  updateContactsGroup(contactsGroup: ContactsGroup): Observable<any> {
    const contactsGroupDto = new ContactsGroupDto(contactsGroup);
    return this.httpClient.put<ContactsGroupDto>(this.baseURL + '/' + contactsGroup.id, contactsGroupDto);
  }

  deleteContactsGroup(contactsGroupsId: number): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      })
    };
    return this.httpClient.delete<any>(this.baseURL + '/' + contactsGroupsId, options);
  }

  addContactsToGroup(contactsGroupId: number, contactIds: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };
    const body = {
      groupId: contactsGroupId,
      contactIds: contactIds
    }
    return this.httpClient.put<any>(`${this.baseURL}/addcontacts`, body, options);
  }

  removeContactsFromGroup(contactsGroupId: number, contactIds: number[]) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        groupId: contactsGroupId,
        contactIds: contactIds
      }
    };
    return this.httpClient.delete<any>(`${this.baseURL}/removecontacts`, options);
  }

}
