import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Company } from 'app/model/company';
import { Auth0ManagementService } from 'app/services/auth0-management.service';
import { CompanyService } from 'app/services/company.service';
import { UIService } from 'core/services/ui.service';
import { UtilityService } from 'core/services/utility.service';

@Component({
  selector: 'app-company-api-credentials-create',
  templateUrl: './company-api-credentials-create.component.html',
  styleUrls: ['./company-api-credentials-create.component.css']
})
export class CompanyApiCredentialsCreateComponent implements OnInit {

  public company: Company;
  public companies: Company[];
  public unavailableCompanies: number[] = [];
  public clientCredentialsGenerateForm: FormBuilder;
  public isGeneratedCredentials: boolean;
  public clientSecret: string;

  constructor(
    public dialogRef: MatDialogRef<CompanyApiCredentialsCreateComponent>,
    public auth0ManagementService: Auth0ManagementService,
    public companyService: CompanyService,
    private snackBar: MatSnackBar,
    public uiService: UIService,
    private utilityService: UtilityService,
    @Inject(MAT_DIALOG_DATA) public data: number[],
  ) {
    if (data != null) {
      this.unavailableCompanies = data;
    }
    this.loadCompanies();
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close(this.isGeneratedCredentials);
  }

  onGenerateCredentials() {
    this.uiService.toggleGlobalSpinner(true);
    this.auth0ManagementService.generateClientCredentials(this.company.companyId).subscribe(credentials => {
      this.isGeneratedCredentials = true;
      this.clientSecret = credentials.client_secret;
      this.uiService.toggleGlobalSpinner(false);
    }, err => {
      this.uiService.toggleGlobalSpinner(false);
      let errorMsg = this.utilityService.handleExceptionMessage(`Unable to generate API credentials.`, err);
      this.snackBar.open(errorMsg, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    });
  }

  loadCompanies() {
    this.companyService.getAll().subscribe(allCompanies => {
      this.companies = allCompanies.filter(c => !this.unavailableCompanies.includes(c.companyId));
    });
  }

}
