<div class="preview-container" (click)="onClose()">
    <div class="preview-phone">
        <div class="preview-header">
            <div class="profile-pic"></div>
            <div>Sample {{selectedType?.name}} message</div>
        </div>
        <div class="preview-body">
            <div class="message-time">11:40 PM</div>
            <div class="message-content">
                <div class="message-body">{{sampleBody}}</div>

                <!-- Preview Media -->
                <div [hidden]="!(contentData?.media)">
                    <img [src]="contentData?.media" alt="Image" class="media-image"
                        [hidden]="contentData?.media.includes('}}')">
                    <img [src]="'assets/SignedealLogo3.jpg'" alt="Image" class="media-image"
                        [hidden]="!contentData?.media.includes('}}')">
                </div>

                <!-- Preview Actions -->
                <!--
                <div class="message-actions" *ngIf="false">
                    
                </div>
                -->
            </div>
        </div>
    </div>
</div>
