<div class="settings-card-container">
  <div class="settings-card-title">Subscription</div>
  <div class="unsuscribed-message" *ngIf="!currentSubscription">You do not have an active subscription</div>
  <div class="unsuscribed-message" *ngIf="expired">Your subscription has expired</div>
  <div class="expiration-message" *ngIf="currentSubscription?.expiration">
    Your subscription will be renewed on {{ currentSubscription?.expiration }}
  </div>
  <div class="cards-container">
    <app-subscription-card
      *ngFor="let sub of subscriptions; let index"
      [subscriptionInfo]="sub"
      [fromSettings]="true"
      [isSelected]="sub.subscriptionType === currentSubscription?.subscriptionType"
      [upgradeable]="sub.subscriptionType !== currentSubscription?.subscriptionType"
      (chooseSubscription)="chooseSub($event)"
    ></app-subscription-card>
  </div>
  <ol class="billing-details">
    <li>Prices are in US dollars and do not include communication credits beyond the monthly bonus.</li>
    <li>An Engagement is defined by an individual that is contacted during a campaign.</li>
    <li>Communication Bonus Credit is applied each month and must be consumed during that month.</li>
    <li>
      WhatsApp for Business onboarding services are included in order to register a business-specific WhatsApp sender.
      If not required, communications will be done via a generic "Signedeal" WhatsApp sender.
    </li>
  </ol>
</div>
