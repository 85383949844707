import { Injectable } from '@angular/core';
import { isEqual } from 'lodash';
import { BehaviorSubject } from 'rxjs';
import { NegotiationOption } from 'app/model/negotiation-option';

@Injectable()
export class NegotiationOptionsService {
  public defaultDemoOptions: any[]  = [];
  public negotiationOptionsSubject = new BehaviorSubject<NegotiationOption[]>([]);

  public setNegotiationOptions(negotiationOptions: NegotiationOption[]) {
    this.negotiationOptionsSubject.next(negotiationOptions);
  }

  public initDefault() {
    this.setNegotiationOptions(this.defaultDemoOptions);
  }

  public getNegotiationOptions(): NegotiationOption[] {
    return this.negotiationOptionsSubject.value;
  }

  public deleteNegotiationOptions(document: NegotiationOption): void {
    const index = this.getNegotiationOptions().findIndex((element) => isEqual(element, document));
    if (index > -1) {
      const newOptions = this.getNegotiationOptions().filter((element, i) => i !== index);
      this.setNegotiationOptions(newOptions);
    }
  }
}
