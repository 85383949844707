const NEGOTIATION_VARIABLES: NegotiationVariable[] = [
    { name: 'Negotiation Id', value: 'negotiationId' },
    { name: 'Negotiation Owner', value: 'negotiationOwner' },
    { name: 'Negotiation Owner Company', value: 'negotiationOwnerCompany' },
    { name: 'Negotiation Expiration Date', value: 'negotiationExpirationDate' },
    { name: 'Negotiation Description', value: 'negotiationDescription' },
    { name: 'CCP Link', value: 'ccpLink' },

    { name: 'Contact First Name', value: 'contactName' },
    { name: 'Contact Last Name', value: 'contactLastName' },
    { name: 'Contact Full Name', value: 'contactFullName' },
    { name: 'Contact Message', value: 'contactMessage' },
    
    { name: 'Current Day', value: 'currentDay' },
    { name: 'Current Time', value: 'currentTime' },
    { name: 'Current Date', value: 'currentDate' },
    { name: 'Current Date and Time', value: 'currentDateTime' },

    { name: 'External Document Id', value: 'externalDocumentId' }
];

export const OPTIONS_VARIABLES: NegotiationVariable[] = [
    { name: 'Selected Option Title', value: 'selectedOptionTitle' },
    { name: 'Selected Option Description', value: 'selectedOptionDescription' }
];

export const ACCOUNTS_RECEIVABLE_VARIABLES: NegotiationVariable[] = [
    { name: 'AR Documents', value: 'arDocumentTable' },
    { name: 'AR Documents Payed', value: 'arPayedDocumentTable' }
];

export default NEGOTIATION_VARIABLES;

export interface NegotiationVariable {
    name: string;
    value: string;
}

export const NEGOTIATION_VARIABLE_SAMPLES = {
    negotiationId: '65535',
    negotiationOwner: 'Stephen',
    negotiationOwnerCompany: 'SigneDeal',
    negotiationExpirationDate: '10/12/1844',
    negotiationDescription: 'This is a sample description for a Negotiation',
    ccpLink: 'https://signedeal.com/65535',

    contactName: 'John',
    contactLastName: 'Doe',
    contactFullName: 'John Doe',
    contactMessage: 'Hi Mr. Contact',
    
    currentDay: 'Monday',
    currentTime: '15:00',
    currentDate: '10/12/2024',
    currentDateTime: '10/12/2024 15:00',

    externalDocumentId: '32768',
};
