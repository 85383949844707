import { WhatsAppContentTemplate } from "app/model/content-template";
import { TwilioContentCategories } from "./twilio-content-categories";
import { TwilioContentTemplateAction } from "./twilio-content-template-action";
import { TwilioLanguages } from "./twilio-languages";
import { TwilioContentTemplateTypes } from "./twilio-content-tempalte-types";


export class CreateTemplateRequest {
    public name: string;
    public lang: TwilioLanguages;
    public body: string;
    public category: TwilioContentCategories;
    public contentType: TwilioContentTemplateTypes;
    public variables?: Array<string>;
    public actions?: TwilioContentTemplateAction[];
    public media?: string;

    public getVariablesAsString() {
        let result = '{';
        if(this.variables && this.variables.length) {
            let variablesLength = this.variables.length;
            this.variables.forEach((variable, index) => {
                let ending = index <= variablesLength ? ',' : '';
                result += `"${(index + 1)}": "${variable}"${ending}`
            });
            result += '}';
        }
        return result;
    }

    public asWhatsAppContentTemplate() {
        let dup = JSON.parse(JSON.stringify(this)); // deep copy
        dup.variables = this.getVariablesAsString();
        return Object.assign(new WhatsAppContentTemplate(), dup);
    }
}
