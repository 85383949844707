import { NegotiationStatus } from './negotiation-status';
import { NegotiationType } from './negotiation-type';
import { AccountsReceivableDocument } from 'app/model/documents/accounts-receivable-document';
import { DemoDocument } from './documents/demo-document';
import { NegotiationOption } from 'app/model/negotiation-option';
import { SignatureStatus } from './signature-status';
import { PaginatedDataRequest } from './pagination';
import { NegotiationMessage } from './NegotiationMessage';
import { Flow } from './flow';
import { Slot } from './slot';
import { NegotiationFlowAction, NegotiationFlowActionDto } from './negotiation-flow-action';

export class Negotiation {
  public negotiationId: number;
  public description: string;
  public dueDate: Date;
  public status: NegotiationStatus;
  public signatureStatus: SignatureStatus;
  public flowId: number;
  public slotId: number;
  public contactStatus: number;
  public accountsPayableDocuments: Array<AccountsReceivableDocument>;
  public demoDocument: DemoDocument;
  public contactId: number;
  public contactName: string;
  public documentId: string;
  public documentDescription: string;
  public negotiationType: NegotiationType;
  public amount: number;
  public optionDocuments: NegotiationOption[];
  public negotiationFlowActions: NegotiationFlowAction[];
  public messageText: string;
  public ccpLink: string;
  public callDuration: number;
  public slot: Slot;
  public succeededContactActions: string;
  public failedContactActions: string;
  public lastModified: Date;
  public created: Date;
  public contactPhone: string;

  constructor(negotiation: Negotiation | NegotiationDto) {
    this.negotiationId = negotiation?.negotiationId;
    this.description = negotiation?.description;
    this.dueDate = negotiation?.dueDate;
    this.status = negotiation?.status;
    this.flowId = negotiation?.flowId;
    this.slotId = negotiation?.slotId;
    this.accountsPayableDocuments = negotiation?.accountsPayableDocuments;
    this.demoDocument = negotiation?.demoDocument;
    this.contactId = negotiation?.contactId;
    this.contactName = negotiation?.contactName;
    this.negotiationType = negotiation?.negotiationType;
    this.amount = negotiation?.amount;
    this.documentId = (negotiation as Negotiation)?.documentId;
    this.documentDescription = (negotiation as Negotiation)?.documentDescription;
    this.optionDocuments = negotiation?.optionDocuments;
    this.negotiationFlowActions = negotiation?.negotiationFlowActions?.map(
      (x: NegotiationFlowActionDto) => new NegotiationFlowAction(x)
    );
    this.messageText = negotiation?.messageText;
    this.callDuration = negotiation?.callDuration;
    this.slot = negotiation?.slot;
    this.succeededContactActions = negotiation?.succeededContactActions;
    this.failedContactActions = negotiation?.failedContactActions;
    this.lastModified = negotiation?.lastModified;
    this.created = negotiation?.created;
    this.contactPhone = negotiation?.contactPhone;

    if (negotiation?.ccpLink && Number(negotiation?.negotiationType) === NegotiationType.THIRD_PARTY) {
      this.ccpLink = negotiation?.ccpLink;
    }
  }
}

export class NegotiationDto {
  public negotiationId: number;
  public description: string;
  public negotiationType: number;
  public owner: string;
  public companyId: number;
  public dueDate: Date;
  public status: number;
  public flowId: number;
  public slotId: number;
  public accountsPayableDocuments: Array<AccountsReceivableDocument>;
  public optionDocuments: NegotiationOption[];
  public negotiationFlowActions: NegotiationFlowActionDto[];
  public negotiationMessages: NegotiationMessage[];
  public demoDocument: DemoDocument;
  public flow: Flow;
  public contactId: number;
  public contactName: string;
  public contactStatus: number;
  public slotName: string;
  public signatureStatus: number;
  public messageText: string;
  public ccpLink: string;
  public callDuration: number;
  public amount: number;
  public slot: Slot;
  public succeededContactActions: string;
  public failedContactActions: string;
  public lastModified: Date;
  public created: Date;
  public contactPhone: string;

  constructor(negotiation: Negotiation) {
    this.negotiationId = negotiation?.negotiationId;
    this.description = negotiation?.description;
    this.dueDate = negotiation?.dueDate;
    this.status = negotiation?.status;
    this.flowId = negotiation?.flowId;
    this.slotId = negotiation?.slotId;
    this.contactStatus = negotiation?.contactStatus;
    this.accountsPayableDocuments = negotiation?.accountsPayableDocuments;
    this.demoDocument = {
      id: undefined,
      externalId: negotiation.documentId,
      description: negotiation.documentDescription,
    };
    this.contactId = negotiation?.contactId;
    this.negotiationType = negotiation.negotiationType;
    this.optionDocuments = negotiation?.optionDocuments;
    this.negotiationFlowActions = negotiation?.negotiationFlowActions?.map((x) => new NegotiationFlowActionDto(x));
    this.messageText = negotiation?.messageText;
    this.callDuration = negotiation?.callDuration;
    this.slot = negotiation?.slot;
    this.succeededContactActions = negotiation?.succeededContactActions;
    this.failedContactActions = negotiation?.failedContactActions;
    this.lastModified = negotiation?.lastModified;
    this.created = negotiation?.created;
    this.contactPhone = negotiation?.contactPhone;

    if (negotiation?.ccpLink && Number(negotiation?.negotiationType) === NegotiationType.THIRD_PARTY) {
      this.ccpLink = negotiation?.ccpLink;
    }
  }
}

export interface PaginatedNegotiationsDataRequest extends PaginatedDataRequest {
  slot?: number;
  mineOnly?: boolean;
}
