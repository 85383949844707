import { animate, state, style, transition, trigger } from '@angular/animations';
import { SelectionModel } from '@angular/cdk/collections';
import { AfterViewInit, Component, EventEmitter, Input, Output, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import downloadFileResponse from 'app/functions/fileFunctions';
import { ContactsGroup } from 'app/model/contacts-group';
import { ContactsWithTotalCountDto } from 'app/model/contacts-with-total-count-dto';
import { Slot } from 'app/model/slot';
import { ContactsGroupService } from 'app/services/contacts-group.service';
import { SlotsService } from 'app/services/slots.service';
import { UIService } from 'core/services/ui.service';
import { merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { Contact } from 'src/app/model/contact';
import { ContactsService } from 'src/app/services/contacts.service';
import { AddContactsGroupComponent } from '../add-contacts-group/add-contacts-group.component';

@Component({
  selector: 'app-contact-groups-grid',
  templateUrl: './contact-groups-grid.component.html',
  styleUrls: ['./contact-groups-grid.component.css'],
  animations: [
    trigger('collapseInOut', [
      state(
        'close',
        style({
          width: '5.5rem',
        })
      ),
      state(
        'open',
        style({
          width: '20rem',
        })
      ),
      transition('close => open', animate('400ms ease-in-out')),
      transition('open => close', animate('400ms ease-in-out')),
    ]),
  ],
})
export class ContactGroupsGridComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild('availableContactsPaginator') availablePaginator: MatPaginator;
  @ViewChild('availableContactsTbSort') availableSort: MatSort;
  @ViewChild('assignedContactsPaginator') assignedPaginator: MatPaginator;
  @ViewChild('assignedContactsTbSort') assignedSort: MatSort;
  @Output()
  selectedContactsGroupEvent = new EventEmitter<ContactsGroup>();

  @Input()
  contactsGroupsChanged: Observable<void>;

  slotElements: any[];
  selectedSlot: any;
  contactsGroups: any[];

  targetSlotControl = new FormControl('');
  filteredSlotOptions: Observable<any[]>;

  isCollapsed = false;
  collapseStr = 'open';
  isLoadingGroups = true;

  // Pagination, filtering and sorting
  public availableContactsPageSize = 10;
  public availableContactsPageSizeOptions = [10, 15, 20];
  public availableContactsFilterString: string;
  public availableContactsDataSource: Contact[] = [];
  public availableContactsTotalCountLength = 0;

  public assignedContactsPageSize = 10;
  public assignedContactsPageSizeOptions = [10, 15, 20];
  public assignedContactsFilterString: string;
  public assignedContactsDataSource: Contact[] = [];
  public assignedContactsTotalCountLength = 0;

  public triggerAvailableContactsDataReloadSubject: Subject<void> = new Subject();
  public triggerAssignedContactsDataReloadSubject: Subject<void> = new Subject();
  public selectedContactsGroup: ContactsGroup;
  assignedContactsSelection = new SelectionModel<Contact>(true, []);
  availableContactsSelection = new SelectionModel<Contact>(true, []);

  public displayedColumns = ['Selection', 'ContactName', 'ContactEmail', 'ContactPhone', 'LastModified'];

  private contactsGroupsChangedSubscription: Subscription;

  constructor(
    public contactsService: ContactsService,
    public contactsGroupsService: ContactsGroupService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private slotsService: SlotsService,
    private uiService: UIService
  ) {}

  ngOnDestroy() {
    this.contactsGroupsChangedSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.assignedSort.sortChange.subscribe(() => (this.assignedPaginator.pageIndex = 0));
    merge(this.assignedSort.sortChange, this.assignedPaginator.page, this.triggerAssignedContactsDataReloadSubject)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (this.selectedContactsGroup != null) {
            return this.contactsService
              .getContactsGroupContacts(
                this.selectedContactsGroup.id,
                this.assignedContactsPageSize,
                this.assignedPaginator.pageIndex + 1,
                this.assignedSort.active,
                this.assignedSort.direction.toUpperCase(),
                this.assignedContactsFilterString
              )
              .pipe(catchError(() => of(null)));
          } else {
            return of(null);
          }
        }),
        map((contactsWithTotals: ContactsWithTotalCountDto) => {
          if (contactsWithTotals === null) {
            return [];
          }
          // Only refresh the result length if there is new data.
          this.assignedContactsTotalCountLength = contactsWithTotals.totalCount;
          return contactsWithTotals.contacts;
        })
      )
      .subscribe((data) => (this.assignedContactsDataSource = data));
    this.availableSort.sortChange.subscribe(() => (this.availablePaginator.pageIndex = 0));
    merge(this.availableSort.sortChange, this.availablePaginator.page, this.triggerAvailableContactsDataReloadSubject)
      .pipe(
        startWith({}),
        switchMap(() => {
          if (this.selectedContactsGroup != null) {
            return this.contactsService
              .getMissingContactsGroupContacts(
                this.selectedContactsGroup.id,
                this.availableContactsPageSize,
                this.availablePaginator.pageIndex + 1,
                this.availableSort.active,
                this.availableSort.direction.toUpperCase(),
                this.availableContactsFilterString
              )
              .pipe(catchError(() => of(null)));
          } else {
            return of(null);
          }
        }),
        map((contactsWithTotals: ContactsWithTotalCountDto) => {
          if (contactsWithTotals === null) {
            return [];
          }
          // Only refresh the result length if there is new data.
          this.availableContactsTotalCountLength = contactsWithTotals.totalCount;
          return contactsWithTotals.contacts;
        })
      )
      .subscribe((data) => (this.availableContactsDataSource = data));
  }

  ngOnInit(): void {
    this.contactsGroupsService.getContactsGroups().subscribe((results) => {
      this.contactsGroups = results;
      if (this.contactsGroups.length !== 0) {
        this.onSelectContactGroup(this.contactsGroups[0]);
      } else {
        this.hideLoading();
      }
    });
    this.contactsGroupsChangedSubscription = this.contactsGroupsChanged.subscribe(() => {
      this.contactsGroupsService.getContactsGroups().subscribe((results) => {
        this.contactsGroups = results;
        if (this.contactsGroups.length !== 0) {
          this.onSelectContactGroup(this.contactsGroups[0]);
        } else {
          this.hideLoading();
        }
      });
    });
    this.filteredSlotOptions = this.targetSlotControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterSlots(value || ''))
    );
    this.slotsService.slots.subscribe((values: Slot[]) => this.onSlotReload(values));
  }

  toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
    this.collapseStr = this.isCollapsed ? 'close' : 'open';
  }

  onSelectContactGroup(contactsGroup: ContactsGroup) {
    if (contactsGroup?.id === this.selectedContactsGroup?.id) {
      return;
    }
    this.selectedContactsGroup = contactsGroup;
    this.selectedContactsGroupEvent.emit(this.selectedContactsGroup);
    this.triggerAvailableContactsDataReloadSubject.next();
    this.triggerAssignedContactsDataReloadSubject.next();
    this.hideLoading();
  }

  onAvailableContactsFilterChange(event) {
    this.triggerAvailableContactsDataReloadSubject.next();
  }

  onClearAvailableContactsFilter() {
    this.availableContactsFilterString = '';
    this.triggerAvailableContactsDataReloadSubject.next();
  }

  onChangeAvailableContactsPageSizeOrIndex(event: PageEvent) {
    this.availableContactsPageSize = event.pageSize;
    this.triggerAvailableContactsDataReloadSubject.next();
  }

  onAssignedContactsFilterChange(event) {
    this.triggerAssignedContactsDataReloadSubject.next();
  }

  onClearAssignedContactsFilter() {
    this.availableContactsFilterString = '';
    this.triggerAssignedContactsDataReloadSubject.next();
  }

  onChangeAssignedContactsPageSizeOrIndex(event: PageEvent) {
    this.assignedContactsPageSize = event.pageSize;
    this.triggerAssignedContactsDataReloadSubject.next();
  }

  onAssignContacts() {
    if (this.availableContactsSelection.isEmpty()) {
      this.snackBar.open(`No Contacts have been selected.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const contacts = this.availableContactsSelection.selected;
    const contactIds = contacts.map((c) => c.contactId);
    this.uiService.toggleGlobalSpinner(true);
    this.contactsGroupsService.addContactsToGroup(this.selectedContactsGroup.id, contactIds).subscribe(
      () => {
        this.availableContactsSelection.clear();
        this.triggerAvailableContactsDataReloadSubject.next();
        this.triggerAssignedContactsDataReloadSubject.next();
        this.uiService.toggleGlobalSpinner(false);
      },
      (err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  onUnassignContacts() {
    if (this.assignedContactsSelection.isEmpty()) {
      this.snackBar.open(`No Contacts have been selected.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    const contacts = this.assignedContactsSelection.selected;
    const contactIds = contacts.map((c) => c.contactId);
    this.uiService.toggleGlobalSpinner(true);
    this.contactsGroupsService.removeContactsFromGroup(this.selectedContactsGroup.id, contactIds).subscribe(
      () => {
        this.assignedContactsSelection.clear();
        this.triggerAvailableContactsDataReloadSubject.next();
        this.triggerAssignedContactsDataReloadSubject.next();
        this.uiService.toggleGlobalSpinner(false);
      },
      (err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.uiService.toggleGlobalSpinner(false);
      }
    );
  }

  isAllAssignedSelected() {
    const numSelected = this.assignedContactsSelection.selected.length;
    const numRows = this.assignedContactsDataSource.length;
    return numSelected === numRows;
  }

  toggleAllAssignedRows() {
    if (this.isAllAssignedSelected()) {
      this.assignedContactsSelection.clear();
      return;
    }
    this.assignedContactsSelection.select(...this.assignedContactsDataSource);
  }

  isAllAvailableSelected() {
    const numSelected = this.availableContactsSelection.selected.length;
    const numRows = this.availableContactsDataSource.length;
    return numSelected === numRows;
  }

  toggleAllAvailableRows() {
    if (this.isAllAvailableSelected()) {
      this.availableContactsSelection.clear();
      return;
    }
    this.availableContactsSelection.select(...this.availableContactsDataSource);
  }

  filterSlots(value) {
    let filtered = this.slotElements;
    if (value == null) {
      return filtered;
    }
    const lowered = value ? value.toLowerCase?.() : '';
    if (typeof value === 'string') {
      this.selectedSlot = null;

      filtered = this.slotElements.filter((el) => this.getSlotFullName(el).toLowerCase().includes(lowered));
    } else {
      this.selectedSlot = value;
      // eslint-disable-next-line
      filtered = filtered.filter((options) => options.key == value);
    }
    return filtered;
  }

  getSlotFullName(slot) {
    return `${slot.isDataInjestor ? 'DI-' : ''}${slot.title}`;
  }

  initFilters() {
    this.filteredSlotOptions = this.targetSlotControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterSlots(value || ''))
    );
  }

  onCloseSlotSelect() {
    const realValue = this.targetSlotControl.value;
    this.initFilters();
    this.targetSlotControl.setValue(realValue);
  }

  public onSlotReload(values: Slot[]) {
    if (values !== null && values !== undefined) {
      const elements = values.map((slot) => ({
        isDataInjestor: slot.isDataIngestor,
        key: slot.slotId,
        title: slot.name,
        count: slot.negotiationCount,
      }));
      this.slotElements = elements;
      this.targetSlotControl.setValue(this.targetSlotControl.value);
    }
  }

  selectSlot(value) {
    const el = this.slotElements.find((slot) => slot.key === value.key);
    if (el) {
      this.selectedSlot = el;
      this.selectedSlot.titleAction();
    }
  }

  onDropContactsGroupToChannel(event: any) {
    const contactsGroup = event.item.data;
    if (contactsGroup.id == null || contactsGroup.name == null) {
      this.dialog.open(AlertComponent, {
        data: 'You must drop a valid Contacts Group',
      });
      return;
    }
    this.selectedContactsGroup = contactsGroup;
    this.assignContactGroups();
  }

  public onClickGroupEdit(event, contactGroup) {
    event.stopPropagation();
    this.dialog
      .open(AddContactsGroupComponent, {
        panelClass: 'custom-dialog-container',
        width: '500px',
        data: contactGroup,
      })
      .afterClosed()
      .subscribe((persited: boolean) => {
        if (persited) {
          this.contactsGroupsService.getContactsGroups().subscribe((results) => {
            this.contactsGroups = results;
          });
        }
      });
  }

  async assignContactGroups() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          // eslint-disable-next-line
          message: `Are you sure you want to assign the Contacts in "${this.selectedContactsGroup.name}" to the "${this.selectedSlot.title}" Channel?`,
        },
      })
      .afterClosed()
      .subscribe(async (confirm: boolean) => {
        if (confirm) {
          this.uiService.toggleGlobalSpinner(true);
          const contactsWithTotals = await this.contactsService
            .getContactsGroupContacts(this.selectedContactsGroup.id, 100000, 1, 'ContactId', 'ASC', null)
            .toPromise();
          const contactIds = contactsWithTotals.contacts.map((contact) => contact.contactId);
          this.slotsService.assignContactsToSlot(contactIds, this.selectedSlot.key).subscribe(
            () => {
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`Contacts assigned successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (err) => {
              this.uiService.toggleGlobalSpinner(false);
              let errorMsg = null;
              try {
                errorMsg = JSON.parse(err.error).title;
              } catch (er) {
                errorMsg = err.message ? err.message : err;
              }
              this.dialog.open(AlertComponent, {
                data: errorMsg,
              });
            }
          );
        }
      });
  }

  slotDisplayFn(nt: any): string {
    const el = this.slotElements.find((se) => se.key === nt.key);
    this.selectedSlot = el;
    return el ? (el.isDataInjestor ? 'DI-' : ' ') + el.title : '';
  }

  exportContacts(contactGroup) {
    this.contactsGroupsService
      .exportContactGroup(contactGroup.name, contactGroup.id)
      .toPromise()
      .then((res) => {
        const data: Blob = new Blob([res], {
          type: 'text/csv;charset=utf-8',
        });
        downloadFileResponse(data, `${contactGroup.name}.csv`);
      })
      .catch((err) => this.uiService.showMessage(err?.message));
  }

  private hideLoading() {
    this.isLoadingGroups = false;
  }
}
