<form [formGroup]="cloneFlowForm" (ngSubmit)="onSubmit()">
    <div class="title">Flow - Save as...</div>
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="flowName" type="text" placeholder="Name" autocomplete="off" required>
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!cloneFlowForm.valid" type="submit">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>