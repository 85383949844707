import { Injectable } from '@angular/core';
import { IntermediateService } from 'app/services/intermediate.service';
import { AccountsReceivableDocument } from 'app/model/documents/accounts-receivable-document';
import { BehaviorSubject } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { AlertComponent } from 'app/components/common/alert/alert.component';

@Injectable({
  providedIn: 'root',
})
export class ReceivableAccountsService {
  receivableDocs: Array<AccountsReceivableDocument> = [];
  $receivableAccounts = new BehaviorSubject<any>([]);
  $negotiationData = new BehaviorSubject<any>([]);
  $page = new BehaviorSubject<number>(1);
  page = 1;
  results = 10;
  $hasNext = new BehaviorSubject<boolean>(false);

  constructor(private dialog: MatDialog, private intermediateService: IntermediateService) {}

  loadReceivableAccounts() {
    // TODO call the service to get the accounts
    let filteredDocuments = [];
    this.intermediateService.negotiations.subscribe((negotiation) => {
      this.receivableDocs = negotiation?.accountsPayableDocuments;
      if (this.receivableDocs) {
        filteredDocuments = this.receivableDocs.slice(
          this.page * this.results - this.results,
          this.page * this.results
        );
        this.$receivableAccounts.next(filteredDocuments);
        const negotiationData = {
          messageText: negotiation.messageText,
        };
        this.$negotiationData.next(negotiationData);
        if (this.page * this.results < this.receivableDocs.length) {
          this.$hasNext.next(true);
        }
      }
    });
  }

  initReceivableAccounts() {
    this.page = 1;
    this.$page.next(1);
    this.loadReceivableAccounts();
  }

  loadNext() {
    this.page++;
    this.$page.next(this.page);
    const res = this.receivableDocs.slice(this.page * this.results - this.results, this.page * this.results);
    this.$receivableAccounts.next(res);
    if (this.page * this.results < this.receivableDocs.length) {
      this.$hasNext.next(true);
    }
  }

  loadPrevious() {
    this.page--;
    this.$page.next(this.page);
    this.loadReceivableAccounts();
  }

  payAccounts(accounts: Array<AccountsReceivableDocument>) {
    this.intermediateService.payAccounts(accounts).subscribe((res) => {
      this.loadReceivableAccounts();
      this.dialog
        .open(AlertComponent, {
          data: 'Your selection was successfully registered',
        })
        .afterClosed().subscribe(() => {
          this.intermediateService.moveToRedirectUrl();
        });
    });
  }
}
