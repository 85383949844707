import { Component, Inject, OnInit } from '@angular/core';
import { stringToKeyValue } from '@angular/flex-layout/extended/typings/style/style-transforms';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NegotiationStatus, NEGOTIATION_STATUS_DESCRIPTIONS } from 'app/model/negotiation-status';

@Component({
  selector: 'app-start-slot',
  templateUrl: './start-slot.component.html',
  styleUrls: ['./start-slot.component.css'],
})
export class StartSlotComponent implements OnInit {
  public message: string;
  public startSlotForm: FormGroup;
  public statusDescription = NEGOTIATION_STATUS_DESCRIPTIONS;
  public statusKeys = [
    'ALL',
    ...Object.keys(NegotiationStatus).filter((key) => !isNaN(Number(NegotiationStatus[key]))),
  ];

  constructor(
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    public dialog: MatDialogRef<StartSlotComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.message = data.message;
  }

  ngOnInit(): void {
    this.buildStartSlotForm();
  }

  buildStartSlotForm() {
    this.startSlotForm = this.formBuilder.group({
      status: [['DRAFT']],
    });
  }

  onCancel(): void {
    this.dialog.close(false);
  }

  onSubmit(): void {
    const actualStatuses = this.startSlotForm.value.status.map((x) => NegotiationStatus[x]);
    this.dialog.close(actualStatuses);
  }

  onSelectingItem(val) {
    if (
      val !== 'ALL' &&
      !this.startSlotForm.value.status.includes('ALL') &&
      this.startSlotForm.value.status.length === this.statusKeys.length - 1
    ) {
      this.startSlotForm.setValue({ ...this.startSlotForm.value, status: [...this.statusKeys] });
    } else if (val === 'ALL' && this.startSlotForm.value.status.length >= this.statusKeys.length - 1) {
      this.startSlotForm.setValue({ ...this.startSlotForm.value, status: [] });
    } else if (val !== 'ALL' && this.startSlotForm.value.status.length < this.statusKeys.length) {
      this.startSlotForm.setValue({
        ...this.startSlotForm.value,
        status: this.startSlotForm.value.status.filter((st) => st !== 'ALL'),
      });
    } else {
      this.startSlotForm.setValue({ ...this.startSlotForm.value, status: [...this.statusKeys] });
    }
  }
}
