import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { CreditsDTO } from './credits-dto';

@Injectable()
export class CreditsApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/credits';
  }

  getCredits(): Observable<CreditsDTO> {
    return this.httpClient.get<CreditsDTO>(`${this.baseURL}`);
  }
}
