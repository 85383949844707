import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { ITemplateMap } from 'app/components/common/dropable-canvas/canvas-node-details/content-template-details/content-template-details.component';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class FlowActionWpTemplateApiService {
    private baseURL: string;
    public templatesMap = new BehaviorSubject<Array<ITemplateMap>>([]);

    constructor(private http: HttpClient) {
        this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionWpTemplateParameters';
    }

    public saveTemplateMapping(flowActionId: number, templatesMap: Array<ITemplateMap>) {
        return this.http.post(`${this.baseURL}/${flowActionId}`, {dtos:templatesMap});
    }

    public loadTemplateMapping(flowActionId: number): void {
        this.http.get(`${this.baseURL}/${flowActionId}`)
        .subscribe(result => {
            this.templatesMap.next(<any>result);
        });
    }
}
