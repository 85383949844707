export class DynamicValue {
  public dynamicContactColumnId: string;
  public contactId: string;
  public value: string;

  constructor(dynamicValue) {
    this.dynamicContactColumnId = dynamicValue.dynamicContactColumnId;
    this.contactId = dynamicValue.contactId;
    this.value = dynamicValue.value;
  }
}

export class DynamicValuesDto {
  public dynamicValues: Array<DynamicValue>;

  constructor(dynamicValues: Array<DynamicValue>) {
    this.dynamicValues = dynamicValues;
  }
}
