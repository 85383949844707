<div class="title">Create enterprise company</div>

<form [formGroup]="companyCreateForm" (ngSubmit)="onCreateEnterpriseCompany()" autocomplete="off">
    <div class="fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label for="name">Company name</mat-label>
            <input matInput formControlName="name" type="text" placeholder="Name" required>
        </mat-form-field>

        <mat-card>
            <mat-card-header>
                <div mat-card-avatar><mat-icon>account_circle</mat-icon></div>
                <mat-card-title>Create user</mat-card-title>
                <mat-card-subtitle>Company owner</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <div class="regular-field">
                    <app-phone-picker (phoneChanged)="onPhoneChanged($event)" [isMobile]="true"></app-phone-picker>
                </div>
                <mat-form-field appearance="fill" class="regular-field">
                    <mat-label for="userFullname">Full name</mat-label>
                    <input matInput id="userFullname" maxlength="100" autocomplete="off" placeholder="User full name"
                        aria-label="User full name" formControlName="userFullname" required />
                </mat-form-field>
                <mat-form-field appearance="fill" class="regular-field">
                    <mat-label for="userCountry">Country</mat-label>
                    <input matInput id="userCountry" maxlength="100" autocomplete="off" placeholder="Country"
                        aria-label="Country" formControlName="userCountry" required />
                </mat-form-field>
                <mat-form-field appearance="fill" class="regular-field">
                    <mat-label for="userEmail">Email</mat-label>
                    <input matInput id="userEmail" maxlength="100" autocomplete="off" placeholder="User email"
                        aria-label="User email" formControlName="userEmail" required />
                </mat-form-field>
                <div class="buttons-container">
                    <button class="sgnd-btn sgnd-btn-ok" type="submit"
                        [disabled]="!companyCreateForm.valid || !userPhone">Create</button>
                    <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Close</button>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</form>