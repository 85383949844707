<div class="title">{{company.name}} - Edit permissions</div>

<div>
    <div class="permissions-tables">
        <mat-card>
            <mat-card-header>
                <mat-card-title>Available Permissions</mat-card-title>
            </mat-card-header>
            <div class="table-container">
                <table mat-table [dataSource]="availablePermissionsDataSource" matSort
                    #availablePermissionsTbSort="matSort" class="table" fixedLayout>
                    <ng-container matColumnDef="Selection">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllAvailableRows() : null"
                                [checked]="availablePermissionsSelection.hasValue() && isAllAvailableSelected()"
                                [indeterminate]="availablePermissionsSelection.hasValue() && !isAllAvailableSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? availablePermissionsSelection.toggle(row) : null"
                                [checked]="availablePermissionsSelection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PermissionName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PermissionDescription">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                        <td mat-cell *matCellDef="let element" [matTooltip]="element.description">
                            {{ element.description }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </mat-card>

        <mat-card>
            <div class="action-buttons-container">
                <button class="sgnd-btn sgnd-btn-ok add-permissions-btn" (click)="onAddPermissions()">
                    Assign <mat-icon>arrow_right</mat-icon>
                </button>
                <button class="sgnd-btn sgnd-btn-ok remove-permissions-btn" (click)="onRemovePermissions()">
                    <mat-icon>arrow_left</mat-icon> Remove
                </button>
            </div>
        </mat-card>

        <mat-card>
            <mat-card-header>
                <mat-card-title>Current Permissions</mat-card-title>
            </mat-card-header>
            <div class="table-container">
                <table mat-table [dataSource]="assignedPermissionsDataSource" matSort
                    #assignedPermissionsTbSort="matSort" class="table" fixedLayout>
                    <ng-container matColumnDef="Selection">
                        <th mat-header-cell *matHeaderCellDef>
                            <mat-checkbox (change)="$event ? toggleAllAssignedRows() : null"
                                [checked]="assignedPermissionsSelection.hasValue() && isAllAssignedSelected()"
                                [indeterminate]="assignedPermissionsSelection.hasValue() && !isAllAssignedSelected()">
                            </mat-checkbox>
                        </th>
                        <td mat-cell *matCellDef="let row">
                            <mat-checkbox (click)="$event.stopPropagation()"
                                (change)="$event ? assignedPermissionsSelection.toggle(row) : null"
                                [checked]="assignedPermissionsSelection.isSelected(row)">
                            </mat-checkbox>
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PermissionName">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
                        <td mat-cell *matCellDef="let element">
                            {{ element.name }}
                        </td>
                    </ng-container>
                    <ng-container matColumnDef="PermissionDescription">
                        <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                        <td mat-cell *matCellDef="let element" [matTooltip]="element.description">{{ element.description
                            }}
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                </table>
            </div>
        </mat-card>
    </div>
    <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-ok" type="button" (click)="onClose()">Close</button>
    </div>
</div>