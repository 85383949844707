import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EmailSettingsService } from 'app/components/settings/services/email-settings.service';
import { EmailSettingType } from 'app/model/email-setting-type';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-email-settings',
  templateUrl: './email-settings.component.html',
  styleUrls: ['./email-settings.component.css']
})
export class EmailSettingsComponent implements OnInit {
  public tooltips: { [key: string]: string };
  public emailConfigTypes: IEmailConfigSettings[];
  public currentEmailConfigType: IEmailConfigSettings;
  public data;

  constructor(
    private uiService: UIService,
    private snackBar: MatSnackBar,
    private emailSettingsService: EmailSettingsService
  ) {
    this.uiService.toggleGlobalSpinner(true);
    this.fillTooltips();
    this.setupEmailConfigSettings();
  }

  ngOnInit(): void {
    this.LoadEmailSettings();
  }

  /**
   * Persists any changes into the database, filtering fields depending
   * on the type of email configuration that is being used.
   */
  onSave() {
    this.uiService.toggleGlobalSpinner(true);
    let infoToSave = {}
    let keys = Object.keys(this.data).filter(key => this.currentEmailConfigType.allowedFields.includes(key));
    keys.forEach(key => {
      infoToSave[key] = this.data[key];
    });
    infoToSave["ConfigType"] = this.currentEmailConfigType.type;
    this.emailSettingsService.SaveEmailSettings(infoToSave).subscribe(result => {
      this.uiService.toggleGlobalSpinner(false, 1);
      this.snackBar.open('Changes saved sucesfully', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 8000,
      });
    }, (err) => {
      this.handleError(err);
      this.uiService.toggleGlobalSpinner(false, 1);
    });
  }

  /*
  
    Verified the entered information is working before persisting

  */
  onTest() {
    this.uiService.toggleGlobalSpinner(true);
    let infoToSave = {}
    let keys = Object.keys(this.data).filter(key => this.currentEmailConfigType.allowedFields.includes(key));
    keys.forEach(key => {
      infoToSave[key] = this.data[key];
    });
    infoToSave["configType"] = this.currentEmailConfigType.type;
    this.emailSettingsService.VerifyEmailSettings(infoToSave).subscribe(result => {
      this.uiService.toggleGlobalSpinner(false, 1);
      this.snackBar.open('Email Sent to Source Email, please verify', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 8000,
      });
    }, (err) => {
      this.handleError(err);
      this.uiService.toggleGlobalSpinner(false, 1);
    });
  }

  /**
   * Reloads the data to discard any changes.
   */
  onRestore() {
    this.uiService.toggleGlobalSpinner(true);
    this.LoadEmailSettings();
  }

  /**
   * Fetches the EmailSettings from the API and updates the
   * corresponding data.
   */
  private LoadEmailSettings() {
    this.emailSettingsService.GetEmailSettings().subscribe(result => {
      this.data = result;
      if (this.data) {
        this.currentEmailConfigType = this.emailConfigTypes[this.data.configType - 1];
      } else {
        this.data = {};
      }
      this.uiService.toggleGlobalSpinner(false, 1);
    }, (err) => {
      this.handleError(err);
      this.uiService.toggleGlobalSpinner(false, 1);
    });
  }

  /**
   * Default error handler
   */
  private handleError(err) {
    console.log(err);
    this.snackBar.open('Something went wrong', null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 8000,
    });
  }

  /**
   * Fills the tooltips for each of the fields involved in the configuration
   * or each type of email setting
   */
  private fillTooltips() {
    this.tooltips = {
      srcAlias: 'A name that will appear as the source of every email your company sends. It\'s recommended to use your company name here',
      srcEmail: 'The source email of every email that your company will send',
      sgApiKey: 'A sendgrid api key owned by your company. If left blank it will use SigneDeal\'s. To use SigneDeal\'s with a Source Email of your own, contact support.',
      host: 'Host of the service of SMTP',
      port: 'Port Number of SMTP service',
      username: 'Username of SMTP service',
      password: 'Password of SMTP service',
    };
  }

  /**
   * Hardcoded setup for the available options of EmailConfigSettings
   * along with the fields involved in each type with the corresponding
   * user friendly description.
   */
  private setupEmailConfigSettings() {
    this.emailConfigTypes = [
      {
        type: EmailSettingType.DEFAULT,
        label: 'Default',
        description: 'Use SigneDeal\'s Sendgrid account and email (info@signedeal.com)',
        allowedFields: []
      },
      {
        type: EmailSettingType.DOMAIN_ONLY,
        label: 'Your own domain within SigneDeal',
        description: 'Use SigneDeal\'s Sendgrid account with your own email. This requires you to have asked support for this configuration',
        allowedFields: ['srcAlias', 'srcEmail']
      },
      {
        type: EmailSettingType.USERS_SENDGRID,
        label: 'Your own SendGrid account',
        description: 'Use your own SendGrid account with an existing api key and source email.',
        allowedFields: ['srcAlias', 'srcEmail', 'sgApiKey']
      },
      {
        type: EmailSettingType.POP3,
        label: 'Use Your own Email Server (POP3)',
        description: 'Configure your own email server to work with our system using the POP3 protocol.',
        allowedFields: ['srcAlias', 'srcEmail', 'host', 'port', 'username', 'password']
      }
    ];
  }
}

export interface IEmailConfigSettings {
  type: number;
  label: string;
  description: string;
  allowedFields: string[];
}