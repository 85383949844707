<div *ngIf="(auth.isAuthenticated$ | async) === false">
  <div (click)="loginWithRedirect()" class="login-button">Log in</div>
</div>

<div *ngIf="auth.user$ | async as user">
  <button mat-button [matMenuTriggerFor]="logmenu" class="nav-user-container">
    <img class="image" [src]="user.picture" />
    <div class="name">{{ user.name | titlecase }}</div>
    <div class="lastname">{{ user.lastname | titlecase }}</div>
  </button>
  <mat-menu #logmenu="matMenu" xPosition="before">
    <button mat-menu-item (click)="onSettingsClick()" *ngIf="showSettingsMenu">
      <mat-icon class="action-icon hoverable" aria-label="Settings">settings</mat-icon>
      <span>Settings</span>
    </button>
    <button mat-menu-item (click)="logout()">
      <mat-icon class="action-icon hoverable" aria-label="Log out">logout</mat-icon>
      <span>Log out</span>
    </button>
  </mat-menu>
</div>
