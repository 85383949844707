<div>
    <div class="title">
        <mat-label>{{templateContent.whatsappOptionTemplateTemplateLabel}}</mat-label>
    </div>
    <div class="field-title">
        <mat-label>Language</mat-label>
    </div>
    <div class="field-content">
        <mat-label>{{allLangs[templateContent.language]}}</mat-label>
    </div>
    <div class="field-title">
        <mat-label>Content</mat-label>
    </div>
    <div class="field-content">
        <mat-label>{{templateContent.content}}</mat-label>
    </div>

    <div class="field-title">
        <mat-label>Twilio Content Sid</mat-label>
    </div>
    <div class="field-content">
        <input matInput type="text"
            [(ngModel)]="templateContent.whatsappOptionTemplateTemplateSid"
            placeholder="Content Sid" required autocomplete="off">
    </div>

    <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-cancel" (click)="onCancel()">Cancel</button>
        <button class="sgnd-btn sgnd-btn-ok" (click)="onAccept()">Accept</button>
        <button class="sgnd-btn sgnd-btn-cancel" (click)="onReject()">Reject</button>
    </div>
</div>