<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>Description</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field class="full-width-textarea">
        <input matInput [(ngModel)]="description" />
    </mat-form-field>
</mat-expansion-panel>
<mat-expansion-panel>
    <mat-expansion-panel-header>
        <mat-panel-title>Delay Time</mat-panel-title>
    </mat-expansion-panel-header>
    <mat-form-field>
        <mat-label>Days</mat-label>
        <input matInput type="number" [(ngModel)]="days" min="0" max="30" autocomplete="off"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Hours</mat-label>
        <input matInput type="number" [(ngModel)]="hours" min="0" max="23" autocomplete="off"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Minutes</mat-label>
        <input matInput type="number" [(ngModel)]="minutes" min="0" max="59" autocomplete="off"/>
    </mat-form-field>
    <mat-form-field>
        <mat-label>Seconds</mat-label>
        <input matInput type="number" [(ngModel)]="seconds" min="0" max="59" autocomplete="off"/>
    </mat-form-field>
</mat-expansion-panel>
<div class="buttons-container">
    <button class="sgnd-btn sgnd-btn-ok" (click)="saveChanges()">Save</button>
</div>
