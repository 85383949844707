import { formatDate } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { CompanySettingsService } from 'app/components/settings';
import { SimpleCompanySettings } from 'app/components/settings/model/simple-company-settings';
import downloadFileResponse from 'app/functions/fileFunctions';
import { AccountsReceivableDocument } from 'app/model/documents/accounts-receivable-document';
import { Flow } from 'app/model/flow';
import { NegotiationOption } from 'app/model/negotiation-option';
import { NegotiationType } from 'app/model/negotiation-type';
import { Slot } from 'app/model/slot';
import { AttachmentsService } from 'app/services/attachments.service';
import { DocumentsService } from 'app/services/documents.service';
import { FlowsService } from 'app/services/flows.service';
import { NegotiationOptionsService } from 'app/services/negotiation-options.service';
import { NegotiationsService } from 'app/services/negotiations.service';
import { UIService } from 'core/services/ui.service';
import { SlotsService } from 'src/app/services/slots.service';

@Component({
  selector: 'app-add-slot',
  templateUrl: './add-slot.component.html',
  styleUrls: ['./add-slot.component.css'],
})
export class AddSlotComponent implements OnInit {
  slotCreateForm: FormGroup;
  public availableFlows: Flow[];
  public displayQR = false;
  public elementType = NgxQrcodeElementTypes.URL;
  public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  private baseUrl = window.location.origin + '/mdi/';
  public url = this.baseUrl + 2;
  public files = [];

  private slot: Slot;
  private selectedIds: number[];

  constructor(
    public slotsService: SlotsService,
    private negotiationService: NegotiationsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddSlotComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      negotiationType: NegotiationType;
      flowId: number;
      slot: Slot;
      selectedIds: number[];
    },
    private flowService: FlowsService,
    private uiService: UIService,
    private snackBar: MatSnackBar,
    private negotiationOptionsService: NegotiationOptionsService,
    public attachmentsService: AttachmentsService,
    private companySettingsService: CompanySettingsService,
    public documentsService: DocumentsService
  ) {
    this.companySettingsService.$companyData.subscribe((companySettings: SimpleCompanySettings) => {
      if(companySettings?.companyId) {
        this.url = this.baseUrl + companySettings?.companyId
      }
    });

    this.attachmentsService.setFiles([]);
    if (data.slot) {
      this.slot = new Slot(data.slot);
      this.attachmentsService.getFilesBySlot(this.slot.slotId);
      if (this.slot.negotiationType === NegotiationType.OPTIONS) {
        this.negotiationOptionsService.setNegotiationOptions(data.slot?.slotOptions);
      }
      else if (this.slot.negotiationType === NegotiationType.ACCOUNTS_RECEIVABLE) {
        this.documentsService.setDocuments(this.slot.accountsPayableDocuments);
      }
    } else {
      this.slot = new Slot(null);
      this.negotiationOptionsService.setNegotiationOptions([]);
      this.documentsService.setDocuments([]);
    }
    this.selectedIds = data.selectedIds;
    this.flowService.flows.subscribe((values) => {
      if (this.data.negotiationType) {
        this.availableFlows = this.flowService.getFilteredFlows(this.data.negotiationType);
      } else {
        this.availableFlows = values;
      }
      if (!this.data.slot) {
        this.slot.isDataIngestor = this.data.slot?.isDataIngestor;
      }
      this.buildContactCreateForm(this.slot);
    });
  }

  ngOnInit(): void {
    this.attachmentsService.$files.subscribe((files) => (this.files = files));
  }

  mustBeDataIngestor() {
    return (!this.selectedIds || this.selectedIds.length === 0) && !this.data.slot;
  }

  buildContactCreateForm(slot: Slot) {
    this.slotCreateForm = this.formBuilder.group({
      slotId: slot.slotId,
      name: slot.name,
      flowId: slot.flowId || this.data.flowId,
      expirationDate: slot.expirationDate ? formatDate(slot.expirationDate, 'yyyy-MM-dd', 'en') : null,
      isDataIngestor: !!slot.isDataIngestor,
      autoSubscribe: slot.autoSubscribe,
      autoTrigger: slot.autoTrigger,
      negotiationType: slot.negotiationType,
    });
  }

  onSubmit(): void {
    const slot = new Slot(this.slotCreateForm.value);
    this.uiService.toggleGlobalSpinner(true);
    if (slot.negotiationType === NegotiationType.OPTIONS) {
      slot.slotOptions = this.negotiationOptionsService.getNegotiationOptions();
    }
    else if (slot.negotiationType === NegotiationType.ACCOUNTS_RECEIVABLE) {
      const docList = this.documentsService.getDocuments();
      slot.accountsPayableDocuments = docList as AccountsReceivableDocument[];
    }
    if (!slot.slotId) {
      this.createSlot(slot, this.selectedIds);
    } else {
      this.updateSlot(slot);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  createSlot(slot: Slot, selectedIds: number[]) {
    slot.negotiationIds = selectedIds;
    this.slotsService.createSlot(slot).subscribe(
      (slotId) => {
        this.attachmentsService
          .postFilesBySlot(slotId, this.files)
          .then(() => {
            this.slotsService.getData();
            this.negotiationService.getData();
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Channel created successfully.', null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
            this.dialogRef.close();
          })
          .catch((err) => {
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Error: ' + err, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
      },
      (errorResponse) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + JSON.parse(errorResponse.error)?.title, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  updateSlot(slot: Slot) {
    this.slotsService.updateSlot(slot).subscribe(
      (slotId) => {
        this.attachmentsService
          .postFilesBySlot(this.slot.slotId, this.files)
          .then(() => {
            this.slotsService.getData();
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Channel updated successfully.', null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
            this.dialogRef.close();
          })
          .catch((err) => {
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Error: ' + JSON.parse(err.error)?.title, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
      },
      (errorResponse) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + JSON.parse(errorResponse.error)?.title, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  public showDataIngestor() {
    return this.slotCreateForm?.value?.isDataIngestor;
  }

  /**
   * Checks if the currently selected flow supports auto trigger and enables
   * or disables the checkbox automatically. It also sets the auto trigger
   * value as false for flows that don't support it.
   * 
   * @returns Returns true or false depending on whether the current flow
   * supports auto trigger or not. So fay, only Third Party and Option type
   * flows are able to support this feature.
   */
  public enableAutoTrigger() {
    let selectedFlowId = this.slotCreateForm.value?.flowId;
    if(selectedFlowId) {
      let validTypes = [NegotiationType.OPTIONS, NegotiationType.THIRD_PARTY];
      let selectedFlow = this.availableFlows.find(f => f.flowId === selectedFlowId)
      let cannotAutoTrigger = !validTypes.includes(parseInt(selectedFlow.type + ''));
      if(cannotAutoTrigger) {
        this.slotCreateForm.patchValue({autoTrigger:false});
      }
      return cannotAutoTrigger;
    }
    return true;
  }

  public onSelectFlow(flowId: number) {
    const flow = this.availableFlows.find((flow) => flow.flowId == flowId);
    (<any>this.slot).negotiationType = flow.type;
    this.slotCreateForm.patchValue({
      flowId: flow.flowId,
      negotiationType: flow != null ? flow.type.toString() : null,
    });
  }

  public disableNegotiationType() {
    return (this.slot && this.slot.slotId != null) || this.data.flowId;
  }

  public onShowQr() {
    this.displayQR = true;
  }

  public OnDownloadQr() {
    this.displayQR = false;
    const fileNameToDownload = 'image_qrcode';
    const base64Img = document.getElementsByClassName('coolQRCode')[0].children[0]['src'];
    fetch(base64Img)
      .then((res) => res.blob())
      .then((blob) => {
        // IE
        if (window.navigator && (<any>window.navigator).msSaveOrOpenBlob) {
          (<any>window.navigator).msSaveOrOpenBlob(blob, fileNameToDownload);
        } else {
          // Chrome
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = fileNameToDownload;
          link.click();
        }
      });
  }

  isOptionsSlot() {
    let negotiationType = this.slotCreateForm.get('negotiationType').value;
    let result = this.isNegotiationType(negotiationType, NegotiationType.OPTIONS);
    return result;
  }

  isARSlot() {
    return this.isNegotiationType(this.slotCreateForm.get('negotiationType').value, NegotiationType.ACCOUNTS_RECEIVABLE);
  }

  is3PSlot() {
    return Number(this.slotCreateForm.get('negotiationType').value) === NegotiationType.THIRD_PARTY;
  }

  private isNegotiationType(value: any, type: NegotiationType): boolean {
    return value !== null && value !== undefined && Number(value) === type;
  }

  onFileSelected(file) {
    this.files = [...this.files, file];
  }

  onRemoveFile(file) {
    this.files = this.files.filter((f) => f !== file);
  }

  onDownloadFile(file) {
    this.attachmentsService
      .getAttachmentForSlot(this.slot.slotId, file.id)
      .then((data) => downloadFileResponse(data, file.originalName));
  }
}
