import { Injectable } from '@angular/core';
import { Negotiation, NegotiationDto } from 'app/model/negotiation';
import { BehaviorSubject } from 'rxjs';
import { NegotiationMessageApiService } from './negotiation-message-api.service';

@Injectable({
  providedIn: 'root',
})
export class NegotiationMessageService {
  $negotiationMessages = new BehaviorSubject(null);
  $showMessages = new BehaviorSubject(false);
  $unreadMessages = new BehaviorSubject({});
  $negotiation = new BehaviorSubject<Negotiation | NegotiationDto>(null);
  $primaryColor = new BehaviorSubject('#101a35');
  $secondaryColor = new BehaviorSubject('white');
  intermediateInterval = null;
  monitorInterval = null;
  private readonly UPDATE_TIME = 20 * 1000;

  constructor(private messageApi: NegotiationMessageApiService) {}

  public toggleMessages(show: boolean) {
    this.$showMessages.next(show);
  }

  public selectNegotiation(negotiation: Negotiation | NegotiationDto) {
    this.$negotiation.next(negotiation);
  }

  setPrimaryBackgroundColor(primaryColor: string) {
    this.$primaryColor.next(primaryColor);
  }

  setSecondaryColor(secondaryColor: string) {
    this.$secondaryColor.next(secondaryColor);
  }

  public getWhatsappUnreadQty(slotId: number) {
    return this.messageApi.getWhatsappUnreadQty(slotId);
  }

  public getMonitorMessages(negotiationId: any) {
    return this.messageApi.getMonitorMessages(negotiationId);
  }

  public getMessagesIntermediate(negotiationId) {
    this.messageApi.getIntermediateUnreadQty(negotiationId).subscribe(
      (res) => this.$unreadMessages.next({ [negotiationId]: res }),
      (err) => console.log(err)
    );
    this.messageApi.getNegotiationMessagesView([negotiationId]).subscribe((messages) => {
      this.$negotiationMessages.next(messages);
    });
    this.intermediateInterval = setInterval(() => {
      this.messageApi.getNegotiationMessagesView([negotiationId]).subscribe((messages) => {
        this.$negotiationMessages.next(messages);
      });
      this.messageApi.getIntermediateUnreadQty(negotiationId).subscribe(
        (res) => this.$unreadMessages.next({ [negotiationId]: res }),
        (err) => console.log(err)
      );
    }, this.UPDATE_TIME);
  }

  public clearIntervals() {
    if (this.intermediateInterval) {
      clearInterval(this.intermediateInterval);
    }
  }

  public postMonitorMessage(parentNegotiationMessageId, negotiationId, contactId, timestamp, content, read, replied) {
    const message = {
      parentNegotiationMessageId,
      negotiationId,
      contactId,
      timestamp,
      content,
      read,
      replied,
      sender: 0,
    };
    return this.messageApi.postMonitorMessage(message);
  }

  public postMessageIntermediate(
    parentNegotiationMessageId,
    negotiationId,
    contactId,
    timestamp,
    content,
    read,
    replied
  ) {
    const message = {
      parentNegotiationMessageId,
      negotiationId,
      contactId,
      timestamp,
      content,
      read,
      replied,
      sender: 1,
    };
    this.messageApi.postMessage(message).subscribe(() => {
      const messages = [...this.$negotiationMessages.getValue(), message];
      this.$negotiationMessages.next(messages);
    });
  }

  public markAsRead(messages) {
    messages.forEach((message) => {
      this.messageApi.updateMessage(message.id, true, true).subscribe();
    });
  }
}
