<div class="messages-modal-container">
  <div class="title">
    <h1>
      Messages from {{ contactName }} <span class="contact-phone">{{ contactPhone }}</span>
    </h1>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="messages-modal-content" #messagesRef>
    <ul class="messages-list">
      <li *ngFor="let message of messages">
        <div class="message-line" [class.sender]="message.sender === 0">
          <div class="message-text">
            {{ message.content }}
          </div>
          <div class="timestamp">
            {{ message.timestamp | date : 'shortTime' }}
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="input-container">
    <textarea
      rows="3"
      style="resize: none"
      [(ngModel)]="messageText"
      (keyup.enter)="sendMessage()"
      appMyAutofocus
    ></textarea>
    <button class="send-button" (click)="sendMessage()">Send</button>
  </div>
</div>
