import { Component, OnInit } from '@angular/core';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { IntermediateService } from 'app/services/intermediate.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-hardcoded-qr-intermediate',
  templateUrl: './hardcoded-qr-intermediate.component.html',
  styleUrls: ['./hardcoded-qr-intermediate.component.css']
})
export class HardcodedQrIntermediateComponent implements OnInit {
  public elementType = NgxQrcodeElementTypes.URL;
  public correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  public companyFlowConfiguration: ICompanyDefaultFlowConfig;
  public hardcodedCompanyId = 2;

  constructor(
    private uiService: UIService,
    private intermediateService: IntermediateService,
  ) {
    this.uiService.toggleGlobalSpinner(true);
    this.intermediateService.flowConfiguration.subscribe(value => {
      this.companyFlowConfiguration = value;
      this.uiService.toggleGlobalSpinner(false);
    });
    // TODO: CompanyId should come from the query string
    this.intermediateService.getDefaultCompanyFlowConfiguration(this.hardcodedCompanyId);
  }

  ngOnInit(): void {
  }

}

export interface ICompanyDefaultFlowConfig {
  iconUrl: string;
  redirectUrl: string;
  primaryColor: string;
  secondaryColor: string;
}
