import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MonitorSlot } from 'app/model/monitor-slot';

@Component({
  selector: 'app-negotiations-chart',
  templateUrl: './negotiations-chart.component.html',
  styleUrls: ['./negotiations-chart.component.css']
})
export class NegotiationsChartComponent implements OnInit {

  single = [];
  view: any[] = [770, 250];

  showLegend = true;
  showLables = true;
  isDoughnut = true;
  public slot: MonitorSlot;

  colorScheme = {
    domain: ['#101a35', '#101a3577', '#101a3533', '#5e2121', '#ff5a5a']
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<NegotiationsChartComponent>
  ) {
    this.slot = data.slot;
    this.single = [
      {
        name: 'Completed',
        value: this.slot.completedQty,
      },
      {
        name: 'Pending',
        value: this.slot.pendingQty,
      },
      {
        name: 'Canceled',
        value: this.slot.cancelledQty,
      },
      {
        name: 'Expired',
        value: this.slot.expiredQty,
      },
      {
        name: 'Error',
        value: this.slot.errorQty,
      },
    ];
  }

  ngOnInit(): void {
  }

  onClose() {
    this.dialogRef.close();
  }
}
