import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { ErrorComponent } from '../core/components/error/error.component';
import { AdminComponent } from './components/admin/admin.component';
import { CompanyApiCredentialsListComponent } from './components/admin/company-api-credentials-list/company-api-credentials-list.component';
import { CompanyListComponent } from './components/admin/company-list/company-list.component';
import { BillingScreenComponent } from './components/billing/billing-screen/billing-screen.component';
import { ContactSuscriptionComponent } from './components/data-ingestion/contact-suscription/contact-suscription.component';
import { ContactsScreenComponent } from './components/deal/contacts/contacts-screen/contacts-screen.component';
import { DealComponent } from './components/deal/deal.component';
import { MonitorComponent } from './components/deal/monitor/monitor.component';
import { NegotiationsScreenComponent } from './components/deal/negotiations/negotiations-screen/negotiations-screen.component';
import { StudioComponent } from './components/deal/studio/studio.component';
import { DocumentationDetailsComponent } from './components/documentation/documentation-details/documentation-details.component';
import { AccountsReceivableComponent } from './components/intermediate/accounts-receivable/accounts-receivable.component';
import { CallbackComponent } from './components/intermediate/callback/callback.component';
import { HardcodedQrIntermediateComponent } from './components/intermediate/hardcoded-qr-intermediate/hardcoded-qr-intermediate.component';
import { IntermediateComponent } from './components/intermediate/intermediate.component';
import { NegotiationIntermediateComponent } from './components/intermediate/negotiation-intermediate/negotiation-intermediate.component';
import { CompanySettingsComponent } from './components/settings/components/company-settings/company-settings.component';
import { BillingSettingsComponent } from './components/settings/components/settings/billing-settings/billing-settings.component';
import { PaymentComponent } from './components/settings/components/settings/billing-settings/payment/payment.component';
import { CompaniesSettingsComponent } from './components/settings/components/settings/companies-settings/companies-settings.component';
import { CompanyCredentialsComponent } from './components/settings/components/settings/company-credentials/company-credentials.component';
import { EmailSettingsComponent } from './components/settings/components/settings/email-settings/email-settings.component';
import { SettingsComponent } from './components/settings/components/settings/settings.component';
import { UserSettingsComponent } from './components/settings/components/settings/user-settings/user-settings.component';
import { CanActivateBilling } from './services/routing/CanActivateBilling';

const routes: Routes = [
  {
    path: 'intermediate',
    component: IntermediateComponent,
    children: [
      {
        path: 'accounts-receivable/:negotiationId',
        component: AccountsReceivableComponent,
      },
      {
        path: 'negotiation/:negotiationId',
        component: NegotiationIntermediateComponent,
      },
    ],
  },
  {
    path: 'intermediate/qr-scan',
    component: HardcodedQrIntermediateComponent,
  },
  {
    path: 'mdi/:companyId',
    component: ContactSuscriptionComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'company',
      },
      {
        path: 'user',
        component: UserSettingsComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'user' },
      },
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'admin' },
      },
      {
        path: 'company',
        component: CompaniesSettingsComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'company' },
      },
      {
        path: 'subscription',
        component: BillingSettingsComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'subscription' },
      },
      {
        path: 'payment',
        component: PaymentComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'payment' },
      },
      {
        path: 'company-list',
        component: CompanyListComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'company-list' },
      },
      {
        path: 'company-api-credentials-list',
        component: CompanyApiCredentialsListComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'company-api-credentials-list' },
      },
      {
        path: 'credentials',
        component: CompanyCredentialsComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'credentials' },
      },
      {
        path: 'email-settings',
        component: EmailSettingsComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'email-settings' },
      }
    ],
  },
  {
    path: '',
    component: DealComponent,
    children: [
      {
        path: 'admin',
        component: AdminComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'admin' },
      },
      {
        path: 'contacts',
        component: ContactsScreenComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'contacts' },
      },
      {
        path: 'channels',
        component: NegotiationsScreenComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'negotiations' },
      },
      {
        path: 'monitor',
        component: MonitorComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'monitor' },
      },
      {
        path: 'studio',
        component: StudioComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'studio' },
      },
      {
        path: 'billing',
        component: BillingScreenComponent,
        data: { animation: 'billing' },
      },
      {
        path: 'settings-company',
        component: CompanySettingsComponent,
        canActivate: [AuthGuard, CanActivateBilling],
        data: { animation: 'settings' },
      },
      {
        path: 'documentation',
        component: DocumentationDetailsComponent,
        data: { animation: 'documentation' },
      },
      {
        path: 'error',
        component: ErrorComponent,
      },
      {
        path: '',
        component: ContactsScreenComponent,
        pathMatch: 'full',
        canActivate: [AuthGuard, CanActivateBilling],
      },
    ],
  },
  {
    path: 'callback',
    component: CallbackComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
