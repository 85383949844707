<div class="demo-button-container" *ngIf="!onlyDownloads">
  <button mat-raised-button (click)="$event.preventDefault(); fileSelector.click()">Add File</button>
  <input
    #fileSelector
    class="hidden-element"
    type="file"
    placeholder="Upload a document"
    (change)="handleFileChange($event.target)"
  />
</div>

<table mat-table [dataSource]="files">
  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let file">{{ file.name ? file.name : file.originalName }}</td>
  </ng-container>
  <ng-container matColumnDef="download">
    <th mat-header-cell *matHeaderCellDef>Download</th>
    <td mat-cell *matCellDef="let file">
      <div (click)="downloadFile(file)">
        <mat-icon>download</mat-icon>
      </div>
    </td></ng-container
  >

  <div *ngIf="!onlyDownloads">
    <ng-container matColumnDef="remove">
      <th mat-header-cell *matHeaderCellDef>Remove</th>
      <td mat-cell *matCellDef="let file">
        <mat-icon (click)="deleteElement(file)">delete</mat-icon>
      </td>
    </ng-container>
  </div>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
