<mat-card style="margin-bottom: 0.25rem">
  <filter-paginator
    placeholder="Filter by name and status"
    [length]="dataLength"
    [reset]="resetFilterPaginator"
    [setState]="setFilterState"
    [pageSize]="10"
    [pageSizeOptions]="[5, 10, 25, 50, 100]"
    (filterPage)="onFilterPage($event)"
  >
  </filter-paginator>
  <table
    mat-table
    [dataSource]="dataSource"
    matSort
    (matSortChange)="onSortSlots($event)"
    class="mat-elevation-z1"
    fixedLayout>
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox
          (change)="masterToggle(); emitSelectedSlots()"
          [checked]="selection.hasValue() && isAllSelected()"
          [indeterminate]="selection.hasValue() && !isAllSelected()"
        >
        </mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (change)="selection.toggle(row); emitSelectedSlots()" [checked]="isSlotSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="SlotName">Name</th>
      <td mat-cell *matCellDef="let element">{{ element.slotName }}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="Type">Type</th>
      <td mat-cell *matCellDef="let element">{{ intToNegotiationType(element.type) }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="Status">Status</th>
      <td mat-cell *matCellDef="let element">{{ intToSlotStatus(element.status) }}</td>
    </ng-container>

    <ng-container matColumnDef="started">
      <th mat-header-cell *matHeaderCellDef>Started</th>
      <td mat-cell *matCellDef="let element">{{ element.started ? 'Yes' : 'No' }}</td>
    </ng-container>

    <ng-container matColumnDef="negotiations">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="TotalQty">Negotiations</th>
      <td mat-cell *matCellDef="let element">{{ element.totalQty }}</td>
    </ng-container>

    <ng-container matColumnDef="progress">
      <th mat-header-cell *matHeaderCellDef>Progress</th>
      <td mat-cell *matCellDef="let element">
        <app-negotiation-progress
          [values]="[element.completedPercent, element.pendingPercent, element.cancelledPercent, element.expiredPercent, element.errorPercent]"
          [labels]="[
            element.completedQty + ' completed (' + getRoundedPercentage(element.completedPercent) + '%)',
            element.pendingQty + ' pending (' + getRoundedPercentage(element.pendingPercent) + '%)',
            element.cancelledQty + ' canceled (' + getRoundedPercentage(element.cancelledPercent) + '%)',
            element.expiredQty + ' expired (' + getRoundedPercentage(element.expiredPercent) + '%)',
            element.errorQty + ' error(s) (' + getRoundedPercentage(element.errorPercent) + '%)'
          ]"
        ></app-negotiation-progress>
      </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <button mat-button [matMenuTriggerFor]="menu">
          <mat-icon class="action-icon hoverable" aria-label="Options">more_vert</mat-icon>
        </button>
        <mat-menu #menu="matMenu">
          <button mat-menu-item (click)="onOpenDetails(element)">
            <mat-icon class="action-icon hoverable" aria-label="View Channel details">search</mat-icon>
            <span>View details</span>
          </button>
          <button mat-menu-item (click)="onViewChart(element)">
            <mat-icon class="action-icon hoverable" aria-label="View Channel chart">insert_chart</mat-icon>
            <span>View chart</span>
          </button>
          <button mat-menu-item (click)="onCancelSlot(element)" *ngIf="!cannotBeCanceled(element)">
            <mat-icon class="action-icon hoverable" aria-label="Cancel Channel Execution">stop_circle</mat-icon>
            <span>Cancel Execution</span>
          </button>
          <button mat-menu-item *ngIf="cannotBeCanceled(element)">
            <mat-icon class="action-icon action-icon-disabled" aria-label="Cancel Channel Execution">stop_circle</mat-icon>
            <span>Cancel Execution</span>
          </button>
          <!-- Remove Channel Currently Disabled-->
          <button mat-menu-item (click)="onRemoveSlot(element)" *ngIf="false">
            <mat-icon class="action-icon" aria-label="Remove Channel">delete</mat-icon>
            <span>Remove Channel</span>
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</mat-card>
