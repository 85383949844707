<div class="title">{{ negotiation.negotiationId ? 'Update' : 'Create' }} Negotiation</div>

<form [formGroup]="negotiationCreateForm" (ngSubmit)="onSubmit()">
  <div class="negotiation-container">
    <div fxLayout="row" fxLayoutAlign="space-around">
      <app-autocomplete
        (selected)="updateContactsSelection($event)"
        placeholder="Contact"
        [options]="contacts"
        [text]="contactNameSelected"
        class="regular-field"
      >
      </app-autocomplete>
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Description</mat-label>
        <input
          matInput
          formControlName="description"
          type="text"
          placeholder="Description"
          required
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around">
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Due Date</mat-label>
        <input matInput formControlName="dueDate" name="dueDate" type="date" placeholder="Due Date" required />
      </mat-form-field>
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Flow</mat-label>
        <mat-select
          matInput
          name="flowId"
          ngDefaultControl
          formControlName="flowId"
          (selectionChange)="onSelectFlow($event.value)"
          [disabled]="negotiation.negotiationId != null"
          required
        >
          <mat-option *ngFor="let flow of availableFlows" [value]="flow.flowId">{{ flow.name }} </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div fxLayout="row" fxLayoutAlign="space-around" *ngIf="!is3PNegotiation()" style="position: relative">
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Message Text</mat-label>
        <input matInput formControlName="messageText" type="text" placeholder="Message Text" autocomplete="off" />
      </mat-form-field>
      <mat-icon
        class="message-tooltip"
        matTooltip="The content of this field is displayed to the Contact in the CCP (mobile) screen on top of all available options. It's useful to add a description of the displayed options or a short explanation."
        >info</mat-icon
      >
    </div>

    <div class="3p-fields-container" fxLayout="row" fxLayoutAlign="space-around" *ngIf="is3PNegotiation()">
      <mat-form-field appearance="fill" class="regular-field">
        <mat-label>Third Party URL</mat-label>
        <input
          matInput
          formControlName="ccpLink"
          type="text"
          placeholder="Third party url*"
          [required]="is3PNegotiation()"
          autocomplete="off"
        />
      </mat-form-field>
    </div>

    <div class="demo-fields-container">
      <div fxLayout="row" fxLayoutAlign="space-around" *ngIf="isOptionsNegotiation()">
        <mat-form-field appearance="fill" class="regular-field">
          <mat-label>Document Id</mat-label>
          <input
            matInput
            formControlName="documentId"
            name="documentId"
            type="text"
            placeholder="Document Id"
            autocomplete="off"
            [required]="!is3PNegotiation()"
          />
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
          <mat-label>Document Description</mat-label>
          <input
            matInput
            formControlName="documentDescription"
            name="documentDescription"
            type="text"
            autocomplete="off"
            placeholder="Document Description"
          />
        </mat-form-field>
      </div>
    </div>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header class="collapsible-title">
          <mat-panel-title>Actions</mat-panel-title>
        </mat-expansion-panel-header>
        <div fxLayout="row" fxLayout class="checkboxes-container">
          <mat-checkbox
            style="min-width: 25%"
            *ngFor="let flowAction of flowActions"
            [checked]="flowAction.enable"
            (change)="checkAction(flowAction, $event.checked)"
            >{{ flowAction.description }}</mat-checkbox
          >
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel *ngIf="!is3PNegotiation()">
        <mat-expansion-panel-header class="collapsible-title">
          <mat-panel-title>Files</mat-panel-title>
        </mat-expansion-panel-header>
        <app-files-list
          (fileSelected)="onFileSelected($event)"
          [files]="files"
          (downloadIt)="onDownloadFile($event)"
          (fileDeleted)="onRemoveFile($event)"
        ></app-files-list>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="demo-fields-container"
        *ngIf="isARNegotiation() || isOptionsNegotiation()"
        (opened)="docsOpenState = true"
        (closed)="docsOpenState = false"
      >
        <mat-expansion-panel-header class="collapsible-title">
          <mat-panel-title *ngIf="isARNegotiation()">Documents</mat-panel-title>
          <mat-panel-title *ngIf="isOptionsNegotiation()">Options</mat-panel-title>
        </mat-expansion-panel-header>
        <app-options-grid *ngIf="isOptionsNegotiation()"></app-options-grid>
        <app-accounts-receivable-documents-grid *ngIf="isARNegotiation()"></app-accounts-receivable-documents-grid>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="create-negotiation-buttons">
      <button class="sgnd-btn sgnd-btn-ok" [disabled]="!negotiationCreateForm.valid" type="submit">Save</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</form>
