import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ContentTemplateService } from 'app/services/content-template';
import { debounceTime } from 'rxjs/operators';
import { CreateTemplateRequest, IInnerFormComponent } from '../../model';

@Component({
  selector: 'app-twilio-media-url',
  templateUrl: './twilio-media-url.component.html',
  styleUrls: ['./twilio-media-url.component.css'],
})
export class TwilioMediaUrlComponent implements OnInit, IInnerFormComponent {
  public isValidUrl = false;
  public inputControl = new FormControl('');
  private readonly DEBOUNCE_SECONDS: number = 1;
  @Input() public variables: any;
  @Output() public onStatusUpdate = new EventEmitter<string>();
  
  constructor(
    private contentTemplateService: ContentTemplateService
  ) {
    this.setupChangeDetector();
  }

  ngOnInit(): void { }
  
  /**
   * Setus up a change detector that will trigger a function 
   * DEBOUNCE_SECONDS after the input has been modified.
   */
  setupChangeDetector() {
    this.inputControl.valueChanges.pipe(
      debounceTime(this.DEBOUNCE_SECONDS * 1000) // Debounce time of DEBOUNCE_SECONDS
    ).subscribe(() => {
      this.onChangesDetected();
    });
  }

  private onChangesDetected() {
    const inputValue: string = this.inputControl.value;
    if(inputValue) {
      this.contentTemplateService.isValidFileUrl(inputValue).subscribe(result => {
        this.setInputValid(result);
      });
    } else {
      this.setInputValid(false);
    }
  }

  private setInputValid(isValid: boolean): void {
    this.isValidUrl = isValid;
    if(isValid) {
      this.inputControl.setErrors(null);
    } else {
      this.inputControl.setErrors({ 'invalid': !isValid });
    }
    this.onStatusUpdate.emit('');
  }

  public onAddVariable(): void {
    let length = this.variables.length;
    let inputValue = this.inputControl.value;
    this.inputControl.setValue(inputValue + `{{${length + 1}}}`);
    this.variables.push('negotiationId');
  }

  public disableAddVariableBtn(): boolean {
    // let inputValue = this.inputControl.value;
    // return inputValue && inputValue.includes('{{') && inputValue.includes('}}');
    return true;
  }

  public clearVariable(index: number) {
    if(index !== -1) {
      let inputValue = this.inputControl.value;
      inputValue = inputValue.replace('{{' + (index + 1) + '}}', ''); // Delete current index
      if(this.variables.length > index) {
        // Decrease higher indexes
        for(let i = index + 1; i < this.variables.length; i++) {
          inputValue = inputValue.replace('{{' + (i + 1) + '}}', '{{' + i + '}}');
        }
      }
      // Persist changes
      this.inputControl.setValue(inputValue);
    }
  }

  public isFormValid(): boolean {
    let formIsValid = this.inputControl.value && this.inputControl.value.length && this.inputControl.valid;
    return formIsValid
  }

  public addData(currentData: CreateTemplateRequest) {
    currentData.media = this.inputControl.value;
  }
}
