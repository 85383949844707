import { HttpBackend, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AnalyticsApiService {
  private baseURL: string;

  constructor(private http: HttpClient, private noAuthHttpClient: HttpClient, private handler: HttpBackend) {
    this.noAuthHttpClient = new HttpClient(this.handler);
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/analytics';
  }

  getMetrics() {
    return this.http.get(this.baseURL);
  }

  countBillingVisit() {
    return this.noAuthHttpClient.put(this.baseURL + '/billing-screen', {});
  }

  clearBillingVisit() {
    return this.http.delete(this.baseURL + '/billing-screen');
  }
}
