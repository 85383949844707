export class Schedule {
  public entityId: number;
  public entityType: string;
  public schedule: string;
  public starts: Date;
  public ends: Date;
  public repeats: string;

  constructor(schedule: Schedule) {
    this.entityId = schedule?.entityId;
    this.entityType = schedule?.entityType;
    this.schedule = schedule?.schedule;
    this.starts = schedule?.starts;
    this.ends = schedule?.ends;
    this.repeats = schedule?.repeats;

  }
}

export class ScheduleObject {
  public timeRun: string;
  public selectedDay: number;
  public selectedHour: number;
  public selectedMin: number;
  public selectedMonth: number;
  public selectedDW: string;
  public status: string;

  constructor({ 
    selectedDW,
    selectedDay,
    selectedHour,
    selectedMin,
    selectedMonth, status, timeRun }) {
    this.selectedDW = selectedDW;
    this.selectedDay = selectedDay;
    this.selectedHour = selectedHour;
    this.selectedMin = selectedMin;
    this.selectedMonth = parseInt(selectedMonth);
    this.status = status;
    this.timeRun = timeRun;

  }


}