import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { SimpleCompanySettings } from 'app/components/settings/model/simple-company-settings';
import { CompanySettingsService } from 'app/components/settings/services/company-settings.service';
import { LanguageService } from 'app/services/language.service';
import { UIService } from 'core/services/ui.service';
import { UserPasswordChangeComponent } from '../../user-password-change/user-password-change.component';

type LanguageOption = {
  languageCode: string;
  name: string;
  enabled: boolean;
};

@Component({
  selector: 'app-companies-settings',
  templateUrl: './companies-settings.component.html',
  styleUrls: ['./companies-settings.component.css'],
})
export class CompaniesSettingsComponent implements OnInit, OnDestroy {
  public tooltips: { [key: string]: string };
  public companyUpdateForm: FormGroup;
  public languages: LanguageOption[] = [];

  constructor(
    private router: Router,
    private uiService: UIService,
    private snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private companySettingsService: CompanySettingsService,
    private languageService: LanguageService
  ) {
    this.uiService.toggleGlobalSpinner(true);
    this.companySettingsService.$companyData.subscribe(
      (result) => {
        console.log(result);
        this.uiService.toggleGlobalSpinner(false);
        if (result) {
          this.checkInvalidCompany(result);
          this.buildCompanyUpdateForm(result);
        }
      },
      (err) => {
        this.handleError(err);
      }
    );
  }

  ngOnInit(): void {
    this.fillTooltips();
    this.loadLanguages();
  }

  fixUrls() {
    this.companyUpdateForm.value.icon = this.fixUrl(this.companyUpdateForm.value.icon);
    this.companyUpdateForm.value.redirectUrl = this.fixUrl(this.companyUpdateForm.value.redirectUrl);
  }

  fixUrl(url: string) {
    const validProtocols = ['http://', 'https://'];
    const usedProtocol = validProtocols.find((p) => url && url.includes(p));
    if (!usedProtocol && url) {
      url = validProtocols[1] + url;
    }
    return url;
  }

  buildCompanyUpdateForm(settings: SimpleCompanySettings) {
    this.companyUpdateForm = this.formBuilder.group({
      name: settings.name,
      primaryColor: settings.primaryColor,
      secondaryColor: settings.secondaryColor,
      redirectUrl: settings.redirectUrl,
      icon: settings.icon,
    });
  }

  disableSubmit() {
    const companyName = this.companyUpdateForm.value.name;
    return !companyName || companyName.length === 0;
  }

  ngOnDestroy(): void {
    this.uiService.toggleNavBars(true);
  }

  async onSubmit() {
    this.fixUrls();
    const values = this.companyUpdateForm.value;
    const enabledLanguages = this.languages.filter((x) => x.enabled).map((x) => x.languageCode);
    this.uiService.toggleGlobalSpinner(true);
    try {
      const promises = [
        this.companySettingsService.saveSimpleCompanySettings(values).toPromise(),
        this.languageService.setCompanyLanguages(enabledLanguages),
      ];
      await Promise.all(promises);
      this.snackBar.open('Company updated successfully.', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    } catch (err) {
      this.handleError(err);
    }
    this.uiService.toggleGlobalSpinner(false);
  }

  private handleError(err) {
    console.error(err);
    this.snackBar.open('Error while attempting to update company data.', null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 3000,
    });
    this.uiService.toggleGlobalSpinner(false);
  }

  private checkInvalidCompany(companySettings: SimpleCompanySettings) {
    if (companySettings.companyId === -1 || companySettings.companyId == null) {
      this.router.navigate(['/']);
    }
  }

  private fillTooltips() {
    this.tooltips = {
      name: 'Name used to identify your company. This can be used in several actions performed while contacting end users.',
      icon:
        'A url that contains an icon or logo that represents your company. This will be shown in the ' +
        'CCP mobile screen and in the Data Ingestor registration screen.',
      redirectUrl:
        'A Url to which end users will be redirected upon interacting with the CCP mobile screen. Using ' +
        'the main website of your company is recomended.',
      primaryColor:
        'A color to be used as background for both headers and footers, as well as buttons, in the CCP Mobile ' +
        'screen and also in the Data Ingestor registration screen.',
      secondaryColor:
        'A color to be used on text for both headers and footers, as well as buttons, in the CCP Mobile screen ' +
        'and also in the Data Ingestor registration screen.',
      languages: 'Enabled languages for this company'
    };
  }

  private async loadLanguages() {
    const allLanguages = await this.languageService.getAllLanguages();
    const companyLanguages = (await this.languageService.getLanguages()).map((x) => x.languageCode);
    this.languages = allLanguages.map((x) => ({
      languageCode: x.languageCode,
      name: x.name,
      enabled: companyLanguages.includes(x.languageCode),
    }));
  }
}
