<div class="negotiation-variables">
  <table>
    <thead>
      <tr>
        <td class="negotiation-variables-title">Negotiation Variables</td>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let negotiationVariable of negotiationVariables" (mousedown)="preventFocus($event)"
        (click)="selectNegotiationVariable(negotiationVariable)">
        <td>{{ negotiationVariable.name }}</td>
      </tr>
    </tbody>
  </table>
</div>