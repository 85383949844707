<div class="messages-modal-container">
  <div class="title">
    <h1>
      SMS Messages from {{ contactName }} <span class="contact-phone">{{ contactPhone }}</span>
    </h1>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div class="messages-modal-content" #messagesRef>
    <ul class="messages-list">
      <li *ngFor="let message of messages">
        <div
          class="message-line"
          [class.sender]="message.messageOrigin === 0"
          [class.undelivered]="message.messageStatus === smsStatus.UNDELIVERED"
          [class.failed]="message.messageStatus === smsStatus.FAILED"
        >
          <div class="message-text">
            {{ message.messageContent }}
            <div *ngIf="message.messageStatus === smsStatus.FAILED" class="invalid-number">Invalid Number</div>
          </div>
          <div class="timestamp">
            {{ message.created | date : 'shortTime' }}
            <mat-icon
              *ngIf="message.messageOrigin === 0"
              class="status-icon"
              [style.color]="smsStatusMapping[message.messageStatus]?.color"
              >{{ smsStatusMapping[message.messageStatus]?.icon }}</mat-icon
            >
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="input-container">
    <textarea
      rows="3"
      style="resize: none"
      [(ngModel)]="messageText"
      (keyup.enter)="sendMessage()"
      appMyAutofocus
    ></textarea>
    <button class="send-button" (click)="sendMessage()">Send</button>
  </div>
</div>
