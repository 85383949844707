<div>
  <app-nav-bar></app-nav-bar>
  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #sidenav mode="side" opened>
      <mat-selection-list [multiple]="false" class="sidenav">
        <mat-list-option
          *ngFor="let opt of options"
          [value]="opt.link"
          [routerLink]="opt.link"
          [selected]="currentOption == opt.link"
        >
          <div class="list-option">
            <mat-icon class="action-icon hoverable" aria-label="Settings">{{ opt.icon }}</mat-icon>
            <span>{{ opt.text }}</span>
          </div>
        </mat-list-option>
      </mat-selection-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
