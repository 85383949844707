import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ContentTemplateType, CreateTemplateRequest } from '../model';
import { ContentTemplateService } from 'app/services/content-template';
import { WhatsAppContentTemplate } from 'app/model/content-template';

@Component({
  selector: 'app-create-content-template.component',
  templateUrl: './create-content-template.component.html',
  styleUrls: ['./create-content-template.component.css'],
})
export class CreateContentTemplateComponent implements OnInit {
  public selectedType: ContentTemplateType;
  public data: CreateTemplateRequest;
  public stepNumber: number = 0;

  constructor(
    private contentTemplateService: ContentTemplateService,
    public dialogRef: MatDialogRef<CreateContentTemplateComponent>,
  ) {}
  
  ngOnInit(): void { }

  public onCancel() {
    this.dialogRef.close(null);
    this.restoreConfiguration();
  }

  private restoreConfiguration() {
    this.stepNumber = 0;
    this.selectedType = null;
  }

  public onSelectedNext(event: ContentTemplateType) {
    this.selectedType = event;
    this.stepNumber = 1;
  }

  public onFormNext(event: any) {
    this.data = event;
    this.stepNumber = 2;
  }

  public onPreviewSubmit(event: any) {
    this.data.contentType = this.selectedType.type;
    this.contentTemplateService.createTemplate(this.data.asWhatsAppContentTemplate())
    .subscribe(response => {
      this.dialogRef.close(response);
    });
  }

  public onBack() {
    this.stepNumber--;
  }
}
