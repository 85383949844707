import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Flow } from 'app/model/flow';
import { FlowAction } from 'app/model/flow-action';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class FlowsApiService {
  private baseURL: string;
  private actionsURL: string;
  private messagesURL: string;
  private delayParameters: string;
  private nodeConnectionsURL: string;
  private voiceCallParameters: string;
  private whatsappParameters: string;
  private ccpFlowActionParameters: string;
  private flowActionSignatureParameters: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Flows';
    this.actionsURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActions';
    this.messagesURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionMessages';
    this.nodeConnectionsURL =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionsNodesConnections';
    this.voiceCallParameters =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionVoiceCallParameters';
    this.ccpFlowActionParameters =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/CcpFlowActionParameters';
    this.flowActionSignatureParameters =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionSignatureParameters';
    this.whatsappParameters =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionWhatsAppParameters';
    this.delayParameters =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/FlowActionDelayParameters';
  }

  getFlowsList(): Observable<Flow[]> {
    return this.httpClient.get<Flow[]>(this.baseURL);
  }

  saveFlow(flow: Flow): Observable<number> {
    return this.httpClient.post<number>(this.baseURL, flow);
  }

  updateFlow(flow: Flow): Observable<any> {
    return this.httpClient.put<any>(this.baseURL, flow);
  }

  deleteFlow(flowId: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.baseURL}/${flowId}`);
  }

  deleteFlows(negotiationIds: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: negotiationIds,
    };
    return this.httpClient.delete<any>(this.baseURL, options);
  }

  getExpandedFlow(flowId: number) {
    return this.httpClient.get<any>(this.baseURL + `/${flowId}/expanded`);
  }

  getExportFlow(flowId: number) {
    return this.httpClient.get<any>(this.baseURL + `/${flowId}/export`);
  }

  saveAction(flowAction: FlowAction) {
    return this.httpClient.post<any>(this.actionsURL, { ...flowAction, index: 0, enable: true });
  }

  updateAction(flowAction: FlowAction) {
    return this.httpClient.put<any>(this.actionsURL, { ...flowAction });
  }

  deleteAction(flowActionId: number) {
    return this.httpClient.delete<any>(this.actionsURL + '/' + flowActionId);
  }

  postRelationship(fromId, toId) {
    return this.httpClient.post(this.nodeConnectionsURL, {
      flowActionFromId: fromId,
      flowActionToId: toId,
    });
  }

  deletetRelationship(fromId, toId) {
    return this.httpClient.delete(this.nodeConnectionsURL, {
      body: {
        flowActionFromId: fromId,
        flowActionToId: toId,
      },
    });
  }

  postMessage(flowActionId, language, content, index = 0, extraContent = '') {
    const body = {
      flowActionId,
      index,
      language,
      content,
      companyId: 0,
      extraContent,
    };
    return this.httpClient.post(this.messagesURL, body);
  }

  saveMessage(language, content, flowActionMessageId, index = 0, extraContent = '') {
    const body = {
      flowActionMessageId,
      index,
      language,
      content,
      extraContent,
    };
    return this.httpClient.put(this.messagesURL, body);
  }

  public cloneFlow(flowId: number, flowName: string): Observable<number> {
    return this.httpClient.post<number>(`${this.baseURL}/clone-deep`, { flowId, flowName });
  }

  postCallProperties(callProperties) {
    return this.httpClient.post(`${this.voiceCallParameters}/`, callProperties);
  }

  saveCallProperties(callProperties) {
    return this.httpClient.put(`${this.voiceCallParameters}/`, callProperties);
  }

  postDelayProperties(delayProperties) {
    return this.httpClient.post(`${this.delayParameters}/`, delayProperties);
  }

  saveDelayProperties(delayProperties) {
    return this.httpClient.put(`${this.delayParameters}/`, delayProperties);
  }

  postCcpProperties(ccpProperties) {
    return this.httpClient.post(`${this.ccpFlowActionParameters}/`, ccpProperties);
  }

  putCcpProperties(ccpProperties) {
    return this.httpClient.put(`${this.ccpFlowActionParameters}/`, ccpProperties);
  }

  postSignatureProperties(signatureProperties) {
    return this.httpClient.post(`${this.flowActionSignatureParameters}/`, signatureProperties);
  }

  putSignatureProperties(signatureProperties) {
    return this.httpClient.put(`${this.flowActionSignatureParameters}/`, signatureProperties);
  }

  postWhatsappProperties(whatsappProperties) {
    return this.httpClient.post(`${this.whatsappParameters}/`, whatsappProperties);
  }

  updateWhatsappProperties(whatsappProperties) {
    return this.httpClient.put(`${this.whatsappParameters}/`, whatsappProperties);
  }
}
