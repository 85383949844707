import { Injectable } from '@angular/core';
import AnalyticMetric from 'app/model/AnalyticsMetrics';
import { BehaviorSubject } from 'rxjs';
import { AnalyticsApiService } from './analytics-api.service';

const mockedMetrics = [{ metric: 'Billing Visits', value: 110 }];

@Injectable({
  providedIn: 'root',
})
export class AnalyticsService {
  $metrics = new BehaviorSubject<AnalyticMetric[]>([]);

  constructor(private analyticsApiService: AnalyticsApiService) {}

  getMetrics() {
    //this.$metrics.next(mockedMetrics);
    /*return this.analyticsApiService.getMetrics().subscribe((res: AnalyticMetric[]) => {
      this.$metrics.next(res);
    });*/
  }

  countBillingVisit() {
    //this.analyticsApiService.countBillingVisit();
  }

  clearBillingVisits() {
    return this.analyticsApiService.clearBillingVisit();
  }
}
