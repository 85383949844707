<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Description</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="full-width-textarea">
    <input matInput [(ngModel)]="description" (change)="setChanged()"/>
  </mat-form-field>
</mat-expansion-panel>
<mat-expansion-panel *ngIf="selectedTemplate != null">
  <mat-expansion-panel-header>
    <mat-panel-title>Template</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field>
    <mat-select [(value)]="templateCode" (selectionChange)="onTemplateSelect(true)">
      <mat-select-trigger>
        <mat-icon *ngIf="mapTemplatesWithNonApproavedLangs.get(selectedTemplate.templateCode) != 0">warning</mat-icon>
        {{ selectedTemplate.templateLabel }}
      </mat-select-trigger>
      <mat-option *ngFor="let templ of whatsappTemplates" [value]="templ.templateCode" >
        <mat-icon *ngIf="mapTemplatesWithNonApproavedLangs.get(templ.templateCode) != 0">warning</mat-icon>
        {{ templ.templateLabel }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <button mat-button (click)="onEditWhatsappTemplate()" matTooltip="Request template">
    <mat-icon>add_circle</mat-icon>
  </button>
  <mat-form-field style="display: flex">
    <mat-form-label>Language</mat-form-label>
    <mat-select [(value)]="selectedTemplateContent" (selectionChange)="onLanguageSelect()">
      <mat-select-trigger>
        <mat-icon *ngIf="selectedTemplateContent?.status == 0">warning</mat-icon>
        {{ selectedTemplateContent?.language }}
      </mat-select-trigger>
      <mat-option *ngFor="let templateContent of selectedTemplate.whatsappOptionTemplateContents"
        [value]="templateContent">
        <mat-icon *ngIf="templateContent.status == 0">warning</mat-icon>
        {{ templateContent.language }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <div *ngIf="selectedTemplate && templateContent" class="template-text">
    <mat-panel-title>Message</mat-panel-title>
    <div class="template-text-container">
      {{ templateContent }}
    </div>
  </div>
  <div *ngIf="selectedTemplate" class="variables-container">
    <mat-panel-title>Variables</mat-panel-title>
    <div>
      <mat-form-field style="display: flex" *ngFor="let property of properties; let $index = index">
        <mat-form-label>{{ property }}</mat-form-label>
        <mat-select [(value)]="props[$index]" [disabled]="$index !== 0 && !props[$index - 1]" (selectionChange)="setChanged()">
          <mat-option *ngFor="let variableOption of whatsappParameterOptions" [value]="variableOption.value">{{
            variableOption.label
            }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</mat-expansion-panel>

<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="saveMessages()"
    [disabled]="!hasChanged || selectedTemplateContent?.status == 0">Save</button>
</div>