import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CompanySettingsService } from 'app/components/settings';
import { Observable } from 'rxjs';

@Injectable()
export class CanActivateBilling implements CanActivate {
  constructor(private companySettings: CompanySettingsService, private router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    // return true;
    // Remove previous line to activate the functionality
    return this.companySettings.getParametersActivate().then((company: any) => {
      if (company?.companyId) {
        return true;
      }
      this.router.navigate(['billing']);
      return false;
    });
  }
}
