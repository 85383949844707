<section class="options-container" [class.collapsed]="isCollapsed" [@collapseInOut]="collapseStr">
  <div class="expand-button">
    <i
      class="material-icons"
      style="font-size: 2rem"
      (click)="toggleCollapse()"
      [matTooltip]="isCollapsed ? 'Expand' : 'Collapse'"
      >{{ isCollapsed ? 'last_page' : 'first_page' }}</i
    >
  </div>
  <div class="options-cards-container">
    <app-lateral-action
      [option]="option"
      *ngFor="let option of options"
      [isCollapsed]="isCollapsed"
    ></app-lateral-action>
    <div *ngIf="elementsListSection && !isCollapsed" class="element-section">
      <div class="element-section-header">
        <mat-icon>{{ elementsListSection.icon }}</mat-icon>
        <div>{{ elementsListSection.title }}</div>
        <mat-icon (click)="elementsListSection.extraAction()" class="right-icon hoverable">{{
          elementsListSection.rightIcon
        }}</mat-icon>
      </div>
      <div class="element-section-body">
        <div *ngFor="let element of elementsListSection.elements"
          class="list-element"
          (click)="selectItem(element.key,element.titleAction)"
          [class.selected]="selected == element.key">
            <div *ngIf="element.isDataInjestor" class="data-injestor-element">
              <div>DI</div>
            </div>
            <div class="hoverable element-title" [matTooltip]="element.title" matTooltipPosition="right">
                {{ element.title }}
            </div>
            <div class="hoverable element-count">
              {{ element.count }}
          </div>
          <div *ngIf="!(element.actions && element.actions.length)" style="min-width: 64px;"></div>
          <div *ngIf="element.actions && element.actions.length">
            <button mat-button [matMenuTriggerFor]="menu">
              <mat-icon class="action-icon hoverable" aria-label="Options">more_vert</mat-icon>
            </button>
            <mat-menu #menu="matMenu">
              <button mat-menu-item *ngFor="let action of element.actions" (click)="selectItem(element.key, action.action)">
                <mat-icon >{{action.icon}}</mat-icon>
                <span>{{action.name}}</span>
              </button>
            </mat-menu>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
