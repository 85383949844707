/* eslint-disable */

const studioActionOptions = {
  CALL: 0,
  MESSAGE: 1,
  WHATSAPP: 2,
  EMAIL: 3,
  CCP: 4,
  SEND_DOCUMENT: 5,
  DELAY: 6,
  WHATSAPP_TEMPLATE: 7,
  START: 98,
  FINISH: 99,
};
const studioOptionsLables = {
  [studioActionOptions.CALL]: 'Call',
  [studioActionOptions.MESSAGE]: 'SMS',
  [studioActionOptions.WHATSAPP]: 'WhatsApp',
  [studioActionOptions.EMAIL]: 'Email',
  [studioActionOptions.CCP]: 'CCP',
  [studioActionOptions.SEND_DOCUMENT]: 'Document',
  [studioActionOptions.DELAY]: 'Delay',
  [studioActionOptions.WHATSAPP_TEMPLATE]: 'WhatsApp Template',
  [studioActionOptions.START]: 'Starting Action',
  [studioActionOptions.FINISH]: 'Finishing Action',
};

const studioIcons = {
  [studioActionOptions.CALL]: 'phone',
  [studioActionOptions.MESSAGE]: 'comment',
  [studioActionOptions.WHATSAPP]: 'whatsapp',
  [studioActionOptions.EMAIL]: 'email',
  [studioActionOptions.CCP]: 'phonelink',
  [studioActionOptions.SEND_DOCUMENT]: 'border_color',
  [studioActionOptions.DELAY]: 'alarm',
  [studioActionOptions.WHATSAPP_TEMPLATE]: 'WHATSAPP_TEMPLATE',
  [studioActionOptions.START]: 'start',
};

// eslint-disable-next-line
export const STUDIO_ACTION_OPTIONS = studioActionOptions,
  STUDIO_OPTIONS_LABELS = studioOptionsLables,
  STUDIO_ICONS = studioIcons;
