<div class="title">Restart Negotiation</div>

<div>
    <div class="flow-container">
        <span class="dropdown-title">Are you sure you want to restart this negotiation?</span>
        <span class="dropdown-title" *ngIf="amount != undefined && amount != null"> It can cost up to ${{amount}}</span>
        <div class="restart-negotiation-buttons">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="processing" (click)="onSubmit()">Yes</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">No</button>
        </div>
    </div>
</div>
