<div class="media-url-input-container">
    <mat-form-field appearance="fill" class="name-field">
        <mat-label>Media Url</mat-label>
        <input matInput [formControl]="inputControl" required
            type="url" autocomplete="off" required max="1600" pattern="https?://.+"/>
        <mat-icon class="message-tooltip"
            matTooltip="Write here a url that references your media. Supports images, audio, documents, and video." matTooltipPosition="right">info</mat-icon>
    </mat-form-field>
    <div class="media-btns-container">
        <button [disabled]="disableAddVariableBtn()"  type="button"
            class="sgnd-btn sgnd-btn-cancel media-url-input-btn"
            (click)="onAddVariable()">Add Variable to URL</button>
    </div>
</div>
