import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgbModalConfig, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContactsService } from 'src/app/services/contacts.service';
import { MatDialog } from '@angular/material/dialog';
import { AddContactComponent } from '../add-contact/add-contact.component';
import { Contact, PaginatedContactsDataRequest } from 'src/app/model/contact';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UploadContactsFileComponent } from '../upload-contacts-file/upload-contacts-file.component';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { TableAction } from 'app/model/table-action';
import { BehaviorSubject, Subject } from 'rxjs';
import { FilterPaginatorComponent } from 'core/components/filter-paginator/filter-paginator.component';
import { StorageService } from 'app/services/persistence.service';
import { ContactsGroup } from 'app/model/contacts-group';
import { ContactsGroupService } from 'app/services/contacts-group.service';
import { AddContactsGroupComponent } from '../add-contacts-group/add-contacts-group.component';

@Component({
  selector: 'app-contacts-screen',
  templateUrl: './contacts-screen.component.html',
  styleUrls: ['./contacts-screen.component.css'],
  providers: [NgbModalConfig, NgbModal],
})
export class ContactsScreenComponent implements OnInit, OnDestroy {
  menus = [
    { key: 'contacts', title: 'Individuals', icon: 'person' },
    { key: 'contacts-groups', title: 'Groups', icon: 'people' },
  ];

  selectedMenu = this.menus[0];
  contactsGroupsChangedSubject: Subject<void> = new Subject<void>();

  contactsMenuOptions: TableAction[] = [
    {
      title: 'New',
      action: this.onNew.bind(this),
      icon: 'add_to_photos',
    },
    {
      title: 'Upload',
      action: this.onUpload.bind(this),
      icon: 'upload_file',
    },
    {
      title: 'Delete selected',
      action: this.onDeleteSelected.bind(this),
      icon: 'delete_forever',
    },
  ];

  contactsGroupsMenuOptions: TableAction[] = [
    {
      title: 'New',
      action: this.onNewContactsGroup.bind(this),
      icon: 'add_to_photos',
    },
    {
      title: 'Delete selected',
      action: this.onDeleteSelectedContactsGroup.bind(this),
      icon: 'delete_forever',
    },
  ];

  filterOptions: TableAction[] = [
    {
      title: 'All',
      action: this.showAllContacts.bind(this),
      collapsedIcon: 'density_small',
    },
    {
      title: 'Owned',
      action: this.showMyContacts.bind(this),
      collapsedIcon: 'person',
    },
  ];

  public selectedContacts: Contact[];
  public selectedContactsGroup: ContactsGroup;
  public resetGrid: Subject<boolean> = new Subject();
  public setGridState: BehaviorSubject<PaginatedContactsDataRequest> = new BehaviorSubject(null);
  public defaultFilter = 'All';
  private readonly STORAGE_CURRENT_REQUEST = 'contacts.currentRequest';
  private processRefreshEvent = true;
  private mineOnly: boolean;
  private currentRequest: PaginatedContactsDataRequest;

  constructor(
    public contactService: ContactsService,
    public contactsGroupService: ContactsGroupService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.currentRequest = this.storageService.getSessionItem(this.STORAGE_CURRENT_REQUEST);
    if (this.currentRequest != null) {
      this.mineOnly = this.currentRequest.mineOnly;
      if (this.mineOnly) {
        this.defaultFilter = 'Owned';
      } else {
        this.defaultFilter = 'All';
      }
      // ignore the initial event with default values
      this.processRefreshEvent = false;
      this.setGridState.next(this.currentRequest);
    } else {
      // default to showing all contacts
      this.mineOnly = false;
    }
  }

  ngOnDestroy(): void {
    this.storageService.setSessionItem(this.STORAGE_CURRENT_REQUEST, this.currentRequest);
  }

  onNew() {
    this.dialog.open(AddContactComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
    });
  }

  onUpload() {
    this.dialog.open(UploadContactsFileComponent, {
      panelClass: 'custom-dialog-container',
      width: '800px',
    });
  }

  onDeleteSelected() {
    if (this.selectedContacts.length === 0) {
      this.snackBar.open(`No Contacts marked for deletion.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Contacts?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          const selectedIds = this.selectedContacts.map((contact) => contact.contactId);
          this.contactService.deleteContacts(selectedIds).subscribe(() => {
            this.refreshContacts({});
            this.snackBar.open(`Selected Contacts deleted successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  onNewContactsGroup() {
    this.dialog.open(AddContactsGroupComponent, {
      panelClass: 'custom-dialog-container',
      width: '500px',
    }).afterClosed().subscribe(() => {
      this.contactsGroupsChangedSubject.next();
    });
  }

  onDeleteSelectedContactsGroup() {
    if (this.selectedContactsGroup == null) {
      this.snackBar.open(`No Contacts Group is selected.`, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      return;
    }
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the "${this.selectedContactsGroup.name}" Contacts Group?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.contactsGroupService.deleteContactsGroup(this.selectedContactsGroup.id).subscribe(() => {
            this.contactsGroupsChangedSubject.next();
            this.snackBar.open(`Selected Contacts Group deleted successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  updateContactsSelection(contacts: Contact[]) {
    this.selectedContacts = contacts;
  }

  updateContactsGroupSelection(contactsGroup: ContactsGroup) {
    this.selectedContactsGroup = contactsGroup;
  }

  async showAllContacts() {
    this.mineOnly = false;
    this.refreshContacts();
    this.resetGrid.next(true);
  }

  async showMyContacts() {
    this.mineOnly = true;
    this.refreshContacts();
    this.resetGrid.next(true);
  }

  onContactModeSelect(menu: any) {
    this.selectedMenu = menu;
  }

  async refreshContacts(dataRequest: PaginatedContactsDataRequest = {}) {
    if (this.processRefreshEvent) {
      // apply default values
      dataRequest.pageSize ??= this.currentRequest?.pageSize ?? FilterPaginatorComponent.DEFAULT_PAGE_SIZE;
      dataRequest.pageNumber ??= 1;
      //dataRequest.companyId ??= 1; // TODO: default to the companyId in the user token
      dataRequest.mineOnly ??= this.mineOnly;
      dataRequest.sortedBy ??= this.currentRequest?.sortedBy;
      dataRequest.sortDirection ??= this.currentRequest?.sortDirection;
      this.currentRequest = dataRequest;
      this.contactService.getPaginatedData(dataRequest);
    } else {
      this.processRefreshEvent = true;
    }
  }

}
