import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
})
export class AdminComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onEditCompanies() {
    this.router.navigate(['settings/company-list']);
  }

  onEditCompanyApiCredentials() {
    this.router.navigate(['settings/company-api-credentials-list']);
  }
}
