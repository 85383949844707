<div class="title">Pause Negotiation</div>

<div>
    <div class="flow-container">
        <span class="dropdown-title">Are you sure you want to pause this negotiation?</span>

        <div class="stop-negotiation-buttons">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="processing" (click)="onSubmit()">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</div>
