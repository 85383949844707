<div class="title">Generate API credentials</div>

<div class="fields-container">
    <div>
        <mat-label>Here is the client secret for {{company.name}}. It won't be shown again, so please store it in a secure place:</mat-label>
        <mat-label class="client-secret-text">{{clientSecret}}</mat-label>
    </div>

    <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onClose()">Close</button>
    </div>
</div>