import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SmsMessageApiService } from './sms-message-api.service';

@Injectable({
  providedIn: 'root',
})
export class SmsMessageService {

  constructor(private messageApi: SmsMessageApiService) { }

  public getMessages(contactId: number): Observable<any> {
    return this.messageApi.getSmsMessages(contactId);
  }

  public postMessage(contactId: number, content: string): Observable<any> {
    const message = {
      contactId,
      content
    };
    return this.messageApi.postSmsMessage(message);
  }

  public markMessagesAsRead(contactId: number) {
    return this.messageApi.markMessagesAsRead(contactId);
  }
}
