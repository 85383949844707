import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { MatTable } from '@angular/material/table';
import downloadFileResponse from 'app/functions/fileFunctions';

@Component({
  selector: 'app-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.css'],
})
export class FilesListComponent implements OnInit, OnChanges {
  @ViewChild(MatTable) table: MatTable<File>;
  @ViewChild('fileSelector') fileSelector: HTMLInputElement;

  @Input()
  files = [];

  @Input()
  onlyDownloads = false;

  @Output()
  fileSelected = new EventEmitter<File>();

  @Output()
  fileDeleted = new EventEmitter<number>();

  @Output()
  downloadIt = new EventEmitter<any>();

  displayedColumns = ['name', 'download'];

  constructor() {}

  ngOnInit(): void {
    this.displayedColumns = this.onlyDownloads ? ['name', 'download'] : ['name', 'download', 'remove'];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.files.firstChange && changes.files.currentValue !== changes.files.previousValue) {
      this.table.renderRows();
    }
  }

  handleFileChange(target) {
    const file = target.files[0];
    if (file) {
      this.fileSelected.emit(file);
    }
  }

  deleteElement(file) {
    this.fileDeleted.emit(file);
  }

  downloadFile(file) {
    if (!file.id) {
      downloadFileResponse(file, file.name);
    } else {
      this.downloadIt.emit(file);
    }
  }
}
