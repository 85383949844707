import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NegotiationMessage } from 'app/model/NegotiationMessage';
import { NegotiationMessageService } from 'app/services/negotiation-message.service';

/* eslint-disable */
@Component({
  selector: 'app-messages-modal',
  templateUrl: './intermediate-messages-modal.component.html',
  styleUrls: ['./intermediate-messages-modal.component.css'],
})
export class IntermediateMessagesModalComponent implements OnInit {
  @ViewChild('messagesRef') messagesRefContent: ElementRef;
  public showModal = false;
  public messages = [];
  public contactName: string | null = '';
  public contactId: string | number = '';
  public messageText = '';
  public primaryColor: string;
  public secondaryColor: string;
  private negotiationId = null;

  constructor(
    public dialog: MatDialogRef<IntermediateMessagesModalComponent>,
    private negotiationMessagesService: NegotiationMessageService
  ) {}

  ngOnInit(): void {
    this.negotiationMessagesService.$negotiation.subscribe((value) => {
      value && (this.contactName = value['contactName']);
      if (value && value['contactName']) {
        this.contactName = value['contactName'];
      } else if (value && value['communityManagerName']) {
        this.contactName = value['communityManagerName'];
      }

      this.contactId = value?.contactId;
      this.negotiationId = value?.negotiationId;
      this.messages = this.negotiationMessagesService.$negotiationMessages
        .getValue()
        ?.filter((msg: NegotiationMessage) => msg.negotiationId === this.negotiationId || !this.negotiationId);
      setTimeout(() => this.scrollToBottom(), 1);
    });
    this.negotiationMessagesService.$primaryColor.subscribe((value) => {
      this.primaryColor = value;
    });
    this.negotiationMessagesService.$secondaryColor.subscribe((value) => {
      this.secondaryColor = value;
    });
    this.negotiationMessagesService.$negotiationMessages.subscribe((value: []) => {
      const oldMessages = this.messages;
      this.messages = value?.filter(
        (msg: NegotiationMessage) => msg.negotiationId === this.negotiationId || !this.negotiationId
      );
      if (oldMessages.length !== this.messages.length) {
        this.markAsRead();
        setTimeout(() => this.scrollToBottom(), 1);
      }
    });
    this.markAsRead();
  }

  sendMessage() {
    const now = new Date().toISOString();
    if (!this.messageText || this.messageText.trim().length === 0) {
      return;
    }
    this.negotiationMessagesService.postMessageIntermediate(
      null,
      this.negotiationId,
      this.contactId,
      now,
      this.messageText,
      false,
      false
    );
    this.messageText = '';
  }

  close() {
    this.dialog.close();
  }

  private markAsRead() {
    const unreadMessages = this.messages?.filter((message) => message.read === false && message.sender === 0);
    if (unreadMessages.length > 0) {
      this.negotiationMessagesService.markAsRead(unreadMessages);
    }
  }

  private scrollToBottom() {
    try {
      this.messagesRefContent.nativeElement.scrollTop = this.messagesRefContent.nativeElement.scrollHeight;
    } catch (err) {}
  }
}
