import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CompanyPermission } from 'app/model/company-permission';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class CompanyPermissionsApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/CompanyPermissions';
  }

  public getForCurrentUser(): Observable<CompanyPermission[]> {
    return this.http.get<CompanyPermission[]>(`${this.baseURL}`);
  }

  public getAll(): Observable<CompanyPermission[]> {
    return this.http.get<CompanyPermission[]>(`${this.baseURL}/all`);
  }

  public getByCompanyId(companyId: number): Observable<CompanyPermission[]> {
    return this.http.get<CompanyPermission[]>(`${this.baseURL}/company/${companyId}`);
  }

  public addPermissions(companyId: number, permissionIds: number[]): Observable<void> {
    return this.http.put<void>(`${this.baseURL}/company/subscriptionpermissions/`, {
      companyId,
      permissionIds
    });
  }

  public removePermissions(companyId: number, permissionIds: number[]): Observable<void> {
    const options = {
      body: {
        companyId,
        permissionIds
      }
    };
    return this.http.delete<void>(`${this.baseURL}/company/subscriptionpermissions/`, options);
  }

}
