import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateContent } from 'app/model/whatsapp-option-template-content';
import { WhatsappOptionTemplateContentExpanded } from 'app/model/whatsapp-option-template-content-expanded';
import { LanguageService } from 'app/services/language.service';
import { StudioService } from 'app/services/studio.service';
import { ReviewWhatsappTemplateContentDialogComponent } from '../review-whatsapp-template-content-dialog/review-whatsapp-template-content-dialog.component';

@Component({
  selector: 'app-review-whatsapp-template-contents-list',
  templateUrl: './review-whatsapp-template-contents-list.component.html',
  styleUrls: ['./review-whatsapp-template-contents-list.component.css']
})
export class ReviewWhatsappTemplateContentsListComponent implements OnInit {

  displayedContentColumns = ['templateLabel', 'language', 'content', 'companyId', 'companyName', 'actions'];
  public contentDataSource: MatTableDataSource<WhatsappOptionTemplateContentExpanded>;
  public whatsappOptionTemplate: WhatsappOptionTemplate;

  allLangs: { [key: string]: string } = {};

  constructor(
    public dialog: MatDialog,
    private studioService: StudioService,
    private languageService: LanguageService
  ) {
  }

  ngOnInit(): void {
    this.contentDataSource = new MatTableDataSource<WhatsappOptionTemplateContentExpanded>();
    this.loadData();
    this.loadLanguages();
  }

  public onReviewTemplateContent(element: WhatsappOptionTemplateContent) {
    const dialogRef = this.dialog.open(ReviewWhatsappTemplateContentDialogComponent, {
      data: element
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.loadData();
      }
    });
  }

  private loadData() {
    this.studioService.getPendingWhatsappOptionTemplateContentsList().subscribe(pendingTemplates => {
      this.contentDataSource.data = pendingTemplates;
    });
  }

  private async loadLanguages() {
    const languages = await this.languageService.getLanguages();
    this.allLangs = Object.fromEntries(languages.map(x => [x.languageCode, x.name]));
  }
}
