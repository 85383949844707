import { Injectable } from '@angular/core';
import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root'
})
export class TextToSpeechService {
  public readonly DEFAULT_LANGUAGE: string = 'en-US';

  constructor(
    private languageService: LanguageService
  ) { }

  public async getAvailableVoices(language: string): Promise<string[]> {
    const languages = await this.languageService.getLanguages();
    const voices = languages.find(x => x.languageCode === language).voices
      ?? languages.find(x => x.languageCode === this.DEFAULT_LANGUAGE).voices;
    return voices.map(x => x.name);
  }

}
