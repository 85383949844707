import { FlowAction } from './flow-action';

export class FlowDelayParameter {
  public id: number;
  public days: number;
  public hours: number;
  public minutes: number;
  public seconds: number;
}

export class FlowActionDelay extends FlowAction {
  flowActionDelayParameters: FlowDelayParameter;

  constructor(
    id: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable: boolean,
    description: string,
    flowActionDelayParameters = new FlowDelayParameter(),
    continueOnFailure = true
  ) {
    super(
      id,
      flowId,
      flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      children,
      enable,
      description,
      continueOnFailure
    );
    this.flowActionDelayParameters = flowActionDelayParameters;
  }

  // Get unreferenced properties
  static getProperties({ id, days, hours, minutes, seconds }) {
    const properties = {
      id,
      days,
      hours,
      minutes,
      seconds,
    };
    return properties;
  }

  // get unreferenced Flow Action
  static getFlowAction(delay: FlowActionDelay) {
    const flowAction = {
      flowActionProperties: FlowActionDelay.getProperties(delay.flowActionDelayParameters),
    };
    return flowAction;
  }
}
