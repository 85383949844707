import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { CompanyParameter } from 'app/model/company-parameter';
import { Auth0ManagementService } from 'app/services/auth0-management.service';
import { CompanyParametersService } from 'app/services/company-parameters.service';
import { UIService } from 'core/services/ui.service';
import { CompanyApiCredentialsCreateComponent } from '../company-api-credentials-create/company-api-credentials-create.component';

@Component({
  selector: 'app-company-api-credentials-list',
  templateUrl: './company-api-credentials-list.component.html',
  styleUrls: ['./company-api-credentials-list.component.css']
})
export class CompanyApiCredentialsListComponent implements OnInit {

  displayedContentColumns = ['companyId', 'companyName', 'apiClientId', 'actions'];
  public contentDataSource: MatTableDataSource<CompanyParameter>;

  constructor(
    public dialog: MatDialog,
    private companyParametersService: CompanyParametersService,
    private auth0ManagementService: Auth0ManagementService,
    private snackBar: MatSnackBar,
    public uiService: UIService
  ) {
  }

  ngOnInit(): void {
    this.contentDataSource = new MatTableDataSource<CompanyParameter>();
    this.loadData();
  }

  public onNewClient() {
    const companyIds = this.contentDataSource.data.map(c => c.companyId);
    const dialogRef = this.dialog.open(CompanyApiCredentialsCreateComponent, {
      data: companyIds
    });
    dialogRef.afterClosed().subscribe((confirm: boolean) => {
      if (confirm) {
        this.loadData();
      }
    });
  }

  public onDeleteClient(companyParameter: CompanyParameter) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the API credentials for the selected Company?` },
      })
      .afterClosed()
      .subscribe(
        (confirm: boolean) => {
          if (confirm) {
            this.uiService.toggleGlobalSpinner(true);
            this.auth0ManagementService.deleteClientCredentials(companyParameter.companyId).subscribe(() => {
              this.loadData();
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`API credentials deleted successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }, (err) => {
              this.handleDeleteCredentialsError(err);
            });
          }
        },
        (err) => {
          this.handleDeleteCredentialsError(err);
        }
      );
  }

  private handleDeleteCredentialsError(err) {
    this.uiService.toggleGlobalSpinner(false);
    let actualError = !!err?.error ? JSON.parse(err?.error)?.title : '';
    this.snackBar.open(`Unable to delete API credentials. ` + actualError, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 8000,
    });
  }

  private loadData() {
    this.companyParametersService.getAllByParameterId('Auth0ClientId').subscribe(companyParameters => {
      this.contentDataSource.data = companyParameters;
    });
  }
}
