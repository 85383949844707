<div class="title">{{contact.contactId ? 'Update' : 'Create'}} Contact</div>

<form [formGroup]="contactCreateForm" (ngSubmit)="onSubmit()" autocomplete="off" >
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" type="text" placeholder="Name" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Last Name</mat-label>
            <input matInput formControlName="lastName" type="text" placeholder="Last Name" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Language</mat-label>
            <mat-select name="language" formControlName="language" required>
                <mat-option *ngFor="let item of allLangs | keyvalue" [value]="item.key"> {{item.value}} </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Phone</mat-label>
            <input matInput formControlName="phone" autocomplete="disabled" type="text" placeholder="Phone" [pattern]="phoneRegexp" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Email</mat-label>
            <input matInput formControlName="email" autocomplete="disabled" type="text" placeholder="Email" [pattern]="emailRegexp" required>
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!contactCreateForm.valid" type="submit">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>