import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BillingApiService } from './billing-api.service';

@Injectable({
  providedIn: 'root',
})
export class BillingService {
  $subscriptionDetails = new BehaviorSubject(null);
  upgradeToSubscription = null;

  constructor(private billingApiService: BillingApiService) {}

  loadSubscriptionDetails() {
    this.billingApiService.getSubscriptionDetails().subscribe((value) => this.$subscriptionDetails.next(value));
  }

  cancelSubscription() {
    return this.billingApiService.cancelSubscription();
  }

  updateSubscription(subscriptionType) {
    return this.billingApiService
      .changeSubscription(subscriptionType)
      .toPromise()
      .then((res: any) => {
        if (!res?.success && res?.message) {
          throw new Error(res.message);
        }
        this.loadSubscriptionDetails();
      })
      .catch((err) => {
        throw new Error(err);
      });
  }

  setUpgradeToSubscription(upgradeTo) {
    this.upgradeToSubscription = upgradeTo;
  }
}
