import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface HtmlMessageDialogData {
  title?: string;
  message?: string,
  acceptButtonTitle?: string;
  cancelButtonTitle?: string;
  titleTextColor?: string,
  titleBackgroundColor?: string,
  buttonTextColor?: string,
  buttonBackgroundColor?: string,
  altButtonTextColor?: string,
  altButtonBackgroundColor?: string
}

export interface HtmlMessageDialogResponse {
  confirmed: boolean;
  message: string;
}

@Component({
  selector: 'app-html-message-dialog',
  templateUrl: './html-message-dialog.component.html',
  styleUrls: ['./html-message-dialog.component.css'],
})
export class HtmlMessageDialogComponent implements OnInit {
  public body: string = '';

  constructor(
    public dialog: MatDialogRef<HtmlMessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: HtmlMessageDialogData
  ) { }

  closeDialog(): void {
    const response: HtmlMessageDialogResponse = {
      confirmed: false,
      message: ''
    };
    this.dialog.close(response);
  }

  confirm(): void {
    const response: HtmlMessageDialogResponse = {
      confirmed: true,
      message: this.body
    };
    this.dialog.close(response);
  }

  ngOnInit() {}
}
