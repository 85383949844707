import { FlowAction } from './flow-action';
import { ActionMessage } from './flow-action-call';

export class FlowWhatsappParameter {
  flowActionId: number;
  propertyId: string;
  propertyValue: string;

  constructor(flowActionId: number, propertyId: string, propertyValue: string) {
    this.flowActionId = flowActionId;
    this.propertyId = propertyId;
    this.propertyValue = propertyValue;
  }
}

export class FlowActionWhatsapp extends FlowAction {
  template: string;
  flowActionMessages: ActionMessage[] = [];
  flowActionWhatsAppParameters: FlowWhatsappParameter[];

  constructor(
    id: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable: boolean,
    description: string,
    messages = [],
    flowActionWhatsAppParameters = [],
    continueOnFailure = true
  ) {
    super(
      id,
      flowId,
      flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      children,
      enable,
      description,
      continueOnFailure
    );
    this.template = 'ar';
    this.flowActionMessages = messages;
    this.flowActionWhatsAppParameters = flowActionWhatsAppParameters;
    if (this.flowActionWhatsAppParameters?.length > 0) {
      const template = this.flowActionWhatsAppParameters.find((parameter) => parameter.propertyId === 'template');
      if (template) {
        this.template = template.propertyValue;
      }
    }
  }
}
