import { animate, style, transition, trigger } from '@angular/animations';

const transitionLeave = transition(':leave', [
  style({ transform: 'translateX(0%)', position: 'fixed', width: '100%' }),
  animate('.5s ease-out', style({ transform: 'translateX(-100%)' })),
]);

const transitionEnter = transition(':enter', [
  style({ transform: 'translateX(100%)', position: 'fixed', width: '100%' }),
  animate('.5s ease-out', style({ transform: 'translateX(0%)' })),
]);

export const slideLeftAnimation = trigger('slideLeftAnimation', [transitionEnter, transitionLeave]);
export const slideLeftAnimationLeave = trigger('slideLeftAnimationLeave', [transitionLeave]);
export const slideLeftAnimationEnter = trigger('slideLeftAnimationEnter', [transitionEnter]);
