import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-sample',
  templateUrl: './modal-sample.component.html',
  styleUrls: ['./modal-sample.component.css'],
})
export class ModalSampleComponent implements OnInit {
  @Input() primaryColor: string;
  @Input() secondaryColor: string;
  @Input() imgUrl: string;
  @Input() redirectUrl: string;

  constructor() {}

  ngOnInit(): void {}
}
