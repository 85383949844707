<div cdkDropListGroup>
  <mat-card class="slots-card">
    <div class="flex items-center flex-1">
      <form>
        <mat-form-field class="form-field-no-padding" appearance="fill">
          <mat-label>Target Channel</mat-label>
          <input
            type="text"
            placeholder="Pick one"
            matInput
            [formControl]="targetSlotControl"
            [matAutocomplete]="matSlotAutocomplete"
          />
          <mat-autocomplete
            autoActiveFirstOption
            #matSlotAutocomplete="matAutocomplete"
            [formControl]="targetSlotControl"
            [displayWith]="slotDisplayFn.bind(this)"
            (closed)="onCloseSlotSelect()"
          >
            <mat-option *ngFor="let slotElement of filteredSlotOptions | async" [value]="slotElement.key">
              {{ (slotElement.isDataInjestor ? 'DI-' : ' ') + slotElement.title }}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </form>
      <div
        *ngIf="selectedTargetSlot"
        class="droppable-slot-container"
        (click)="assignNegotiationsToSlotClick()"
        (cdkDropListDropped)="assignNegotiationsToSlot($event)"
        cdkDropList
      >
        Click or drag here to assign the selected contacts to the slot
      </div>
    </div>
  </mat-card>
  <mat-card style="margin-bottom: 0.25rem">
    <filter-paginator
      placeholder="Filter by last name, name or email"
      [length]="dataLength"
      [reset]="resetFilter"
      [setState]="setFilterState"
      (filterPage)="onFilterPage($event)"
    >
    </filter-paginator>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      (matSortChange)="sortNegotitations($event)"
      class="mat-elevation-z1"
      fixedLayout
      cdkDropList
    >
      <!-- Checkbox Column -->
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox
            (change)="masterToggle(); emitSelectedNegotiations()"
            [checked]="selection.hasValue() && isAllSelected"
            [indeterminate]="selection.hasValue() && !isAllSelected"
          >
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox
            (change)="toggleSelection(row); emitSelectedNegotiations()"
            [checked]="isNegotiationSelected(row)"
          >
          </mat-checkbox>
        </td>
      </ng-container>
      <ng-container matColumnDef="contactName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="CONTACTNAME">Contact Name</th>
        <td mat-cell *matCellDef="let element">{{ element.contactName }}</td>
      </ng-container>

      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="TYPE">Type</th>
        <td mat-cell *matCellDef="let element">{{ capitalize(allNegotiationTypes[element.negotiationType]) }}</td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="STATUS">Status</th>
        <td mat-cell *matCellDef="let element">{{ capitalize(allNegotiationStatus[element.status]) }}</td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="DESCRIPTION">Description</th>
        <td mat-cell *matCellDef="let element">{{ element.description }}</td>
      </ng-container>

      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="DUEDATE">Due Date</th>
        <td mat-cell *matCellDef="let element">{{ element?.created < element?.dueDate ?
          (element?.dueDate?.toISOString() | date: 'yyyy/MM/dd':'UTC') : 'N/A' }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef>Amount</th>
        <td mat-cell *matCellDef="let element">
          {{ amountApplicable(element.negotiationType) ? (element.amount | currency) : 'N/A' }}
        </td>
      </ng-container>
      <ng-container matColumnDef="lastModified">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="LASTMODIFIED">Last Modified</th>
        <td mat-cell *matCellDef="let element">
          {{ (element?.created < element?.lastModified ? (element?.lastModified) :  element?.created) | date: 'MM/dd/yyyy hh:mm a' }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let element">
          <button mat-button [matMenuTriggerFor]="menu">
            <mat-icon class="action-icon hoverable" aria-label="Options">more_vert</mat-icon>
          </button>
          <mat-menu #menu="matMenu">
            <button mat-menu-item (click)="onStart(element)" *ngIf="showStart(element)">
              <mat-icon class="action-icon" aria-label="Start Negotiation">play_circle_outline</mat-icon>
              <span>Start Negotiation</span>
            </button>
            <button mat-menu-item (click)="onStop(element)" *ngIf="showStop(element)">
              <mat-icon class="action-icon" aria-label="Stop Negotiation">stop_circle</mat-icon>
              <span>Stop Negotiation</span>
            </button>
            <button mat-menu-item (click)="onResume(element)" *ngIf="showResume(element)">
              <mat-icon class="action-icon" aria-label="Resume Negotiation">play_circle_outline</mat-icon>
              <span>Resume Negotiation</span>
            </button>
            <button mat-menu-item (click)="onPause(element)" *ngIf="showPause(element)">
              <mat-icon class="action-icon" aria-label="Pause Negotiation" matTooltip="Pause">pause_circle</mat-icon>
              <span>Pause Negotiation</span>
            </button>
            <button mat-menu-item (click)="onRestart(element)" *ngIf="showRestart(element)">
              <mat-icon class="action-icon" aria-label="Restart Negotiation">restart_alt</mat-icon>
              <span>Restart Negotiation</span>
            </button>
            <button mat-menu-item (click)="onAddToSlot(element)" *ngIf="showAddToSlot(element)">
              <mat-icon class="action-icon" aria-label="Add Negotiation to Channel">add_circle</mat-icon>
              <span>Add Negotiation to Channel</span>
            </button>
            <button mat-menu-item (click)="onRemoveFromSlot(element)" *ngIf="showRemoveFromSlot(element)">
              <mat-icon class="action-icon" aria-label="Remove Negotiation from Channel">remove_circle</mat-icon>
              <span>Remove from Channel</span>
            </button>
            <button mat-menu-item (click)="onEdit(element)">
              <mat-icon class="action-icon" aria-label="Edit Negotiation">edit</mat-icon>
              <span>Edit Negotiation</span>
            </button>
            <button mat-menu-item (click)="onDelete(element)">
              <mat-icon class="action-icon" aria-label="Delete Negotiation">delete_outline</mat-icon>
              <span>Delete</span>
            </button>
          </mat-menu>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr
        mat-row
        *matRowDef="let row; columns: displayedColumns"
        cdkDrag
        [cdkDragData]="row"
        [cdkDragDisabled]="!selectedTargetSlot"
      >
        <div *cdkDragPreview class="dragged-placeholder">
          <div>+ {{ selection.selected.length ? selection.selected.length : 1 }}</div>
        </div>
      </tr>
    </table>
  </mat-card>
</div>
