import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-canvas-arrow',
  templateUrl: './canvas-arrow.component.html',
  styleUrls: ['./canvas-arrow.component.css'],
})
export class CanvasArrowComponent implements OnInit {
  @Input() edge = null;
  @Input() selected = false;

  @Output() edgeSelected = new EventEmitter();
  @Output() deleteEdge = new EventEmitter();

  startX = null;
  startY = null;
  xDiff = 0;
  yDiff = 0;
  endX = null;
  endY = null;
  width = null;
  angle = null;
  remValue = 0;

  constructor() {
    try {
      this.remValue = Number(getComputedStyle(document.documentElement).fontSize.match(/(\d+)/)[0]);
      if (isNaN(this.remValue)) {
        this.remValue = 16;
      }
    } catch (err) {}
  }

  /**
   * calculate the values to draw the arrow
   */
  draw() {
    this.startX = this.edge?.start[0];
    this.startY = this.edge?.start[1];
    this.endX = this.edge?.end[0];
    this.endY = this.edge?.end[1];

    const x = Math.abs(this.endX - this.startX);
    const y = Math.abs(this.endY - this.startY);
    // calc the width of the arrow
    const width = Math.sqrt(x ** 2 + y ** 2);

    this.xDiff = width / 2 - (this.endX - this.startX) / 2;
    this.yDiff = (this.endY - this.startY) / 2;

    // calc angle to rotate the arrow
    this.angle = Math.atan2(this.endY - this.startY, this.endX - this.startX);
    // transform the angle to degrees
    this.angle *= 180 / Math.PI;

    this.width = width;
  }

  onSelected() {
    this.edgeSelected.emit(this.edge?.edgeId);
  }

  deleteLinkAction() {
    this.deleteEdge.emit(this.edge?.edgeId);
  }

  ngOnInit(): void {
    this.draw();
  }
}
