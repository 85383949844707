import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NegotiationStatus } from 'app/model/negotiation-status';
import { CreateSlotDTO, Slot, UpdateSlotDto } from 'app/model/slot';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class SlotsApiService {
  private baseURL: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Slots';
  }

  calculateExecutionCost(request): Observable<number> {
    let url = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/billing/calculate-cost';
    return this.httpClient.post<number>(`${url}`, request);
  }

  getSlotsList(): Observable<Slot[]> {
    return this.httpClient.get<Slot[]>(this.baseURL);
  }

  saveSimplifiedSlot(slot: Slot): Observable<number> {
    const slotsDto = new CreateSlotDTO(slot);
    return this.httpClient.post<number>(`${this.baseURL}/simplified`, slotsDto);
  }

  updateSlot(slot: Slot): Observable<any> {
    const slotsDto = new UpdateSlotDto(slot);
    return this.httpClient.put<any>(this.baseURL, slotsDto);
  }

  deleteSlot(slotId: number): Observable<any> {
    return this.httpClient.delete<any>(this.baseURL + '/' + slotId);
  }

  cancelSlots(slotsIds: number[]) {
    return this.httpClient.put<any>(this.baseURL + '/stop/many', {
      slotIds: slotsIds,
    });
  }

  deleteSlots(slotsIds: number[]): Observable<any> {
    return this.httpClient.delete<any>(this.baseURL + '?slots=' + slotsIds.join('|'));
  }

  startSlot(slotId: number, statusesToStart: Array<string>): Observable<any> {
    return this.httpClient.put<any>(this.baseURL + '/start/' + slotId, {statusesToStart: statusesToStart});
  }

  stopSlot(slotId: number): Observable<any> {
    return this.httpClient.put<any>(this.baseURL + '/stop/' + slotId, null);
  }

  assignContactsToSlot(contactIds = [], slotId) {
    return this.httpClient.put(`${this.baseURL}/${slotId}/Contacts`, { contactIds });
  }

  assignNegotiationsToSlot(negotiationIds = [], slotId) {
    return this.httpClient.put(`${this.baseURL}/${slotId}/Negotiations`, { negotiationIds });
  }
}
