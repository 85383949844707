<div class="title">Create Option</div>

<form [formGroup]="optionCreateForm" (ngSubmit)="onSubmit()">
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Title</mat-label>
            <input matInput formControlName="title" type="text" placeholder="Title" required autocomplete="off">
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Description</mat-label>
            <input matInput formControlName="description" type="text" placeholder="Description" required autocomplete="off">
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!optionCreateForm.valid" type="submit">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>