<div class="title">{{ slotCreateForm.value.slotId ? 'Update' : 'Create' }} Channel</div>

<form [formGroup]="slotCreateForm" (ngSubmit)="onSubmit()">
  <div class="form-fields-container">
    <mat-form-field appearance="fill" class="regular-field">
      <mat-label>Name</mat-label>
      <input matInput formControlName="name" type="text" placeholder="Name" required />
    </mat-form-field>

    <mat-form-field appearance="fill" class="regular-field">
      <mat-label>Flow</mat-label>
      <mat-select
        matInput
        name="flowId"
        ngDefaultControl
        formControlName="flowId"
        (selectionChange)="onSelectFlow($event.value)"
        [disabled]="disableNegotiationType()"
        required
      >
        <mat-option *ngFor="let flow of availableFlows" [value]="flow.flowId">{{ flow.name }} </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill" class="regular-field">
      <mat-label>Expiration Date</mat-label>
      <input matInput formControlName="expirationDate" name="expirationDate" type="date" placeholder="Expiration Date" required />
    </mat-form-field>

    <div class="di-checkbox-and-btn-container">
      <mat-checkbox
        class="di-checkbox"
        formControlName="isDataIngestor"
        name="isDataIngestor">
        Data Ingestor
      </mat-checkbox>
      <mat-checkbox class="di-checkbox" formControlName="autoTrigger" name="autoTrigger" [disabled]="enableAutoTrigger()">
        Auto Trigger
      </mat-checkbox>
      <mat-checkbox class="di-checkbox" formControlName="autoSubscribe" name="autoSubscribe" [style.opacity]="showDataIngestor() ? 1 : 0">
        Auto Subscribe
      </mat-checkbox>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onShowQr()" [style.opacity]="showDataIngestor() ? 1 : 0" >
        Show QR Code
      </button>
    </div>

    <mat-accordion>
      <mat-expansion-panel>
        <mat-expansion-panel-header class="collapsible-title">
          <mat-panel-title>Files</mat-panel-title>
        </mat-expansion-panel-header>
        <app-files-list
          (fileSelected)="onFileSelected($event)"
          [files]="files"
          (downloadIt)="onDownloadFile($event)"
          (fileDeleted)="onRemoveFile($event)"
        ></app-files-list>
      </mat-expansion-panel>

      <mat-expansion-panel
        class="di-fields-container"
        *ngIf="isARSlot() || isOptionsSlot()"
      >
        <mat-expansion-panel-header class="collapsible-title">
          <mat-panel-title *ngIf="isARSlot()">Documents</mat-panel-title>
          <mat-panel-title *ngIf="isOptionsSlot()">Options</mat-panel-title>
        </mat-expansion-panel-header>
        <app-accounts-receivable-documents-grid *ngIf="isARSlot()"></app-accounts-receivable-documents-grid>
        <app-options-grid *ngIf="isOptionsSlot()"></app-options-grid>
      </mat-expansion-panel>
    </mat-accordion>

    <div class="buttons-container">
      <button class="sgnd-btn sgnd-btn-ok" [disabled]="!slotCreateForm.valid" type="submit">Save</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
  </div>
</form>

<div *ngIf="displayQR" class="di-qr-container">
  <div class="di-qr-inner-container">
    <ngx-qrcode
      id="qrelement"
      [elementType]="elementType"
      [errorCorrectionLevel]="correctionLevel"
      [value]="url"
      [width]="350"
      cssClass="coolQRCode"
    >
    </ngx-qrcode>
    <div class="di-qr-buttons">
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="OnDownloadQr()">Download QR</button>
      <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="displayQR = false">Close</button>
    </div>
  </div>
</div>
