import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Flow } from 'app/model/flow';
import { FlowAction } from 'app/model/flow-action';
import { NegotiationType } from 'app/model/negotiation-type';
import { STUDIO_ACTION_OPTIONS } from 'app/model/studio-action-options';
import { FlowsService } from 'app/services/flows.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-add-flow',
  templateUrl: './add-flow.component.html',
  styleUrls: ['./add-flow.component.css'],
})
export class AddFlowComponent implements OnInit {
  flowForm: FormGroup;
  public negotiationTypesOptions: { [key: string]: string }[];
  private allNegotiationTypes = NegotiationType;

  constructor(
    public dialogRef: MatDialogRef<AddFlowComponent>,
    public flowsService: FlowsService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public data: { formControl: FormControl; isEdit: boolean }
  ) {
    this.negotiationTypesOptions = Object.keys(this.allNegotiationTypes)
      .filter((k) => !isNaN(Number(k)) && Number(k) < 3)
      .map((key) => ({ key, value: this.allNegotiationTypes[key] }));
    let flow;
    if (this.data.isEdit) {
      flow = new Flow(this.data.formControl.value);
    } else {
      flow = new Flow(null);
    }
    this.buildFlowCreateForm(flow);
  }

  ngOnInit(): void {}

  buildFlowCreateForm(flow: Flow) {
    this.flowForm = this.formBuilder.group({
      flowId: flow.flowId,
      companyId: flow.companyId,
      name: flow.name,
      type: flow.type,
      created: flow.created,
      createdBy: flow.CreatedBy,
      lastModified: flow.lastModified,
      lastModifiedBy: flow.lastModifiedBy,
    });
  }

  onSubmit(): void {
    const flow = new Flow(this.flowForm.value);
    if (this.data.isEdit) {
      this.updateFlow(flow);
    } else {
      this.createFlow(flow);
    }
  }

  updateFlow(flow: Flow) {
    this.uiService.toggleGlobalSpinner(true);
    this.flowsService.updateFlow(flow).subscribe(
      () => {
        this.flowsService.reloadFlowsList().subscribe(
          () => {
            const flows = this.flowsService.flows.value;
            const selectedFlow = flows.find((f) => f.flowId == flow.flowId);
            const menuBarFlowFormControl = this.data.formControl;
            menuBarFlowFormControl.setValue(selectedFlow);
            this.flowsService.selectFlow(selectedFlow);
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Flow updated successfully.', null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
            this.dialogRef.close();
          },
          (err) => {
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Error: ' + err, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          }
        );
      },
      (error) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + error, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  createFlow(flow: Flow) {
    this.uiService.toggleGlobalSpinner(true);
    this.flowsService.saveFlow(flow).subscribe(
      (flowId) => {
        this.flowsService.reloadFlowsList().subscribe(
          async () => {
            const flows = this.flowsService.flows.value;
            const persistedNode = new FlowAction(
              null,
              flowId,
              null,
              100,
              100,
              STUDIO_ACTION_OPTIONS.START,
              [],
              false,
              '',
              true
            );
            await this.flowsService
              .addAction(persistedNode)
              .toPromise()
              .catch((err) => {
                this.uiService.showMessage(err?.message ? err.message : err);
              });
            const selectedFlow = flows.find((f) => f.flowId == flowId);
            const menuBarFlowFormControl = this.data.formControl;
            menuBarFlowFormControl.setValue(selectedFlow);
            this.flowsService.selectFlow(selectedFlow);
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Flow created successfully.', null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
            this.dialogRef.close();
          },
          (err) => {
            this.uiService.toggleGlobalSpinner(false);
            this.snackBar.open('Error: ' + err, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          }
        );
      },
      (error) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + error, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  onCancel() {
    this.dialogRef.close();
  }

  capitalize(text: string, splitStr: string = '_'): string {
    return text && splitStr
      ? text
          .toLowerCase()
          .split(splitStr)
          .map((x) => x.charAt(0).toUpperCase() + x.substring(1, x.length))
          .join(' ')
      : text;
  }
}
