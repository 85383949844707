import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UserService } from 'core/services/user.service';
import { UserPasswordChangeComponent } from '../../user-password-change/user-password-change.component';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css'],
})
export class UserSettingsComponent implements OnInit {
  public user = null;
  constructor(public dialog: MatDialog, private userService: UserService) {
    this.userService.$currentUser.subscribe((user) => {
      this.user = user;
    });
  }

  ngOnInit(): void {}

  onPasswordChange() {
    this.dialog.open(UserPasswordChangeComponent, {
      panelClass: 'custom-dialog-container',
      width: '320px',
    });
  }
}
