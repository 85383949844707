<section class="data-ingestor-slots-selection" *ngIf="slots && slots.length > 0">
  <div class="ingestor-title">{{ 'data-ingestor-slots-selection.channels-subscriptions' | translate }}</div>
  <div class="slots-container">
    <mat-checkbox
      class="sd-checkbox"
      *ngFor="let slot of slots"
      [(ngModel)]="slot.checked"
      (change)="clickCheckbox(slot)"
      >{{ slot.name }}</mat-checkbox
    >
  </div>
</section>
