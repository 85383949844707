import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanySettingsService } from 'app/components/settings/services/company-settings.service';
import { UIService } from 'core/services/ui.service';
// eslint-disable-next-line max-len
import { CompanyCredentialsCreateComponent } from './company-credentials-create/company-credentials-create.component';
import { CompanyCredentialsService } from 'app/services/company-credentials.service';
import { CompanyCredentials } from 'app/model/company-credentials';
import { Auth0ManagementService } from 'app/services/auth0-management.service';
import { SimpleCompanySettings } from 'app/components/settings/model/simple-company-settings';
import { Company } from 'app/model/company';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { UtilityService } from 'core/services/utility.service';


@Component({
  selector: 'app-company-credentials',
  templateUrl: './company-credentials.component.html',
  styleUrls: ['./company-credentials.component.css'],
})
export class CompanyCredentialsComponent implements OnInit {
  public tooltips: { [key: string]: string };
  public companyUpdateForm: FormGroup;
  public cred: CompanyCredentials;
  company: SimpleCompanySettings;

  constructor(
    public dialog: MatDialog,
    private uiService: UIService,
    private snackBar: MatSnackBar,
    private utilityService: UtilityService,
    public auth0ManagementService: Auth0ManagementService,
    private companySettingsService: CompanySettingsService,
    private companyCredentialsService: CompanyCredentialsService,
  ) {
    this.companySettingsService.$companyData.subscribe((data) => {
      this.company = data;
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.companySettingsService.getSimpleCompanyParameters();
  }


  onGenerateCredentials() {
    this.uiService.toggleGlobalSpinner(true);
    this.auth0ManagementService.generateClientCredentials(this.company.companyId).subscribe(credentials => {
      this.uiService.toggleGlobalSpinner(false);

      const dialogRef = this.dialog.open(CompanyCredentialsCreateComponent, {
        data: { company: new Company(this.company.companyId, this.company.name), clientSecret: credentials.client_secret}
      });
      dialogRef.afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          this.loadData();
        }
      });
      }, err => {
      this.uiService.toggleGlobalSpinner(false);
      let errorMsg = this.utilityService.handleExceptionMessage(`Unable to generate API credentials.`, err);
      this.snackBar.open(errorMsg, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
    });
  }


  public onDeleteCredentials() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the API credentials?` },
      })
      .afterClosed()
      .subscribe(
        (confirm: boolean) => {
          if (confirm) {
            this.uiService.toggleGlobalSpinner(true);
            this.auth0ManagementService.deleteClientCredentials(this.company.companyId).subscribe(() => {
              this.loadData();
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`API credentials deleted successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }, (err) => {
              this.handleDeleteCredentialsError(err);
            });
          }
        },
        (err) => {
          this.handleDeleteCredentialsError(err);
        }
      );
  }

  private handleDeleteCredentialsError(err) {
    this.uiService.toggleGlobalSpinner(false);
    let errorMsg = this.utilityService.handleExceptionMessage(`Unable to delete API credentials.`, err);
    this.snackBar.open(errorMsg, null, {
      horizontalPosition: 'center',
      verticalPosition: 'bottom',
      duration: 8000,
    });
  }

  private loadData() {
    this.companyCredentialsService.getCompanyCredentials().subscribe(companyParameters => {
      this.cred = companyParameters;
    });
  }
}
