<div>
    <div class="type-selector-container">
        <div class="type-selector-title">Select the type of message you want to request</div>
        <div *ngFor="let contentType of typesList"
            (click)="selectedType = contentType.type"
            [class]="contentType.type === selectedType ? 'type-selector-card selected-card': 'type-selector-card'">
            <mat-checkbox [checked]= "contentType.type === selectedType" [color]="blue"></mat-checkbox>
            <div class="type-selector-card-text">
                <div class="content-type-name">{{contentType.name}}</div>
                <div class="content-type-description">{{contentType.description}}</div>
            </div>
        </div>
    </div>

    <div class="buttons-container">
        <button class="sgnd-btn sgnd-btn-ok" [disabled]="!selectedType" type="submit" (click)="onNext()">Next</button>
        <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
    </div>
</div>
