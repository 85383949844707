import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DataIngestorSlot } from 'app/model/data-ingestor/di-slot';
import { DataIngestionService } from 'app/services/data-ingestion.service';

@Component({
  selector: 'app-data-ingestor-slots-selection',
  templateUrl: './data-ingestor-slots-selection.component.html',
  styleUrls: ['./data-ingestor-slots-selection.component.css'],
})
export class DataIngestorSlotsSelectionComponent implements OnInit {
  @Output() public slotChecked = new EventEmitter();
  public slots: DataIngestorSlot[] = [];
  private companyId = null;

  constructor(private route: ActivatedRoute, private dataIngestionService: DataIngestionService) {
    const companyId = this.route.snapshot.params?.companyId;
    this.companyId = companyId;
    this.dataIngestionService.loadDataIngestorSlots(companyId);
    this.dataIngestionService.dataIngestors.subscribe((value) => {
      this.slots = value;
    });
  }

  ngOnInit(): void {}

  public clickCheckbox(slot) {
    const checkedSlots = this.slots
      .filter((x) => {
        // eslint-disable-next-line
        if (this.companyId == 19) {
          return (x as any).checked && x.slotId === slot.slotId;
        }
        return (x as any).checked;
      })
      .map((x) => ({ slotId: x.slotId, name: x.name }));
    this.slots = this.slots.map((sl) => {
      if (checkedSlots.find((checked) => checked.slotId === sl.slotId)) {
        sl.checked = true;
      } else {
        sl.checked = false;
      }
      return sl;
    });
    this.slotChecked.emit(checkedSlots);
  }
}
