import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { ScheduleApiService } from './schedule-api.service';
import { Schedule } from 'app/model/schedule';

@Injectable()
export class ScheduleService {
  public schedule = new BehaviorSubject<Schedule>(null);

  constructor(
    private scheduleApiService: ScheduleApiService
  ) {
  }

  getScheduleData(slot: number) : Observable<Schedule> {
    return this.scheduleApiService.getSlotSchedules(slot);

  }


  public saveSchedule(schedule: Schedule): Observable<any> {
    return this.scheduleApiService.saveSchedule(schedule);
  }
  public deleteSchedule(schedule: Schedule): Observable<any> {
     return this.scheduleApiService.deleteSchedule(schedule.entityId);
  }


}
