import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { STUDIO_ACTION_OPTIONS, STUDIO_ICONS, STUDIO_OPTIONS_LABELS } from 'app/model/studio-action-options';
import { FlowsService } from 'app/services/flows.service';
import { StudioService } from 'app/services/studio.service';
import { UIService } from 'core/services/ui.service';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-canvas-node-details',
  templateUrl: './canvas-node-details.component.html',
  styleUrls: ['./canvas-node-details.component.css'],
})
export class CanvasNodeDetailsComponent implements OnInit {
  node: any;
  isLoading = false;
  actionIcons = STUDIO_ICONS;
  actionTypes = STUDIO_ACTION_OPTIONS;
  studioOptionsLabels = STUDIO_OPTIONS_LABELS;

  startAction = STUDIO_ACTION_OPTIONS.START;

  yRelative = 0;
  xRelative = 0;

  constructor(
    private studioService: StudioService,
    private flowService: FlowsService,
    private uiService: UIService,
    private snackBar: MatSnackBar,
    private dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.studioService.$isLoadingInfo.subscribe((val) => (this.isLoading = val));
    this.studioService.$nodeDetails.subscribe((val) => {
      this.node = val;
    });
  }

  onDoubleClick(event) {
    const containerElement = event.srcElement.closest('.node-details-container');
    if (containerElement) {
      containerElement.style.position = 'absolute';
      containerElement.style.maxWidth = '';
      containerElement.style.width = '';
      containerElement.style.resize = '';
      containerElement.style.top = '';
      containerElement.style.left = '';
      containerElement.style.overflow = '';
      containerElement.style.minHeight = '';
      containerElement.style.minWidth = '';
      containerElement.style.height = '';
    }
  }

  dontDrag(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  enableAction() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Are you sure that you want to ${this.node.enable ? 'skip' : 'run'} this action?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.uiService.toggleGlobalSpinner(true);
          const action = { ...this.node, enable: !this.node.enable };
          this.flowService
            .updatedAction(action)
            .toPromise()
            .then(() => {
              this.node.enable = !this.node.enable;
            })
            .catch((errorResponse) => {
              this.snackBar.open(`Error: ${errorResponse.error}`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            })
            .finally(() => {
              this.uiService.toggleGlobalSpinner(false);
            });
        }
      });
  }

  toggleContinueOnFailure({ checked: continueOnFailure }) {
    this.uiService.toggleGlobalSpinner(true);
    const action = { ...this.node, continueOnFailure };
    this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.continueOnFailure = action.continueOnFailure;
      })
      .catch((errorResponse) => {
        this.snackBar.open(`Error: ${errorResponse.error}`, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  onDragInit(event) {
    const rect = event.target.getBoundingClientRect();
    event.dataTransfer.setData('dragType', 'details');
    this.xRelative = event.clientX - rect.left; //x position within the element.
    this.yRelative = event.clientY - rect.top; //y position within the element.
  }

  onDrop(event) {
    const rect = event.target.getBoundingClientRect();
    if (event.clientX - this.xRelative + rect.width / 2 > window.innerWidth) {
      event.target.style.position = 'absolute';
      event.target.style.maxWidth = '';
      event.target.style.width = '';
      event.target.style.resize = '';
      event.target.style.top = '';
      event.target.style.left = '';
      event.target.style.overflow = '';
      event.target.style.minHeight = '';
      event.target.style.minWidth = '';
      event.target.style.height = '';
    } else {
      if (event.target.style.position !== 'fixed') {
        event.target.style.width = '400px';
      }
      event.target.style.position = 'fixed';
      event.target.style.maxWidth = '100%';
      event.target.style.resize = 'both';
      event.target.style.top = `${event.clientY - this.yRelative}px`;
      event.target.style.left = `${event.clientX - this.xRelative}px`;
      event.target.style.overflow = 'auto';
      event.target.style.minHeight = '500px';
      event.target.style.minWidth = '400px';
      event.target.style.height = 'auto';
    }
  }
}
