import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FlowAction } from 'app/model/flow-action';
import { FlowActionDelay } from 'app/model/flow-action-delay';
import { FlowsService } from 'app/services/flows.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-delay-details',
  templateUrl: './delay-details.component.html',
  styleUrls: ['./delay-details.component.css'],
})
export class DelayDetailsComponent implements OnInit {
  public description: string;
  public parameterId: string | number;
  public days: number;
  public hours: number;
  public minutes: number;
  public seconds: number;
  @Input() node: FlowActionDelay;
  @Output() nodeChange = new EventEmitter<FlowAction>();

  constructor(private uiService: UIService, private flowService: FlowsService) {}

  ngOnInit(): void {
    this.description = this.node.description;
    this.loadParameters();
  }

  loadParameters() {
    if (this.node.flowActionDelayParameters != null) {
      const { id, days, hours, minutes, seconds } = FlowActionDelay.getProperties(this.node.flowActionDelayParameters);
      this.parameterId = id;
      this.days = days;
      this.hours = hours;
      this.minutes = minutes;
      this.seconds = seconds;
    } else {
      this.parameterId = null;
      this.days = 0;
      this.hours = 0;
      this.minutes = 0;
      this.seconds = 0;
    }
  }

  CreateArray(start: number, end: number) {
    let size = start <= 0 ? end + Math.abs(start) + 1 : end;
    return Object.keys((<any>Array(size)).fill()).map((x) => parseInt(x) + start);
  }

  /**
   * Save the messages for all the translations, if any fails then show all the original messages,
   * does the same with parameters, but this does not need to check anything, just if the call was correct
   * TODO: Check when just some fail and rollback only those. Make validations, debouncing and loading indicator.
   */
  public saveChanges() {
    this.uiService.toggleGlobalSpinner(true);
    let promises: Array<Promise<any>> = [];
    promises.push(this.saveFlowAction());
    promises.push(this.saveProperties());

    Promise.all(promises).then(() => {
      this.uiService.toggleGlobalSpinner(false);
    });

    // TODO: Delete this and fix issue where sometimes the spinner remains
    setTimeout(() => {
      this.uiService.toggleGlobalSpinner(false);
    }, 3000);
  }

  private saveProperties(): Promise<any> {
    const delayParams = {
      days: this.days,
      hours: this.hours,
      minutes: this.minutes,
      seconds: this.seconds,
    };

    /**
     * If there is a parameters id means that it was previously created. If not create them.
     */
    let savePropertiesPromise: Promise<any>;
    if (this.parameterId) {
      // eslint-disable-next-line
      delayParams['id'] = this.parameterId;
      savePropertiesPromise = this.flowService
        .saveDelayProperties(delayParams)
        .toPromise()
        .then(() => {
          this.node.flowActionDelayParameters.days = this.days;
          this.node.flowActionDelayParameters.hours = this.hours;
          this.node.flowActionDelayParameters.minutes = this.minutes;
          this.node.flowActionDelayParameters.seconds = this.seconds;
        })
        .catch((err) => {
          this.days = this.node.flowActionDelayParameters.days;
          this.hours = this.node.flowActionDelayParameters.hours;
          this.minutes = this.node.flowActionDelayParameters.minutes;
          this.seconds = this.node.flowActionDelayParameters.seconds;
          this.uiService.showMessage(err?.message ? err.message : err);
        });
    } else {
      // eslint-disable-next-line
      delayParams['flowActionId'] = this.node.flowActionId;
      savePropertiesPromise = this.flowService
        .postDelayProperties(delayParams)
        .toPromise()
        .then((newId: string | number) => {
          this.parameterId = newId;
          this.node.flowActionDelayParameters.id = parseInt(newId + '');
          this.node.flowActionDelayParameters.days = this.days;
          this.node.flowActionDelayParameters.hours = this.hours;
          this.node.flowActionDelayParameters.minutes = this.minutes;
          this.node.flowActionDelayParameters.seconds = this.seconds;
        })
        .catch((err) => {
          this.days = this.node.flowActionDelayParameters.days;
          this.hours = this.node.flowActionDelayParameters.hours;
          this.minutes = this.node.flowActionDelayParameters.minutes;
          this.seconds = this.node.flowActionDelayParameters.seconds;
          this.uiService.showMessage(err?.message ? err.message : err);
        });
    }
    return savePropertiesPromise;
  }

  /**
   * Saves the current flow action into the database.
   */
  private saveFlowAction(): Promise<any> {
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    let saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        this.uiService.showMessage(err?.message ? err.message : err);
      });
    return saveActionPromise;
  }
}
