export class WhatsappOptionTemplateButton {
  id: number;
  text: string;
  url: string;

  constructor(text: string, url: string) {
    this.text = text;
    this.url = url;
  }
}
