import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { BillingService } from 'app/services/billing.service';
import { CreditsService } from 'app/services/credits/credits.service';
import { UIService } from 'core/services/ui.service';
import { UserService } from 'core/services/user.service';
import { CompanySettingsService } from '../../services/company-settings.service';

const defaultOptions = [
  { icon: 'person', text: 'User', link: 'user' },
  { icon: 'supervisor_account', text: 'Admin', link: 'admin' },
  { icon: 'credit_card', text: 'Subscription', link: 'subscription' },
  { icon: 'settings', text: 'Company Settings', link: 'company' },
  { icon: 'email', text: 'Email', link: 'email-settings' },
  { icon: 'key', text: 'Credentials', link: 'credentials' },
];

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
})
export class SettingsComponent implements OnInit {
  public options = [];
  public currentOption = null;

  constructor(
    public auth: AuthService,
    private userService: UserService,
    private companySettingsService: CompanySettingsService,
    private billingService: BillingService,
    private creditsService: CreditsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.auth.isAuthenticated$.subscribe((isAuthenticated) => {
      if (isAuthenticated) {
        this.companySettingsService.getSimpleCompanyParameters();
        this.creditsService.updateCredits();
      }
    });
    this.userService.$currentUser.subscribe((user) => {
      let isAdminUser = this.userService.isAdminUser(user);
      if (isAdminUser) {
        this.options = defaultOptions;
      } else {
        this.options = defaultOptions.filter((opt) => opt.link !== 'admin');
      }
      if (user) {
        this.billingService.loadSubscriptionDetails();
      }
    });
    this.route.url.subscribe((val: UrlSegment[]) => {
      this.currentOption =
        this.route.snapshot.firstChild?.routeConfig.path !== 'settings'
          ? this.route.snapshot.firstChild?.routeConfig.path
          : 'company';
    });
  }
}
