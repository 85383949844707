import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { WhatsappMessageApiService } from './whatsapp-message-api.service';

@Injectable({
  providedIn: 'root',
})
export class WhatsappMessageService {
  
  constructor(private messageApi: WhatsappMessageApiService) { }

  public getMessages(contactId: number): Observable<any> {
    return this.messageApi.getWhatsappMessages(contactId);
  }

  public postMessage(contactId: number, content: string): Observable<any> {
    const message = {
      contactId,
      content
    };
    return this.messageApi.postWhatsappMessage(message);
  }

  public markMessagesAsRead(contactId: number) {
    return this.messageApi.markMessagesAsRead(contactId);
  }
}
