<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Description</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="full-width-textarea">
    <input matInput [(ngModel)]="description" />
  </mat-form-field>
</mat-expansion-panel>
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Text</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field style="display: flex">
    <mat-form-label>Language</mat-form-label>
    <mat-select [(value)]="language" (selectionChange)="onLanguageSelect()">
      <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
    </mat-select>
  </mat-form-field>
  <app-insertable-textarea #textField [(value)]="selectedMessage.content" *ngIf="selectedMessage"></app-insertable-textarea>
</mat-expansion-panel>
<app-negotiation-variables [customVars]="customVars" (selectOption)="insertText($event)"></app-negotiation-variables>
<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="saveMessages()">Save</button>
</div>
