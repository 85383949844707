import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ContactSuscription } from 'app/model/data-ingestor/contact-suscription';
import { Country } from 'app/model/data-ingestor/country';
import { SubscribingContact } from 'app/model/data-ingestor/subscribing-contact';
import { DataIngestionService } from 'app/services/data-ingestion.service';
import { UIService } from 'core/services/ui.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { Contact } from 'src/app/model/contact';

@Component({
  selector: 'app-phone-picker',
  templateUrl: './phone-picker.component.html',
  styleUrls: ['./phone-picker.component.css'],
})
export class PhonePickerComponent implements OnInit, OnChanges {
  @Input() public companyId: any;
  @Input() public isMobile = false;
  @Output() public phoneChanged = new EventEmitter();

  public phoneRegexp = /^[\+]?[\d]{1,3}[(]?[0-9]{1,4}[)]?[\d]{1,5}[\-\.\s]?[\d]{1,6}$/;
  public countryData: Array<Country> = [];
  public countryCodeFrom = new FormControl();
  public filteredOptions: Observable<Country[]>;
  public currentCode: string;
  public currentLocal: string;

  constructor() {
    this.countryData = this.getCountryData();
    this.setupCountryFilter();
  }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.companyId && changes.companyId.currentValue && changes.companyId.currentValue == 19) {
      this.countryData = this.getCountryData();
      this.countryCodeFrom.disable();
      this.countryCodeFrom.setValue('+1');
      this.onCodeChange('+1');
    }
    if (changes.companyId && changes.companyId.currentValue && changes.companyId.currentValue == 2) {
      this.countryData = this.getCountryData();
      this.countryCodeFrom.disable();
      this.countryCodeFrom.setValue('+55');
      this.onCodeChange('+55');
    }
  }

  public onCodeChange(value: string) {
    this.currentCode = value;
    this.emitValue();
  }

  public onLocalChange(value: any) {
    this.currentLocal = value.target.value;
    this.emitValue();
  }

  private emitValue() {
    if (this.currentCode !== undefined && this.currentLocal !== undefined) {
      this.phoneChanged.emit(this.currentCode + this.currentLocal);
    }
  }

  private setupCountryFilter(): void {
    this.filteredOptions = this.countryCodeFrom.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterCountryCodes(value))
    );
  }

  private filterCountryCodes(value: string): Country[] {
    const filterValue = value.toLowerCase();
    return this.countryData.filter(
      (country) =>
        country.name.toLowerCase().includes(filterValue) || country.phoneCode.toLowerCase().includes(filterValue)
    );
  }

  private getCountryData(): Array<Country> {
    if (this.companyId && this.companyId == 19) {
      return [
        {
          name: 'United States',
          isoCode: 'us',
          phoneCode: '+1',
        },
      ];
    }
    return [
      {
        name: 'Argentina',
        isoCode: 'ar',
        phoneCode: '+54',
      },
      {
        name: 'Austria',
        isoCode: 'at',
        phoneCode: '+43',
      },
      {
        name: 'Brazil',
        isoCode: 'br',
        phoneCode: '+55',
      },
      {
        name: 'Canada',
        isoCode: 'ca',
        phoneCode: '+1',
      },
      {
        name: 'Chile',
        isoCode: 'cl',
        phoneCode: '+56',
      },
      {
        name: 'Colombia',
        isoCode: 'co',
        phoneCode: '+57',
      },
      {
        name: 'Costa Rica',
        isoCode: 'cr',
        phoneCode: '+506',
      },
      {
        name: 'France',
        isoCode: 'fr',
        phoneCode: '+33',
      },
      {
        name: 'Germany',
        isoCode: 'de',
        phoneCode: '+49',
      },
      {
        name: 'Guatemala',
        isoCode: 'gt',
        phoneCode: '+502',
      },
      {
        name: 'Honduras',
        isoCode: 'hn',
        phoneCode: '+504',
      },
      {
        name: 'India',
        isoCode: 'in',
        phoneCode: '+91',
      },
      {
        name: 'Israel',
        isoCode: 'il',
        phoneCode: '+972',
      },
      {
        name: 'Italy',
        isoCode: 'it',
        phoneCode: '+39',
      },
      {
        name: 'Mexico',
        isoCode: 'mx',
        phoneCode: '+52',
      },
      {
        name: 'Netherlands',
        isoCode: 'nl',
        phoneCode: '+31',
      },
      {
        name: 'Nicaragua',
        isoCode: 'ni',
        phoneCode: '+505',
      },
      {
        name: 'Panama',
        isoCode: 'pa',
        phoneCode: '+507',
      },
      {
        name: 'Saudi Arabia',
        isoCode: 'sa',
        phoneCode: '+966',
      },
      {
        name: 'Spain',
        isoCode: 'es',
        phoneCode: '+34',
      },
      {
        name: 'Switzerland',
        isoCode: 'ch',
        phoneCode: '+41',
      },
      {
        name: 'United Arab Emirates',
        isoCode: 'ae',
        phoneCode: '+971',
      },
      {
        name: 'United Kingdom',
        isoCode: 'gb',
        phoneCode: '+44',
      },
      {
        name: 'United States',
        isoCode: 'us',
        phoneCode: '+1',
      },
      {
        name: 'Uruguay',
        isoCode: 'uy',
        phoneCode: '+598',
      },
      {
        name: 'Indonesia',
        isoCode: 'id',
        phoneCode: '+62',
      },
    ];
  }
}
