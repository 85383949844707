import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { Negotiation, PaginatedNegotiationsDataRequest } from 'app/model/negotiation';
import { NegotiationsService } from 'app/services/negotiations.service';
import { AddNegotiationComponent } from '../add-negotiation/add-negotiation.component';
import { NegotiationType } from 'app/model/negotiation-type';
import { NegotiationStatus } from 'app/model/negotiation-status';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { UIService } from 'core/services/ui.service';
import { FilterPageEvent, FilterPaginatorResetMode } from 'core/components/filter-paginator/filter-paginator.component';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { MatSort, Sort } from '@angular/material/sort';
import { AlertComponent } from 'app/components/common/alert/alert.component';
import { SlotsService } from 'app/services/slots.service';
import { Slot } from 'app/model/slot';
import { FormControl } from '@angular/forms';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-negotiations-grid',
  templateUrl: './negotiations-grid.component.html',
  styleUrls: ['./negotiations-grid.component.css'],
})
export class NegotiationsGridComponent implements OnInit, OnDestroy {
  @Input() reset: Subject<boolean>;
  @Input() setState: Subject<PaginatedNegotiationsDataRequest>;
  @Output() selectedNegotiations = new EventEmitter<Negotiation[]>();
  @Output() negotiationToStart = new EventEmitter<Negotiation>();
  @Output() negotiationToStop = new EventEmitter<Negotiation>();
  @Output() negotiationToResume = new EventEmitter<Negotiation>();
  @Output() negotiationToPause = new EventEmitter<Negotiation>();
  @Output() negotiationToRestart = new EventEmitter<Negotiation>();
  @Output() negotiationToAddToSlot = new EventEmitter<Negotiation>();
  @Output() negotiationToDelete = new EventEmitter<Negotiation>();
  @Output() refresh = new EventEmitter<PaginatedNegotiationsDataRequest>();
  @ViewChild(MatSort, { static: true }) matSort: MatSort;
  dataSource;
  displayedColumns = [
    'select',
    'contactName',
    'description',
    'type',
    'status',
    'dueDate',
    'amount',
    'lastModified',
    'actions',
  ];
  selection = new SelectionModel<Negotiation>(true, []);
  public negotiations: Array<Negotiation> = [];
  public allNegotiationTypes = NegotiationType;
  public allNegotiationStatus = NegotiationStatus;
  public resetFilter: Subject<FilterPaginatorResetMode> = new Subject();
  public setFilterState: BehaviorSubject<FilterPageEvent> = new BehaviorSubject(null);
  public dataLength: number;
  targetSlotControl = new FormControl('');
  filteredSlotOptions: Observable<any[]>;
  slotElements: any[];
  public selectedTargetSlot: any;
  private isAllSelected = false;

  private currentRequest: PaginatedNegotiationsDataRequest;
  private processSortEvent = true;

  constructor(
    public negotiationsService: NegotiationsService,
    private slotsService: SlotsService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private uiService: UIService
  ) {
    //this.sendDataRequest(this.currentRequest);
  }

  emitSelectedNegotiations() {
    this.selectedNegotiations.emit(this.selection.selected);
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected) {
      const currentSet = new Set(this.dataSource.data.map((el) => el.negotiationId));
      const filtered = this.selection.selected.filter((element) => !currentSet.has(element.negotiationId));
      this.selection.clear();
      this.selection.select(...filtered);
      this.isAllSelected = false;
    } else {
      const filteredData = this.dataSource.data.filter(
        (x) => !this.selection.selected.some((y) => y.negotiationId === x.negotiationId)
      );
      this.selection.select(...filteredData);
      this.isAllSelected = true;
    }
  }

  checkAllSelected(negotiations = []) {
    const selectedMap = new Map(negotiations.map((neg) => [neg.negotiationId, false]));
    this.selection.selected.forEach((el) => {
      if (selectedMap.has(el.negotiationId)) {
        selectedMap.set(el.negotiationId, true);
      }
    });
    if ([...selectedMap.values()].some((val) => val === false)) {
      this.isAllSelected = false;
    } else {
      this.isAllSelected = true;
    }
  }

  filterSlots(value) {
    let filtered = this.slotElements;
    if (value == null) {
      return filtered;
    }
    const lowered = value ? value.toLowerCase?.() : '';
    if (typeof value === 'string') {
      this.selectedTargetSlot = null;

      filtered = this.slotElements.filter((el) => this.getFullName(el).toLowerCase().includes(lowered));
    } else {
      this.selectedTargetSlot = value;
      // eslint-disable-next-line
      filtered = filtered.filter((options) => options.key == value.key);
    }
    return filtered;
  }

  getFullName(slot) {
    return `${slot.isDataInjestor ? 'DI-' : ''}${slot.title}`;
  }

  initFilters() {
    this.filteredSlotOptions = this.targetSlotControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterSlots(value || ''))
    );
  }

  onCloseSlotSelect() {
    const realValue = this.targetSlotControl.value;
    this.initFilters();
    this.targetSlotControl.setValue(realValue);
  }

  ngOnInit(): void {
    this.filteredSlotOptions = this.targetSlotControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filterSlots(value || ''))
    );
    this.negotiationsService.paginatedNegotiations.subscribe((paginatedNegotiations) => {
      if (paginatedNegotiations) {
        this.negotiations = paginatedNegotiations.data.map((x) => new Negotiation(x));
        this.dataLength = paginatedNegotiations.totalCount;
      } else {
        this.negotiations = [];
        this.dataLength = 0;
      }
      this.dataSource = new MatTableDataSource<Negotiation>(this.negotiations);
      this.checkAllSelected(this.negotiations);
      const selected = this.selection.selected;
      this.selection.clear();
      this.selection.select(...selected);
      this.selectedNegotiations.emit(this.selection.selected);
      this.uiService.toggleGlobalSpinner(false);
    });
    // reset grid
    this.reset.subscribe(() => {
      this.resetFilter.next(FilterPaginatorResetMode.ALL);
    });
    // change internal state
    this.setState.subscribe((state: PaginatedNegotiationsDataRequest) => {
      if (state) {
        this.currentRequest = state;
        if (state.sortedBy && state.sortDirection) {
          this.processSortEvent = false;
          this.matSort.sort({
            id: state.sortedBy,
            start: state.sortDirection.toLowerCase() as any,
            disableClear: false,
          });
        }

        // forward the state to the filter and paginator component
        this.setFilterState.next({
          filterString: state.filterString,
          pageSize: state.pageSize,
          pageNumber: state.pageNumber,
        });
      }
    });
    this.slotsService.slots.subscribe((values: Slot[]) => this.onSlotReload(values));
  }

  isNegotiationSelected(negotition: Negotiation) {
    return this.selection.selected.some((sel) => sel.negotiationId === negotition.negotiationId);
  }

  public onSlotReload(values: Slot[]) {
    if (values !== null && values !== undefined) {
      const elements = values.map((slot) => ({
        isDataInjestor: slot.isDataIngestor,
        key: slot.slotId,
        title: slot.name,
        count: slot.negotiationCount,
      }));
      this.slotElements = elements;
      this.targetSlotControl.setValue(this.targetSlotControl.value);
    }
  }

  public onEdit(negotiation) {
    this.dialog.open(AddNegotiationComponent, {
      panelClass: 'custom-dialog-container',
      width: '60vw',
      maxWidth: '900px',
      minWidth: '500px',
      data: negotiation,
    });
  }

  /**
   * Triggers an event that will eventually set a negotiation state as ready so that the
   * AuctionFlowWorker in the API picks it up and executes it.
   *
   * @param negotiation
   */
  public onStart(negotiation: Negotiation) {
    this.negotiationToStart.emit(negotiation);
  }

  public onStop(negotiation: Negotiation) {
    this.negotiationToStop.emit(negotiation);
  }
  public onResume(negotiation: Negotiation) {
    this.negotiationToResume.emit(negotiation);
  }

  public onPause(negotiation: Negotiation) {
    this.negotiationToPause.emit(negotiation);
  }

  public onRestart(negotiation: Negotiation) {
    this.negotiationToRestart.emit(negotiation);
  }

  public showStart(negotiation: Negotiation): boolean {
    return negotiation.status === NegotiationStatus.DRAFT;
  }

  public showStop(negotiation: Negotiation): boolean {
    const stoppableStatuses = [
      NegotiationStatus.READY,
      NegotiationStatus.IN_PROGRESS,
      NegotiationStatus.READY_TO_CONTINUE,
    ];
    return stoppableStatuses.includes(negotiation.status);
  }

  public showResume(negotiation: Negotiation): boolean {
    return negotiation.status === NegotiationStatus.PAUSED;
  }

  public showPause(negotiation: Negotiation): boolean {
    const stoppableStatuses = [
      NegotiationStatus.READY,
      NegotiationStatus.IN_PROGRESS,
      NegotiationStatus.READY_TO_CONTINUE,
    ];
    return stoppableStatuses.includes(negotiation.status);
  }

  public showAddToSlot(negotiation: Negotiation): boolean {
    return !negotiation.slotId;
  }

  public showRemoveFromSlot(negotiation: Negotiation): boolean {
    return !!negotiation.slotId;
  }

  public onAddToSlot(negotiation: Negotiation) {
    this.negotiationToAddToSlot.emit(negotiation);
  }

  public onRemoveFromSlot(negotiation: Negotiation) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Are you sure that you want to remove this negotiation from it's current slot?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.uiService.toggleGlobalSpinner(true);
          this.negotiationsService.removeNegotiationFromSlot(negotiation.negotiationId).subscribe(
            () => {
              this.sendDataRequest(this.currentRequest); // This method refreshes the negotiations list in the service.
              this.uiService.toggleGlobalSpinner(false);
              this.snackBar.open(`Negotiation removed successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (err) => {
              this.uiService.toggleGlobalSpinner(false);
            }
          );
        }
      });
  }

  public showRestart(negotiation: Negotiation) {
    const restartableStatuses = [
      NegotiationStatus.CANCELED,
      NegotiationStatus.COMPLETED,
      NegotiationStatus.ERROR,
      NegotiationStatus.IN_PROGRESS,
    ];
    return restartableStatuses.includes(negotiation.status);
  }

  public onDelete(negotiation: Negotiation) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Negotiation?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.negotiationsService.deleteNegotiation(negotiation.negotiationId).subscribe(() => {
            this.sendDataRequest(this.currentRequest); // This method refreshes the negotiations list in the service.
            this.negotiationToDelete.emit(negotiation);
            this.snackBar.open(`Negotiation deleted successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  ngOnDestroy(): void {
    //this.negotiationsService.negotiations.unsubscribe();
  }

  amountApplicable(type: NegotiationType) {
    return type != NegotiationType.OPTIONS;
  }

  /**
   * Capitalizes a given string.
   */
  capitalize(text: string, splitStr: string = '_'): string {
    return text && splitStr
      ? text
          .toLowerCase()
          .split(splitStr)
          .map((x) => x.charAt(0).toUpperCase() + x.substring(1, x.length))
          .join(' ')
      : text;
  }

  public onFilterPage(event: FilterPageEvent) {
    const dataRequest: PaginatedNegotiationsDataRequest = {
      pageSize: event.pageSize,
      pageNumber: event.pageNumber,
      filterByParameter: ['LASTNAME', 'NAME', 'EMAIL'],
      filterString: event.filterString,
      slot: this.negotiationsService.getSlot(),
      sortedBy: this.currentRequest?.sortedBy,
      sortDirection: this.currentRequest?.sortDirection,
    };
    this.sendDataRequest(dataRequest);
  }

  public sortNegotitations(sort: Sort) {
    if (this.processSortEvent) {
      this.resetFilter.next(FilterPaginatorResetMode.PAGINATOR);
      if (this.currentRequest) {
        this.currentRequest.pageNumber = 1;
        this.currentRequest.sortedBy = sort.active;
        (this.currentRequest.slot = this.negotiationsService.getSlot()),
          (this.currentRequest.sortDirection = sort.direction.toUpperCase());
        this.sendDataRequest(this.currentRequest);
      }
    } else {
      this.processSortEvent = true;
    }
  }

  toggleSelection(negotiation) {
    // TODO: check this and do it right
    if (this.isNegotiationSelected(negotiation)) {
      const selected = this.selection.selected.filter((x) => x.negotiationId !== negotiation.negotiationId);
      this.selection.clear();
      this.selection.select(...selected);
    } else {
      this.selection.select(negotiation);
    }
  }

  assignNegotiationsToSlotClick() {
    if (this.selection.selected.length === 0) {
      this.dialog.open(AlertComponent, {
        data: 'You must select at least one negotiation',
      });
    } else {
      this.dialog
        .open(ConfirmDialogComponent, {
          data: { message: `Are you sure you want to assign the selected negotiations to the slot?` },
        })
        .afterClosed()
        .subscribe((confirm: boolean) => {
          if (confirm) {
            const negotiationIds = this.selection.selected.map((negotiation) => negotiation.negotiationId);
            this.slotsService.assignNegotiationsToSlot(negotiationIds, this.selectedTargetSlot).subscribe(
              (res) => {
                console.log(res);
              },
              (err) => {
                let errorMsg = null;
                try {
                  errorMsg = JSON.parse(err.error).title;
                } catch (er) {
                  errorMsg = err.message ? err.message : err;
                }
                this.dialog.open(AlertComponent, {
                  data: errorMsg,
                });
              }
            );
          }
        });
    }
  }

  assignNegotiationsToSlot($event) {
    if (this.selection.selected.length === 0) {
      this.selection.select($event.item.data);
    }
    this.assignNegotiationsToSlotClick();
  }

  slotDisplayFn(nt: any): string {
    // eslint-disable-next-line
    const el = this.slotElements.find((se) => se.key == nt);
    this.selectedTargetSlot = el?.key;
    return el ? (el.isDataInjestor ? 'DI-' : ' ') + el.title : '';
  }

  private sendDataRequest(dataRequest: PaginatedNegotiationsDataRequest) {
    this.currentRequest = dataRequest;
    this.uiService.toggleGlobalSpinner(true);
    this.refresh.emit(dataRequest);
  }
}
