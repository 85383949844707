import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CompanyService } from 'app/services/company.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-company-create',
  templateUrl: './company-enterprise-create.component.html',
  styleUrls: ['./company-enterprise-create.component.css']
})
export class CompanyEnterpriseCreateComponent implements OnInit {

  public companyCreateForm: FormGroup;
  public userPhone: string;

  constructor(
    public dialogRef: MatDialogRef<CompanyEnterpriseCreateComponent>,
    public companyService: CompanyService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    public uiService: UIService
  ) {
    this.buildCompanyCreateForm();
  }

  buildCompanyCreateForm() {
    this.companyCreateForm = this.formBuilder.group({
      name: new FormControl(null, [Validators.required]),
      userFullname: new FormControl(null, [Validators.required]),
      userCountry: new FormControl(null, [Validators.required]),
      userEmail: new FormControl(null, [Validators.required, Validators.email]),
    });
  }

  ngOnInit(): void {
  }

  public onPhoneChanged(value: string): void {
    this.userPhone = value;
  }

  onCreateEnterpriseCompany() {
    this.uiService.toggleGlobalSpinner(true);
    this.companyService.createCompanyEnterpriseSubscription(
      this.companyCreateForm.value.name,
      this.companyCreateForm.value.userFullname,
      this.companyCreateForm.value.userCountry,
      this.companyCreateForm.value.userEmail,
      this.userPhone
    ).subscribe(() => {
      this.uiService.toggleGlobalSpinner(false);
      this.snackBar.open('Company created successfully.', null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
      this.dialogRef.close(true);
    }, err => {
      this.uiService.toggleGlobalSpinner(false);
      this.snackBar.open(err, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000,
      });
      this.dialogRef.close(true);
    });
  }

  onCancel() {
    this.dialogRef.close(false);
  }

}
