import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditWhatsappTemplateComponent } from 'app/components/deal/studio/edit-whatsapp-template/edit-whatsapp-template.component';
import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from 'app/model/flow-action-call';
import { FlowActionWhatsapp, FlowWhatsappParameter } from 'app/model/flow-action-whatsapp';
import { WhatsappOptionTemplate } from 'app/model/whatsapp-option-template';
import { WhatsappOptionTemplateContent } from 'app/model/whatsapp-option-template-content';
import { WhatsappParameterOption } from 'app/model/whatsapp-parameter-option';
import { FlowsService } from 'app/services/flows.service';
import { StudioService } from 'app/services/studio.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-whatsapp-details',
  templateUrl: './whatsapp-details.component.html',
  styleUrls: ['./whatsapp-details.component.css'],
})
export class WhatsappDetailsComponent implements OnChanges {
  @Input() node: FlowActionWhatsapp;

  whatsappTemplates: WhatsappOptionTemplate[];
  public mapTemplatesWithNonApproavedLangs = new Map<string, number>();
  properties = [];
  props = [];

  selectedTemplateContent: WhatsappOptionTemplateContent;
  flowActionMessages: ActionMessage[] = [];
  selectedTemplate: WhatsappOptionTemplate = null;
  templateCode = null;
  templateContent: string = null;
  description: string;
  whatsappParameterOptions: WhatsappParameterOption[];

  hasChanged = false;

  constructor(
    public dialog: MatDialog,
    private uiService: UIService,
    private studioService: StudioService,
    private flowService: FlowsService,
    private snackBar: MatSnackBar
  ) {
    this.whatsappParameterOptions = this.studioService.getWhatsappParameterOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.node.firstChange ||
      // eslint-disable-next-line
      changes.node.currentValue['flowActionId'] !== changes.node.previousValue['flowActionId']
    ) {
      this.init();
    }
  }

  loadProperties() {
    if (this.node.flowActionWhatsAppParameters) {
      this.node.flowActionWhatsAppParameters.forEach((wpPar) => {
        const index = this.properties.findIndex((prop) => prop === wpPar.propertyId);
        if (index !== -1) {
          this.props[index] = wpPar.propertyValue;
        }
      });
    }
  }

  onLanguageSelect() {
    const templateContent = this.selectedTemplate.whatsappOptionTemplateContents.find(
      (cont) => cont.language === this.selectedTemplateContent.language
    );
    if (templateContent != null) {
      this.selectedTemplateContent = templateContent;
      this.templateContent = this.selectedTemplateContent.content;
    } else {
      let defaultLangTemplateContent = this.selectedTemplate.whatsappOptionTemplateContents.find(
        (cont) => cont.language === 'en-US'
      );
      if (defaultLangTemplateContent == null) {
        defaultLangTemplateContent = this.selectedTemplate.whatsappOptionTemplateContents[0];
      }
      this.selectedTemplateContent = defaultLangTemplateContent;
      this.templateContent = defaultLangTemplateContent.content;
    }
  }

  setNewMessages(template: WhatsappOptionTemplate) {
    this.flowActionMessages = [];
    template?.whatsappOptionTemplateContents.forEach((templateMsg) => {
      const actionMessage = this.flowActionMessages.find((msg) => msg.language === templateMsg.language);
      if (actionMessage) {
        actionMessage.content = templateMsg.content;
      } else {
        this.flowActionMessages.push(new ActionMessage(templateMsg.language, templateMsg.content, ''));
      }
    });
    this.properties = this.createParametersArray(template.variablesQty);
    this.props = new Array(this.properties.length);
  }

  onTemplateSelect(isChange) {
    if (isChange) {
      this.hasChanged = true;
    }
    const template = this.whatsappTemplates.find((templ) => templ.templateCode === this.templateCode);
    if (template) {
      this.selectedTemplate = template;
      this.onLanguageSelect();
      this.setNewMessages(this.selectedTemplate);
      this.loadProperties();
    }
  }

  onEditWhatsappTemplate() {
    const templateDialog = this.dialog.open(EditWhatsappTemplateComponent, {
      panelClass: 'custom-dialog-container',
      width: '600px',
    });
    templateDialog.afterClosed().subscribe((templateCreated: boolean) => {
      if (templateCreated == true) {
        this.init();
        this.hasChanged = true;
      }
    });
  }

  saveMessages() {
    this.uiService.toggleGlobalSpinner(true);
    const promises: Array<Promise<any>> = [];
    const action = FlowAction.cloneFlowAction(this.node);
    action.description = this.description;
    const saveActionPromise = this.flowService
      .updatedAction(action)
      .toPromise()
      .then(() => {
        this.node.description = this.description;
      })
      .catch((err) => {
        this.description = this.node.description;
        throw err;
      });
    promises.push(saveActionPromise);

    const messages = [];
    this.flowActionMessages.forEach((msg) =>
      messages.push({
        flowActionId: this.node.flowActionId,
        language: msg.language,
        content: msg.content,
        flowActionMessageId: msg.flowActionMessageId,
      })
    );

    const saveMessagesPromise = this.flowService
      .saveMessages(
        messages as [{ flowActionId: number; language: string; content: string; flowActionMessageId: string }]
      )
      .catch((err) => {
        this.uiService.showMessage(err?.message ? err.message : err);
      });
    promises.push(saveMessagesPromise);

    const templateProperties = new FlowWhatsappParameter(this.node.flowActionId, 'template', this.templateCode);
    const whatsappProps = [];
    this.properties.forEach((prop, index) => {
      whatsappProps.push(new FlowWhatsappParameter(this.node.flowActionId, this.properties[index], this.props[index]));
    });
    const savePropertiesPromise = this.flowService
      .saveWhatsappProperties([templateProperties, ...whatsappProps])
      .then(() => {
        this.node.flowActionWhatsAppParameters = [templateProperties, ...whatsappProps];
      });
    promises.push(savePropertiesPromise);
    Promise.all(promises)
      .then(() => {
        this.hasChanged = false;
      })
      .catch((err) => {
        this.snackBar.open(err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
          panelClass: 'snack-error',
        });
      })
      .finally(() => {
        this.uiService.toggleGlobalSpinner(false);
      });
  }

  setChanged() {
    this.hasChanged = true;
  }

  private init() {
    this.description = this.node.description;
    this.studioService.getWhatsappTemplates().subscribe((templates) => {
      templates.forEach((templ) => {
        const count = templ.whatsappOptionTemplateContents.reduce((accumulator, content) => {
          if (content.status == 0) {
            return accumulator + 1;
          }
          return accumulator;
        }, 0);
        this.mapTemplatesWithNonApproavedLangs.set(templ.templateCode, count);
      });
      this.whatsappTemplates = templates;
      const templateCodeParam = this.node.flowActionWhatsAppParameters.find((templ) => templ.propertyId === 'template');
      if (templateCodeParam) {
        this.selectedTemplate = this.whatsappTemplates.find((t) => t.templateCode == templateCodeParam.propertyValue);
      } else {
        this.selectedTemplate = this.whatsappTemplates[0];
      }
      if (this.selectedTemplate) {
        this.templateCode = this.selectedTemplate?.templateCode;
        this.selectedTemplateContent =
          this.selectedTemplate?.whatsappOptionTemplateContents &&
          this.selectedTemplate?.whatsappOptionTemplateContents.length
            ? this.selectedTemplate?.whatsappOptionTemplateContents[0]
            : undefined;
        this.properties = this.createParametersArray(
          this.selectedTemplate?.variablesQty ? this.selectedTemplate?.variablesQty : 0
        );
        this.props = new Array(this.properties.length);
        this.loadFlowActionMessages();
        this.onTemplateSelect(false);
      } else {
        this.loadDefaultEmptyTemplate();
      }
    });
  }

  /**
   * Generates an empty template that shall not be used for any messages.
   * It's a placeholder for cases in which there are no templates available of any kind.
   */
  private loadDefaultEmptyTemplate() {
    this.templateCode = 'default';
    this.selectedTemplate = new WhatsappOptionTemplate(this.templateCode, 'Empty Template');
    this.selectedTemplateContent = new WhatsappOptionTemplateContent('en-US', 'Empty template content', 0);
    this.selectedTemplate.whatsappOptionTemplateContents = [this.selectedTemplateContent];
    this.properties = this.createParametersArray(0);
    this.props = new Array(this.properties.length);
    this.flowActionMessages = [];
  }

  private loadFlowActionMessages() {
    this.flowActionMessages = [];
    this.node.flowActionMessages.forEach((msg) => {
      this.flowActionMessages.push(ActionMessage.cloneMsg(msg));
    });
    if (this.flowActionMessages?.length > 0) {
      this.selectedTemplateContent = this.selectedTemplate.whatsappOptionTemplateContents.find(
        (tc) => tc.language == this.flowActionMessages[0].language
      );
    } else {
      this.setNewMessages(this.whatsappTemplates[0]);
    }
  }

  private createParametersArray(numberOfParameters: number): Array<string> {
    return [...Array(numberOfParameters).keys()].map((x) => '{{' + (x + 1) + '}}');
  }
}
