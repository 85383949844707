import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { MonitorSlot } from 'app/model/monitor-slot';
import { PaginatedSlotsDataRequest } from 'app/model/slot';
import { TableAction } from 'app/model/table-action';
import { MonitorService } from 'app/services/monitor.service';
import { StorageService } from 'app/services/persistence.service';
import { SlotsService } from 'app/services/slots.service';
import { BehaviorSubject, Subject } from 'rxjs';

@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css'],
})
export class MonitorComponent implements OnInit, OnDestroy {
  menuOptions: TableAction[] = [
    {
      title: 'Cancel Selected',
      action: this.cancelSelectedSlots.bind(this),
      icon: 'cancel',
    },
    {
      title: 'Delete Selected',
      action: this.deleteSelectedSlots.bind(this),
      icon: 'delete_forever',
    },
  ];

  filterOptions: TableAction[] = [
    {
      title: 'All',
      action: this.filterAllSlots.bind(this),
    },
    {
      title: 'Owned',
      action: this.filterMySlots.bind(this),
    },
  ];

  selectedSlots: MonitorSlot[];
  defaultFilter = 'All';
  public ownedOnly = false;
  public reloadGrid: BehaviorSubject<PaginatedSlotsDataRequest> = new BehaviorSubject(null);

  private readonly UPDATE_TIME = 20 * 1000;
  private readonly STORAGE_MINE_ONLY = 'monitor.mineOnly';
  private refreshAllSlotsTimeoutId: NodeJS.Timeout;

  constructor(
    private monitorService: MonitorService,
    private slotsService: SlotsService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private storageService: StorageService
  ) { }

  ngOnInit(): void {
    this.ownedOnly = this.storageService.getSessionItem(this.STORAGE_MINE_ONLY) ?? false;
    if (this.ownedOnly) {
      this.filterMySlots();
      this.defaultFilter = 'Owned';
    } else {
      this.filterAllSlots();
      this.defaultFilter = 'All';
    }
  }

  ngOnDestroy(): void {
    this.storageService.setSessionItem(this.STORAGE_MINE_ONLY, this.ownedOnly);
    this.clearSlotsRefreshTimer();
  }

  setSelected(slots: MonitorSlot[]) {
    this.selectedSlots = slots;
  }

  private cancelSelectedSlots() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: 'Do you want to cancel the selected channels?' },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.slotsService.cancelSlots(this.selectedSlots.map((slot) => slot.slotId)).subscribe(
            () => {
              this.reloadGrid.next({ ownedOnly: this.ownedOnly});
              this.snackBar.open(`Channels Canceled successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorResponse) => {
              this.snackBar.open('Error: ' + errorResponse.error, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  private deleteSelectedSlots() {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Channels?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.slotsService.deleteSlots(this.selectedSlots.map((slot) => slot.slotId)).subscribe(
            () => {
              this.reloadGrid.next({ ownedOnly: this.ownedOnly});
              this.snackBar.open(`Channels deleted successfully.`, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorResponse) => {
              this.snackBar.open('Error: ' + errorResponse.error, null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  private clearSlotsRefreshTimer() {
    if (this.refreshAllSlotsTimeoutId != null) {
      clearTimeout(this.refreshAllSlotsTimeoutId);
    }
  }

  private setSlotsRefreshTimer(resetPageNumber = true) {
    this.clearSlotsRefreshTimer();
    this.reloadGrid.next({ ownedOnly: this.ownedOnly, resetPageNumber: resetPageNumber});
    this.refreshAllSlotsTimeoutId = setTimeout(() => {
      this.setSlotsRefreshTimer(false);
    }, this.UPDATE_TIME);
  }

  private filterAllSlots() {
    this.defaultFilter = 'All';
    this.ownedOnly = false;
    this.setSlotsRefreshTimer();
  }

  private filterMySlots() {
    this.defaultFilter = 'Owned';
    this.ownedOnly = true;
    this.setSlotsRefreshTimer();
  }

}
