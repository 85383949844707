import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-bulk-edit',
  templateUrl: './list-bulk-edit.component.html',
  styleUrls: ['./list-bulk-edit.component.css'],
})
export class ListBulkEditComponent implements OnInit {
  @Input() public hideButtons = true;
  @Input() options = [];
  @Input() filterOptions = [];
  @Input() defaultFilter = null;

  filterSelected = null;
  selectedFilter = null;

  constructor() {}

  ngOnInit(): void {
    this.selectedFilter = this.defaultFilter ?? this.filterOptions[0].title;
  }

  selectFilter(event) {
    const option = this.filterOptions.find((opt) => opt.title === event.value);
    option?.action();
  }
}
