import { CodeSample } from "./code-sample";
import { ScrollableElement } from "./scrollable-element";

export class DocumentationEntry  extends ScrollableElement {
    public title: string;
    public content: string;
    public samples: Array<CodeSample>;
    
    constructor() {
        super();
    }
}
