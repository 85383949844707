import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatButtonModule } from '@angular/material/button';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSelectModule } from '@angular/material/select';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NavUserWidgetComponent } from './components/nav-user-widget/nav-user-widget.component';
import { UIService } from '../core/services/ui.service';
import { ErrorComponent } from '../core/components/error/error.component';
import { NavBarComponent } from './components/nav-bar/nav-bar.component';
import { LoadingComponent } from './components/loading/loading.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GlobalSpinnerComponent } from './components/global-spinner/global-spinner.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { ListBulkEditComponent } from './components/list-bulk-edit/list-bulk-edit.component';
import { UserService } from 'src/core/services/user.service';
import { AuthHttpInterceptor } from '@auth0/auth0-angular';
import { MatPaginatorModule } from '@angular/material/paginator';
import { FilterPaginatorComponent } from './components/filter-paginator/filter-paginator.component';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { CommonModule } from '@angular/common';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { HtmlEditorComponent } from './components/html-editor/html-editor.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatTableModule } from '@angular/material/table';
import { NavCompanyWidgetComponent } from './components/nav-company-widget/nav-company-widget.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    SpinnerComponent,
    NavUserWidgetComponent,
    ErrorComponent,
    NavBarComponent,
    LoadingComponent,
    GlobalSpinnerComponent,
    PaginatorComponent,
    ListBulkEditComponent,
    FilterPaginatorComponent,
    HtmlEditorComponent,
    NavCompanyWidgetComponent,
  ],
  imports: [
    FormsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    NgbModule,
    FontAwesomeModule,
    MatMenuModule,
    CKEditorModule,
    DragDropModule,
    MatTableModule,
    RouterModule
  ],
  providers: [
    UIService,
    UserService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },
    {
      provide: Window,
      useValue: window,
    },
  ],
  exports: [
    BrowserModule,
    ErrorComponent,
    FormsModule,
    GlobalSpinnerComponent,
    ListBulkEditComponent,
    LoadingComponent,
    NavBarComponent,
    NavUserWidgetComponent,
    PaginatorComponent,
    SpinnerComponent,
    FilterPaginatorComponent,
    HtmlEditorComponent,
  ],
})
export class CoreModule {}
