import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SmsMessageApiService {
  baseURL: string;

  constructor(private http: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/TwilioSmsConversations';
  }

  public getSmsMessages(contactId: number) {
    return this.http.get(`${this.baseURL}/${contactId}`);
  }

  public postSmsMessage(message: any) {
    return this.http.post(`${this.baseURL}/sendsmsmessage`, message);
  }

  public markMessagesAsRead(contactId: number) {
    return this.http.post(`${this.baseURL}/markconversationasread`, { contactId: contactId });
  }

}
