import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ContactsGroup } from 'app/model/contacts-group';
import { ContactsGroupService } from 'app/services/contacts-group.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'add-contacts-group',
  templateUrl: './add-contacts-group.component.html',
  styleUrls: ['./add-contacts-group.component.css'],
})
export class AddContactsGroupComponent implements OnInit {
  contactsGroupCreateForm;
  contactsGroup: ContactsGroup;

  constructor(
    public contactsGroupService: ContactsGroupService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<AddContactsGroupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ContactsGroup,
    private snackBar: MatSnackBar,
    private uiService: UIService
  ) {
    if (data) {
      this.contactsGroup = new ContactsGroup(data);
    } else {
      this.contactsGroup = new ContactsGroup(null);
    }
    this.buildContactsGroupCreateForm(this.contactsGroup);
  }

  ngOnInit(): void {}

  buildContactsGroupCreateForm(contactsGroup: ContactsGroup) {
    this.contactsGroupCreateForm = this.formBuilder.group({
      contactId: contactsGroup.id,
      companyId: contactsGroup.companyId,
      name: contactsGroup.name,
      id: contactsGroup.id,
    });
  }

  onSubmit(): void {
    const contactsGroup = new ContactsGroup(this.contactsGroupCreateForm.value);
    this.uiService.toggleGlobalSpinner(true);
    if (!contactsGroup.id) {
      this.saveContactsGroup(contactsGroup);
    } else {
      this.updateContactsGroup(contactsGroup);
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  saveContactsGroup(contactsGroup: ContactsGroup) {
    this.contactsGroupService.saveContactsGroup(contactsGroup).subscribe(
      (contactId) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('New Contacts Group added successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.dialogRef.close(true);
      },
      (error) => {
        const errorMsg = 'Error: ' + error;
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error - ' + errorMsg, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }

  updateContactsGroup(contactsGroup: ContactsGroup) {
    this.contactsGroupService.updateContactsGroup(contactsGroup).subscribe(
      (id) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Contacts Group updated successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
        this.dialogRef.close(true);
      },
      (error) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + error, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000,
        });
      }
    );
  }
}
