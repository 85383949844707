<div class="admin-content">
    <app-admin-analytics></app-admin-analytics>
    <app-review-whatsapp-template-contents-list></app-review-whatsapp-template-contents-list>
    <app-rebrandly-links></app-rebrandly-links>
    <div class="table-container">
        <div class="table-title">Companies</div>
        <button class="sgnd-btn sgnd-btn-ok" (click)="onEditCompanies()">Manage</button>
    </div>
    <div class="table-container">
        <div class="table-title">Company API credentials</div>
        <button class="sgnd-btn sgnd-btn-ok" (click)="onEditCompanyApiCredentials()">Manage</button>
    </div>
</div>