<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>Description</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field class="full-width-textarea">
    <input matInput [(ngModel)]="description" />
  </mat-form-field>
</mat-expansion-panel>
<mat-expansion-panel class="color-picker-expansion-panel">
  <mat-expansion-panel-header>
    <mat-panel-title>Aesthetics</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="aesthetics-contents">
    <mat-form-label>Primary Color: </mat-form-label>
    <input
      [(colorPicker)]="primaryColor"
      [(ngModel)]="primaryColor"
      [style.background]="primaryColor"
      [cpCancelButton]="true"
      [cpOKButton]="true"
      [cpOKButtonClass]="'btn btn-primary btn-xs'"
    />
    <mat-form-label>Secondary Color: </mat-form-label>
    <input
      [(colorPicker)]="secondaryColor"
      [(ngModel)]="secondaryColor"
      [style.background]="secondaryColor"
      [cpCancelButton]="true"
      [cpOKButton]="true"
      [cpOKButtonClass]="'btn btn-primary btn-xs'"
    />
    <mat-form-label>Company Logo: </mat-form-label>
    <input [(ngModel)]="logo" />
    <mat-form-label>Redirect URL: </mat-form-label>
    <input [(ngModel)]="redirectUrl" />
  </div>
</mat-expansion-panel>

<!--
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-checkbox labelPosition="before" (click)="clickCheckbox($event)" [(ngModel)]="use2FA">Use 2FA</mat-checkbox>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-label>Language</mat-form-label>
  <mat-select [(value)]="language" (selectionChange)="onLanguageSelect()">
    <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
  </mat-select>
  <mat-form-label>Text</mat-form-label>
  <app-insertable-textarea #textField [disabled]="!use2FA" [(value)]="selectedMessage.content">
  </app-insertable-textarea>
</mat-expansion-panel>
-->
<mat-expansion-panel>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-checkbox labelPosition="before" (click)="clickCheckbox($event)" [(ngModel)]="use2FA">Use 2FA</mat-checkbox>
    </mat-panel-title>
  </mat-expansion-panel-header>
  <mat-accordion>
    <div class="fields-container">
      <mat-form-field>
        <mat-form-label>Language</mat-form-label>
        <mat-select [(value)]="language" (selectionChange)="onLanguageSelect()">
          <mat-option *ngFor="let lang of languages" [value]="lang">{{ lang }}</mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Subject</mat-panel-title>
      </mat-expansion-panel-header>
      <app-insertable-textarea #subjectField [(value)]="subjectMessage.content" *ngIf="subjectMessage"></app-insertable-textarea>
    </mat-expansion-panel>

    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title>Body</mat-panel-title>
      </mat-expansion-panel-header>
      <app-html-editor #bodyField [(value)]="bodyMessage.content" *ngIf="bodyMessage"></app-html-editor>
    </mat-expansion-panel>
  </mat-accordion>
</mat-expansion-panel>

<mat-expansion-panel hideToggle>
  <mat-expansion-panel-header>
    <mat-panel-title>
      <mat-checkbox labelPosition="before" (click)="clickCheckbox($event)" [(ngModel)]="enableFiles"
        >Enable files
      </mat-checkbox>
    </mat-panel-title>
  </mat-expansion-panel-header>
</mat-expansion-panel>

<app-negotiation-variables [customVars]="customVars" (selectOption)="insertText($event)"></app-negotiation-variables>
<div class="buttons-container">
  <button class="sgnd-btn sgnd-btn-ok" (click)="saveMessages()">Save</button>
</div>
