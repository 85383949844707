import { Injectable } from '@angular/core';
import { Company } from 'app/model/company';
import { Observable } from 'rxjs';
import { CompanyApiService } from './company-api.service';

@Injectable()
export class CompanyService {

  constructor(private apiService: CompanyApiService) { }

  public createCompanyEnterpriseSubscription(name: string, userFullName: string, userCountry: string, userEmail: string, userPhone: string): Observable<any> {
    return this.apiService.createCompanyEnterpriseSubscription(name, userFullName, userCountry, userEmail, userPhone);
  }

  public getAll(): Observable<Company[]> {
    return this.apiService.getAll();
  }

  public getAllExpandedWithSubscriptions(): Observable<Company[]> {
    return this.apiService.getAllExpandedWithSubscriptions();
  }

}
