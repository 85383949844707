import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { ConfirmDialogComponent } from 'app/components/common/confirm-dialog/confirm-dialog.component';
import { SmsMessagesModalComponent } from 'app/components/common/sms-messages-modal/sms-messages-modal.component';
import { WhatsappMessagesModalComponent } from 'app/components/common/whatsapp-messages-modal/whatsapp-messages-modal.component';
import downloadFileResponse from 'app/functions/fileFunctions';
import { ContactStatus } from 'app/model/contact-status';
import { MonitorNegotiation } from 'app/model/monitor-negotiation';
import { MonitorNegotiationTotals } from 'app/model/monitor-negotiation-totals';
import { MonitorSlot } from 'app/model/monitor-slot';
import { Negotiation } from 'app/model/negotiation';
import { NegotiationStatus } from 'app/model/negotiation-status';
import { SignatureStatus } from 'app/model/signature-status';
import { MonitorService } from 'app/services/monitor.service';
import { NegotiationsService } from 'app/services/negotiations.service';
import { UIService } from 'core/services/ui.service';
import { merge, of, Subject } from 'rxjs';
import { catchError, map, startWith, switchMap } from 'rxjs/operators';
import { FlowRestarterComponent } from '../../negotiations/flows/flow-restarter/flow-restarter.component';
import { MonitorChatComponent } from '../monitor-chat/monitor-chat.component';

@Component({
  selector: 'app-monitor-slot-detail',
  templateUrl: './monitor-slot-detail.component.html',
  styleUrls: ['./monitor-slot-detail.component.css'],
})
export class MonitorSlotDetailComponent implements AfterViewInit, OnInit, OnDestroy {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  // Pagination, filtering and sorting
  public pageSize = 10;
  public pageSizeOptions = [10, 15, 20];
  public filterString: string;
  public triggerDataReloadSubject: Subject<void> = new Subject();
  public dataSource: MonitorNegotiation[] = [];
  public totalCountLength = 0;

  public showSignatureStatus = false;
  public negotiationStatus = NegotiationStatus;
  public slot: MonitorSlot;

  public displayedColumns = [
    'CompletedCheck',
    'Favorite',
    'ContactName',
    'Description',
    'DocumentsQty',
    'OptionsQty',
    'LastModified',
    'ContactStatus',
    'Actions',
    'UnreadMsgQty',
    'UnreadSmsQty',
    'UnreadWpQty',
  ];

  private ownedOnly: boolean;
  // eslint-disable-next-line
  private readonly UPDATE_NEGOTIATIONS_INTERVAL_MS = 30 * 1000;
  private loadNegotiationsDataInterval: NodeJS.Timer;

  constructor(
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<MonitorSlotDetailComponent>,
    private negotiationsService: NegotiationsService,
    private monitorService: MonitorService,
    private uiService: UIService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.slot = this.data.slot;
    this.ownedOnly = this.data.ownedOnly;
    this.loadNegotiationsDataInterval = setInterval(
      () => this.triggerDataReloadSubject.next(),
      this.UPDATE_NEGOTIATIONS_INTERVAL_MS
    );
  }

  ngAfterViewInit() {
    // If the user changes the sort order, reset back to the first page.
    this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));
    merge(this.sort.sortChange, this.paginator.page, this.triggerDataReloadSubject)
      .pipe(
        startWith({}),
        switchMap(() =>
          this.monitorService
            .getNegotiationsList(
              this.slot.slotId,
              this.ownedOnly,
              this.pageSize,
              this.paginator.pageIndex + 1,
              this.sort.active,
              this.sort.direction.toUpperCase(),
              this.filterString
            )
            .pipe(catchError(() => of(null)))
        ),
        map((monitorNegotiationTotals: MonitorNegotiationTotals) => {
          if (monitorNegotiationTotals === null) {
            return [];
          }
          // Only refresh the result length if there is new data.
          this.totalCountLength = monitorNegotiationTotals.totalCount;
          this.showSignatureStatusColumn(monitorNegotiationTotals.monitorNegotiations);
          return monitorNegotiationTotals.monitorNegotiations;
        })
      )
      .subscribe((data) => (this.dataSource = data));
  }

  onFilterChange(event) {
    this.triggerDataReloadSubject.next();
  }

  onClearFilter() {
    this.filterString = '';
    this.triggerDataReloadSubject.next();
  }

  onChangePageSizeOrIndex(event: PageEvent) {
    this.pageSize = event.pageSize;
    this.triggerDataReloadSubject.next();
  }

  showMessages(negotiation) {
    this.dialog.open(MonitorChatComponent, {
      data: negotiation,
      height: '97vh',
    });
  }

  showSmsMessages(negotiation) {
    // Show 0 in all the unread messages of negotiations where the Contact is the same.
    this.dataSource
      .filter((neg) => neg.contactId == negotiation.contactId)
      .forEach((fneg) => {
        fneg.unreadSmsQty = 0;
      });
    this.dialog.open(SmsMessagesModalComponent, { data: negotiation, height: '97vh' });
  }

  showWhatsappMessages(negotiation) {
    // Show 0 in all the unread messages of negotiations where the Contact is the same.
    this.dataSource
      .filter((neg) => neg.contactId == negotiation.contactId)
      .forEach((fneg) => {
        fneg.unreadWpQty = 0;
      });
    this.dialog.open(WhatsappMessagesModalComponent, { data: negotiation, height: '97vh' });
  }

  intContactStatusToStr(n: number): string {
    return this.capitalize(ContactStatus[n]);
  }

  intSignatureStatusToStr(n: number): string {
    return this.capitalize(SignatureStatus[n]);
  }

  onRestart(negotiation: Negotiation) {
    this.dialog.open(FlowRestarterComponent, this.getDialogConfig({ negotiation, onRefresh: () => {} }));
  }

  showRestart(negotiation: Negotiation) {
    const restartableStatuses = [
      NegotiationStatus.CANCELED,
      NegotiationStatus.COMPLETED,
      NegotiationStatus.ERROR,
      NegotiationStatus.IN_PROGRESS,
    ];
    return restartableStatuses.includes(negotiation.status);
  }

  onClose() {
    this.dialogRef.close();
  }

  shouldGlow(negotiation: Negotiation, statusLetter: string): boolean {
    let result = false;
    const contactGlowStats = [
      NegotiationStatus.READY,
      NegotiationStatus.IN_PROGRESS,
      NegotiationStatus.READY_TO_CONTINUE,
    ];
    const signGlowStats = [NegotiationStatus.COMPLETED];
    switch (statusLetter) {
      case 'S': {
        result =
          contactGlowStats.includes(negotiation.status) && negotiation.contactStatus === ContactStatus.IRRELEVANT;
        break;
      }
      case 'T': {
        result = contactGlowStats.includes(negotiation.status) && negotiation.contactStatus === ContactStatus.STARTED;
        break;
      }
      case 'V': {
        result = contactGlowStats.includes(negotiation.status) && negotiation.contactStatus === ContactStatus.TAKEN;
        break;
      }
      case 'B': {
        result = contactGlowStats.includes(negotiation.status) && negotiation.contactStatus === ContactStatus.VIEWED;
        break;
      }
      case 'R': {
        result =
          signGlowStats.includes(negotiation.status) &&
          negotiation.contactStatus === ContactStatus.BIDDEN &&
          negotiation.signatureStatus === SignatureStatus.IRRELEVANT;
        break;
      }
      case 'C': {
        result =
          signGlowStats.includes(negotiation.status) &&
          negotiation.contactStatus === ContactStatus.BIDDEN &&
          negotiation.signatureStatus === SignatureStatus.SENT;
        break;
      }
    }
    return result;
  }

  public showErrorTooltip(negotiation: Negotiation) {
    var error = this.contactStatusText(negotiation, 'S');
    return error && error.includes('Failed') ? error : 'Something failed while executing this negotiation';
  }

  public contactStatusText(negotiation: Negotiation, statusLetter: string) {
    let result = '';
    switch (statusLetter) {
      case 'S': {
        let contactedMsg = negotiation.succeededContactActions
          ? 'Contact requests were successfully sent through the following means: ' +
            negotiation.succeededContactActions +
            '.'
          : '';
        contactedMsg = negotiation.failedContactActions
          ? contactedMsg + ' - Failed to send contact requests through: ' + negotiation.failedContactActions
          : contactedMsg;
        result =
          negotiation.contactStatus >= 1
            ? contactedMsg
              ? contactedMsg
              : 'This Negotiation has been started'
            : 'This Negotiation has not yet been started';
        break;
      }
      case 'T': {
        result =
          negotiation.callDuration && negotiation.contactStatus >= 2
            ? 'The Contact was called by phone and listened to the call for ' + negotiation.callDuration + ' seconds'
            : 'Contact has not been called by phone';
        break;
      }
      case 'V': {
        result =
          negotiation.contactStatus >= 3
            ? 'Contact has clicked on the CCP link'
            : 'Contact has not yet viewed the CCP link';
        break;
      }
      case 'B': {
        result =
          negotiation.contactStatus == 4
            ? 'Contact has sent a response to this Negotiation'
            : 'Contact has not yet sent a response';
        break;
      }
      case 'R': {
        result =
          negotiation.signatureStatus >= 1
            ? 'The Contact was given a Contract to be signed'
            : 'No Contract has been sent';
        break;
      }
      case 'C': {
        result =
          negotiation.signatureStatus == 2
            ? 'The Contact has received and signed the Contract'
            : 'No Contract has been signed';
        break;
      }
    }
    return result;
  }

  public onCancelNegotiation(negotiation: MonitorNegotiation) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Are you sure that you want to cancel this Negotiation?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.negotiationsService.cancelNegotiation(negotiation.negotiationId).subscribe(
            (response) => {
              this.triggerDataReloadSubject.next();
              this.snackBar.open('Negotiation canceled successfully.', null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            },
            (errorMsg) => {
              this.snackBar.open('Error while canceling the Negotiation.', null, {
                horizontalPosition: 'center',
                verticalPosition: 'bottom',
                duration: 3000,
              });
            }
          );
        }
      });
  }

  public onRemoveFromSlot(negotiation: MonitorNegotiation) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Are you sure that you want to remove this Negotiation from it's current Channel?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.negotiationsService.removeNegotiationFromSlot(negotiation.negotiationId).subscribe(() => {
            this.triggerDataReloadSubject.next();
            this.snackBar.open(`Negotiation removed successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  public onDeleteNegotiation(negotiation: MonitorNegotiation) {
    this.dialog
      .open(ConfirmDialogComponent, {
        data: { message: `Do you want to delete the selected Negotiation?` },
      })
      .afterClosed()
      .subscribe((confirm: boolean) => {
        if (confirm) {
          this.negotiationsService.deleteNegotiation(negotiation.negotiationId).subscribe(() => {
            this.triggerDataReloadSubject.next();
            this.snackBar.open(`Negotiation deleted successfully.`, null, {
              horizontalPosition: 'center',
              verticalPosition: 'bottom',
              duration: 3000,
            });
          });
        }
      });
  }

  public onToggleFavorite(negotiation: MonitorNegotiation) {
    this.negotiationsService.setFavorite(negotiation.negotiationId, !negotiation.favorite).subscribe();
    negotiation.favorite = !negotiation.favorite;
  }

  public ngOnDestroy() {
    clearInterval(this.loadNegotiationsDataInterval);
  }

  showCheck(negotiation: any): boolean {
    const isFullyCompleted =
      negotiation.status === NegotiationStatus.COMPLETED &&
      ((negotiation.requiresSignature && negotiation.signatureStatus === 2) || !negotiation.requiresSignature);
    return isFullyCompleted;
  }

  showError(negotiation: any, status: string = null): boolean {
    if (status) {
      switch (status) {
        case 'S':
          return negotiation.status === NegotiationStatus.ERROR || !!negotiation.failedContactActions;
      }
    }
    return negotiation.status === NegotiationStatus.ERROR;
  }

  showExpired(negotiation: any): boolean {
    return negotiation.status === NegotiationStatus.EXPIRED;
  }

  exportNegotiations() {
    this.monitorService
      .exportSlotDetails(this.slot.slotName, this.slot.slotId)
      .toPromise()
      .then((res) => {
        const data: Blob = new Blob([res], {
          type: 'text/csv;charset=utf-8',
        });
        downloadFileResponse(data, `${this.slot.slotName}.csv`);
      })
      .catch((err) => this.uiService.showMessage(err?.message));
  }

  private showSignatureStatusColumn(monitorNegotiations: MonitorNegotiation[]) {
    const reqSignatureNegotiation = monitorNegotiations.find((negotiation) => negotiation.requiresSignature);
    const indexSignatureStatus = this.displayedColumns.indexOf('SignatureStatus');
    if (reqSignatureNegotiation) {
      this.showSignatureStatus = true;
      if (indexSignatureStatus == -1) {
        // Is the column already added?
        this.displayedColumns.splice(6, 0, 'SignatureStatus'); // Add the column after Contact Status.
      }
    } else {
      if (indexSignatureStatus > -1) {
        // If found, clean the column.
        this.showSignatureStatus = false;
        this.displayedColumns.splice(indexSignatureStatus, 1); // Remove the column.
      }
    }
  }

  private getDialogConfig(data: { negotiation: Negotiation; onRefresh: any }) {
    return {
      panelClass: 'custom-dialog-container',
      width: '40vw',
      maxWidth: '800px',
      minWidth: '400px',
      data,
    };
  }

  private capitalize(text: string, splitStr: string = '_'): string {
    return text && splitStr
      ? text
          .toLowerCase()
          .split(splitStr)
          .map((x) => x.charAt(0).toUpperCase() + x.substring(1, x.length))
          .join(' ')
      : text;
  }
}
