import { DocumentationEntry } from "./documentation-entry";
import { ScrollableElement } from "./scrollable-element";

export class DocumentedFeature extends ScrollableElement {
    public name: string;
    public entries: Array<DocumentationEntry>;

    constructor() {
        super();
    }
}