import { Component } from '@angular/core';
import { UIService } from 'src/core/services/ui.service';
import { CompanySettingsService } from './components/settings';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  public title = 'SigneDeal';
  public showNavBars = true;

  constructor(private uiService: UIService, private companySettingsService: CompanySettingsService) {
    this.uiService.showNavBars.subscribe((value) => {
      this.showNavBars = value;
    });
    window.addEventListener('resize', this.resizeListenerFunction);
    setTimeout(() => {
      window.location.reload();
    }, 60 * 60 * 1000);
  }

  resizeListenerFunction() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    const vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }
}
