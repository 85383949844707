export default async function downloadFileResponse(
  data,
  attachmentName: string
) {
  const url = window.URL.createObjectURL(data);
  const a = document.createElement('a');
  document.body.appendChild(a);
  a.setAttribute('style', 'display: none');
  a.href = url;
  a.download = attachmentName;
  a.click();
  window.URL.revokeObjectURL(url);
  a.remove();
}
