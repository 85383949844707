import { FlowAction } from 'app/model/flow-action';
import { ActionMessage } from './flow-action-call';

export class FlowActionEmail extends FlowAction {
  flowActionMessages: ActionMessage[] = [];
  constructor(
    flowActionId: number,
    flowId: number,
    flowType: number,
    canvasPositionX: number,
    canvasPositionY: number,
    actionType: number,
    children = [],
    enable: boolean,
    description: string,
    messages: ActionMessage[] = [],
    continueOnFailure = true
  ) {
    super(
      flowActionId,
      flowId,
      flowType,
      canvasPositionX,
      canvasPositionY,
      actionType,
      children,
      enable,
      description,
      continueOnFailure
    );
    this.flowActionMessages = messages;
  }

  getFlowAction() {
    const flowAction = {
      flowActionMessages: this.flowActionMessages,
    };
    return flowAction;
  }
}
