<div>
  <div class="add-documents-row">
    <label class="add-documents-label"> Documents </label>
    <mat-icon class="add-documents-icon" aria-label="Add document" (click)="onAdd()">add_circle_outline</mat-icon>
  </div>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
    
    <ng-container matColumnDef="title">
      <th mat-header-cell *matHeaderCellDef>Title</th>
      <td mat-cell *matCellDef="let element">{{ element.title }}</td>
    </ng-container>
    
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef>Description</th>
      <td mat-cell *matCellDef="let element">{{ element.description }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef>Actions</th>
      <td mat-cell *matCellDef="let element">
        <mat-icon aria-label="Edit Negotiation" class="hoverable" (click)="onEdit(element)" matTooltip="Edit">edit</mat-icon>
        <mat-icon aria-label="Delete Negotiation" class="hoverable"  (click)="onDelete(element)" matTooltip="Delete">delete_outline</mat-icon>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>