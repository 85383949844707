import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FlowsService } from 'app/services/flows.service';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-clone-flow',
  templateUrl: './clone-flow.component.html',
  styleUrls: ['./clone-flow.component.css']
})
export class CloneFlowComponent implements OnInit {

  cloneFlowForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<CloneFlowComponent>,
    public flowsService: FlowsService,
    private formBuilder: FormBuilder,
    private snackBar: MatSnackBar,
    private uiService: UIService,
    @Inject(MAT_DIALOG_DATA) public data: { flowId: number }
  ) {
    this.buildFlowCloneForm(this.data.flowId);
  }

  ngOnInit(): void {
  }

  buildFlowCloneForm(flowId: number) {
    this.cloneFlowForm = this.formBuilder.group({
      flowId: flowId,
      flowName: ''
    });
  }

  onSubmit(): void {
    this.uiService.toggleGlobalSpinner(true);
    this.flowsService.cloneFlow(this.cloneFlowForm.value.flowId, this.cloneFlowForm.value.flowName).subscribe((flowId) => {
      this.flowsService.reloadFlowsList().subscribe(() => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Flow created successfully.', null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000
        });
        this.dialogRef.close();

      }, (err) => {
        this.uiService.toggleGlobalSpinner(false);
        this.snackBar.open('Error: ' + err, null, {
          horizontalPosition: 'center',
          verticalPosition: 'bottom',
          duration: 3000
        });
      });
    }, (error) => {
      this.uiService.toggleGlobalSpinner(false);
      this.snackBar.open('Error: ' + error, null, {
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 3000
      });
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

}
