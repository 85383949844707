import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { LateralListSection } from 'app/model/lateral-list-section';
import { TableAction } from 'app/model/table-action';
import { UIService } from 'core/services/ui.service';

@Component({
  selector: 'app-lateral-table-actions',
  templateUrl: './lateral-table-actions.component.html',
  styleUrls: ['./lateral-table-actions.component.css'],
  animations: [
    trigger('collapseInOut', [
      state(
        'close',
        style({
          width: '5.5rem',
        })
      ),
      state(
        'open',
        style({
          width: '20rem',
        })
      ),
      transition('close => open', animate('400ms ease-in-out')),
      transition('open => close', animate('400ms ease-in-out')),
    ]),
  ],
})
export class LateralTableActionsComponent implements OnInit {
  @Input()
  options: TableAction[];
  @Input()
  hasElementsList = false;
  @Input()
  elementsListSection: LateralListSection;

  isCollapsed = false;
  collapseStr = 'open';
  selected = null;

  constructor(private uiService: UIService) {
    uiService.showCollapsedLateralBar.subscribe(value => {
      this.isCollapsed = value;
      this.collapseStr = value ? 'close' : 'open';
    });
  }

  ngOnInit(): void {}

  toggleCollapse() {
    this.uiService.toggleLateralBarCollapsed();
  }

  selectItem(key, action) {
    this.selected = key;
    action();
  }
}
