<form [formGroup]="flowForm" (ngSubmit)="onSubmit()">
    <div class="title">{{flowForm.value.flowId ? 'Update' : 'Create'}} Flow</div>
    <div class="form-fields-container">
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Name</mat-label>
            <input matInput formControlName="name" type="text" placeholder="Name" autocomplete="off" required>
        </mat-form-field>
        <mat-form-field appearance="fill" class="regular-field">
            <mat-label>Negotiation Type</mat-label>
            <mat-select name="type" formControlName="type" placeholder="Negotiation Type" [disabled]="data.isEdit" required>
                <mat-option *ngFor="let option of negotiationTypesOptions" [value]="option.key">
                    {{capitalize(option.value.toLowerCase())}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div class="buttons-container">
            <button class="sgnd-btn sgnd-btn-ok" [disabled]="!flowForm.valid" type="submit">Save</button>
            <button class="sgnd-btn sgnd-btn-cancel" type="button" (click)="onCancel()">Cancel</button>
        </div>
    </div>
</form>