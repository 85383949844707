<div class="filter-paginator-container">
    <mat-form-field class="filter">
        <input #filterInput matInput [placeholder]="placeholder" [(ngModel)]="filterString" autocomplete="off">
        <mat-icon matSuffix *ngIf="filterString?.length > 0" (click)="onClearFilter()">close</mat-icon>
    </mat-form-field>
    <ng-content></ng-content>
    <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        [pageIndex]="pageIndex"
        (page)="onPageEvent($event)"
        aria-label="Select page">
    </mat-paginator>
</div>