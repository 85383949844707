import { BaseDocument } from './base-document';

export class DemoDocument extends BaseDocument {
    constructor(document: Partial<DemoDocument>) {
        super();
        this.id = document.id;
        this.description = document.description;
        this.externalId = document.externalId;
    }
}


export class DemoDocumentDto {
    public id: number;
    public title: string;
    public description: string;
    public externalId: string;

    constructor(document: DemoDocument) {
        this.id = document?.id;
        this.description = document?.description;
        this.externalId = document?.externalId;
    }
}
