import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Negotiation, NegotiationDto, PaginatedNegotiationsDataRequest } from 'app/model/negotiation';
import { NegotiationFlowAction } from 'app/model/negotiation-flow-action';
import { PaginatedDataResponse } from 'app/model/pagination';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ServiceUtils } from './service-utils';

@Injectable()
export class NegotiationsApiService {
  private baseURL: string;
  private negotiationActionsUrl: string;

  constructor(private httpClient: HttpClient) {
    this.baseURL = environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/Negotiations';
    this.negotiationActionsUrl =
      environment.apiUrl + (environment.apiUrl.endsWith('/') ? '' : '/') + 'api/NegotiationFlowActions';
  }

  getNegotiationsList(slot?: number, acceptCachedResponse: boolean = false): Observable<NegotiationDto[]> {
    const options = ServiceUtils.getRequestOptions(acceptCachedResponse);
    return this.httpClient.get<NegotiationDto[]>(`${this.baseURL}/slot/${slot || 0}`, options);
  }

  getMyNegotiationsList(slot?: number, acceptCachedResponse: boolean = false): Observable<NegotiationDto[]> {
    const options = ServiceUtils.getRequestOptions(acceptCachedResponse);
    return this.httpClient.get<NegotiationDto[]>(`${this.baseURL}/slot/${slot || 0}/mine`, options);
  }

  getPaginatedNegotiationsList(
    filter: PaginatedNegotiationsDataRequest,
    acceptCachedResponse: boolean = false
  ): Observable<PaginatedDataResponse<NegotiationDto>> {
    const options = ServiceUtils.getRequestOptions(acceptCachedResponse);
    return this.httpClient.post<PaginatedDataResponse<NegotiationDto>>(this.baseURL + '/Search', filter, options);
  }

  saveNegotiation(negotiation: Negotiation): Observable<any> {
    const negotiationDto = new NegotiationDto(negotiation);
    return this.httpClient.post<Negotiation>(this.baseURL, negotiationDto);
  }

  updateNegotiation(negotiation: Negotiation): Observable<any> {
    const negotiationDto = new NegotiationDto(negotiation);
    return this.httpClient.put<Negotiation>(this.baseURL + '/' + negotiation.negotiationId, negotiationDto);
  }

  deleteNegotiations(negotiationIds: Array<number>): Observable<any> {
    const options = {
      headers: new HttpHeaders({
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
      }),
      body: negotiationIds,
    };
    return this.httpClient.delete<any>(this.baseURL, options);
  }

  startNegotiation(negotiationId: number, flowId: number): Observable<any> {
    const url = `${this.baseURL}/start/${negotiationId}/${flowId}`;
    return this.httpClient.post(url, null);
  }

  cancelNegotiation(negotiationId: number): Observable<any> {
    const url = `${this.baseURL}/cancel/${negotiationId}`;
    return this.httpClient.post(url, null);
  }

  pauseNegotiation(negotiationId: number): Observable<any> {
    const url = `${this.baseURL}/pause/${negotiationId}`;
    return this.httpClient.post(url, null);
  }

  restartNegotiation(negotiationId: number): Observable<any> {
    const url = `${this.baseURL}/restart/${negotiationId}`;
    return this.httpClient.post(url, null);
  }

  removeNegotiationFromSlot(negotiationId: number): Observable<any> {
    const url = `${this.baseURL}/remove/${negotiationId}`;
    return this.httpClient.post(url, null);
  }

  removeManyNegotiations(negotiationIds: Array<number>): Observable<any> {
    const url = `${this.baseURL}/remove/many`;
    return this.httpClient.post(url, { negotiationIds });
  }

  addNegotiationToSlot(negotiationId: number, slotId: number, flowId: number): Observable<any> {
    const url = `${this.baseURL}/add/${negotiationId}/${slotId}/${flowId}`;
    return this.httpClient.post(url, null);
  }

  postUploadNegotiations(docType, formData): Observable<any> {
    return this.httpClient.post<File>(this.baseURL + `/upload/${docType}`, formData);
  }

  getNegotiationFlowActions(flowId) {
    return this.httpClient.get<NegotiationFlowAction[]>(`${this.negotiationActionsUrl}/flow/${flowId}`);
  }

  getFlowActionsByNegotiation(negotiationId) {
    return this.httpClient.get<NegotiationFlowAction[]>(`${this.negotiationActionsUrl}/negotiation/${negotiationId}`);
  }

  setFavorite(negotiationId: number, favorite: boolean): Observable<any> {
    const url = `${this.baseURL}/favorite/${negotiationId}/${favorite}`;
    return this.httpClient.post(url, null);
  }
}
